export const objWorkStation = {
  preparePlot: 'การเตรียมพื้นที่', //StationPreparePlot
  prepareSeeding: 'การจัดการกล้าพันธุ์ในโรงเรือน', //stationPlantGreenhouse
  planting: 'การเพาะปลูกไผ่ลงในพื้นที่เกษตรแปลงใหญ่', //StationBambooPlant
  nutrientManagement: 'การจัดการธาตุอาหารพืช', //StationNutrientManagement
  waterManagement: 'การจัดการน้ำ', //StationWaterManagement
  plantProtection: 'การอารักขาพืช', //StationProtectionPlant
  growthTracking: 'การติดตามการเจริญเติบโต', //StationGrowthTracking
  harvesting: 'การบริหารจัดการการเก็บเกี่ยวในเกษตรแปลงใหญ่', //StationHarvesting
  postHarvesting: 'การบริหารจัดการหลังการเก็บเกี่ยว', //StationPostHarvest
}
