import React from 'react'
import { View } from 'react-native'
import Text from 'components/Text'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useLocalization } from 'contexts/LocalizationContext'
import theme from '../../theme'
import request from 'utils/request'

interface Props {
  farmReq?: any[] | undefined
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

const FarmRequest = ({ farmReq, setRefresh }: Props) => {
  const { t } = useLocalization()
  const onAccept = async (reqId: string) => {
    setRefresh(true)
    try {
      await request.post(`/user/me/farm/requests/${reqId}`)
      setRefresh(false)
    } catch (e) {
      console.log(JSON.stringify(e.response, null, 2))
    }
  }

  const onReject = async (reqId: string) => {
    setRefresh(true)
    try {
      await request.delete(`/user/me/farm/requests/${reqId}`)
      setRefresh(false)
    } catch (e) {
      console.log(JSON.stringify(e.response, null, 2))
    }
  }
  return (
    <>
      {typeof farmReq === 'object' &&
        farmReq?.map((item: any, index: number) => {
          const ownerName = item.farm.farmMembers
            .filter((item: { role: string }) => item.role === 'owner')
            .map((item: { user: { firstName: string; lastName: string } }) => {
              return `${item.user.firstName} ${item.user.lastName}`
            })[0]
          return (
            <View key={index}>
              <View
                style={{
                  paddingVertical: 10,
                }}
              >
                <Text sub1>
                  {t('screens.HomeScreen.request', {
                    owner: ownerName,
                    farm: item.farm.name,
                  })}
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <TouchableOpacity
                  style={{
                    backgroundColor: theme.color.white,
                    borderWidth: 1,
                    borderColor: theme.color.error,
                    padding: 5,
                    alignSelf: 'baseline',
                    alignItems: 'center',
                    borderRadius: 50,
                    width: 100,
                    marginHorizontal: 5,
                  }}
                  onPress={() => onReject(item.id)}
                >
                  <Text color="error" body1 medium>
                    ปฏิเสธ
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    backgroundColor: theme.color.primary1,
                    borderWidth: 1,
                    borderColor: theme.color.primary1,
                    padding: 5,
                    alignSelf: 'baseline',
                    alignItems: 'center',
                    borderRadius: 50,
                    width: 100,
                    marginHorizontal: 5,
                  }}
                  onPress={() => onAccept(item.id)}
                >
                  <Text color="white" body1 medium>
                    ยอมรับ
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          )
        })}
    </>
  )
}
export default FarmRequest
