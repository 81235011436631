import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import LoginScreen from 'screens/LoginScreen'
import SignupScreen from 'screens/SignupScreen'
import ForgetPasswordScreen from 'screens/ForgetPasswordScreen'
import FillNewPasswordScreen from 'screens/FillNewPasswordScreen'
import OtpScreen from 'screens/ForgetPasswordScreen/otp'
import FinalFillNewPasswordScreen from 'screens/FillNewPasswordScreen/finalFillNewPassword'

export type StackParamList = {
  Login: undefined
  Signup: undefined
  Role: undefined
  CreateProfile: undefined
  ForgetPassword: undefined
  FillNewPassword: undefined
  OtpScreen: undefined
  FinalFillNewPasswordScreen: undefined
}

const Stack = createStackNavigator<StackParamList>()

const AuthStack = (): JSX.Element => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Login" component={LoginScreen} />
      <Stack.Screen name="Signup" component={SignupScreen} />
      <Stack.Screen name="ForgetPassword" component={ForgetPasswordScreen} />
      <Stack.Screen name="FillNewPassword" component={FillNewPasswordScreen} />
      <Stack.Screen
        name="FinalFillNewPassword"
        component={FinalFillNewPasswordScreen}
      />
      <Stack.Screen name="OtpScreen" component={OtpScreen} />
    </Stack.Navigator>
  )
}

export default AuthStack
