export const tabData = [
  {
    title: 'screens.DetailFarmScreen.module',
    value: 'module',
  },
  {
    title: 'screens.DetailFarmScreen.progress',
    value: 'progress',
  },
  { title: 'screens.DetailFarmScreen.completed', value: 'completed' },
]
