/* eslint-disable @typescript-eslint/no-explicit-any */
import { View } from 'react-native'
import React, { useEffect } from 'react'
import ImagePickerMulti from 'components/FormArray/ImagePickerMulti'
import { InputText } from 'components/FormArray/InputText'
import { useFieldArray, useFormContext } from 'react-hook-form'
import PlusIconButton from 'components/PlusIconButton'
import Text from 'components/Text'
import DeleteButton from 'components/DeleteButton'
import { PestRecordType } from 'utils/constants'

export default function FormInsectList(): JSX.Element {
  const { control, watch } = useFormContext()
  const [isDisabled, setIsDisabled] = React.useState(false)
  const { append, remove, fields } = useFieldArray({
    control,
    name: 'formInsectList',
  })
  useEffect(() => {
    if (fields.length < 1) {
      append({ type: PestRecordType.INSECT })
    }
  }, [])

  const watchBudget = watch(`formInsectList[0].quantityPercentage`)
  const watchImage = watch('formInsectList[0].image')

  useEffect(() => {
    if (watchBudget !== undefined && watchImage) {
      setIsDisabled(true)
    }
  }, [watchBudget, watchImage])
  return (
    <View>
      {fields.map((field: any, i) => {
        return (
          <View key={field.id}>
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Text sub1>{`ภาพแมลง ชุดที่ ${i + 1}`}</Text>

              {i === 0 ? (
                <PlusIconButton
                  disabled={!isDisabled}
                  onPress={() => append({ type: PestRecordType.INSECT })}
                />
              ) : (
                <DeleteButton onPress={() => remove(i)} />
              )}
            </View>
            <ImagePickerMulti
              index={i}
              defaultValue={field.image}
              errorName="image"
              nameList="formInsectList"
              name={`formInsectList[${i}].image`}
            />

            <InputText
              index={i}
              errorName="quantityPercentage"
              nameList="formInsectList"
              name={`formInsectList[${i}].quantityPercentage`}
              unitLabelInside
              unitLabel={'%'}
              showCheckBox
              label="จำนวนประมาณการ"
              colorUnit="black85"
            />
          </View>
        )
      })}
    </View>
  )
}
