import React from 'react'
import { View, StyleSheet, Platform, ViewStyle } from 'react-native'
import Text from 'components/Text'
import theme from 'theme/index'

export type Props = {
  value: {
    formula: string
    amount: number
  }[]
  style?: ViewStyle
}

const FertilizerFormula = ({ value, style }: Props): JSX.Element => {
  return (
    <View style={style}>
      <View style={styles.formulaHead}>
        <View style={styles.formula}>
          <Text semiBold sub1 color="secondary2">
            ใช้ปุ๋ยสูตร
          </Text>
        </View>
        <View style={styles.formula}>
          <Text semiBold sub1 color="secondary2">
            จำนวน
          </Text>
        </View>
      </View>
      <View style={styles.viewBorder} />
      {value.map((item, index) => {
        return (
          <>
            <View style={styles.formulaHead} key={item.formula}>
              <View style={styles.formula}>
                <Text color="black85" sub1>
                  {item.formula}
                </Text>
              </View>
              <View style={styles.formula}>
                <Text color="black85" sub1>
                  {`${item.amount} กิโลกรัม`}
                </Text>
              </View>
            </View>
            <View style={styles.viewBorder} />
          </>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  viewHead: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 12,
    paddingTop: 12,
  },
  viewLeft: {
    flex: 0.6,
  },
  viewRight: { flex: 0.4 },
  viewBorder: { borderBottomWidth: 1, borderColor: theme.color.black6 },
  formulaHead: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 8,
    paddingTop: 8,
  },
  formula: { flex: 0.35 },
  Input: {
    ...Platform.select({
      web: {
        paddingVertical: 6,
        height: 40,
      },
      default: {
        paddingVertical: 6,
        flex: 1,
      },
    }),
  },
})

export default FertilizerFormula
