import {
  ActivityIndicator,
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import React, { useEffect, useState } from 'react'
import Scanner from 'assets/images/icons/Scanner.png'
import { BarCodeScanner as BarCode } from 'expo-barcode-scanner'
import Modal from 'components/Modal/Modal'
import Text from 'components/Text'
import Close from 'assets/images/icons/Close.png'
import theme from 'theme/index'
interface Props {
  children?: React.ReactNode
  onChange?: (value: string) => void
}
const BarcodeScanner = ({ children, onChange }: Props) => {
  const [hasPermission, setHasPermission] = useState(false)
  const [scanned, setScanned] = useState(false)
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getBarCodeScannerPermissions = async () => {
      const { status } = await BarCode.requestPermissionsAsync()
      setHasPermission(status === 'granted')
      if (status === 'denied') {
        setVisible(false)
      }
    }

    getBarCodeScannerPermissions()
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleBarCodeScanned = ({ data }: { data: any }) => {
    try {
      setLoading(true)
      setScanned(true)
      if (data) {
        onChange?.(data)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }
  if (!hasPermission) {
    return <Text>กรุณาเปิดการใช้งานกล้อง</Text>
  }

  return (
    <>
      <Modal
        visible={visible}
        disableOnBlur
        onRequestClose={() => {
          setVisible(false)
        }}
      >
        <View
          style={{
            paddingBottom: 16,
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <Text title1 medium>
            แสกนบาร์โค้ด
          </Text>
          <TouchableOpacity
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
            }}
            onPress={() => setVisible(false)}
          >
            <Image
              source={Close}
              style={{
                width: 24,
                height: 24,
              }}
            />
          </TouchableOpacity>
        </View>
        <View
          style={{
            height: 350,
            minWidth: 300,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {loading ? (
            <ActivityIndicator size={'large'} color={theme.color.primary1} />
          ) : (
            <BarCode
              onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
              style={StyleSheet.absoluteFillObject}
              barCodeTypes={[BarCode.Constants.BarCodeType.qr]}
            />
          )}
        </View>
      </Modal>

      <TouchableOpacity onPress={() => setVisible(true)}>
        {children ? (
          children
        ) : (
          <Image
            source={Scanner}
            style={{
              width: 24,
              height: 24,
            }}
          />
        )}
      </TouchableOpacity>
    </>
  )
}

export default BarcodeScanner
