import React, { useState } from 'react'
import * as yup from 'yup'
import { useLocalization } from 'contexts/LocalizationContext'
import { useAuth } from 'contexts/AuthContext'
import Container from 'components/Container'
import Content from 'components/Content'
import Text from 'components/Text'
import { Form, Input, SubmitButton } from 'components/Form'
import Background from 'components/Background'
import { Image, TouchableOpacity, View } from 'react-native'
import Logo from 'assets/images/icons/logo.png'
import theme from 'theme/index'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import { useDimension } from 'contexts/DimensionContext'
import ModalAlert from 'components/ModalAlert'
import logoKu from 'assets/logo-ku.png'
import logoPmuc from 'assets/logo-pmuc.png'

const defaultValues = {
  email: '',
  password: '',
}

interface Props {
  navigation: StackNavigationHelpers
}
const LoginScreen = ({ navigation }: Props): JSX.Element => {
  const { t } = useLocalization()
  const { width: widthLogo } = useDimension()
  const [modalLogin, setModalLogin] = useState(false)
  const width = widthLogo * 0.3
  const height = (580 / 542) * width
  const {
    authContext: { login },
  } = useAuth()

  const schema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .lowercase()
      .email(t('screens.LoginScreen.errors.emailInvalid'))
      .required(t('screens.LoginScreen.errors.emailRequired')),
    password: yup
      .string()
      .required(t('screens.LoginScreen.errors.passwordRequired')),
  })
  const onSubmit = async (data: { email: string; password: string }) => {
    try {
      await login(data)
      // alert(t('screens.LoginScreen.alert.success'))
    } catch (e) {
      console.log(e)
      setModalLogin(true)
    }
  }

  return (
    <Container>
      <Background typeBackground="BgWithCircle" />
      <Content>
        <View
          style={{
            paddingTop: 60,
            width: widthLogo,
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 16,
          }}
        >
          <Image
            resizeMode="contain"
            source={Logo}
            style={{ width, height, marginRight: 32 }}
          />
        </View>
        <Text color="white" h1 center style={{ marginBottom: 16 }}>
          {t('screens.LoginScreen.title')}
        </Text>
        <Form schema={schema} defaultValues={defaultValues}>
          <Input
            testID="login.input.email"
            name="email"
            colorLabel="white"
            selectionColor={'white'}
            themeWhite
            label={t('screens.LoginScreen.form.email')}
            style={{ color: theme.color.white, paddingVertical: 8 }}
            keyboardType="email-address"
            autoCapitalize="none"
          />
          <Input
            colorLabel="white"
            selectionColor={'white'}
            themeWhite
            testID="login.input.password"
            name="password"
            style={{ color: theme.color.white, paddingVertical: 8 }}
            label={t('screens.LoginScreen.form.password')}
            // placeholder={t('screens.LoginScreen.form.password')}
            secureTextEntry
          />
          <SubmitButton
            style={{ marginTop: 16 }}
            testID="loginButton"
            title={t('screens.LoginScreen.button')}
            onSubmit={onSubmit}
            color="secondary2"
          />
        </Form>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 16,
          }}
        >
          <TouchableOpacity onPress={() => navigation.navigate('Signup')}>
            <Text color="white">{t('screens.LoginScreen.signUp')}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate('ForgetPassword')}
          >
            <Text color="white">{t('screens.LoginScreen.forgetPassword')}</Text>
          </TouchableOpacity>
        </View>

        {/* <Divider lineColor="white">
          <Text color="white" style={{ width: 40 }} center>
            {t('screens.LoginScreen.or')}
          </Text>
        </Divider>
        <View
          style={{
            marginTop: 16,
            flexDirection: 'row',
            alignSelf: 'center',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: 140,
          }}
        >
          {staticButton.map(({ icon }, idx) => {
            return (
              <TouchableOpacity key={idx}>
                <Image source={icon} style={{ width: 36, height: 36 }} />
              </TouchableOpacity>
            )
          })}
        </View> */}
        <View
          style={{
            width: '100%',
            marginTop: 48,
            marginBottom: 48,
            flexDirection: 'row',
            alignSelf: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            //width: 140,
          }}
        >
          <Image source={logoKu} style={{ width: width, height: height }} />
          <Image
            source={logoPmuc}
            style={{ width: width * 2, height: height }}
          />
        </View>
      </Content>

      <ModalAlert
        type="error"
        visible={modalLogin}
        setVisibile={setModalLogin}
        textDesc="อีเมลหรือรหัสผ่านผิด"
        onRequestClose={() => setModalLogin(false)}
      />
    </Container>
  )
}

export default LoginScreen
