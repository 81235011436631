import React, { useState } from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Text from 'components/Text'
import Background from 'components/Background'
import { StyleSheet, View, ActivityIndicator } from 'react-native'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import Card from 'components/Card'
import Button from 'components/Button'
import { useFocusEffect } from '@react-navigation/native'
import { useLocalization } from 'contexts/LocalizationContext'
import { RouteProp } from '@react-navigation/native'
import AddPlus from 'assets/images/icons/AddPlus.png'
import MonthCalendar from './MonthCalendar'
import AddPlanAndOtModal from './AddPlanAndOtModal'
import AddWorkSchedule from './AddWorkSchedule'
import { fetcher } from 'utils/request'
import useSWR from 'swr'
import ProgressLine from 'components/ProgressLine'
import theme from 'theme/index'
import dayjs from 'dayjs'
import { useAuth } from 'contexts/AuthContext'
import ModalViewDetail from './ModalViewDetail'

export type Props = {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<
    {
      params: {
        PoId?: string
        PjId?: string
        id: string
        type: 'report' | 'schedule'
      }
    },
    'params'
  >
}

const WorkAssignmentReportScreen = ({
  route,
  navigation,
}: Props): JSX.Element => {
  const { t } = useLocalization()
  const { id, type } = route.params
  const [focusDate, setFocusDate] = useState(new Date())
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1)
  const [reportVisible, setReportVisible] = useState(false)
  const [scheduleVisible, setScheduleVisible] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [selectTaskId, setSelectTaskId] = useState<string | null>(null)
  const {
    state: { userRole },
  } = useAuth()

  const { data: workAssignment, mutate: mutateWorkAssignment } = useSWR(
    () =>
      type === 'report'
        ? [`/workAssignment/${id}`, refresh]
        : [`/user/me/workAssignment/${id}`],
    fetcher
  )
  useFocusEffect(
    React.useCallback(() => {
      mutate(() =>
        type === 'report'
          ? [`/workAssignment/${id}`, refresh]
          : [`/user/me/workAssignment/${id}`]
      )
    }, [mutateWorkAssignment, refresh])
  )
  const { data: assignmentTask, mutate } = useSWR(
    [
      `/workAssignment/${id}/task?limit=${32}&page=1&year=${currentYear}&month=${currentMonth}`,
      refresh,
    ],
    fetcher
  )
  useFocusEffect(
    React.useCallback(() => {
      mutate(
        `/workAssignment/${id}/task?limit=${32}&page=1&year=${currentYear}&month=${currentMonth}`
      )
    }, [mutate, refresh])
  )

  if (id && !workAssignment) {
    return (
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={
            type === 'report'
              ? t('screens.WorkAssignmentReportScreen.title')
              : t('screens.WorkScheduleScreen.title')
          }
          alignTitle="flex-start"
        />
        <View style={{ marginTop: 70, marginBottom: 20 }}></View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size="large" color={theme.color.primary1} />
          <Text center style={{ marginTop: 16 }} h2 color="primary2">
            Loading...
          </Text>
        </View>
      </Container>
    )
  }

  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <Header
        showBack
        title={
          type === 'report'
            ? t('screens.WorkAssignmentReportScreen.title')
            : t('screens.WorkScheduleScreen.title')
        }
        alignTitle="flex-start"
      />
      {type === 'report' ? (
        <View style={{ marginTop: 100 }} />
      ) : (
        <View style={styles.headerView}>
          <Card>
            <View style={styles.viewHead}>
              <Text sub1 medium>
                {t('screens.WorkScheduleScreen.progress')}
              </Text>
              <Text sub1 medium>
                {`${(
                  ((workAssignment?.actual + workAssignment?.ot) /
                    workAssignment?.quantity) *
                  100
                ).toFixed(2)}%`}
              </Text>
            </View>
            <ProgressLine
              value={`${(
                ((workAssignment?.actual + workAssignment?.ot) /
                  workAssignment?.quantity) *
                100
              ).toFixed(2)}%`}
              height={25}
              isLinearGradient
            />
          </Card>
        </View>
      )}
      <Content
        enabledScroll={true}
        style={{
          backgroundColor: '#fff',
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
        }}
      >
        <View
          style={{
            paddingVertical: 20,
          }}
        >
          {type === 'report' && (
            <View
              style={{
                paddingHorizontal: 16,
              }}
            >
              <View style={{ display: 'flex', flexDirection: 'column' }}>
                <Text color="black" sub1 medium>
                  {`${t('screens.WorkAssignmentScreen.poId')} : ${
                    workAssignment?.documentNumber
                  }`}
                </Text>
                <Text color="black" sub1 medium>
                  {`${t('screens.WorkAssignmentScreen.projectNumber')} : ${
                    workAssignment?.projectNumber
                  }`}
                </Text>
                <Text color="black" sub1 medium>
                  {`${t(
                    'screens.WorkAssignmentScreen.workAssignmentNumber'
                  )} : ${workAssignment?.workAssignmentNumber}`}
                </Text>
              </View>
              <View style={styles.flex}>
                <Text color="black" sub1 style={styles.title}>
                  วันที่
                </Text>
                <Text color="black" sub1>
                  {`${dayjs(workAssignment?.startDate).format(
                    'DD/MM/YYYY'
                  )} - ${dayjs(workAssignment?.endDate).format('DD/MM/YYYY')}`}
                </Text>
              </View>
              <View style={styles.flex}>
                <Text color="black" sub1 style={styles.title}>
                  Cap / Day
                </Text>
                <Text color="black" sub1>
                  {`${workAssignment?.capacityPerDay} ${t(
                    'screens.WorkAssignmentScreen.unit.rai'
                  )}`}
                </Text>
              </View>
              <View style={styles.flex}>
                <Text color="black" sub1 style={styles.title}>
                  Total
                </Text>
                <Text sub1>
                  {`${workAssignment?.quantity} ${t(
                    'screens.WorkAssignmentScreen.unit.rai'
                  )}`}
                </Text>
              </View>
              <View style={styles.flex}>
                <Text color="black" sub1 style={styles.title}>
                  Actual
                </Text>
                <Text color="success" sub1>
                  {`${workAssignment?.actual} ${t(
                    'screens.WorkAssignmentScreen.unit.rai'
                  )}`}
                </Text>
              </View>
              <View style={styles.flex}>
                <Text color="black" sub1 style={styles.title}>
                  Diff
                </Text>
                <Text color="error" sub1>
                  {`${workAssignment?.diff} ${t(
                    'screens.WorkAssignmentScreen.unit.rai'
                  )}`}
                </Text>
              </View>
              <View style={[styles.flex, { marginBottom: 24 }]}>
                <Text color="black" sub1 style={styles.title}>
                  OT
                </Text>
                <Text color="warning" sub1>
                  {`${workAssignment?.ot} ${t(
                    'screens.WorkAssignmentScreen.unit.rai'
                  )}`}
                </Text>
              </View>
            </View>
          )}
          <Card style={{ padding: 0, borderColor: '#C0C0C0', borderWidth: 1 }}>
            <MonthCalendar
              focusDate={focusDate}
              setFocusDate={setFocusDate}
              setCurrentMonth={setCurrentMonth}
              setCurrentYear={setCurrentYear}
              assignmentTask={assignmentTask?.data}
              setSelectTaskId={setSelectTaskId}
              setReportVisible={setReportVisible}
              setScheduleVisible={setScheduleVisible}
              type={type}
            />
          </Card>
          <View style={styles.calendarType}>
            <Text>P : Plan</Text>
            <View style={styles.gap} />
            <Text>A : Actual</Text>
          </View>
          {type === 'report' && (
            <View style={styles.footer}>
              <Button
                icon={AddPlus}
                title={`เบิกปัจจัยการผลิต`}
                secondary
                onPress={() => {
                  navigation.navigate('MeansOfProduction', {
                    id: id,
                  })
                }}
                style={{ marginBottom: 16 }}
              />
              <Button
                icon={AddPlus}
                title={
                  type === 'report'
                    ? 'เพิ่ม Plan & OT'
                    : t('screens.WorkScheduleScreen.save')
                }
                onPress={() => {
                  setReportVisible((prev) => !prev)
                }}
              />
            </View>
          )}
        </View>
      </Content>
      {userRole !== 'farmer' ? (
        <>
          <AddWorkSchedule
            visible={scheduleVisible}
            setRefresh={setRefresh}
            workAssignmentId={id}
            taskId={selectTaskId}
            onRequestClose={() => {
              setScheduleVisible((prev) => !prev)
              setSelectTaskId(null)
            }}
          />
          <AddPlanAndOtModal
            visible={reportVisible}
            setRefresh={setRefresh}
            workAssignmentId={id}
            taskId={selectTaskId}
            onRequestClose={() => {
              setReportVisible((prev) => !prev)
              setSelectTaskId(null)
            }}
          />
        </>
      ) : (
        <ModalViewDetail
          visible={scheduleVisible}
          focusDate={focusDate}
          taskId={selectTaskId}
          workAssignmentData={assignmentTask?.data}
          setVisible={setScheduleVisible}
          id={id}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
    </Container>
  )
}

const styles = StyleSheet.create({
  flex: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#EFEFEF',
    paddingVertical: 10,
  },
  title: {
    width: '35%',
  },
  calendarType: {
    marginRight: 16,
    marginTop: 5,
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-end',
  },
  gap: {
    width: 15,
  },
  footer: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  headerView: { width: '90%', marginTop: 20, marginBottom: 20 },
  viewHead: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 6,
  },
})

export default WorkAssignmentReportScreen
