import { View, ViewStyle } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ErrorMessage, Item, Label } from './components'
import styled from '@emotion/native'
import CheckBox from 'components/CheckBox'
import Text from 'components/Text'

interface Props {
  data: {
    value: string
    label: string
    key?: string
  }[]
  itemStyle?: ViewStyle
  label?: string
  name: string
  justify?: 'row' | 'column'
  defaultValue?: string[]
  colorLabel?:
    | 'primary1'
    | 'primary2'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
  errorInside?: boolean
  isGroup?: boolean
  enableReinitial?: boolean
}
interface ErrorBoxProps {
  errorInside: boolean
}
const ErrorBox = styled.View<ErrorBoxProps>`
  position: ${(props) => props.errorInside && 'absolute'};
  bottom: ${(props) => props.errorInside && '5px'};
  padding: ${(props) => props.errorInside && '8px'};
`
export default function CheckBoxForm({
  data = [],
  itemStyle = {},
  label,
  name,
  colorLabel = 'black',
  errorInside = false,
  isGroup = true,
  defaultValue = [],
  enableReinitial,
}: Props): JSX.Element {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext()

  const [checkBoxValue, setCheckBoxValue] = useState<string[]>(defaultValue)
  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue)
    }
  }, [enableReinitial, defaultValue])

  return (
    <Item style={{ marginBottom: 7, ...itemStyle }}>
      {label && (
        <Label input color={colorLabel}>
          {label}
        </Label>
      )}
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <Item style={itemStyle}>
              {data.map((item, idx) => {
                return (
                  <View
                    key={idx}
                    style={{
                      flexDirection: 'row',
                      marginVertical: 10,
                      alignItems: 'center',
                      paddingRight: 10,
                    }}
                  >
                    {isGroup ? (
                      <CheckBox
                        onChange={() => {
                          if (checkBoxValue.includes(item.value)) {
                            setCheckBoxValue((prev) =>
                              prev.filter((value) => item.value !== value)
                            )
                            onChange(
                              checkBoxValue.filter(
                                (value) => item.value !== value
                              )
                            )
                          } else {
                            setCheckBoxValue((prev) => [...prev, item.value])
                            onChange([...checkBoxValue, item.value])
                          }
                        }}
                        checkBoxColor={'black40'}
                        isChecked={checkBoxValue.includes(item.value)}
                        style={{
                          borderRadius: 4,
                        }}
                      />
                    ) : (
                      <CheckBox
                        onChange={() => {
                          onChange(item.value)
                        }}
                        checkBoxColor={'black40'}
                        isChecked={value === item.value}
                        style={{
                          borderRadius: 4,
                        }}
                      />
                    )}
                    <Text lineHeight="20px">{item.label}</Text>
                  </View>
                )
              })}
            </Item>
            {errors[name] && (
              <ErrorBox errorInside={errorInside}>
                <ErrorMessage error>{errors[name].message}</ErrorMessage>
              </ErrorBox>
            )}
          </>
        )}
        name={name}
      />
    </Item>
  )
}
