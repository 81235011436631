export const workStationOption = [
  {
    label: 'การเตรียมพื้นที่',
    value: 'preparePlot',
  },
  {
    label: 'การจัดการกล้าพันธุ์ในโรงเรือน',
    value: 'prepareSeeding',
  },
  {
    label: 'การเพาะปลูกไผ่ลงในพื้นที่เกษตรแปลงใหญ่',
    value: 'planting',
  },
  {
    label: 'การจัดการธาตุอาหารพืช',
    value: 'nutrientManagement',
  },
  {
    label: 'การจัดการน้ำ',
    value: 'waterManagement',
  },
  {
    label: 'การอารักขาพืช',
    value: 'plantProtection',
  },
  {
    label: 'การติดตามการเจริญเติบโต',
    value: 'growthTracking',
  },
  // {
  //   label: 'การส่งเสริม',
  //   value: 'extension',
  // },
  {
    label: 'การบริหารจัดการการเก็บเกี่ยวในเกษตรแปลงใหญ่',
    value: 'harvesting',
  },
  // {
  //   label: 'ปัจจัยการผลิต',
  //   value: 'productionFactor',
  // },
  {
    label: 'การบริหารจัดการหลังการเก็บเกี่ยว',
    value: 'postHarvesting',
  },
]
