/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Background from 'components/Background'
import { View, StyleSheet, Platform, ActivityIndicator } from 'react-native'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { Provider } from 'react-native-paper'
import Card from 'components/Card'
import { Form, SubmitButton, Input } from 'components/Form'
import * as yup from 'yup'
import { RouteProp } from '@react-navigation/native'
import request from 'utils/request'
import useSWR from 'swr'
import { fetcher } from '../../utils/request'
import Text from 'components/Text'
import theme from 'theme/index'
import { useLocalization } from 'contexts/LocalizationContext'

import ProductForm from './ProductForm'
import InputDate from 'components/Form/InputDate'
import dayjs from 'dayjs'
export type Props = {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<{ params: { PoId: string; title: string } }, 'params'>
}

type FormData = {
  itemCode: null
  detail: null
  quantity: number
  pricePerUnit: number
  totalPrice: number
  deliveryDate: string
}
type ArrayFormData = {
  orderDate: string
  documentNumber: null
  customer: null
  products: FormData[]
}

const PurchaseOrderDetailEditScreen = ({
  navigation,
  route,
}: Props): JSX.Element => {
  const { t } = useLocalization()
  const { PoId } = route.params

  const limit = 10
  const { data } = useSWR(
    () => (PoId ? [`/purchaseOrder/${PoId}?limit=${limit}&page=1`] : null),
    fetcher
  )
  const formDefaultValue = {
    orderDate: dayjs(data?.orderDate).format('YYYY-MM-DD'),
    documentNumber: data?.documentNumber || '',
    customer: data?.customer || '',
    products: data
      ? data.products
      : [
          {
            itemCode: '',
            detail: '',
            quantity: '',
            pricePerUnit: '',
            totalPrice: '',
          },
        ],
  }

  const schema = yup.object().shape({
    // orderDate: yup
    //   .string()
    //   .nullable()
    //   .required(t('screens.PurchaseOrderScreen.form.required.orderDate')),
    documentNumber: yup
      .string()
      .nullable()
      .required(t('screens.PurchaseOrderScreen.form.required.documentNumber')),
    customer: yup
      .string()
      .nullable()
      .required(t('screens.PurchaseOrderScreen.form.required.customer')),
    products: yup.array().of(
      yup.object().shape({
        itemCode: yup
          .string()
          .nullable()
          .required(t('screens.PurchaseOrderScreen.form.required.itemCode')),
        detail: yup
          .string()
          .nullable()
          .required(t('screens.PurchaseOrderScreen.form.required.detail')),
        quantity: yup
          .number()
          .typeError('กรุณากรอกจำนวนเป็นตัวเลข')
          .nullable()
          .required(t('screens.PurchaseOrderScreen.form.required.quantity')),
        pricePerUnit: yup
          .number()
          .typeError('กรุณากรอกราคาต่อหน่วยเป็นตัวเลข')
          .nullable()
          .required(
            t('screens.PurchaseOrderScreen.form.required.pricePerUnit')
          ),
        totalPrice: yup
          .number()
          .typeError('กรุณากรอกราคารวมเป็นตัวเลข')
          .nullable()
          .required(t('screens.PurchaseOrderScreen.form.required.totalPrice')),
        deliveryDate: yup
          .string()
          .nullable()
          .required(
            t('screens.PurchaseOrderScreen.form.required.deliveryDate')
          ),
      })
    ),
  })

  const onSubmit = async (data: ArrayFormData) => {
    if (PoId) {
      try {
        await request.patch(`/purchaseOrder/${PoId}`, {
          ...data,
          orderDate: dayjs(data.orderDate).toISOString(),
        })
        navigation.navigate('PurchaseOrder')
      } catch (e: any) {
        console.log(JSON.stringify(e.response, null, 2))
      }
    } else {
      try {
        await request.post(`/purchaseOrder`, data)
        navigation.navigate('PurchaseOrder')
      } catch (e: any) {
        console.log(JSON.stringify(e.response, null, 2))
      }
    }
  }

  if (PoId && !data) {
    return (
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={
            PoId
              ? t('screens.PurchaseOrderScreen.edit')
              : t('screens.PurchaseOrderScreen.create')
          }
          alignTitle="flex-start"
        />
        <View style={{ marginTop: 70, marginBottom: 20 }}></View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size="large" color={theme.color.primary1} />
          <Text center style={{ marginTop: 16 }} h2 color="primary2">
            Loading...
          </Text>
        </View>
      </Container>
    )
  }
  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={
            PoId
              ? t('screens.PurchaseOrderScreen.edit')
              : t('screens.PurchaseOrderScreen.create')
          }
          alignTitle="flex-start"
        />
        <View style={{ marginTop: 70, marginBottom: 20 }}></View>
        <Content noPadding enabledScroll={false}>
          <Card
            borderRadiusTop
            style={{
              alignSelf: 'flex-end',
              flex: 1,
              borderBottomStartRadius: 0,
              borderBottomEndRadius: 0,
              padding: 0,
              marginTop: 16,
            }}
          >
            <Content
              noPadding
              // enabledScroll={page === 1 ? true : false}
              style={{
                flex: 1,
              }}
            >
              <Form
                defaultValues={formDefaultValue}
                schema={schema}
                enableReinitial
                //key={key}
                style={{
                  paddingTop: 20,
                  paddingHorizontal: 16,
                  flex: 1,
                  justifyContent: 'space-between',
                }}
              >
                <InputDate
                  name="orderDate"
                  label={t('screens.PurchaseOrderScreen.form.orderDate')}
                  testID="date"
                  style={{ height: 42 }}
                  defaultValue={formDefaultValue?.orderDate}
                />
                <Input
                  name="documentNumber"
                  label={t('screens.PurchaseOrderScreen.form.documentNumber')}
                  style={styles.Input}
                />
                <Input
                  name="customer"
                  label={t('screens.PurchaseOrderScreen.form.customer')}
                  style={styles.Input}
                />
                <View
                  style={{
                    width: '100%',
                    marginVertical: 8,
                    borderColor: '#CDCDCD',
                    borderWidth: 0.7,
                  }}
                ></View>
                <ProductForm />

                <View style={{ marginTop: 56, marginBottom: 24 }}>
                  <SubmitButton
                    testID="form.createCompany"
                    title={t('Buttons.Save')}
                    onSubmit={onSubmit}
                  />
                </View>
              </Form>
            </Content>
          </Card>
        </Content>
      </Container>
    </Provider>
  )
}

const styles = StyleSheet.create({
  Input: {
    ...Platform.select({
      web: {
        paddingVertical: 6,
        height: 40,
      },
      default: {
        paddingVertical: 6,
        flex: 1,
      },
    }),
  },
})

export default PurchaseOrderDetailEditScreen
