import { StyleSheet, View } from 'react-native'
import React, { useState } from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Background from 'components/Background'

import { RouteProp } from '@react-navigation/native'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import Header from 'components/Header'
import { useLocalization } from 'contexts/LocalizationContext'
import theme from 'theme/index'
import CreateProfileForm from './CreateProfileForm'
import { useDimension } from 'contexts/DimensionContext'

type Props = {
  route: RouteProp<{
    params: {
      role: 'company' | 'farmer' | 'extensionist'
      setting: 'create' | 'edit'
    }
  }>
  navigation: StackNavigationHelpers
}
export default function CreateProfileScreen({
  route,
  navigation,
}: Props): JSX.Element {
  const { role, setting } = route.params
  const { t } = useLocalization()
  const { height, width } = useDimension()
  const diff = height * 0.12
  const [page, setPage] = useState<number>(1)

  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <Content noPadding>
        <Header
          title={
            setting === 'create'
              ? t('screens.CreateProfileScreen.title.create')
              : t('screens.CreateProfileScreen.title.edit')
          }
          showBack
          onPressBack={() => {
            if (page === 1) {
              setting === 'create'
                ? navigation.navigate('Role')
                : navigation.navigate('Settings')
            } else {
              setPage(1)
            }
          }}
        />
        <View
          style={{
            flexDirection: 'column',
            minHeight: diff,
          }}
        />
        <View
          style={[styles.bgWhite, { minHeight: height - diff * 1.6, width }]}
        >
          <CreateProfileForm
            navigation={navigation}
            role={role}
            setting={setting}
            setPage={setPage}
            page={page}
          />
        </View>
      </Content>
    </Container>
  )
}
const styles = StyleSheet.create({
  bgWhite: {
    paddingHorizontal: 16,
    backgroundColor: 'white',
    alignSelf: 'center',

    alignItems: 'center',
  },
  image: {
    height: 160,
    width: 160,
    borderRadius: 160 / 2,
  },
  shadow: {
    position: 'absolute',
    top: -80,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
    width: 162,
    height: 162,
    borderRadius: 160 / 2,
  },
  icon: {
    width: 36,
    height: 36,
  },
  button: {
    width: 50,
    height: 50,
    borderRadius: 50 / 2,
    backgroundColor: theme.color.primary1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 8,
    bottom: 0,
  },
})
