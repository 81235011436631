import React from 'react'
import Container from 'components/Container'
import Background from 'components/Background'
import * as FileSystem from 'expo-file-system'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { useLocalization } from 'contexts/LocalizationContext'
import { RouteProp } from '@react-navigation/native'
import { Platform, View } from 'react-native'
import Text from 'components/Text'
import Button from 'components/Button'

import StationFirstStep from 'components/StationFirstStep'

import * as Sharing from 'expo-sharing'
import Print from '../../../assets/images/icons/print.png'
import request from 'utils/request'
import env from '../../../config'
import { useAuth } from 'contexts/AuthContext'
export type Props = {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<
    {
      params: {
        id: string
        data: {
          workAssignmentId: string
          workStation: string
          id: string
          documentId: string
          workAssignmentNumber: string
          projectNumber: string
        }
      }
    },
    'params'
  >
}

const StationBambooPlant = ({ navigation, route }: Props): JSX.Element => {
  const { t } = useLocalization()
  const { id, data } = route.params
  const config = env()
  const baseUrl = config.API_URL
  const {
    state: { userToken },
  } = useAuth()

  const openShareDialogAsync = async (fileUri: string) => {
    await Sharing.shareAsync(fileUri)
  }
  const onPrintSaveWeb = async () => {
    try {
      const dataPdf = await request
        .get(`/workAssignment/${id}/planting/qrcode`, { responseType: 'blob' })
        .then((res: any) => res.data)
      console.log(dataPdf)
      const url = window.URL.createObjectURL(
        new Blob([dataPdf as BlobPart], {
          type: 'application/pdf',
        })
      )
      window.open(url)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${data.workAssignmentNumber}.pdf`) //or any other extension
      document.body.appendChild(link)
      link.click()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      //message.error('Failed file.')
      console.log(e)
    }
  }
  const onPrintSaveApp = async () => {
    try {
      const downloadedFile = await FileSystem.downloadAsync(
        `${baseUrl}/workAssignment/${id}/planting/qrcode`,
        FileSystem.documentDirectory + `${data.workAssignmentNumber}.pdf`,
        { headers: { Authorization: 'Bearer ' + userToken } }
      )
      if (downloadedFile.status === 200) {
        openShareDialogAsync(downloadedFile.uri)
      }
    } catch (e: any) {
      //message.error('Failed file.')
      console.log(e)
    }
  }

  const onPrintSave = () => {
    if (Platform.OS !== 'web') {
      onPrintSaveApp()
    } else {
      onPrintSaveWeb()
    }
  }

  const RenderFooter = () => {
    return (
      <View style={{ alignItems: 'center' }}>
        <Button
          style={{
            width: Platform.OS === 'web' ? '100%' : '100%',
            marginBottom: 12,
          }}
          icon={Print}
          secondary
          onPress={() => {
            onPrintSave()
          }}
          title="พิมพ์ QR CODE ลำ ID"
        />
        <Button
          style={{ width: Platform.OS === 'web' ? '100%' : '100%' }}
          arrowBack
          onPress={() =>
            navigation.navigate('StationBambooPlantDetail', { id, data })
          }
          title="ระบบตรวจสอบย้อนกลับ"
        />
      </View>
    )
  }
  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <View style={{ width: '100%', alignItems: 'center', height: 130 }}>
        <Header
          showBack
          title={t('screens.StationBambooPlant.header')} //สถานีงาน : การเพาะปลูกไผ่ ลงในพื้นที่เกษตรแปลงใหญ่
          style={{ paddingBottom: 0 }}
        />
        <Text
          title1
          color="white"
          style={{ width: '70%', textAlign: 'center', lineHeight: 30 }}
        >
          {t(`screens.StationBambooPlant.title`)}
        </Text>
      </View>

      <StationFirstStep
        dataAssignment={{
          assignmentId: data?.workAssignmentNumber,
          projectId: data?.projectNumber,
          refId: data?.documentNumber || data?.project?.order?.documentNumber,
        }}
        FooterComponent={<RenderFooter />}
      />
    </Container>
  )
}

export default StationBambooPlant
