import { TouchableOpacity, Platform, ViewStyle } from 'react-native'
import React, { useEffect, useState } from 'react'
import Modal from 'components/Modal/Modal'
import Button from 'components/Button'
import { useLocalization } from 'contexts/LocalizationContext'
import * as ImageExpo from 'expo-image-picker'
import * as ImageResize from 'expo-image-manipulator'
import * as FileSystem from 'expo-file-system'
import env from '../../../config'
import { useAuth } from 'contexts/AuthContext'
import request from 'utils/request'
const config = env()

type Props = {
  children: React.ReactNode
  setImage: (value: string) => void
  aspect?: [number, number]
  style?: ViewStyle
  onFinish?: (value: string) => void
}
interface ImgPick {
  cancelled: boolean
  uri?: string
  type?: string
}

export default function UploadImage({
  children,
  setImage,
  aspect = [1, 1],
  style,
  onFinish,
}: Props): JSX.Element {
  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const {
    state: { userToken },
  } = useAuth()

  const { t } = useLocalization()
  useEffect(() => {
    const PermissionMobile = async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImageExpo.requestMediaLibraryPermissionsAsync()
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!')
        }
      }
    }

    PermissionMobile()
  }, [isOpenSelect])

  const pickImage = async () => {
    try {
      const result: any = await ImageExpo.launchImageLibraryAsync({
        mediaTypes: ImageExpo.MediaTypeOptions.All,
        allowsEditing: true,
        aspect,
        quality: 1,
      })
      const base64Response = async (base64Data: string) => {
        const blob = await (await fetch(`${base64Data}`)).blob()
        return blob
      }
      const newImage = await ImageResize.manipulateAsync(
        result.assets[0].uri || ''
      )
      if (!result.cancelled) {
        setImage(newImage.uri)
        setIsOpenSelect(false)
        const newFile = await base64Response(newImage.uri || '').then(
          (blob) => {
            const newFile = new File(
              [blob],
              `image_${Math.floor(Math.random() * 100 * 10)}.${
                result.assets[0].uri?.split(';base64,')[0].split(':')[1] ===
                'image/png'
                  ? 'png'
                  : 'jpg'
              }`,
              {
                type:
                  result.assets[0].uri?.split(';base64,')[0].split(':')[1] ===
                  'image/png'
                    ? 'image/png'
                    : 'image/jpeg',
              }
            )
            return newFile
          }
        )
        try {
          if (Platform.OS === 'web') {
            try {
              const formData = new FormData()
              formData.append('file', newFile)
              const { data } = await request.post(`/container/upload`, formData)
              onFinish?.(data.filename)
            } catch (e) {
              console.log(e)
            }
          } else {
            try {
              const uploadResult = await FileSystem.uploadAsync(
                `${config.API_URL}/container/upload`,
                newImage.uri,
                {
                  headers: {
                    Authorization: `Bearer ${userToken}`,
                  },
                  uploadType: FileSystem.FileSystemUploadType.MULTIPART,
                  fieldName: 'file',
                }
              )

              const response = JSON.parse(uploadResult?.body)
              onFinish?.(response.filename)
            } catch (e) {
              console.log(e)
            }
          }
        } catch (e) {
          console.log('error', e)
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log('qwe err', error?.response)
    }
  }
  const onCamera = async () => {
    const result: any = await ImageExpo.launchCameraAsync({
      mediaTypes: ImageExpo.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect,
      quality: 1,
      base64: true,
    })
    const newImage = await ImageResize.manipulateAsync(
      result.assets[0].uri || ''
    )
    // const newImage = await ImageManipulator.manipulateAsync(result.uri || '', [
    //   { resize: { width: 200, height: 200 } },
    // ])
    if (!result.cancelled) {
      setImage(newImage.uri)
      setIsOpenSelect(false)
      try {
        const uploadResult = await FileSystem.uploadAsync(
          `${config.API_URL}/container/upload`,
          newImage.uri,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
            uploadType: FileSystem.FileSystemUploadType.MULTIPART,
            fieldName: 'file',
          }
        )
        const response = JSON.parse(uploadResult.body)
        onFinish?.(response.filename)
      } catch (e) {
        console.log('error', e)
      }
    }
  }
  return (
    <>
      <TouchableOpacity
        onPress={() =>
          Platform.OS === 'web' ? pickImage() : setIsOpenSelect(true)
        }
        style={style}
      >
        {children}
      </TouchableOpacity>
      <Modal
        visible={isOpenSelect}
        onRequestClose={() => setIsOpenSelect(false)}
      >
        <Button
          title={t('UploadImage.button.takePicture')}
          onPress={onCamera}
        />
        <Button
          title={t('UploadImage.button.device')}
          onPress={pickImage}
          style={{ marginTop: 16 }}
        />
      </Modal>
    </>
  )
}
