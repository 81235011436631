import { View } from 'react-native'
import React, { useState } from 'react'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import { useLocalization } from 'contexts/LocalizationContext'
import Content from 'components/Content'
import Text from 'components/Text'
import * as yup from 'yup'
import { Form, Input, SubmitButton } from 'components/Form'
import theme from 'theme/index'
import request from 'utils/request'
import ModalAlert from 'components/ModalAlert'
interface Props {
  navigation: StackNavigationHelpers
}
export default function ForgetPasswordScreen({
  navigation,
}: Props): JSX.Element {
  const { t } = useLocalization()
  const [isFail, setIsfail] = useState<boolean>(false)
  const defaultValues = {
    email: '',
  }
  const schema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .lowercase()
      .email(t('screens.LoginScreen.errors.emailInvalid')),
  })
  const onSubmit = async (data: { email: string }) => {
    try {
      const res = await request.post(`/user/forget-password`, {
        email: data?.email,
      })
      if (res?.data?.message === 'This email has not in user account') {
        setIsfail(true)
      } else {
        navigation.navigate('OtpScreen', { email: data?.email })
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Container>
      <Background typeBackground="BgWithCircle" />
      <Header showBack title="" />
      <Content enabledScroll={false}>
        <View style={{ flex: 0.8, justifyContent: 'center' }}>
          <Text semiBold h1 color="white" center style={{ marginBottom: 16 }}>
            {t('screens.ForgetPasswordScreen.title')}
          </Text>
          <Form defaultValues={defaultValues} schema={schema}>
            <Input
              name="email"
              label={t('screens.ForgetPasswordScreen.form.email')}
              colorLabel="white"
              selectionColor={'white'}
              style={{ color: theme.color.white, paddingVertical: 8 }}
              themeWhite
            />
            <View style={{ marginTop: 16 }}>
              <SubmitButton
                testID="signupForm"
                onSubmit={onSubmit}
                title={t('screens.ForgetPasswordScreen.button')}
              />
            </View>
          </Form>
        </View>
        <ModalAlert
          type="error"
          visible={isFail}
          setVisibile={setIsfail}
          textDesc="ไม่พบอีเมล"
          onRequestClose={() => setIsfail(false)}
        />
      </Content>
    </Container>
  )
}
