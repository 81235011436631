import { RouteProp, useFocusEffect } from '@react-navigation/native'
import Background from 'components/Background'
import Button from 'components/Button'
import Container from 'components/Container'
import Header from 'components/Header'
import ModalAlert from 'components/ModalAlert'
import StationSecondStep from 'components/StationSecondStep'
import React, { useState } from 'react'
import { Dimensions, Platform, View } from 'react-native'
import { Provider } from 'react-native-paper'
import request from 'utils/request'
import PlusIcon from 'assets/images/icons/Plus.png'
import { useAuth } from 'contexts/AuthContext'
export interface RootObject {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  path: string
  text: string
}
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigation: any
  route: RouteProp<{ params: { id: string } }, 'params'>
}
function StationPreparePlotDetail({ route, navigation }: Props): JSX.Element {
  const { id } = route.params
  const [visible, setVisible] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const {
    state: { link },
  } = useAuth()
  const url: RootObject[] = []
  if (link) {
    link?.map((item: RootObject) => {
      if (item.text === 'คู่มือการปรับปรุงดิน') {
        return url.push(item)
      }
    })
  }

  const limit = 10
  const page = 1
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any>({
    data: [],
    page: 1,
    total: 1,
    limit: 10,
  })
  const fatchData = async () => {
    try {
      const res = await request.get(
        `/workAssignment/${id}/nutrient-usage?limit=${limit}&page=${page}&type=soil`
      )
      setData(res?.data)
    } catch (err) {
      console.log(err)
    }
  }
  useFocusEffect(
    React.useCallback(() => {
      fatchData()
    }, [refresh])
  )

  const onLoadMore = async () => {
    try {
      if (data.data.length < data.total) {
        const res = await request.get(
          `/workAssignment/${id}/nutrient-usage?limit=${limit}&page=${
            data?.page + 1
          }&type=soil`
        )
        const value = {
          data: [...data.data, ...res.data.data],
          page: data?.page + 1,
          total: data.total,
          limit: data.limit,
        }
        setData(value)
      } else {
        return null
      }
    } catch (e) {
      console.log(e)
    }
  }
  const onEdit = (editId: string) => {
    navigation.navigate('EditPreparePlot', { id, editId, link: url })
  }

  const onDelete = async (deleteId: string) => {
    try {
      await request.delete(`/workAssignment/${id}/nutrient-usage/${deleteId}`)
      setVisible(true)
      setRefresh((prev) => !prev)
    } catch (e) {
      console.log(e)
    }
  }
  const RenderฺButton = () => {
    return (
      <View style={{ marginTop: 32, width: '100%', alignItems: 'center' }}>
        <Button
          title="เพิ่มข้อมูล"
          icon={PlusIcon}
          style={{ width: Platform.OS === 'web' ? '90%' : '90%' }}
          onPress={() =>
            navigation.navigate('AddPreparePlot', {
              id,
              link: url,
            })
          }
        />
      </View>
    )
  }
  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={'ปริมาณธาตุอาหาร และ ฮอร์โมน (ดิน)'}
          style={{ paddingBottom: 0 }}
          numberOfLines={2}
          widthText={Dimensions.get('window').width - 60}
          componentBelowHeader={<RenderฺButton />}
        />

        <StationSecondStep
          data={data.data}
          onEdit={onEdit}
          onDelete={onDelete}
          onLoadMore={onLoadMore}
        />
      </Container>
      <ModalAlert
        visible={visible}
        setVisibile={setVisible}
        textDesc="ลบสำเร็จ"
      />
    </Provider>
  )
}

export default StationPreparePlotDetail
