/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Background from 'components/Background'
import { View, ActivityIndicator, StyleSheet } from 'react-native'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { Provider } from 'react-native-paper'
import Card from 'components/Card'
import { RouteProp } from '@react-navigation/native'
import Text from 'components/Text'
import theme from 'theme/index'
import { useLocalization } from 'contexts/LocalizationContext'

export type Props = {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<{ params: { id: string; data: any } }, 'params'>
}

const WorksheetSummaryScreen = ({ route }: Props): JSX.Element => {
  const { t } = useLocalization()
  const { id, data } = route.params
  if (id && !data) {
    return (
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={t('screens.WorksheetSummaryScreen.title')}
          alignTitle="flex-start"
        />
        <View style={styles.viewHeader}></View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size="large" color={theme.color.primary1} />
          <Text center style={{ marginTop: 16 }} h2 color="primary2">
            Loading...
          </Text>
        </View>
      </Container>
    )
  }

  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={t('screens.WorksheetSummaryScreen.title')}
          alignTitle="flex-start"
        />
        <View style={styles.viewHeader}></View>
        <Card borderRadiusTop width="100%" style={{ height: '100%' }}>
          <Content>
            <View style={styles.viewHead}>
              <View style={styles.viewLeft}>
                <Text sub1>{t('screens.WorksheetSummaryScreen.plan')}</Text>
              </View>
              <View style={styles.viewRight}>
                <Text color="black85" sub1>
                  {data.quantity}
                </Text>
              </View>
            </View>
            <View style={styles.viewBorder} />

            <View style={styles.viewHead}>
              <View style={styles.viewLeft}>
                <Text sub1>{t('screens.WorksheetSummaryScreen.actual')}</Text>
              </View>
              <View style={styles.viewRight}>
                <Text color="black85" sub1>
                  {data.actual}
                </Text>
              </View>
            </View>
            <View style={styles.viewBorder} />

            <View style={styles.viewHead}>
              <View style={styles.viewLeft}>
                <Text sub1>{t('screens.WorksheetSummaryScreen.diff')}</Text>
              </View>
              <View style={styles.viewRight}>
                <Text color="error" sub1>
                  {data.diff}
                </Text>
              </View>
            </View>
            <View style={styles.viewBorder} />
            <View style={styles.viewHead}>
              <View style={styles.viewLeft}></View>
              <View style={styles.viewRight}>
                <Text color="error" body3>
                  {t('screens.WorksheetSummaryScreen.note')}
                </Text>
              </View>
            </View>
          </Content>
        </Card>
      </Container>
    </Provider>
  )
}

const styles = StyleSheet.create({
  viewHeader: { width: '90%', marginTop: 20, marginBottom: 20 },
  viewHead: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 12,
    paddingTop: 12,
  },
  viewLeft: {
    flex: 0.4,
  },
  viewRight: { flex: 0.6 },
  viewBorder: { borderWidth: 1, borderColor: theme.color.black6 },
})

export default WorksheetSummaryScreen
