import {
  View,
  Dimensions,
  ScrollView,
  Image,
  TouchableOpacity,
  Linking,
  ActivityIndicator,
} from 'react-native'
import React from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Header from 'components/Header'
import Background from 'components/Background'
import Card from 'components/Card'
import { Form, Input, SubmitButton } from 'components/Form'
import * as yup from 'yup'
import LinkIcon from 'assets/images/icons/Link.png'
import { useFocusEffect } from '@react-navigation/native'
import InputDate from 'components/Form/InputDate'
import InputTimeForm from 'components/Form/InputTimeForm'
import dayjs from 'dayjs'
import Text from 'components/Text'
import theme from 'theme/index'
import FormList from './FormList'
import ModalAlert from 'components/ModalAlert'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import { RouteProp } from '@react-navigation/native'
import request, { fetcher } from 'utils/request'
import useSWR from 'swr'
import CannotGetData from 'components/CannotGetData'
import { PestRecordType } from 'utils/constants'
export interface RootObject {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  path: string
  text: string
}
interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<
    {
      params: {
        id: string
        farmName: string
        editId: string
        link: RootObject[]
      }
    },
    'params'
  >
}
interface FormType {
  date: string
  position: {
    latitude: number
    longitude: number
  }
  time: {
    hour: string
    minute: string
  }
  formKorList: {
    image: string
    itemId: string
    quantityPercentage: string | null
  }[]
  formLumList: {
    image: string
    itemId: string
    quantityPercentage: string | null
  }[]
  formNorList: {
    image: string
    itemId: string
    quantityPercentage: string | null
  }[]
  formLeafList: {
    image: string
    quantityPercentage: string | null
  }[]
  formInsectList: {
    image: string
    quantityPercentage: string | null
  }[]
  formAnimalList: {
    image: string
    quantityPercentage: string | null
  }[]
  formWeedList: {
    image: string
    quantityPercentage: string | null
  }[]
}
export default function EditAnalysisMeasurement({
  navigation,
  route,
}: Props): JSX.Element {
  const { id, farmName, editId, link } = route.params
  const schema = yup.object().shape({
    date: yup.string().required('กรุณากรอกวันที่'),
    time: yup.object().shape({
      hour: yup.string().required('กรุณากรอกชั่วโมง'),
      minute: yup.string().required('กรุณากรอกนาที'),
    }),
    factory: yup.string(),
    formKorList: yup.array().of(
      yup.object().shape({
        itemId: yup.string().required('กรุณากรอก กอ ID'),
        //image: yup.string().required('กรุณาเลือกรูป'),
        quantityPercentage: yup.number().nullable(),
      })
    ),
    formLumList: yup.array().of(
      yup.object().shape({
        itemId: yup.string().required('กรุณากรอก ลำ ID'),
        //image: yup.string().required('กรุณาเลือกรูป'),
        quantityPercentage: yup.number().nullable(),
      })
    ),
    formNorList: yup.array().of(
      yup.object().shape({
        itemId: yup.string().required('กรุณากรอก หน่อ ID'),
        //image: yup.string().required('กรุณาเลือกรูป'),
        quantityPercentage: yup.number().nullable(),
      })
    ),
    formLeafList: yup.array().of(
      yup.object().shape({
        //image: yup.string(),
        quantityPercentage: yup.number().nullable(),
      })
    ),
    formInsectList: yup.array().of(
      yup.object().shape({
        //image: yup.string(),
        quantityPercentage: yup.number().nullable(),
      })
    ),
    formAnimalList: yup.array().of(
      yup.object().shape({
        //image: yup.string(),
        quantityPercentage: yup.number().nullable(),
      })
    ),
    formWeedList: yup.array().of(
      yup.object().shape({
        //image: yup.string(),
        quantityPercentage: yup.number().nullable(),
      })
    ),
  })

  const { data, mutate } = useSWR(
    `/workAssignment/${id}/pestLogs/${editId}`,
    fetcher
  )
  useFocusEffect(
    React.useCallback(() => {
      mutate(`/workAssignment/${id}/pestLogs/${editId}`)
    }, [mutate])
  )
  const [isSuccess, setIsSuccess] = React.useState(false)
  const onSubmit = async (data: FormType) => {
    try {
      const recordsData = [
        ...data.formKorList,
        ...data.formLumList,
        ...data.formNorList,
        ...data.formLeafList,
        ...data.formInsectList,
        ...data.formAnimalList,
        ...data.formWeedList,
      ].filter(
        (el) => el.image !== undefined && el.quantityPercentage !== undefined
      )
      const formatedDate = dayjs(data.date)
        .hour(+data.time.hour)
        .minute(+data.time.minute)
      const payload = {
        date: dayjs(formatedDate).toISOString(),

        lat: +data.position.latitude,
        lng: +data.position.longitude,
        assignmentId: id,
        records: recordsData,
      }
      await request.patch(`/workAssignment/${id}/pestLogs/${editId}`, payload)
      setIsSuccess(true)
    } catch (e) {
      console.log(e)
    }
  }

  if (!data) {
    return (
      <CannotGetData titleHeader="ระบบตรวจวัดและวิเคราะห์การเจริญเติบโตของพืช" />
    )
  }
  const formKorList = (data.records || []).filter(
    (el: { type: string }) => el.type === PestRecordType.DISEASE_KO
  )
  const formLumList = (data.records || []).filter(
    (el: { type: string }) => el.type === PestRecordType.DISEASE_LUM
  )
  const formNorList = (data.records || []).filter(
    (el: { type: string }) => el.type === PestRecordType.DISEASE_NOR
  )
  const formLeafList = (data.records || []).filter(
    (el: { type: string }) => el.type === PestRecordType.DISEASE_LEAF
  )
  const formInsectList = (data.records || []).filter(
    (el: { type: string }) => el.type === PestRecordType.INSECT
  )
  const formAnimalList = (data.records || []).filter(
    (el: { type: string }) => el.type === PestRecordType.ANIMAL
  )
  const formWeedList = (data.records || []).filter(
    (el: { type: string }) => el.type === PestRecordType.WEED
  )
  const defaultValues = {
    date: dayjs(data.date),
    time: {
      hour: dayjs(data.date).format('HH'),
      minute: dayjs(data.date).format('mm'),
    },
    position: {
      latitude: data.lat,
      longitude: data.lng,
    },
    formKorList,
    formLumList,
    formNorList,
    formLeafList,
    formInsectList,
    formAnimalList,
    formWeedList,
  }
  if (id && !farmName && !data) {
    return (
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header showBack title="กอ ID / ลำ ID" widthText={'90%'} />
        <View style={{ marginTop: 70, marginBottom: 20 }}></View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size="large" color={theme.color.primary1} />
          <Text center style={{ marginTop: 16 }} h2 color="primary2">
            Loading...
          </Text>
        </View>
      </Container>
    )
  }
  return (
    <Container>
      <Header
        showBack
        title="ระบบตรวจจับและวิเคราะห์ศัตรูพืช"
        style={{ paddingBottom: 0 }}
        numberOfLines={2}
        widthText={Dimensions.get('window').width - 60}
      />
      <Background typeBackground="BgDefaultHeader" />
      <Content enabledScroll={false} noPadding>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <Card style={{ paddingTop: 16, marginTop: 24 }} borderRadiusTop>
            <Form defaultValues={defaultValues} schema={schema}>
              <InputDate name="date" label="วันที่" defaultValue={data.date} />
              <InputTimeForm name="time" label="เวลา" />
              <Input
                name="factory"
                label="แปลง/โรงงาน"
                editable
                defaultValue={farmName}
              />

              <FormList />
              <View
                style={{
                  flexDirection: 'column',
                  marginTop: 12,
                }}
              >
                {link?.map((item: RootObject, idx: any) => {
                  return (
                    <View
                      key={idx}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 12,
                      }}
                    >
                      <Image
                        source={LinkIcon}
                        style={{
                          width: 18,
                          height: 18,
                          marginRight: 8,
                        }}
                      />
                      <TouchableOpacity
                        onPress={() => Linking.openURL(item.path)}
                      >
                        <Text
                          sub1
                          color="secondary2"
                          style={{
                            textDecorationLine: 'underline',
                            textDecorationColor: theme.color.secondary2,
                          }}
                        >
                          {item.text}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )
                })}
              </View>

              <View style={{ marginTop: 32, paddingHorizontal: '8%' }}>
                <SubmitButton title="แก้ไข" onSubmit={onSubmit} />
              </View>
            </Form>
          </Card>
        </ScrollView>
      </Content>
      <ModalAlert
        visible={isSuccess}
        setVisibile={setIsSuccess}
        textDesc="แก้ไขสำเร็จ"
        onRequestClose={() => navigation.goBack()}
      />
    </Container>
  )
}
