import {
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  Linking,
} from 'react-native'
import React, { useState } from 'react'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import Content from 'components/Content'
import Card from 'components/Card'
import dayjs from 'dayjs'
import * as yup from 'yup'
import { Form, Input, SubmitButton } from 'components/Form'
import Text from 'components/Text'
import theme from 'theme/index'
import ModalAlert from 'components/ModalAlert'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import { RouteProp } from '@react-navigation/native'
import InputDate from 'components/Form/InputDate'
import LinkIcon from 'assets/images/icons/Link.png'
import request from 'utils/request'
import Address from 'components/Form/Address'
export interface RootObject {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  path: string
  text: string
}
interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<
    {
      params: {
        assignmentId: string
        projectNumber: string
        link: RootObject[]
      }
    },
    'params'
  >
}

interface FormData {
  date: string
  recipientName: string
  phoneNumber: string
  address: string
  subDistrict: string
  district: string
  province: string
  zipCode: string
  deliveredBy: string
  projectNumber: string
}
const AddDeliveryManagement = ({ navigation, route }: Props) => {
  const { assignmentId, projectNumber, link } = route.params
  const [valueProvince, setValueProvince] = useState<string>()
  const schema = yup.object().shape({
    date: yup.string(),
    recipientName: yup.string().required('กรุณากรอกชื่อผู้รับ'),
    phoneNumber: yup.string().required('กรุณากรอกเบอร์โทร'),
    address: yup.string().required('กรุณากรอกที่อยู่'),
    subDistrict: yup.string().required('กรุณากรอกแขวง/ตำบล'),
    district: yup.string().required('กรุณากรอกเขต/อำเภอ'),
    province: yup.string().required('กรุณากรอกจังหวัด'),

    zipCode: yup.string().required('กรุณากรอกรหัสไปรษณีย์'),
    deliveredBy: yup.string().required('กรุณากรอกข้อมูล'),
    projectNumber: yup.string().required('กรุณากรอกเลขที่โปรเจค'),
  })
  const [isSuccess, setIsSuccess] = useState(false)
  const onSubmitForm = async (data: FormData) => {
    try {
      await request.post(
        `/workAssignment/${assignmentId}/afterHarvestTransport`,
        {
          date: dayjs(data.date).toISOString(),
          recipientName: data.recipientName,
          phoneNumber: data.phoneNumber,
          address: data.address,
          subDistrict: data.subDistrict,
          district: data.district,
          province: data.province,
          zipCode: data.zipCode,
          deliveredBy: data.deliveredBy,
          projectNumber: data.projectNumber,
        }
      )
      setIsSuccess(true)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(JSON.stringify(e.response, null, 2))
    }
  }
  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <Header
        showBack
        title={'ระบบจัดการการขนส่ง'}
        numberOfLines={2}
        widthText={'90%'}
      />
      <Content enabledScroll={false} noPadding>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <Card
            borderRadiusTop
            style={{ justifyContent: 'space-between', flex: 1, marginTop: 32 }}
          >
            <Form
              schema={schema}
              defaultValues={{
                date: dayjs(),
                projectNumber: projectNumber,
              }}
            >
              <InputDate label="วันที่" name="date" iconRight />

              <Input label="ชื่อผู้รับ" name="recipientName" />
              <Input label="เบอร์โทร" name="phoneNumber" />
              <Input label="ที่อยู่จัดส่ง" name="address" />
              <Address
                name="subDistrict"
                keyName="district"
                label="แขวง/ตำบล"
                province={valueProvince}
                // defaultValue={defaultValue.subDistrict}
              />
              <Address
                name="district"
                keyName="amphoe"
                label="เขต/อำเภอ"
                // defaultValue={defaultValue.district}
              />
              <Address
                name="province"
                keyName="province"
                label="จังหวัด"
                setProvince={setValueProvince}
              />
              <Address
                name="zipCode"
                keyName="zipcode"
                label="รหัสไปรษณีย์"
                // setProvince={setValueProvince}
              />

              <Input
                label="จัดส่งโดย"
                name="deliveredBy"
                style={{ width: '100%' }}
              />
              <Input
                label="เลขที่โปรเจค"
                name="projectNumber"
                style={{ width: '100%' }}
                editable
              />
              <View
                style={{
                  flexDirection: 'column',
                  marginTop: 12,
                }}
              >
                {link?.map((item: RootObject, idx: any) => {
                  return (
                    <View
                      key={idx}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 12,
                      }}
                    >
                      <Image
                        source={LinkIcon}
                        style={{
                          width: 18,
                          height: 18,
                          marginRight: 8,
                        }}
                      />
                      <TouchableOpacity
                        onPress={() => Linking.openURL(item.path)}
                      >
                        <Text
                          sub1
                          color="secondary2"
                          style={{
                            textDecorationLine: 'underline',
                            textDecorationColor: theme.color.secondary2,
                          }}
                        >
                          {item.text}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )
                })}
              </View>
              <View style={{ marginTop: 32, paddingHorizontal: '8%' }}>
                <SubmitButton title="ตกลง" onSubmit={onSubmitForm} />
              </View>
            </Form>
          </Card>
        </ScrollView>
      </Content>
      <ModalAlert
        visible={isSuccess}
        setVisibile={setIsSuccess}
        textDesc="เพิ่มข้อมูลสำเร็จ"
        onRequestClose={() =>
          navigation.navigate('DeliveryManagementDetail', {
            assignmentId: assignmentId,
          })
        }
      />
    </Container>
  )
}

export default AddDeliveryManagement
