import { View, Platform } from 'react-native'
import React from 'react'
import { useLocalization } from 'contexts/LocalizationContext'
// import { Grid } from 'antd-mobile'
import Button from 'components/Button'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import StationFirstStep from 'components/StationFirstStep'
import Text from 'components/Text'
import { RouteProp } from '@react-navigation/native'
import useSWR from 'swr'
import { fetcher } from '../../utils/request'
import { useAuth } from 'contexts/AuthContext'
export interface RootObject {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  path: string
  text: string
}
interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<
    { params: { data: any; id: string; farmData: any; farmId: string } },
    'params'
  >
}
const StationProtectionPlantScreen = ({
  navigation,
  route,
}: Props): JSX.Element => {
  const { data, id, farmData, farmId } = route.params

  const {
    state: { link },
  } = useAuth()
  const urlPestForecasting: RootObject[] = []
  if (link) {
    link?.map((item: RootObject) => {
      if (
        item.text === 'ข้อมูลสภาพอากาศ' ||
        item.text === 'ชนิดและรายละเอียดของศัตรูพืช สาเหตุ ระดับความเสียหาย' ||
        item.text === 'ข้อมูลเตือนภัยศัตรูพืชจากหน่วยงานภายนอก' ||
        item.text === 'ข้อมูลสภาพอากาศจากกรมอุตุ' ||
        item.text === 'ข้อมูลสภาพอากาศในพื้นที่'
      ) {
        return urlPestForecasting.push(item)
      }
    })
  }
  const urlManageProtectPlant: RootObject[] = []
  if (link) {
    link?.map((item: RootObject) => {
      if (
        item.text === 'คู่มือการป้องกันและกำจัดศัตรูพืช' ||
        item.text === 'ข้อมูลเตือนภัยศัตรูพืช'
      ) {
        return urlManageProtectPlant.push(item)
      }
    })
  }
  const { data: farm } = useSWR([`/farm/${farmId}`], fetcher)
  const { t } = useLocalization()
  const FooterRender = () => {
    return (
      <View style={{ alignItems: 'center' }}>
        <Button
          title="ระบบตรวจจับและวิเคราะห์ศัตรูพืช "
          arrowBack
          onPress={() =>
            navigation.navigate('StationAnalysisMeasurementDetail', {
              id,
              farmName: farmData?.name || farm?.name,
            })
          }
          style={{
            width: Platform.OS === 'web' ? '100%' : '100%',
            marginBottom: 8,
          }}
        />
        <Button
          title="ระบบการพยากรณ์ศัตรูพืช"
          arrowBack
          onPress={() =>
            navigation.navigate('PestForecasting', { link: urlPestForecasting })
          }
          style={{
            width: Platform.OS === 'web' ? '100%' : '100%',
            marginBottom: 8,
          }}
        />
        <Button
          title="ระบบจัดการการอารักขาพืชในเกษตรแปลงใหญ่"
          arrowBack
          onPress={() =>
            navigation.navigate('ManageProtectPlant', {
              link: urlManageProtectPlant,
            })
          }
          style={{
            width: Platform.OS === 'web' ? '100%' : '100%',
          }}
        />
      </View>
    )
  }
  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <View style={{ width: '100%', alignItems: 'center', height: 130 }}>
        <Header
          showBack
          title={t('screens.StationNutrientManagementScreen.header')}
          style={{ paddingBottom: 0 }}
        />
        <Text
          title1
          color="white"
          style={{ width: '70%', textAlign: 'center', lineHeight: 30 }}
        >
          สถานีงาน : การอารักขาพืช
        </Text>
      </View>
      <StationFirstStep
        dataAssignment={{
          assignmentId: data?.workAssignmentNumber,
          refId: data?.documentNumber || data?.project?.order?.documentNumber,
          projectId: data?.projectNumber,
        }}
        FooterComponent={<FooterRender />}
      />
    </Container>
  )
}

export default StationProtectionPlantScreen
