/* eslint-disable @typescript-eslint/no-explicit-any */
import { Image, Platform, TouchableOpacity, View } from 'react-native'
import React, { useState } from 'react'
import Text from 'components/Text'
import Modal from 'components/Modal/Modal'
import Close from 'assets/images/icons/Close.png'
import { Form, Input, SubmitButton } from 'components/Form'
import * as yup from 'yup'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { useLocalization } from 'contexts/LocalizationContext'
import request from 'utils/request'
import ModalAlert from 'components/ModalAlert'
export interface RootObject {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  path: string
  text: string
}
interface Props {
  visible: boolean
  setVisible: (visible: boolean) => void
  navigation: BottomTabNavigationHelpers
  assignmentId: string
  framData: any
  link: RootObject[]
}
interface FormData {
  minHeight: number
  maxHeight: number
  minOuterDiameter: string
  maxOuterDiameter: string
}
const ModalSearch = ({
  visible,
  setVisible,
  navigation,
  assignmentId,
  framData,
  link,
}: Props): JSX.Element => {
  const { t } = useLocalization()
  const [isFail, setIsfail] = useState<boolean>(false)
  const onSubmit = async (values: FormData) => {
    const { minHeight, maxHeight, minOuterDiameter, maxOuterDiameter } = values
    try {
      const res = await request.get(
        `/workAssignment/${assignmentId}/harvest/targets?assignmentId=${assignmentId}&minHeight=${minHeight}&maxHeight=${maxHeight}&minOuterDiameter=${minOuterDiameter}&maxOuterDiameter=${maxOuterDiameter}`
      )

      if (res?.data?.length > 0) {
        setVisible(false)
        navigation.navigate('StationHarvestingAdd', {
          assignmentId: assignmentId,
          lumIds: res?.data,
          framData: framData,
          link: link,
        })
      } else {
        setIsfail(true)
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(JSON.stringify(e.response, null, 2))
    }
  }

  const schema = yup.object().shape({
    minHeight: yup
      .number()
      .typeError('กรุณากรอกตัวเลข')
      .required('กรุณากรอกขนาดความสูง'),
    maxHeight: yup
      .number()
      .typeError('กรุณากรอกตัวเลข')
      .required('กรุณากรอกขนาดความสูง'),
    minOuterDiameter: yup
      .number()
      .typeError('กรุณากรอกตัวเลข')
      .required('กรุณากรอกข้อมูล'),
    maxOuterDiameter: yup
      .number()
      .typeError('กรุณากรอกตัวเลข')
      .required('กรุณากรอกข้อมูล'),
  })

  return (
    <>
      <Modal
        visible={visible}
        onRequestClose={() => setVisible(false)}
        width={Platform.OS === 'web' ? '75%' : '90%'}
        disableOnBlur
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text medium title1>
            {t('screens.StationHarvestingScreen.modal.title')}
          </Text>
          <TouchableOpacity onPress={() => setVisible(false)}>
            <Image source={Close} style={{ width: 24, height: 24 }} />
          </TouchableOpacity>
        </View>
        <Form style={{ marginTop: 24 }} schema={schema} defaultValues={{}}>
          <Text sub1>{t('screens.StationHarvestingScreen.modal.label')}</Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Input
              name="minHeight"
              keyboardType="numeric"
              style={{ width: 79 }}
            />
            <View style={{ paddingTop: 10, paddingLeft: 8, paddingRight: 8 }}>
              <Text>-</Text>
            </View>
            <Input
              name="maxHeight"
              keyboardType="numeric"
              style={{ width: 79 }}
            />
            <Text
              sub1
              color="black85"
              style={{ paddingTop: 10, paddingLeft: 10 }}
            >
              {t('screens.StationHarvestingScreen.modal.labelText')}
            </Text>
          </View>
          <Text sub1>{t('screens.StationHarvestingScreen.modal.label2')}</Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Input
              name="minOuterDiameter"
              keyboardType="numeric"
              style={{ width: 79 }}
            />
            <View style={{ paddingTop: 10, paddingLeft: 8, paddingRight: 8 }}>
              <Text>-</Text>
            </View>
            <Input
              name="maxOuterDiameter"
              keyboardType="numeric"
              style={{ width: 79 }}
            />
            <Text
              sub1
              color="black85"
              style={{ paddingTop: 10, marginLeft: 24 }}
            >
              {t('screens.StationHarvestingScreen.modal.labelText2')}
            </Text>
          </View>
          <View style={{ paddingTop: 10 }}>
            <SubmitButton
              title={t('screens.StationHarvestingScreen.modal.search')}
              onSubmit={onSubmit}
            />
          </View>
        </Form>
      </Modal>
      <ModalAlert
        type="error"
        visible={isFail}
        setVisibile={setIsfail}
        textDesc="ไม่พบข้อมูล"
        onRequestClose={() => setIsfail(false)}
      />
    </>
  )
}

export default ModalSearch
