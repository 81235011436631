import React, { Fragment } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import DeleteRed from '../../../assets/images/icons/deleteRed.png'
import Text from 'components/Text'
import {
  View,
  Platform,
  StyleSheet,
  Image,
  TouchableOpacity,
} from 'react-native'
import { useLocalization } from 'contexts/LocalizationContext'
import PlusAdd from '../../../assets/images/icons/PlusAdd.png'

import { InputText } from 'components/FormArray/InputText'
import { Provider } from 'react-native-paper'
import { InputMap } from 'components/FormArray/InputMap'

const RecordsForm = (): JSX.Element => {
  const { t } = useLocalization()
  const { control } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    name: 'records',
    control,
  })

  return (
    <Provider>
      <Fragment>
        {fields.map((field, index) => {
          return (
            <Fragment key={field.id}>
              <View
                style={{
                  position: 'relative',
                  borderWidth: 1,
                  borderRadius: 10,
                  borderColor: '#C0C0C0',
                  padding: 16,
                  marginBottom: 16,
                }}
              >
                {fields.length > 1 ? (
                  <TouchableOpacity
                    onPress={() => {
                      remove(index)
                    }}
                    style={{
                      alignItems: 'flex-end',
                      position: 'absolute',
                      top: 8,
                      right: 10,
                      height: 24,
                      width: 24,
                      zIndex: 100,
                    }}
                  >
                    <Image
                      source={DeleteRed}
                      style={{ width: 16, height: 16 }}
                    />
                  </TouchableOpacity>
                ) : (
                  <></>
                )}

                <InputText
                  name={`records.${index}.koId`}
                  label={'กอ ID'}
                  unitLabelInside
                  //showCheckBox
                  hasScanner
                  colorLabel="black"
                  nameList={`records.${index}.koId`}
                  errorName="estimatedAmountAnimal"
                  itemStyle={{ width: '100%' }}
                />
                <InputText
                  name={`records.${index}.lumIds`}
                  label={'ลำ ID'}
                  unitLabelInside
                  //showCheckBox
                  hasScanner
                  colorLabel="black"
                  nameList={`records.${index}.lumIds`}
                  errorName="estimatedAmountAnimal"
                  itemStyle={{ width: '100%' }}
                />
                <InputMap
                  name={`records.${index}.position`}
                  nameList={`records.${index}.position`}
                  //errorName="korId"
                  itemStyle={{ width: '100%' }}
                  label="พิกัด"
                  index={index}
                />
              </View>
            </Fragment>
          )
        })}
        <View
          style={{
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginBottom: 20,
          }}
        >
          <Text>เพิ่มข้อมูล</Text>

          <TouchableOpacity
            onPress={() =>
              append({
                koId: '',
                lat: '',
                lng: '',
                lumIds: '',
                position: {
                  latitude: 0,
                  longitude: 0,
                },
              })
            }
          >
            <Image source={PlusAdd} style={{ width: 16, height: 16 }} />
          </TouchableOpacity>
        </View>
      </Fragment>
    </Provider>
  )
}

const styles = StyleSheet.create({
  Input: {
    ...Platform.select({
      web: {
        paddingVertical: 6,
        height: 40,
      },
      default: {
        paddingVertical: 6,
        flex: 1,
      },
    }),
  },
})

export default RecordsForm
