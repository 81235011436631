import { View, Text, TouchableOpacity, Image } from 'react-native'
import React from 'react'
import DeleteRed from 'assets/images/icons/deleteRed.png'
interface Props {
  onPress: () => void
  disabled?: boolean
}
const DeleteButton = ({ onPress, disabled }: Props): JSX.Element => {
  return (
    <TouchableOpacity disabled={disabled} onPress={onPress}>
      <Image
        source={DeleteRed}
        style={{
          width: 24,
          height: 24,
          opacity: disabled ? 0.2 : 1,
        }}
      />
    </TouchableOpacity>
  )
}

export default DeleteButton
