import { Dimensions, Platform, View, useWindowDimensions } from 'react-native'
import React, { useState } from 'react'
import { Provider } from 'react-native-paper'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import StationSecondStep from 'components/StationSecondStep'
import Button from 'components/Button'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import { RouteProp, useFocusEffect } from '@react-navigation/native'
import PlusIcon from 'assets/images/icons/Plus.png'
import request from 'utils/request'
import { useAuth } from 'contexts/AuthContext'
export interface RootObject {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  path: string
  text: string
}
interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { params: { assignmentId: string; projectNumber: string } },
    'params'
  >
}
export default function DeliveryManagementDetail({
  navigation,
  route,
}: Props): JSX.Element {
  const { height } = useWindowDimensions()
  const { assignmentId, projectNumber } = route.params
  const [refresh, setRefresh] = useState(false)
  const {
    state: { link },
  } = useAuth()
  const url: RootObject[] = []
  if (link) {
    link?.map((item: RootObject) => {
      if (
        item.text === 'ข้อมูลความต้องการการส่งสินค้า' ||
        item.text === 'คู่มือการขนส่ง'
      ) {
        return url.push(item)
      }
    })
  }
  const limit = 10
  const page = 1
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any>({
    data: [],
    page: 1,
    total: 1,
    limit: 10,
  })
  const fatchData = async () => {
    try {
      const res = await request.get(
        `/workAssignment/${assignmentId}/afterHarvestTransport?limit=${limit}&page=${page}`
      )
      setData(res?.data)
    } catch (err) {
      console.log(err)
    }
  }
  useFocusEffect(
    React.useCallback(() => {
      fatchData()
    }, [refresh])
  )
  const onLoadMore = async () => {
    try {
      if (data.data.length < data.total) {
        const res = await request.get(
          `/workAssignment/${assignmentId}/afterHarvestTransport?limit=${limit}&page=${
            data?.page + 1
          }`
        )
        const value = {
          data: [...data.data, ...res.data.data],
          page: data?.page + 1,
          total: data.total,
          limit: data.limit,
        }
        setData(value)
      } else {
        return null
      }
    } catch (e) {
      console.log(e)
    }
  }

  // const { data, mutate } = useSWR(
  //   () =>
  //     assignmentId
  //       ? [
  //           `/workAssignment/${assignmentId}/afterHarvestTransport?limit=${limit}&page=1`,
  //           refresh,
  //         ]
  //       : null,
  //   fetcher
  // )

  const onEdit = (editId: string) => {
    navigation.navigate('EditDeliveryManagement', {
      editId,
      assignmentId,
      link: url,
    })
  }

  const onDelete = async (deleteId: string) => {
    try {
      await request.delete(
        `/workAssignment/${assignmentId}/afterHarvestTransport/${deleteId}`
      )
      setRefresh((prev) => !prev)
    } catch (e) {
      console.log(e)
    }
  }

  const RenderFooter = () => {
    return (
      <View style={{ marginTop: 32, width: '100%', alignItems: 'center' }}>
        <Button
          title="เพิ่มข้อมูล"
          icon={PlusIcon}
          style={{ width: Platform.OS === 'web' ? '90%' : '90%' }}
          onPress={() =>
            navigation.navigate('AddDeliveryManagement', {
              assignmentId: assignmentId,
              projectNumber: projectNumber,
              link: url,
            })
          }
        />
      </View>
    )
  }
  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <View style={{ height: height, width: '100%' }}>
          <Header
            showBack
            title="ระบบจัดการการขนส่ง"
            style={{ paddingBottom: 0 }}
            numberOfLines={2}
            widthText={Dimensions.get('window').width - 60}
            componentBelowHeader={<RenderFooter />}
          />

          <StationSecondStep
            data={data?.data}
            onEdit={onEdit}
            onDelete={onDelete}
            onLoadMore={onLoadMore}
          />
        </View>
      </Container>
    </Provider>
  )
}
