import {
  View,
  ViewStyle,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Platform,
} from 'react-native'
import React from 'react'

interface Props {
  children: JSX.Element | JSX.Element[]
  style?: ViewStyle
  backgroundColor?: string
  width?: string | number
  borderShadow?: number
  shadowAround?: boolean
  Pressable?: boolean
  Scrollable?: boolean
  onPress?: () => void
  borderRadiusTop?: boolean
  activeOpacity?: number
}

export default function Card({
  children,
  backgroundColor = '#fff',
  style,
  width = '100%',
  borderShadow = 16,
  shadowAround = false,
  Pressable = false,
  borderRadiusTop = false,
  onPress,
  activeOpacity = 1,
  Scrollable = false,
}: Props): JSX.Element {
  return Pressable ? (
    <TouchableOpacity
      onPress={onPress}
      activeOpacity={activeOpacity}
      style={[
        shadowAround ? styles.cardShadowAround : styles.cardShadow,
        { borderRadius: borderShadow, width },
      ]}
    >
      <View style={[styles.cardContainer, { backgroundColor }, style]}>
        {children}
      </View>
    </TouchableOpacity>
  ) : Scrollable ? (
    <View
      style={[
        shadowAround ? styles.cardShadowAround : styles.cardShadow,
        {
          borderRadius: borderShadow,
          width,
        },
      ]}
    >
      <ScrollView
        style={[
          styles.cardContainer,
          {
            backgroundColor,
          },
          style,
        ]}
      >
        {children}
      </ScrollView>
    </View>
  ) : borderRadiusTop ? (
    <View
      style={[
        styles.cardContainerBorderTop,
        {
          backgroundColor,
        },
        style,
      ]}
    >
      {children}
    </View>
  ) : (
    <View
      style={[
        shadowAround ? styles.cardShadowAround : styles.cardShadow,
        {
          borderRadius: borderShadow,
          width,
        },
      ]}
    >
      <View
        style={[
          styles.cardContainer,
          {
            backgroundColor,
          },
          style,
        ]}
      >
        {children}
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  cardShadow: {
    ...Platform.select({
      web: {
        boxShadow: '2px 2px 4px rgba(91, 91, 91, 0.2)',
        width: '100%',
      },
      default: {
        shadowColor: '#000000a1',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 2,
        paddingBottom: 2,
        paddingRight: 2,
      },
    }),
  },
  cardContainer: {
    overflow: 'hidden',
    padding: 16,
    width: '100%',
    borderRadius: 10,
  },
  cardContainerBorderTop: {
    overflow: 'hidden',
    padding: 16,
    width: '100%',
    borderTopEndRadius: 25,
    borderTopStartRadius: 25,
  },

  cardShadowAround: {
    ...Platform.select({
      ios: {
        shadowColor: 'rgba(90, 106, 56, 0.4)',
        shadowOffset: {
          width: 10,
          height: 10,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 2,
        paddingHorizontal: 2,
        paddingVertical: 2,
      },
      android: {
        shadowColor: 'rgba(90, 106, 56, 0.4)',
        shadowOffset: {
          width: 10,
          height: 10,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 2,
        paddingHorizontal: 2,
        paddingVertical: 2,
      },
      web: {
        shadowColor: 'rgba(90, 106, 56, 0.8)',
        shadowOffset: {
          width: 2,
          height: 1,
        },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,

        elevation: 10,
      },
    }),
  },
})
