import {
  View,
  TouchableOpacity,
  Image,
  Platform,
  StyleSheet,
} from 'react-native'
import React from 'react'
import Modal from 'components/Modal/Modal'
import dayjs from 'dayjs'
import CloseIcon from 'assets/images/icons/Close.png'
import Text from 'components/Text'
import * as yup from 'yup'
import request from 'utils/request'
import { useLocalization } from 'contexts/LocalizationContext'
import { Form, SubmitButton, Input } from 'components/Form'
interface Props {
  visible: boolean
  focusDate: Date
  taskId?: string | null
  id: string
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
  refresh: boolean
  workAssignmentData: { id: string; actual: number; ot: number; plan: number }[]
  setVisible: (visible: boolean) => void
}
export default function ModalViewDetail({
  visible,
  setVisible,
  setRefresh,
  focusDate,
  taskId,
  workAssignmentData,
  id,
}: Props) {
  const { t } = useLocalization()
  const findData = workAssignmentData
    ? workAssignmentData.find((item) => item.id === taskId)
    : null

  const dataModal = [
    {
      label: 'วันที่',
      value: dayjs(focusDate).format('DD/MM/BB'),
      color: 'black',
    },
    {
      label: 'Plan',
      value: findData?.plan,
      color: 'success',
    },
    // {
    //   label: 'Actual',
    //   value: findData?.actual,
    //   color: 'success',
    // },
    {
      label: 'OT',
      value: findData?.ot,
      color: 'warning',
    },
  ]
  const formDefaultValue = {
    actual: '',
  }
  const schema = yup.object().shape({
    actual: yup
      .number()
      .typeError('กรุณากรอกข้อมูลเป็นตัวเลข')
      .required('กรุณากรอกข้อมูลเป็นตัวเลข'),
  })
  const onSubmit = async (data: { Actual: string }) => {
    try {
      await request.patch(`/workAssignment/${id}/task/${taskId}/actual`, data)
      setRefresh((value) => !value)
      setVisible(false)
    } catch (e: any) {
      console.log(JSON.stringify(e.response, null, 2))
    }
  }
  return (
    <Modal
      visible={visible}
      //onRequestClose={() => setVisible(false)}
      width="70%"
      style={{ height: '60%' }}
    >
      <TouchableOpacity
        style={{ position: 'absolute', right: 8, top: 8 }}
        onPress={() => setVisible(false)}
      >
        <Image source={CloseIcon} style={{ width: 20, height: 20 }} />
      </TouchableOpacity>
      <View style={{ flex: 2, justifyContent: 'space-between' }}>
        <Form
          defaultValues={formDefaultValue}
          schema={schema}
          enableReinitial
          // style={{
          //   flex: 1,
          //   justifyContent: 'space-between',
          // }}
        >
          <View>
            {dataModal.map((item, idx) => {
              return (
                <View key={idx} style={{ flexDirection: 'row' }}>
                  <Text
                    color={
                      item.color === 'success'
                        ? 'success'
                        : item.color === 'warning'
                        ? 'warning'
                        : 'black'
                    }
                    style={{ width: 70 }}
                  >
                    {`${item.label}:`}
                  </Text>
                  <Text
                    color={
                      item.color === 'success'
                        ? 'success'
                        : item.color === 'warning'
                        ? 'warning'
                        : 'black'
                    }
                  >
                    {item.value}
                  </Text>
                </View>
              )
            })}
          </View>
          <Input name="actual" label={'actual'} style={styles.Input} />

          <View style={{ marginTop: 15, marginBottom: 24 }}>
            <SubmitButton
              testID="form.createCompany"
              title={t('Buttons.Save')}
              onSubmit={onSubmit}
              style={{ height: 16 }}
            />
          </View>
        </Form>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  Input: {
    ...Platform.select({
      web: {
        paddingVertical: 6,
        height: 40,
      },
      default: {
        paddingVertical: 6,
        //flex: 1,
        height: 40,
      },
    }),
  },
})
