import React from 'react'
import styled from '@emotion/native'
import Text from 'components/Text'
import {
  Image,
  ImageSourcePropType,
  Platform,
  StyleSheet,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native'
import ArrowWhite from 'assets/images/icons/ArrowWhite.png'
export type TouchableProps = {
  textColor?:
    | 'primary1'
    | 'primary2'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
  color?:
    | 'primary1'
    | 'primary2'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
  outline?: boolean
  block?: boolean
  disabled?: boolean
  icon?: ImageSourcePropType
  style?: ViewStyle
  arrowBack?: boolean
  padding?: string
  secondary?: boolean
  iconSize?: number
  iconBack?: boolean
}
interface Text {
  secondary?: boolean
}
export type Props = {
  testID?: string
  title: string
  onPress?: () => void
} & TouchableProps &
  ViewProps

const Touchable = styled.TouchableOpacity<TouchableProps>`
  background-color: ${(props) => props.theme.color[props.color || 'primary1']};
  padding: ${(props) => (props.padding ? '0px' : '10px')};
  border-radius: 10px;
  align-items: center;
  min-width: 120px;
  min-height: 60px;
  overflow: hidden;
  justify-content: center;
  ${({ theme, secondary }) => {
    if (secondary) {
      return `
        background-color: #ffffff;
        border: 1.5px solid ${theme.color.primary1};
      `
    } else {
      return `
        background-color: ${theme.color.primary1};
        border: 1.5px solid ${theme.color.primary1};
      `
    }
  }}
`
const ButtonText = styled(Text)<Text>`
  color: white;
  ${({ theme, secondary }) => {
    if (secondary) {
      return `
        color: ${theme.color.primary1};
      `
    }
  }}
`

const Button = ({
  testID,
  title,
  onPress = () => null,
  color = 'primary1',
  outline = false,
  block = false,
  disabled = false,
  textColor = 'white',
  icon,
  iconSize = 24,
  style,
  secondary = false,
  iconBack = false,
  ...props
}: Props): JSX.Element => {
  const isFrontIcon = !iconBack && icon
  const isBackIcon = iconBack && icon
  return (
    <Touchable
      testID={testID}
      onPress={onPress}
      color={color}
      outline={outline}
      block={block}
      disabled={disabled}
      secondary={secondary}
      style={[
        icon
          ? styles.hasIcon
          : props.arrowBack
          ? { ...styles.hasIcon, position: 'relative' }
          : styles.justifyCenter,
        style,
      ]}
      {...props}
    >
      {isFrontIcon && (
        <Image
          source={icon}
          style={{
            width: iconSize,
            height: iconSize,
            marginRight: 8,
            resizeMode: 'contain',
          }}
        />
      )}
      <ButtonText
        bold
        color={textColor}
        secondary={secondary}
        center
        fontSize="20px"
        lineHeight={'30px'}
        style={{
          paddingRight: props.arrowBack ? 24 : 0,
          width: props.arrowBack ? '90%' : 'auto',
        }}
      >
        {title}
        {props.arrowBack && Platform.OS === 'web' ? (
          <View style={{ width: 30 }} />
        ) : null}
      </ButtonText>
      {props.arrowBack && (
        <Image
          source={ArrowWhite}
          style={{
            position: 'absolute',
            right: 16,
            width: iconSize,
            height: iconSize,

            resizeMode: 'contain',
          }}
        />
      )}
      {isBackIcon && (
        <Image
          source={icon}
          style={{
            width: iconSize,
            height: iconSize,
            marginLeft: 8,
            resizeMode: 'contain',
          }}
        />
      )}
    </Touchable>
  )
}

export default Button
const styles = StyleSheet.create({
  hasIcon: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  justifyCenter: { justifyContent: 'center', alignItems: 'center' },
})
