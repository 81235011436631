import React, { Fragment, useState } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import Button from 'components/Button'
import Text from 'components/Text'
import { Input } from 'components/Form'
import {
  View,
  Platform,
  StyleSheet,
  Image,
  TouchableOpacity,
} from 'react-native'
import { useLocalization } from 'contexts/LocalizationContext'
import PlusAdd from '../../../assets/images/icons/PlusAdd.png'
import DeleteRed from '../../../assets/images/icons/deleteRed.png'
import Close from '../../../assets/images/icons/Close.png'
import Modal from '../../components/Modal/Modal'
import InputDate from 'components/Form/InputDate'
import dayjs from 'dayjs'
type FormData = {
  itemCode: null
  detail: null
  quantity: number
  pricePerUnit: number
  totalPrice: number
  deliveryDate: string
}

const ProductForm = (): JSX.Element => {
  const { t } = useLocalization()
  const { control } = useFormContext()
  const [isShowModal, setIsShowModal] = useState(false)
  const [removeIndex, setRemoveIndex] = useState<null | number>(null)
  const { fields, append, remove } = useFieldArray({
    name: 'products',
    control,
  })

  return (
    <Fragment>
      {fields.map((field, index) => {
        return (
          <Fragment key={field.id}>
            <View style={{ position: 'relative' }}>
              {fields.length > 1 ? (
                <TouchableOpacity
                  onPress={() => {
                    setIsShowModal(true)
                    setRemoveIndex(index)
                  }}
                  style={{
                    alignItems: 'flex-end',
                    position: 'absolute',
                    top: 8,
                    right: 0,
                    height: 24,
                    width: 24,
                    zIndex: 100,
                  }}
                >
                  <Image source={DeleteRed} style={{ width: 16, height: 16 }} />
                </TouchableOpacity>
              ) : (
                <></>
              )}
              <Input
                name={`products.${index}.itemCode`}
                label={t('screens.PurchaseOrderScreen.form.itemCode')}
                style={styles.Input}
                backgroundColor={
                  field?.project?.status === 'canceled' ? '#c0c0c0' : ''
                }
                textColor={
                  field?.project?.status === 'canceled' ? '#777777' : ''
                }
                editable={field?.project?.status === 'canceled' ? true : false}
              />

              <Input
                name={`products.${index}.detail`}
                multiline
                numberOfLines={6}
                label={t('screens.PurchaseOrderScreen.form.detail')}
                style={styles.Input}
                backgroundColor={
                  field?.project?.status === 'canceled' ? '#c0c0c0' : ''
                }
                textColor={
                  field?.project?.status === 'canceled' ? '#777777' : ''
                }
                editable={field?.project?.status === 'canceled' ? true : false}
              />
              <Input
                name={`products.${index}.quantity`}
                label={t('screens.PurchaseOrderScreen.form.quantity')}
                style={styles.Input}
                backgroundColor={
                  field?.project?.status === 'canceled' ? '#c0c0c0' : ''
                }
                textColor={
                  field?.project?.status === 'canceled' ? '#777777' : ''
                }
                editable={field?.project?.status === 'canceled' ? true : false}
              />
              <Input
                keyboardType="numeric"
                name={`products.${index}.pricePerUnit`}
                label={t('screens.PurchaseOrderScreen.form.pricePerUnit')}
                style={styles.Input}
                backgroundColor={
                  field?.project?.status === 'canceled' ? '#c0c0c0' : ''
                }
                textColor={
                  field?.project?.status === 'canceled' ? '#777777' : ''
                }
                editable={field?.project?.status === 'canceled' ? true : false}
              />
              <Input
                name={`products.${index}.totalPrice`}
                label={t('screens.PurchaseOrderScreen.form.totalPrice')}
                style={styles.Input}
                backgroundColor={
                  field?.project?.status === 'canceled' ? '#c0c0c0' : ''
                }
                textColor={
                  field?.project?.status === 'canceled' ? '#777777' : ''
                }
                editable={field?.project?.status === 'canceled' ? true : false}
              />
              <InputDate
                name={`products.${index}.deliveryDate`}
                label={t('screens.PurchaseOrderScreen.form.deliveryDate')}
                testID="date"
                style={{ height: 42 }}
                defaultValue={field?.deliveryDate}
                backgroundColor={
                  field?.project?.status === 'canceled' ? '#c0c0c0' : ''
                }
                textColor={
                  field?.project?.status === 'canceled' ? '#777777' : ''
                }
              />
            </View>
            <View
              style={{
                width: '100%',
                marginVertical: 8,
                borderColor: '#CDCDCD',
                borderWidth: 0.7,
              }}
            ></View>
            <Modal
              visible={isShowModal}
              onRequestClose={() => setIsShowModal(false)}
            >
              <View style={{ alignItems: 'center', position: 'relative' }}>
                <Image
                  source={Close}
                  style={{
                    width: 24,
                    height: 24,
                    position: 'absolute',
                    top: -10,
                    right: -10,
                  }}
                />
                <Text title1>ลบรายการสินค้า</Text>
                <Text>
                  คุณแน่ใจหรือไม่ว่าต้องการลบรายการสินค้านี้
                  หากลบแล้วข้อมูลจะหายไปทันที
                </Text>
                <View style={{ flexDirection: 'row', margin: 16 }}>
                  <Button
                    padding="0"
                    style={{ height: 40, marginRight: 12 }}
                    secondary
                    title="ยกเลิก"
                    onPress={() => setIsShowModal(false)}
                  />
                  <Button
                    padding="0"
                    style={{ height: 40, marginRight: 0 }}
                    title="ยืนยัน"
                    onPress={() => {
                      if (typeof removeIndex === 'number') {
                        remove(removeIndex)
                        setRemoveIndex(null)
                        setIsShowModal(false)
                      }
                    }}
                  />
                </View>
              </View>
            </Modal>
          </Fragment>
        )
      })}
      <View
        style={{
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <Text>เพิ่มสินค้า</Text>

        <TouchableOpacity
          onPress={() =>
            append({
              itemCode: '',
              detail: '',
              quantity: '',
              pricePerUnit: '',
              totalPrice: '',
              deliveryDate: dayjs().format('YYYY-MM-DD'),
            })
          }
        >
          <Image source={PlusAdd} style={{ width: 16, height: 16 }} />
        </TouchableOpacity>
      </View>
    </Fragment>
  )
}

const styles = StyleSheet.create({
  Input: {
    ...Platform.select({
      web: {
        paddingVertical: 6,
        height: 40,
      },
      default: {
        paddingVertical: 6,
        flex: 1,
      },
    }),
  },
})

export default ProductForm
