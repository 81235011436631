import React, { useState } from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Text from 'components/Text'
import Background from 'components/Background'
import { FlatList, View, Platform } from 'react-native'
import Plus from 'assets/images/icons/Plus.png'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { Provider } from 'react-native-paper'
import DotMenuLiat from 'components/DotMenuLiat'
import Card from 'components/Card'
import Button from 'components/Button'
import { useFocusEffect } from '@react-navigation/native'
import dayjs from 'dayjs'
import { useLocalization } from 'contexts/LocalizationContext'
import { RouteProp } from '@react-navigation/native'
import Download from 'assets/images/icons/Download.png'
import request from 'utils/request'
import env from '../../../config'
import { useAuth } from 'contexts/AuthContext'
import * as Sharing from 'expo-sharing'
import * as FileSystem from 'expo-file-system'
import ModalAlert from 'components/ModalAlert'
export type Props = {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<{ params: { PoId: string; title: string } }, 'params'>
}

export interface Datum {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  projectNumber: string
  projectDate: Date
  itemCode: string
  detail: string
  quantity: number
  unit: string
  fundPerUnit: number
  totalFund: number
  dueDate: Date
  status: string
  deliveredDate: Date
  lastedUpdateActualDate?: any
  farmId?: any
  speciesId?: any
  orderId: string
  productId: string
}

export interface RootObject {
  data: Datum[]
  total: number
  page: number
  limit: number
}

const ProjectScreen = ({ navigation, route }: Props): JSX.Element => {
  const { t, locale } = useLocalization()
  const [refresh, setRefresh] = useState<boolean>(false)
  const [isFail, setIsfail] = useState<boolean>(false)
  const { PoId } = route.params
  const limit = 10
  const page = 1
  const [Project, setProject] = useState<RootObject>({
    data: [],
    page: 1,
    total: 1,
    limit: 10,
  })
  const fatchData = async () => {
    try {
      const res = await request.get(
        `/Project?limit=${limit}&page=${page}&purchaseOrderId=${PoId}&isCanceled=false`
      )
      setProject(res?.data)
    } catch (err) {
      console.log(err)
    }
  }

  const onLoadMore = async () => {
    try {
      if (Project?.total > Project.data.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        try {
          const res = await request.get(
            `/Project?limit=${limit}&page=${
              Project?.page + 1
            }&purchaseOrderId=${PoId}&isCanceled=false`
          )
          const data = {
            data: [...Project.data, ...res.data.data],
            page: Project?.page + 1,
            total: Project.total,
            limit: Project.limit,
          }
          setProject(data)
        } catch (err) {
          console.log(err)
        }
      }
    } catch (e) {
      // console.log(e)
    }
  }

  const [ProjectCancel, setProjectCancel] = useState<RootObject>({
    data: [],
    page: 1,
    total: 1,
    limit: 10,
  })
  const fatchDataCancel = async () => {
    try {
      const res = await request.get(
        `/Project?limit=${limit}&page=${page}&purchaseOrderId=${PoId}&isCanceled=true`
      )
      setProjectCancel(res?.data)
    } catch (err) {
      console.log(err)
    }
  }
  const onLoadMoreCancel = async () => {
    try {
      if (ProjectCancel?.total > ProjectCancel.data.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        try {
          const res = await request.get(
            `/Project?limit=${limit}&page=${
              ProjectCancel?.page + 1
            }&purchaseOrderId=${PoId}&isCanceled=true`
          )
          const data = {
            data: [...ProjectCancel.data, ...res.data.data],
            page: ProjectCancel?.page + 1,
            total: ProjectCancel.total,
            limit: ProjectCancel.limit,
          }
          setProjectCancel(data)
        } catch (err) {
          console.log(err)
        }
      }
    } catch (e) {
      // console.log(e)
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      fatchData()
      fatchDataCancel()
    }, [refresh])
  )

  //โหลดไฟล์
  const {
    state: { userToken },
  } = useAuth()
  const config = env()
  const baseUrl = config.API_URL
  const openShareDialogAsync = async (fileUri: string) => {
    await Sharing.shareAsync(fileUri)
  }
  const onPrintSaveWeb = async (projectNumber: string) => {
    try {
      const dataPdf = await request
        .get(`/project/${projectNumber}/report`, { responseType: 'blob' })
        .then((res: any) => res.data)
      const url = window.URL.createObjectURL(
        new Blob([dataPdf as BlobPart], {
          type: 'application/xlsx',
        })
      )
      // window.open(url)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `โปรเจค-${projectNumber}.xlsx`) //or any other extension
      document.body.appendChild(link)
      link.click()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      //message.error('Failed file.')
      console.log(e)
    }
  }
  const onPrintSaveApp = async (projectNumber: string) => {
    try {
      const downloadedFile = await FileSystem.downloadAsync(
        `${baseUrl}/project/${projectNumber}/report`,
        FileSystem.documentDirectory + `โปรเจค-${projectNumber}.xlsx`,
        { headers: { Authorization: 'Bearer ' + userToken } }
      )

      if (downloadedFile.status === 200) {
        openShareDialogAsync(downloadedFile.uri)
      }
    } catch (e: any) {
      //message.error('Failed file.')
      console.log(e)
    }
  }

  const onPrintSave = (projectNumber: string) => {
    if (Platform.OS !== 'web') {
      onPrintSaveApp(projectNumber)
    } else {
      onPrintSaveWeb(projectNumber)
    }
  }
  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={t('screens.ProjectScreen.title')}
          alignTitle="flex-start"
        />
        <View style={{ width: '90%', marginTop: 70, marginBottom: 20 }}>
          <Button
            title={t('screens.ProjectScreen.buttonProject')}
            onPress={() =>
              navigation.navigate('ProjectDetailEdit', {
                PoId: PoId,
                PjId: null,
              })
            }
            icon={Plus}
          />
        </View>
        <Content enabledScroll={true}>
          <FlatList
            nestedScrollEnabled={true}
            data={Project?.data}
            onEndReached={onLoadMore}
            indicatorStyle="default"
            style={{ marginTop: 15 }}
            contentContainerStyle={{ paddingBottom: 0 }}
            renderItem={({ item }) => {
              return (
                <View
                  style={{
                    marginBottom: 20,
                  }}
                >
                  <Card>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <View>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <Text color="black" sub1 medium>
                            {`${t('screens.ProjectScreen.projectNumber')} : ${
                              item.projectNumber
                            }`}
                          </Text>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <Text color="black85" sub1 medium>
                            {`${t('screens.ProjectScreen.projectDate')} : `}
                          </Text>
                          <Text color="success" sub1 medium>
                            {dayjs(item.projectDate)
                              .add(locale === 'th' ? 543 : 0, 'years')
                              .format('D MMM YYYY')}
                          </Text>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <Text color="black" sub1 medium>
                            {`สถานะ : `}
                          </Text>
                          {item.status === 'completed' ? (
                            <Text color="success" sub1 medium>
                              {`• ${t('screens.ProjectScreen.success')}`}
                            </Text>
                          ) : item.status === 'inProgress' ? (
                            <Text color="success" sub1 medium>
                              {`• กำลังดำเนินการ`}
                            </Text>
                          ) : item.status === 'pending' ? (
                            <Text color="success" sub1 medium>
                              {`• ยังไม่เริ่มดำเนินการ`}
                            </Text>
                          ) : (
                            <></>
                          )}
                        </View>
                      </View>
                      <DotMenuLiat
                        navigation={() =>
                          navigation.navigate('ProjectDetailEdit', {
                            PoId: PoId,
                            PjId: item.projectNumber,
                          })
                        }
                        onCancel={async () => {
                          await request.delete(
                            `/project/${item.projectNumber}/cancel`
                          )
                          setRefresh((prev) => !prev)
                        }}
                        onDelete={async () => {
                          await request.delete(`/project/${item.projectNumber}`)
                          setRefresh((prev) => !prev)
                        }}
                      />
                    </View>
                    <View
                      style={{
                        paddingTop: 10,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        padding="10px"
                        style={{ width: '48%' }}
                        icon={Download}
                        iconSize={20}
                        title={t('screens.ProjectScreen.report')}
                        onPress={() => {
                          onPrintSave(item.projectNumber)
                        }}
                        color="primary1"
                        secondary
                      />
                      <Button
                        padding="10px"
                        style={{ width: '48%' }}
                        title={t('screens.ProjectScreen.workAssignment')}
                        onPress={() => {
                          console.log(item?.speciesId)
                          item?.speciesId
                            ? navigation.navigate('WorkAssignment', {
                                PoId: PoId,
                                PjId: item.projectNumber,
                              })
                            : setIsfail(true)
                        }}
                      />
                    </View>
                  </Card>
                  <ModalAlert
                    type="error"
                    visible={isFail}
                    setVisibile={setIsfail}
                    textDesc="กรุณาเพิ่มข้อมูลสายพันธุ์ไผ่ก่อน"
                    onRequestClose={() => setIsfail(false)}
                  />
                </View>
              )
            }}
            keyExtractor={(item) => item.projectNumber}
          />
          {ProjectCancel?.data?.length > 0 ? (
            <>
              <View
                style={{
                  width: '100%',
                  marginVertical: 8,
                  borderColor: '#CDCDCD',
                  borderWidth: 0.7,
                }}
              ></View>
              <Text
                style={{
                  color: 'red',
                  paddingLeft: 15,
                }}
              >
                รายการที่ยกเลิก
              </Text>
              <FlatList
                nestedScrollEnabled={true}
                data={ProjectCancel?.data}
                onEndReached={onLoadMoreCancel}
                indicatorStyle="default"
                style={{ marginTop: 15 }}
                contentContainerStyle={{ paddingBottom: 60 }}
                renderItem={({ item }) => {
                  return (
                    <View
                      style={{
                        marginBottom: 10,
                      }}
                    >
                      <Card>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <View>
                            <View
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <Text color="black" sub1 medium>
                                {`${t(
                                  'screens.ProjectScreen.projectNumber'
                                )} : ${item.projectNumber}`}
                              </Text>
                            </View>
                            <View
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <Text color="black85" sub1 medium>
                                {`${t('screens.ProjectScreen.projectDate')} : `}
                              </Text>
                              <Text color="success" sub1 medium>
                                {dayjs(item.projectDate)
                                  .add(locale === 'th' ? 543 : 0, 'years')
                                  .format('D MMM YYYY')}
                              </Text>
                            </View>
                            <View
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <Text color="black" sub1 medium>
                                สถานะ :
                              </Text>
                              <Text color="error" sub1 medium>
                                • ยกเลิกโปรเจค
                              </Text>
                            </View>
                          </View>
                          <DotMenuLiat
                            // navigation={() =>
                            //   navigation.navigate('ProjectDetailEdit', {
                            //     PoId: PoId,
                            //     PjId: item.projectNumber,
                            //   })
                            // }
                            // onCancel={async () => {
                            //   await request.delete(
                            //     `/project/${item.projectNumber}/cancel`
                            //   )
                            //   setRefresh((prev) => !prev)
                            // }}
                            onDelete={async () => {
                              await request.delete(
                                `/project/${item.projectNumber}`
                              )
                              setRefresh((prev) => !prev)
                            }}
                          />
                        </View>
                        <View
                          style={{
                            paddingTop: 10,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Button
                            disabled
                            padding="10px"
                            style={{ width: '48%' }}
                            icon={Download}
                            iconSize={20}
                            title={t('screens.ProjectScreen.report')}
                            onPress={() => {
                              onPrintSave(item.projectNumber)
                            }}
                            color="primary1"
                            secondary
                          />
                          <Button
                            disabled
                            padding="10px"
                            style={{ width: '48%' }}
                            title={t('screens.ProjectScreen.workAssignment')}
                            onPress={() =>
                              navigation.navigate('WorkAssignment', {
                                PoId: PoId,
                                PjId: item.projectNumber,
                              })
                            }
                          />
                        </View>
                      </Card>
                    </View>
                  )
                }}
                keyExtractor={(item) => item.projectNumber}
              />
            </>
          ) : (
            <></>
          )}
        </Content>
      </Container>
    </Provider>
  )
}

export default ProjectScreen
