import { View, Dimensions, Platform } from 'react-native'
import React, { useState } from 'react'
import { Provider } from 'react-native-paper'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import StationSecondStep from 'components/StationSecondStep'
import Button from 'components/Button'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import { RouteProp, useFocusEffect } from '@react-navigation/native'
import PlusIcon from 'assets/images/icons/Plus.png'
import request from 'utils/request'
import { useAuth } from 'contexts/AuthContext'
export interface RootObject {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  path: string
  text: string
}
interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { params: { assignmentId: string; farmData: any } },
    'params'
  >
}
export default function StationGrowthTrackingDetail({
  navigation,
  route,
}: Props): JSX.Element {
  const { assignmentId, farmData } = route.params
  const [refresh, setRefresh] = useState(false)
  const {
    state: { link },
  } = useAuth()
  const url: RootObject[] = []
  if (link) {
    link?.map((item: RootObject) => {
      if (
        item.text === 'ชนิด อายุพืช จากรายงานการปลูก' ||
        item.text === 'ข้อมูลพืช' ||
        item.text === 'คู่มือการสุ่มตรวจการเจริญเติบโตของพืช'
      ) {
        return url.push(item)
      }
    })
  }
  const limit = 10
  const page = 1
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any>({
    data: [],
    page: 1,
    total: 1,
    limit: 10,
  })
  const fatchData = async () => {
    try {
      const res = await request.get(
        `/workAssignment/${assignmentId}/growthLogs?limit=${limit}&page=${page}`
      )
      setData(res?.data)
    } catch (err) {
      console.log(err)
    }
  }
  useFocusEffect(
    React.useCallback(() => {
      fatchData()
    }, [refresh])
  )
  const onLoadMore = async () => {
    try {
      if (data.data.length < data.total) {
        const res = await request.get(
          `/workAssignment/${assignmentId}/growthLogs?limit=${limit}&page=${
            data?.page + 1
          }`
        )
        const value = {
          data: [...data.data, ...res.data.data],
          page: data?.page + 1,
          total: data.total,
          limit: data.limit,
        }
        setData(value)
      } else {
        return null
      }
    } catch (e) {
      console.log(e)
    }
  }
  // const { data, mutate } = useSWR(
  //   () =>
  //     assignmentId
  //       ? [
  //           `/workAssignment/${assignmentId}/growthLogs?limit=${limit}&page=1`,
  //           refresh,
  //         ]
  //       : null,
  //   fetcher
  // )

  const onEdit = (editId: string) => {
    navigation.navigate('EditGrowthTracking', {
      editId,
      assignmentId,
      farmData,
      link: url,
    })
  }

  const onDelete = async (deleteId: string) => {
    try {
      await request.delete(
        `/workAssignment/${assignmentId}/growthLogs/${deleteId}`
      )
      setRefresh((prev) => !prev)
    } catch (e) {
      console.log(e)
    }
  }

  const RenderFooter = () => {
    return (
      <View style={{ marginTop: 32, alignItems: 'center', width: '100%' }}>
        <Button
          title="เพิ่มข้อมูล"
          style={{ width: Platform.OS === 'web' ? '90%' : '90%' }}
          icon={PlusIcon}
          onPress={() => {
            navigation.navigate('AddGrowthTracking', {
              assignmentId: assignmentId,
              farmData: farmData,
              link: url,
            })
          }}
        />
      </View>
    )
  }

  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title="ระบบตรวจวัดและวิเคราะห์การเจริญเติบโตของพืช"
          style={{ paddingBottom: 0 }}
          numberOfLines={2}
          widthText={Dimensions.get('window').width - 60}
          componentBelowHeader={<RenderFooter />}
        />

        <StationSecondStep
          data={data?.data}
          onDelete={onDelete}
          onEdit={onEdit}
          onLoadMore={onLoadMore}
        />
      </Container>
    </Provider>
  )
}
