import {
  View,
  ScrollView,
  TouchableOpacity,
  Image,
  Linking,
} from 'react-native'
import React, { useState } from 'react'
import Container from 'components/Container'
import Header from 'components/Header'
import Background from 'components/Background'
import Content from 'components/Content'
import Text from 'components/Text'
import Card from 'components/Card'
import { Form, SubmitButton } from 'components/Form'
import * as yup from 'yup'
// import { Grid } from 'antd-mobile'
import theme from 'theme/index'
import PlusAdd from 'assets/images/icons/PlusAdd.png'
import LinkIcon from 'assets/images/icons/Link.png'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import FakeForm from 'components/Form/FakeForm'
import request, { fetcher } from 'utils/request'
import { RouteProp } from '@react-navigation/native'
import ResultAnalysis from 'components/ResultAnalysis'
import InputDate from 'components/Form/InputDate'
import useSWR from 'swr'
import CannotGetData from 'components/CannotGetData'
import ModalAlert from 'components/ModalAlert'
export interface RootObject {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  path: string
  text: string
}
export default function EditPreparePlot({
  navigation,
  route,
}: {
  navigation: StackNavigationHelpers
  route: RouteProp<
    { params: { id: string; editId: string; link: RootObject[] } },
    'params'
  >
}): JSX.Element {
  const { id, editId, link } = route.params
  const schema = yup.object().shape({
    date: yup.string().required('กรุณากรอกวันที่'),
    data: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string(),
          quantity: yup.string(),
          numberOfArea: yup.string(),
        })
      )
      .required('กรุณากรอกข้อมูล'),
  })

  const { data } = useSWR(
    `/workAssignment/${id}/nutrient-usage/${editId}`,
    fetcher
  )

  const [visible, setVisible] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const onSubmitForm = async (data: {
    date: string
    data: {
      name: string
      quantity: string
      numberOfArea: string
    }[]
  }) => {
    try {
      await request.patch(`/workAssignment/${id}/nutrient-usage/${editId}`, {
        data:
          data.data.map((el) => ({
            ...el,
            quantity: +el.quantity,
            numberOfArea: +el.numberOfArea,
          })) || [],
        date: data?.date,
        assignmentId: id,
        type: 'soil',
      })
      setIsSuccess(true)
    } catch (e) {
      console.log(e)
    }
  }
  if (!data) {
    return <CannotGetData titleHeader="ปริมาณธาตุอาหารและฮอร์โมน (ดิน)" />
  }

  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <Header
        showBack
        title="แก้ไขปริมาณธาตุอาหารและฮอร์โมน (ดิน)"
        widthText={'90%'}
      />
      <Content enabledScroll={false} noPadding>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <Card
            borderRadiusTop
            style={{ justifyContent: 'space-between', flex: 1, marginTop: 32 }}
          >
            <Form schema={schema} defaultValues={{}}>
              <InputDate
                label="วันที่"
                name="date"
                iconRight
                defaultValue={data.date}
              />

              <ResultAnalysis id={id} />

              <FakeForm
                name="data"
                visible={visible}
                setVisible={setVisible}
                defaultValue={data.data}
              />
              <View
                style={{
                  marginTop: 8,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text sub1>เพิ่มข้อมูล</Text>
                <TouchableOpacity onPress={() => setVisible(true)}>
                  <Image source={PlusAdd} style={{ height: 24, width: 24 }} />
                </TouchableOpacity>
              </View>
              <View
                style={{
                  flexDirection: 'column',
                  marginTop: 12,
                }}
              >
                {link?.map((item: RootObject, idx: any) => {
                  return (
                    <View
                      key={idx}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 12,
                      }}
                    >
                      <Image
                        source={LinkIcon}
                        style={{
                          width: 18,
                          height: 18,
                          marginRight: 8,
                        }}
                      />
                      <TouchableOpacity
                        onPress={() => Linking.openURL(item.path)}
                      >
                        <Text
                          sub1
                          color="secondary2"
                          style={{
                            textDecorationLine: 'underline',
                            textDecorationColor: theme.color.secondary2,
                          }}
                        >
                          {item.text}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )
                })}
              </View>
              <View style={{ marginTop: 32, paddingHorizontal: '8%' }}>
                <SubmitButton title="บันทึก" onSubmit={onSubmitForm} />
              </View>
            </Form>
          </Card>
        </ScrollView>
      </Content>
      <ModalAlert
        visible={isSuccess}
        setVisibile={setIsSuccess}
        textDesc="แก้ไขสำเร็จ"
        onRequestClose={() => navigation.goBack()}
      />
    </Container>
  )
}
