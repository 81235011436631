import { View, Platform } from 'react-native'
import React, { useState } from 'react'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import { RouteProp } from '@react-navigation/native'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import { useLocalization } from 'contexts/LocalizationContext'
import Content from 'components/Content'
import Text from 'components/Text'
import * as yup from 'yup'
import { Form, Input, SubmitButton } from 'components/Form'
import theme from 'theme/index'
import request from 'utils/request'
import OTPInput from 'react-native-otp-textinput'
import ModalAlert from 'components/ModalAlert'
interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<{ params: { email: string } }, 'params'>
}
export default function OtpScreen({ route, navigation }: Props): JSX.Element {
  const { t } = useLocalization()
  const [otpInput, setOtpInput] = useState<string>('')
  const defaultValues = {
    otp: '',
  }
  const [isFail, setIsfail] = useState<boolean>(false)
  const { email } = route.params
  const schema = yup.object().shape({
    //otp: yup.string().required('กรุณาใส่รหัส otp'),
  })
  const onSubmit = async () => {
    try {
      const res = await request.post(`/user/validate-otp`, {
        email: email,
        otp: otpInput,
      })
      if (res?.data?.token) {
        navigation.navigate('FillNewPassword', { token: res?.data?.token })
      } else {
        setIsfail(true)
      }
    } catch (e) {
      setIsfail(true)
      console.log(e)
    }
  }
  return (
    <Container>
      <Background typeBackground="BgWithCircle" />
      <Header showBack title="" />
      <Content enabledScroll={false}>
        <View style={{ flex: 0.8, justifyContent: 'center' }}>
          <Text semiBold h1 color="white" center style={{ marginBottom: 16 }}>
            {t('screens.OtpScreen.title')}
          </Text>
          <Text
            semiBold
            color="white"
            fontSize="16px"
            style={{ marginBottom: 16 }}
          >
            {'เราได้ส่งรหัส OTP ไปที่อีเมลของคุณ'}
          </Text>
          <Text
            semiBold
            color="white"
            fontSize="16px"
            style={{ marginBottom: 16 }}
          >
            {'โปรดตรวจสอบอีเมลของคุณเพื่อดูรหัส 6 หลัก'}
          </Text>
          <Text
            semiBold
            color="white"
            fontSize="16px"
            style={{ marginBottom: 16 }}
          >
            {'เราได้ส่งรหัสไปให้คุณที่'}
          </Text>
          <Text
            semiBold
            color="white"
            fontSize="16px"
            style={{ marginBottom: 16 }}
          >
            {email}
          </Text>
          <Form defaultValues={defaultValues} schema={schema}>
            {Platform.OS !== 'web' ? (
              <OTPInput
                handleTextChange={(code: string) => setOtpInput(code)}
                inputCount={6}
                keyboardType="numeric"
                containerStyle={{ paddingHorizontal: 0 }}
                textInputStyle={{
                  paddingHorizontal: 0,
                  width: 34,
                  color: '#fff',
                }}
                tintColor={theme.color.white}
                offTintColor={theme.color.white}
              />
            ) : (
              <Input
                maxLength={6}
                name="otp"
                label={t('screens.OtpScreen.form.Otp')}
                colorLabel="white"
                selectionColor={'white'}
                onChangeInput={(value: string) => setOtpInput(value)}
                style={{ color: theme.color.white, paddingVertical: 8 }}
                themeWhite
              />
            )}

            <View style={{ marginTop: 16 }}>
              <SubmitButton
                testID="signupForm"
                onSubmit={onSubmit}
                title={t('screens.ForgetPasswordScreen.button')}
              />
            </View>
          </Form>
        </View>
        <ModalAlert
          type="error"
          visible={isFail}
          setVisibile={setIsfail}
          textDesc="กรุณาตรวจสอบรหัส OTP อีกครั้ง"
          onRequestClose={() => setIsfail(false)}
        />
      </Content>
    </Container>
  )
}
