import React, { Suspense, useState } from 'react'
import { Form, SubmitButton } from 'components/Form'
import { useLocalization } from 'contexts/LocalizationContext'
import { useNavigation } from '@react-navigation/native'
import { ScrollView } from 'react-native-gesture-handler'
import * as yup from 'yup'
import Container from 'components/Container'
import Background from 'components/Background'
import Content from 'components/Content'
import Header from 'components/Header'
import { View } from 'react-native'
import Card from 'components/Card'
import Text from 'components/Text'
import { FarmerTool } from 'src/interface'
import request, { fetcher } from 'utils/request'
import Collapse from 'components/Collapse'
import useSWR from 'swr'
const LazyFormMachineOne = React.lazy(() => import('./FormMachineOne'))
const LazyFormMachineTwo = React.lazy(() => import('./FormMachineTwo'))
const LazyFormMachineThree = React.lazy(() => import('./FormMachineThree'))
const LazyFormMachineFour = React.lazy(() => import('./FormMachineFour'))
const LazyFormMachineFive = React.lazy(() => import('./FormMachineFive'))
const LazyFormMachineSix = React.lazy(() => import('./FormMachineSix'))
const LazyFormMachineSeven = React.lazy(() => import('./FormMachineSeven'))
const LazyFormMachineEight = React.lazy(() => import('./FormMachineEight'))

type FormData = FarmerTool

const FarmerMachineryScreen = (): JSX.Element => {
  const navigation = useNavigation()
  const { t } = useLocalization()
  const [collapseOne, setCollapseOne] = useState<boolean>(true)
  const [collapseTwo, setCollapseTwo] = useState<boolean>(true)
  const [collapseThree, setCollapseThree] = useState<boolean>(true)
  const [collapseFour, setCollapseFour] = useState<boolean>(true)
  const [collapseFive, setCollapseFive] = useState<boolean>(true)
  const [collapseSix, setCollapseSix] = useState<boolean>(true)
  const [collapseSeven, setCollapseSeven] = useState<boolean>(true)
  const [collapseEight, setCollapseEight] = useState<boolean>(true)
  const [refresh, setRefresh] = useState(false)
  const { data } = useSWR(() => ['/user/me/tool', refresh], fetcher)
  const schema = yup.object().shape({})

  const defaultValues = {
    generatorHire:
      data?.generatorHire === 0 ? undefined : data?.generatorHire?.toString(),
    generatorNonHire:
      data?.generatorNonHire === 0
        ? undefined
        : data?.generatorNonHire?.toString(),
    wheelPloughHire:
      data?.wheelPloughHire === 0
        ? undefined
        : data?.wheelPloughHire?.toString(),
    wheelPloughNonHire:
      data?.wheelPloughNonHire === 0
        ? undefined
        : data?.wheelPloughNonHire?.toString(),
    singleCylinderEngineTractorHire:
      data?.singleCylinderEngineTractorHire === 0
        ? undefined
        : data?.singleCylinderEngineTractorHire?.toString(),
    singleCylinderEngineTractorNonHire:
      data?.singleCylinderEngineTractorNonHire === 0
        ? undefined
        : data?.singleCylinderEngineTractorNonHire?.toString(),
    less50HpTractorHire:
      data?.less50HpTractorHire === 0
        ? undefined
        : data?.less50HpTractorHire?.toString(),
    less50HpTractorNonHire:
      data?.less50HpTractorNonHire === 0
        ? undefined
        : data?.less50HpTractorNonHire?.toString(),
    hp51To80TractorHire:
      data?.hp51To80TractorHire === 0
        ? undefined
        : data?.hp51To80TractorHire?.toString(),
    hp51To80TractorNonHire:
      data?.hp51To80TractorNonHire === 0
        ? undefined
        : data?.hp51To80TractorNonHire?.toString(),
    hp81To100TractorHire:
      data?.hp81To100TractorHire === 0
        ? undefined
        : data?.hp81To100TractorHire?.toString(),
    hp81To100TractorNonHire:
      data?.hp81To100TractorNonHire === 0
        ? undefined
        : data?.hp81To100TractorNonHire?.toString(),
    more100HpTractorHire:
      data?.more100HpTractorHire === 0
        ? undefined
        : data?.more100HpTractorHire?.toString(),
    more100HpTractorNonHire:
      data?.more100HpTractorNonHire === 0
        ? undefined
        : data?.more100HpTractorNonHire?.toString(),
    dieselEngineHire:
      data?.dieselEngineHire === 0
        ? undefined
        : data?.dieselEngineHire?.toString(),
    dieselEngineNonHire:
      data?.dieselEngineNonHire === 0
        ? undefined
        : data?.dieselEngineNonHire?.toString(),
    benzineEngineHire:
      data?.benzineEngineHire === 0
        ? undefined
        : data?.benzineEngineHire?.toString(),
    benzineEngineNonHire:
      data?.benzineEngineNonHire === 0
        ? undefined
        : data?.benzineEngineNonHire?.toString(),
    solarCellHire:
      data?.solarCellHire === 0 ? undefined : data?.solarCellHire?.toString(),
    solarCellNonHire:
      data?.solarCellNonHire === 0
        ? undefined
        : data?.solarCellNonHire?.toString(),
    plate3To4PlowHire:
      data?.plate3To4PlowHire === 0
        ? undefined
        : data?.plate3To4PlowHire?.toString(),
    plate3To4PlowNonHire:
      data?.plate3To4PlowNonHire === 0
        ? undefined
        : data?.plate3To4PlowNonHire?.toString(),
    plate5To7PlunderHire:
      data?.plate5To7PlunderHire === 0
        ? undefined
        : data?.plate5To7PlunderHire?.toString(),
    plate5To7PlunderNonHire:
      data?.plate5To7PlunderNonHire === 0
        ? undefined
        : data?.plate5To7PlunderNonHire?.toString(),
    ripperHire:
      data?.ripperHire === 0 ? undefined : data?.ripperHire?.toString(),
    ripperNonHire:
      data?.ripperNonHire === 0 ? undefined : data?.ripperNonHire?.toString(),
    moldboardPlowHire:
      data?.moldboardPlowHire === 0
        ? undefined
        : data?.moldboardPlowHire?.toString(),
    moldboardPlowNonHire:
      data?.moldboardPlowNonHire === 0
        ? undefined
        : data?.moldboardPlowNonHire?.toString(),
    twoRowsCultivatorHire:
      data?.twoRowsCultivatorHire === 0
        ? undefined
        : data?.twoRowsCultivatorHire?.toString(),
    twoRowsCultivatorNonHire:
      data?.twoRowsCultivatorNonHire === 0
        ? undefined
        : data?.twoRowsCultivatorNonHire?.toString(),
    crawlerTractorHire:
      data?.crawlerTractorHire === 0
        ? undefined
        : data?.crawlerTractorHire?.toString(),
    crawlerTractorNonHire:
      data?.crawlerTractorNonHire === 0
        ? undefined
        : data?.crawlerTractorNonHire?.toString(),
    rotaryCultivatorHire:
      data?.rotaryCultivatorHire === 0
        ? undefined
        : data?.rotaryCultivatorHire?.toString(),
    rotaryCultivatorNonHire:
      data?.rotaryCultivatorNonHire === 0
        ? undefined
        : data?.rotaryCultivatorNonHire?.toString(),
    soilAmendmentToolHire:
      data?.soilAmendmentToolHire === 0
        ? undefined
        : data?.soilAmendmentToolHire?.toString(),
    soilAmendmentToolNonHire:
      data?.soilAmendmentToolNonHire === 0
        ? undefined
        : data?.soilAmendmentToolNonHire?.toString(),
    cultivateMachineHire:
      data?.cultivateMachineHire === 0
        ? undefined
        : data?.cultivateMachineHire?.toString(),
    cultivateMachineNonHire:
      data?.cultivateMachineNonHire === 0
        ? undefined
        : data?.cultivateMachineNonHire?.toString(),
    manualTransplanterHire:
      data?.manualTransplanterHire === 0
        ? undefined
        : data?.manualTransplanterHire?.toString(),
    manualTransplanterNonHire:
      data?.manualTransplanterNonHire === 0
        ? undefined
        : data?.manualTransplanterNonHire?.toString(),
    transplanterTractorHire:
      data?.transplanterTractorHire === 0
        ? undefined
        : data?.transplanterTractorHire?.toString(),
    transplanterTractorNonHire:
      data?.transplanterTractorNonHire === 0
        ? undefined
        : data?.transplanterTractorNonHire?.toString(),
    seedingMachineHire:
      data?.seedingMachineHire === 0
        ? undefined
        : data?.seedingMachineHire?.toString(),
    seedingMachineNonHire:
      data?.seedingMachineNonHire === 0
        ? undefined
        : data?.seedingMachineNonHire?.toString(),
    sowingMachineHire:
      data?.sowingMachineHire === 0
        ? undefined
        : data?.sowingMachineHire?.toString(),
    sowingMachineNonHire:
      data?.sowingMachineNonHire === 0
        ? undefined
        : data?.sowingMachineNonHire?.toString(),
    cassavaPlanterHire:
      data?.cassavaPlanterHire === 0
        ? undefined
        : data?.cassavaPlanterHire?.toString(),
    cassavaPlanterNonHire:
      data?.cassavaPlanterNonHire === 0
        ? undefined
        : data?.cassavaPlanterNonHire?.toString(),
    sugarCanePlanterHire:
      data?.sugarCanePlanterHire === 0
        ? undefined
        : data?.sugarCanePlanterHire?.toString(),
    sugarCanePlanterNonHire:
      data?.sugarCanePlanterNonHire === 0
        ? undefined
        : data?.sugarCanePlanterNonHire?.toString(),
    cornPlanterHire:
      data?.cornPlanterHire === 0
        ? undefined
        : data?.cornPlanterHire?.toString(),
    cornPlanterNonHire:
      data?.cornPlanterNonHire === 0
        ? undefined
        : data?.cornPlanterNonHire?.toString(),
    mountedSprayerHire:
      data?.mountedSprayerHire === 0
        ? undefined
        : data?.mountedSprayerHire?.toString(),
    mountedSprayerNonHire:
      data?.mountedSprayerNonHire === 0
        ? undefined
        : data?.mountedSprayerNonHire?.toString(),
    knapsackSprayerHire:
      data?.knapsackSprayerHire === 0
        ? undefined
        : data?.knapsackSprayerHire?.toString(),
    knapsackSprayerNonHire:
      data?.knapsackSprayerNonHire === 0
        ? undefined
        : data?.knapsackSprayerNonHire?.toString(),
    mountedFertilizerHire:
      data?.mountedFertilizerHire === 0
        ? undefined
        : data?.mountedFertilizerHire?.toString(),
    mountedFertilizerNonHire:
      data?.mountedFertilizerNonHire === 0
        ? undefined
        : data?.mountedFertilizerNonHire?.toString(),
    knapsackFertilizerHire:
      data?.knapsackFertilizerHire === 0
        ? undefined
        : data?.knapsackFertilizerHire?.toString(),
    knapsackFertilizerNonHire:
      data?.knapsackFertilizerNonHire === 0
        ? undefined
        : data?.knapsackFertilizerNonHire?.toString(),
    droneHire: data?.droneHire === 0 ? undefined : data?.droneHire?.toString(),
    droneNonHire:
      data?.droneNonHire === 0 ? undefined : data?.droneNonHire?.toString(),
    lawnMowerHire:
      data?.lawnMowerHire === 0 ? undefined : data?.lawnMowerHire?.toString(),
    lawnMowerNonHire:
      data?.lawnMowerNonHire === 0
        ? undefined
        : data?.lawnMowerNonHire?.toString(),
    sugarCanePruningMachineHire:
      data?.sugarCanePruningMachineHire === 0
        ? undefined
        : data?.sugarCanePruningMachineHire?.toString(),
    sugarCanePruningMachineNonHire:
      data?.sugarCanePruningMachineNonHire === 0
        ? undefined
        : data?.sugarCanePruningMachineNonHire?.toString(),
    less3MRiceCombineHarvesterHire:
      data?.less3MRiceCombineHarvesterHire === 0
        ? undefined
        : data?.less3MRiceCombineHarvesterHire?.toString(),
    less3MRiceCombineHarvesterNonHire:
      data?.less3MRiceCombineHarvesterNonHire === 0
        ? undefined
        : data?.less3MRiceCombineHarvesterNonHire?.toString(),
    more3MRiceCombineHarvesterHire:
      data?.more3MRiceCombineHarvesterHire === 0
        ? undefined
        : data?.more3MRiceCombineHarvesterHire?.toString(),
    more3MRiceCombineHarvesterNonHire:
      data?.more3MRiceCombineHarvesterNonHire === 0
        ? undefined
        : data?.more3MRiceCombineHarvesterNonHire?.toString(),
    cornCombineHarvesterHire:
      data?.cornCombineHarvesterHire === 0
        ? undefined
        : data?.cornCombineHarvesterHire?.toString(),
    cornCombineHarvesterNonHire:
      data?.cornCombineHarvesterNonHire === 0
        ? undefined
        : data?.cornCombineHarvesterNonHire?.toString(),
    cassavaHarvesterHire:
      data?.cassavaHarvesterHire === 0
        ? undefined
        : data?.cassavaHarvesterHire?.toString(),
    cassavaHarvesterNonHire:
      data?.cassavaHarvesterNonHire === 0
        ? undefined
        : data?.cassavaHarvesterNonHire?.toString(),
    sugarCaneCuttingMachineHire:
      data?.sugarCaneCuttingMachineHire === 0
        ? undefined
        : data?.sugarCaneCuttingMachineHire?.toString(),
    sugarCaneCuttingMachineNonHire:
      data?.sugarCaneCuttingMachineNonHire === 0
        ? undefined
        : data?.sugarCaneCuttingMachineNonHire?.toString(),
    sugarCaneCuttingTractorHire:
      data?.sugarCaneCuttingTractorHire === 0
        ? undefined
        : data?.sugarCaneCuttingTractorHire?.toString(),
    sugarCaneCuttingTractorNonHire:
      data?.sugarCaneCuttingTractorNonHire === 0
        ? undefined
        : data?.sugarCaneCuttingTractorNonHire?.toString(),
    forageMowerHire:
      data?.forageMowerHire === 0
        ? undefined
        : data?.forageMowerHire?.toString(),
    forageMowerNonHire:
      data?.forageMowerNonHire === 0
        ? undefined
        : data?.forageMowerNonHire?.toString(),
    pumpPipeHire:
      data?.pumpPipeHire === 0 ? undefined : data?.pumpPipeHire?.toString(),
    pumpPipeNonHire:
      data?.pumpPipeNonHire === 0
        ? undefined
        : data?.pumpPipeNonHire?.toString(),
    pumpHire: data?.pumpHire === 0 ? undefined : data?.pumpHire?.toString(),
    pumpNonHire:
      data?.pumpNonHire === 0 ? undefined : data?.pumpNonHire?.toString(),
    electricPumpHire:
      data?.electricPumpHire === 0
        ? undefined
        : data?.electricPumpHire?.toString(),
    electricPumpNonHire:
      data?.electricPumpNonHire === 0
        ? undefined
        : data?.electricPumpNonHire?.toString(),
    groundWaterPumpHire:
      data?.groundWaterPumpHire === 0
        ? undefined
        : data?.groundWaterPumpHire?.toString(),
    groundWaterPumpNonHire:
      data?.groundWaterPumpNonHire === 0
        ? undefined
        : data?.groundWaterPumpNonHire?.toString(),
    farmerTruckHire:
      data?.farmerTruckHire === 0
        ? undefined
        : data?.farmerTruckHire?.toString(),
    farmerTruckNonHire:
      data?.farmerTruckNonHire === 0
        ? undefined
        : data?.farmerTruckNonHire?.toString(),
    towTruckHire:
      data?.towTruckHire === 0 ? undefined : data?.towTruckHire?.toString(),
    towTruckNonHire:
      data?.towTruckNonHire === 0
        ? undefined
        : data?.towTruckNonHire?.toString(),
    riceThresherHire:
      data?.riceThresherHire === 0
        ? undefined
        : data?.riceThresherHire?.toString(),
    riceThresherNonHire:
      data?.riceThresherNonHire === 0
        ? undefined
        : data?.riceThresherNonHire?.toString(),
    smallRiceMillingMachineHire:
      data?.smallRiceMillingMachineHire === 0
        ? undefined
        : data?.smallRiceMillingMachineHire?.toString(),
    smallRiceMillingMachineNonHire:
      data?.smallRiceMillingMachineNonHire === 0
        ? undefined
        : data?.smallRiceMillingMachineNonHire?.toString(),
    swatherHire:
      data?.swatherHire === 0 ? undefined : data?.swatherHire?.toString(),
    swatherNonHire:
      data?.swatherNonHire === 0 ? undefined : data?.swatherNonHire?.toString(),
    balersHire:
      data?.balersHire === 0 ? undefined : data?.balersHire?.toString(),
    balersNonHire:
      data?.balersNonHire === 0 ? undefined : data?.balersNonHire?.toString(),
    shredderHire:
      data?.shredderHire === 0 ? undefined : data?.shredderHire?.toString(),
    shredderNonHire:
      data?.shredderNonHire === 0
        ? undefined
        : data?.shredderNonHire?.toString(),
  }

  const onSubmit = async (values: FormData) => {
    setRefresh(true)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload = Object.keys(values).reduce((acc: any, key) => {
      acc[key] =
        values[key as keyof FormData] === undefined
          ? 0
          : +values[key as keyof FormData]
      return acc
    }, {})

    try {
      await request.patch(`/user/me/tool`, payload)
      setRefresh(false)

      navigation.goBack()
    } catch (e) {
      console?.log(JSON.stringify(e, null, 2))
    }
  }

  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <Header title={t('screens.FarmerMachineryScreen.title')} showBack />
      <Content noPadding enabledScroll={false}>
        <Card
          borderRadiusTop
          style={{
            alignSelf: 'flex-end',
            flex: 1,
            borderBottomStartRadius: 0,
            borderBottomEndRadius: 0,
            padding: 0,
            marginTop: 16,
          }}
        >
          <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
            <Form
              defaultValues={defaultValues}
              schema={schema}
              style={{
                marginTop: 16,
                flex: 1,
                justifyContent: 'space-between',
              }}
            >
              <Text sub1 semiBold style={{ paddingHorizontal: 16 }}>
                {t('screens.FarmerMachineryScreen.title')}
              </Text>
              <Text body1 style={{ paddingHorizontal: 16 }}>
                {t('screens.FarmerMachineryScreen.desc')}
              </Text>
              <Collapse
                isCollapsed={collapseOne}
                title={t('screens.FarmerMachineryScreen.1.title')}
                onPress={() => setCollapseOne((prev) => !prev)}
                style={{ padding: 16 }}
              >
                <ScrollView>
                  <Suspense fallback={<View />}>
                    <LazyFormMachineOne />
                  </Suspense>
                </ScrollView>
              </Collapse>
              <Collapse
                isCollapsed={collapseTwo}
                title={t('screens.FarmerMachineryScreen.2.title')}
                onPress={() => setCollapseTwo((prev) => !prev)}
                style={{ padding: 16 }}
              >
                <ScrollView>
                  <Suspense fallback={<View />}>
                    <LazyFormMachineTwo />
                  </Suspense>
                </ScrollView>
              </Collapse>
              <Collapse
                isCollapsed={collapseThree}
                title={t('screens.FarmerMachineryScreen.3.title')}
                onPress={() => setCollapseThree((prev) => !prev)}
                style={{ padding: 16 }}
              >
                <ScrollView>
                  <Suspense fallback={<View />}>
                    <LazyFormMachineThree />
                  </Suspense>
                </ScrollView>
              </Collapse>
              <Collapse
                isCollapsed={collapseFour}
                title={t('screens.FarmerMachineryScreen.4.title')}
                onPress={() => setCollapseFour((prev) => !prev)}
                style={{ padding: 16 }}
              >
                <ScrollView>
                  <Suspense fallback={<View />}>
                    <LazyFormMachineFour />
                  </Suspense>
                </ScrollView>
              </Collapse>
              <Collapse
                isCollapsed={collapseFive}
                title={t('screens.FarmerMachineryScreen.5.title')}
                onPress={() => setCollapseFive((prev) => !prev)}
                style={{ padding: 16 }}
              >
                <ScrollView>
                  <Suspense fallback={<View />}>
                    <LazyFormMachineFive />
                  </Suspense>
                </ScrollView>
              </Collapse>
              <Collapse
                isCollapsed={collapseSix}
                title={t('screens.FarmerMachineryScreen.6.title')}
                onPress={() => setCollapseSix((prev) => !prev)}
                style={{ padding: 16 }}
              >
                <ScrollView>
                  <Suspense fallback={<View />}>
                    <LazyFormMachineSix />
                  </Suspense>
                </ScrollView>
              </Collapse>
              <Collapse
                isCollapsed={collapseSeven}
                title={t('screens.FarmerMachineryScreen.7.title')}
                onPress={() => setCollapseSeven((prev) => !prev)}
                style={{ padding: 16 }}
              >
                <ScrollView>
                  <Suspense fallback={<View />}>
                    <LazyFormMachineSeven />
                  </Suspense>
                </ScrollView>
              </Collapse>
              <Collapse
                isCollapsed={collapseEight}
                title={t('screens.FarmerMachineryScreen.8.title')}
                onPress={() => setCollapseEight((prev) => !prev)}
                style={{ padding: 16 }}
              >
                <ScrollView>
                  <Suspense fallback={<View />}>
                    <LazyFormMachineEight />
                  </Suspense>
                </ScrollView>
              </Collapse>
              <View style={{ padding: 16 }}>
                <SubmitButton
                  testID="form.createCompany"
                  title={t('Buttons.Save')}
                  onSubmit={onSubmit}
                />
              </View>
            </Form>
          </ScrollView>
        </Card>
      </Content>
    </Container>
  )
}

export default FarmerMachineryScreen
