import { View, Image, Platform, StyleSheet } from 'react-native'
import React from 'react'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import { useLocalization } from 'contexts/LocalizationContext'
import Content from 'components/Content'
import Text from 'components/Text'
import Button from 'components/Button'
import SuccessIcon from 'assets/images/icons/SuccessAlertWhite.png'
interface Props {
  navigation: StackNavigationHelpers
}
export default function FinalFillNewPasswordScreen({
  navigation,
}: Props): JSX.Element {
  return (
    <Container>
      <Background typeBackground="BgWithCircle" />
      <Header title="" />
      <Content enabledScroll={false}>
        <View style={{ flex: 0.8, justifyContent: 'center' }}>
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 200,
            }}
          >
            <Text semiBold h1 color="white" center style={{ marginBottom: 16 }}>
              {'รีเซ็ตรหัสผ่านสำเร็จ'}
            </Text>
            <Image source={SuccessIcon} style={styles.iconStyle} />
            <Text color="white" center style={{ marginBottom: 16 }}>
              {'โปรดทำการเข้าสู่ระบบอีกครั้ง'}
            </Text>
          </View>
          <Button
            onPress={() => navigation.navigate('Login')}
            title={'เข้าสู่ระบบ'}
          ></Button>
        </View>
      </Content>
    </Container>
  )
}
const styles = StyleSheet.create({
  iconStyle: {
    ...Platform.select({
      web: { width: 120, height: 120 },
      default: { width: 90, height: 90 },
    }),
  },
})
