/* eslint-disable @typescript-eslint/no-explicit-any */
import { View, Platform } from 'react-native'
import React from 'react'
import { useLocalization } from 'contexts/LocalizationContext'
import Button from 'components/Button'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import Text from 'components/Text'
import StationFirstStep from 'components/StationFirstStep'
import { RouteProp } from '@react-navigation/native'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'

interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<
    {
      params: {
        id: string
        data: {
          documentNumber: string
          workAssignmentNumber: string
          projectNumber: string
        }
        farmData: any
      }
    },
    'params'
  >
}
const StationWaterManagementScreen = ({
  navigation,
  route,
}: Props): JSX.Element => {
  const { t } = useLocalization()
  const { id, data } = route.params

  const RenderFooter = () => {
    return (
      <View style={{ alignItems: 'center' }}>
        <Button
          title={t('screens.StationWaterManagementScreen.button.waterMeansure')}
          arrowBack
          onPress={() =>
            navigation.navigate('WaterMeansurementAndAnalysis', {
              id,
            })
          }
          style={{
            width: Platform.OS === 'web' ? '100%' : '100%',
            marginBottom: 8,
          }}
        />
        <Button
          title="การจัดการน้ำในเกษตรแปลงใหญ่"
          arrowBack
          onPress={() => {
            navigation.navigate('WaterManagement', { id })
          }}
          style={{
            width: Platform.OS === 'web' ? '100%' : '100%',
          }}
        />
      </View>
    )
  }
  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <View style={{ width: '100%', alignItems: 'center', height: 130 }}>
        <Header
          showBack
          title={t('screens.StationWaterManagementScreen.header')}
          style={{ paddingBottom: 0 }}
        />
        <Text
          title1
          color="white"
          style={{ width: '70%', textAlign: 'center', lineHeight: 30 }}
        >
          {t('screens.StationWaterManagementScreen.title')}
        </Text>
      </View>
      <StationFirstStep
        dataAssignment={{
          assignmentId: data?.workAssignmentNumber,
          projectId: data?.projectNumber,
          refId: data?.documentNumber || data?.project?.order?.documentNumber,
        }}
        // isLoading={!soilSampleData}
        FooterComponent={<RenderFooter />}
      />
    </Container>
  )
}

export default StationWaterManagementScreen
