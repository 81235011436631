import { Dimensions, Platform, View } from 'react-native'
import React, { useState } from 'react'
import Button from 'components/Button'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import { Provider } from 'react-native-paper'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import StationSecondStep from 'components/StationSecondStep'
import { RouteProp, useFocusEffect } from '@react-navigation/native'
import PlusIcon from 'assets/images/icons/Plus.png'
import request from 'utils/request'
import ModalAlert from 'components/ModalAlert'
import { useAuth } from 'contexts/AuthContext'
export interface RootObject {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  path: string
  text: string
}
interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<{ params: { id: string; farmName: string } }, 'params'>
}
const StationAnalysisMeasurementDetail = ({
  navigation,
  route,
}: Props): JSX.Element => {
  const { id, farmName } = route.params
  const [visible, setVisible] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const {
    state: { link },
  } = useAuth()
  const url: RootObject[] = []
  if (link) {
    link?.map((item: RootObject) => {
      if (
        item.text === 'คู่มือการสุ่มตรวจศัตรูพืช' ||
        item.text === 'ข้อมูลศัตรูพืช'
      ) {
        return url.push(item)
      }
    })
  }

  const limit = 10
  const page = 1
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any>({
    data: [],
    page: 1,
    total: 1,
    limit: 10,
  })
  // const { data: initialData, mutate } = useSWR(
  //   [`/workAssignment/${id}/pestLogs?limit=${limit}&page=1`, refresh],
  //   fetcher
  // )
  const fatchData = async () => {
    try {
      const res = await request.get(
        `/workAssignment/${id}/pestLogs?limit=${limit}&page=${page}`
      )
      setData(res?.data)
    } catch (err) {
      console.log(err)
    }
  }
  useFocusEffect(
    React.useCallback(() => {
      fatchData()
    }, [refresh])
  )

  const onLoadMore = async () => {
    try {
      if (data.data.length < data.total) {
        const res = await request.get(
          `/workAssignment/${id}/pestLogs?limit=${limit}&page=${data?.page + 1}`
        )
        const value = {
          data: [...data.data, ...res.data.data],
          page: data?.page + 1,
          total: data.total,
          limit: data.limit,
        }
        setData(value)
      } else {
        return null
      }
    } catch (e) {
      console.log(e)
    }
  }
  const onDelete = async (deleteId: string) => {
    try {
      await request.delete(`/workAssignment/${id}/pestLogs/${deleteId}`)
      setVisible(true)
      setRefresh((prev) => !prev)
    } catch (e) {
      console.log(e)
    }
  }
  const onEdit = (editId: string) => {
    navigation.navigate('EditAnalysisMeasurement', {
      id,
      editId,
      farmName,
      link: url,
    })
  }
  const RenderFooter = () => {
    return (
      <View
        style={{
          marginTop: 32,
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Button
          title="เพิ่มข้อมูล"
          icon={PlusIcon}
          style={{ width: Platform.OS === 'web' ? '90%' : '90%' }}
          onPress={() =>
            navigation.navigate('AddAnalysisMeasurement', {
              id,
              farmName,
              link: url,
            })
          }
        />
      </View>
    )
  }
  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title="ระบบตรวจจับและวิเคราะห์ศัตรูพืช"
          style={{ paddingBottom: 0 }}
          numberOfLines={2}
          widthText={Dimensions.get('window').width - 60}
          componentBelowHeader={<RenderFooter />}
        />

        <StationSecondStep
          data={data.data}
          onLoadMore={onLoadMore}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      </Container>
      <ModalAlert
        visible={visible}
        setVisibile={setVisible}
        textDesc="ลบสำเร็จ"
      />
    </Provider>
  )
}

export default StationAnalysisMeasurementDetail
