import axios, { AxiosRequestConfig } from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage'
import env from '../../config'

const config = env()
export const TOKEN_KEY = 'userToken'
export const BASE_URL = config.API_URL

const instance = axios.create({
  baseURL: BASE_URL,
})

instance.interceptors.request.use(
  async function (config) {
    const token = await AsyncStorage.getItem(TOKEN_KEY)
    return {
      ...config,
      headers: {
        accept: 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
  },
  function (error) {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default instance
export const fetcher = (url: string, config?: AxiosRequestConfig) => {
  return instance.get(url, config).then((res) => res.data)
}
