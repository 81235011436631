import React from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Background from 'components/Background'
import Text from 'components/Text'
import { useLocalization } from 'contexts/LocalizationContext'
import { ScrollView, View } from 'react-native'
import { Form, Input, SubmitButton } from 'components/Form'
import * as yup from 'yup'
import Header from 'components/Header'
import theme from 'theme/index'
import { useAuth } from 'contexts/AuthContext'

type FormData = {
  email: string
  password: string
  confirmPassword: string
}
const SignupScreen = (): JSX.Element => {
  const {
    authContext: { register, login },
  } = useAuth()
  const { t } = useLocalization()
  const defaultValues = {
    email: '',
    password: '',
    confirmPassword: '',
  }
  const schema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .lowercase()
      .email(t('screens.LoginScreen.errors.emailInvalid'))
      .required(t('screens.LoginScreen.errors.emailRequired')),
    password: yup
      .string()
      .required(t('screens.SignupScreen.validation.password'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        t('screens.SignupScreen.validation.validPassword')
      ),
    confirmPassword: yup
      .string()
      .required(t('screens.SignupScreen.validation.confirmPassword'))
      .oneOf(
        [yup.ref('password'), null],
        t('screens.SignupScreen.validation.notMatch')
      ),
  })
  const onSubmit = async (data: FormData) => {
    try {
      await register(data)
      await login({ email: data.email, password: data.password })
    } catch (e) {
      alert(t('screens.SignupScreen.alert.failed'))
    }
  }
  return (
    <Container>
      <Background typeBackground="BgWithCircle" />
      <Header showBack title="" />
      <Content enabledScroll={false}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <View style={{ flex: 0.8, justifyContent: 'center' }}>
            <Text semiBold h1 color="white" center style={{ marginBottom: 16 }}>
              {t('screens.SignupScreen.title')}
            </Text>
            <Form schema={schema} defaultValues={defaultValues}>
              <Input
                name="email"
                label={t('screens.SignupScreen.form.email')}
                colorLabel="white"
                selectionColor={'white'}
                style={{ color: theme.color.white, paddingVertical: 8 }}
                themeWhite
                autoCapitalize="none"
              />
              <Input
                colorLabel="white"
                selectionColor={'white'}
                name="password"
                label={t('screens.SignupScreen.form.password')}
                secureTextEntry
                themeWhite
                style={{ color: theme.color.white, paddingVertical: 8 }}
              />
              <Input
                secureTextEntry
                colorLabel="white"
                selectionColor={'white'}
                name="confirmPassword"
                label={t('screens.SignupScreen.form.confirmPassword')}
                style={{ color: theme.color.white, paddingVertical: 8 }}
                themeWhite
              />
              <View style={{ marginTop: 16 }}>
                <SubmitButton
                  testID="signupForm"
                  onSubmit={onSubmit}
                  title={t('screens.SignupScreen.button')}
                />
              </View>
            </Form>
          </View>
        </ScrollView>
      </Content>
    </Container>
  )
}

export default SignupScreen
