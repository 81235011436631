import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Platform,
} from 'react-native'
import React from 'react'
import Text from 'components/Text'
import ArrowBackWhite from 'assets/images/icons/ArrowBack.png'
import ArrowBackBlack from 'assets/images/icons/ArrowBack_black.png'
import { useNavigation } from '@react-navigation/native'
import { ViewStyle } from 'react-native'
type Props = {
  title?: string
  showBack?: boolean
  onPressBack?: () => void
  alignTitle?: 'flex-start' | 'flex-end' | 'center'
  style?: ViewStyle
  numberOfLines?: number
  widthText?: number | string
  styleRightEl?: ViewStyle
  isModule?: boolean
  componentBelowHeader?: JSX.Element
  color?: 'black' | 'white'
}
export default function Header({
  title = 'header',
  onPressBack,
  alignTitle = 'center',
  showBack = false,
  style,
  widthText,
  numberOfLines,
  styleRightEl,
  isModule = false,
  color = 'white',
  componentBelowHeader,
}: Props): JSX.Element {
  const navigation = useNavigation()
  const height = Platform.OS === 'web' ? 145 : 140
  return showBack ? (
    <>
      <View
        style={[
          styles.container,
          {
            justifyContent: 'space-between',
            minHeight: isModule ? height : 'auto',
          },
          style,
        ]}
      >
        <TouchableOpacity
          style={styles.pressable}
          onPress={
            onPressBack
              ? onPressBack
              : () => {
                  navigation.goBack()
                }
          }
        >
          <Image
            source={color === 'black' ? ArrowBackBlack : ArrowBackWhite}
            style={{ width: 20, height: 20 }}
            resizeMode="contain"
          />
        </TouchableOpacity>
        <Text
          color={color}
          h1
          bold
          center
          numberOfLines={numberOfLines}
          style={{ width: widthText }}
        >
          {title}
        </Text>
        <View style={{ width: 16, ...styleRightEl }} />
      </View>
      {componentBelowHeader}
    </>
  ) : (
    <View style={[styles.container, { justifyContent: alignTitle }, style]}>
      <Text
        color={color}
        h1
        bold
        numberOfLines={numberOfLines}
        style={{ width: widthText }}
      >
        {title}
      </Text>
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    padding: 16,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'row',
    zIndex: 100,
  },
  pressable: {
    zIndex: 100,
    position: 'relative',
    top: 12,
    width: 28,
  },
})
