import { Image, StyleSheet, ViewStyle } from 'react-native'
import React, { useEffect } from 'react'
import styled from '@emotion/native'
import { ErrorMessage, Item, Label } from './components'
import { Controller, useFormContext } from 'react-hook-form'
import theme from 'theme/index'
import UploadImage from 'components/UploadImage'
import Camera from 'assets/images/icons/Camera.png'

interface Props {
  name: string
  label?: string
  aspect?: [number, number]
  colorLabel?:
    | 'primary1'
    | 'primary2'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
  itemStyle?: ViewStyle
  errorInside?: boolean
  setImage: (value: string) => void
  index?: number
  indexNested?: number
  errorName?: string
  isMultiForm?: boolean
  nameList?: string
  children?: React.ReactNode
  nestNameList?: string
  defaultValue?: string
}
interface ErrorBoxProps {
  errorInside: boolean
}
const ErrorBox = styled.View<ErrorBoxProps>`
  position: ${(props) => props.errorInside && 'absolute'};
  bottom: ${(props) => props.errorInside && '5px'};
  padding: ${(props) => props.errorInside && '8px'};
`

export default function ImagePicker({
  name,
  label,
  colorLabel,
  itemStyle,
  errorInside = false,
  setImage,
  aspect,
  index = 0,
  indexNested = 0,
  errorName = '',
  nameList = '',
  children,
  nestNameList = '',
  defaultValue = '',
}: Props): JSX.Element {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext()
  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue)
      setImage(defaultValue)
    }
  }, [defaultValue, name, setValue])
  return (
    <Item style={itemStyle}>
      {label && (
        <Label input color={colorLabel}>
          {label}
        </Label>
      )}
      <Controller
        control={control}
        render={({ field: { onChange } }) => (
          <>
            <Item style={{ marginTop: 8, ...itemStyle }}>
              <UploadImage
                setImage={setImage}
                onFinish={(value) => {
                  onChange(value)
                }}
                aspect={aspect}
              >
                {children ? (
                  children
                ) : (
                  <Image source={Camera} style={styles.icon} />
                )}
              </UploadImage>
            </Item>
            {errors[name] && (
              <ErrorBox errorInside={errorInside}>
                <ErrorMessage error>{errors[name].message}</ErrorMessage>
              </ErrorBox>
            )}
            {errors?.[nameList]?.[index]?.[errorName] && (
              <ErrorBox errorInside={errorInside}>
                <ErrorMessage error>
                  {errors?.[nameList]?.[index]?.[errorName].message}
                </ErrorMessage>
              </ErrorBox>
            )}
            {errors?.[nameList]?.[index]?.[nestNameList]?.[indexNested]?.[
              errorName
            ] ? (
              <ErrorBox errorInside={errorInside}>
                <ErrorMessage error>
                  {
                    errors?.[nameList]?.[index]?.[nestNameList]?.[
                      indexNested
                    ]?.[errorName].message
                  }
                </ErrorMessage>
              </ErrorBox>
            ) : null}
          </>
        )}
        name={name}
      />
    </Item>
  )
}
const styles = StyleSheet.create({
  noImage: {
    borderRadius: 10,
    borderWidth: 2,
    borderStyle: 'dotted',
    borderColor: theme.color.primary1,
    height: 180,
    justifyContent: 'center',
    alignItems: 'center',
  },
  hasImage: {
    height: 180,
  },
  icon: {
    width: 30,
    height: 30,
  },
})
