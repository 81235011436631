/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  View,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  Image,
} from 'react-native'
import React from 'react'
import Container from 'components/Container'
import Header from 'components/Header'
import Background from 'components/Background'
import Content from 'components/Content'
import Card from 'components/Card'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import useSWR from 'swr'
import { fetcher } from 'utils/request'
import { RouteProp } from '@react-navigation/native'
import Text from 'components/Text'
import theme from 'theme/index'
import LinkIcon from 'assets/images/icons/Link.png'

interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<
    {
      params: {
        id: string
        itemId: string
      }
    },
    'params'
  >
}
function WaterManagementDetail({ route, navigation }: Props): JSX.Element {
  // const { t } = useLocalization()
  const { id, itemId } = route.params
  const { data: managementData } = useSWR(
    () => `/workAssignment/${id}/waterManagement/${itemId}`,
    fetcher
  )
  console.log('managementData', managementData)
  if (!managementData) {
    return <View></View>
  }
  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <Header
        showBack
        title={'ระบบจัดการน้ำ\nในเกษตรแปลงใหญ่'}
        widthText={'90%'}
      />
      <Content enabledScroll={false} noPadding>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <Card borderRadiusTop style={{ flex: 1, marginTop: 32 }}>
            <View style={styles.mdGap}>
              <Text input>วันที่</Text>
              <View style={styles.box}>
                <Text input>{managementData?.date}</Text>
              </View>
            </View>

            <View style={styles.lgGap}>
              <Text color="secondary2" sub1 style={styles.line}>
                ข้อมูลสภาพอากาศ
              </Text>
              <View style={[styles.row, styles.line]}>
                <Text sub1 style={styles.flex}>
                  แปลง
                </Text>
                <Text sub1 color="black85" style={styles.flex}>
                  {managementData?.farm?.name}
                </Text>
              </View>
              <View style={[styles.row, styles.line]}>
                <Text sub1 style={styles.flex}>
                  ข้อมูลพยากรณ์อากาศ
                </Text>
                <View style={styles.flex}>
                  <Text sub1 color="black85">
                    {'ต่ำสุด ' +
                      (managementData?.environmentDatas?.weatherForecastLow ||
                        0) +
                      ' °C'}
                  </Text>
                  <Text sub1 color="black85">
                    {'สูงสุด ' +
                      (managementData?.environmentDatas?.weatherForecastHigh ||
                        0) +
                      ' °C'}
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <Text sub1 style={styles.flex}>
                  ปริมาณน้ำฝน
                </Text>
                <Text sub1 color="black85" style={styles.flex}>
                  {managementData?.areaDatas?.rainfall} มม.
                </Text>
              </View>
            </View>
            <View style={styles.lgGap}>
              <Text color="secondary2" sub1 style={[styles.line]}>
                ข้อมูลปริมาณกักเก็บน้ำ
              </Text>
              <Text input>อายุไผ่</Text>
              <View style={styles.box}>
                <Text input>{managementData?.plantEnvironment?.age} เดือน</Text>
              </View>
              <View style={[styles.row, styles.line]}>
                <Text sub1 style={styles.flex}>
                  ชนิดของพืช
                </Text>
                <Text sub1 color="black85" style={styles.flex}>
                  {managementData?.plantEnvironment?.type}
                </Text>
              </View>
              <View style={[styles.row, styles.line]}>
                <Text sub1 style={styles.flex}>
                  ปริมาณน้ำ
                </Text>
                <Text sub1 color="black85" style={styles.flex}>
                  {managementData?.plantEnvironment?.waterAmount +
                    ' มม. ต่อวัน'}
                </Text>
              </View>
              <View style={[styles.row, styles.line]}>
                <Text sub1 style={styles.flex}>
                  อุณหภูมิ
                </Text>
                <Text sub1 color="black85" style={styles.flex}>
                  {(managementData?.plantEnvironment?.minTemperature || 0) +
                    ' - ' +
                    (managementData?.plantEnvironment?.maxTemperature || 0) +
                    ' องศา'}
                </Text>
              </View>
              <View style={styles.row}>
                <Text sub1 style={styles.flex}>
                  ความชื้นสัมพัทธ์
                </Text>
                <Text sub1 color="black85" style={styles.flex}>
                  {managementData?.plantEnvironment?.humidity + '%'}
                </Text>
              </View>
            </View>
            <View style={styles.mdGap}>
              <Text color="secondary2" sub1 style={styles.line}>
                ข้อมูลการให้น้ำพืชจริง
              </Text>
              <Text input>ระบบ</Text>
              <View style={styles.box}>
                <Text input>{managementData?.waterSystem}</Text>
              </View>
              <Text input>แรงดัน</Text>
              <View style={styles.box}>
                <Text input>{managementData?.waterPressure}</Text>
              </View>
              <Text input>อัตราการไหล</Text>
              <View style={styles.box}>
                <Text input>{managementData?.waterFlowRate}</Text>
              </View>
              <Text input>เวลาให้น้ำ / ชม.</Text>
              <View style={styles.box}>
                <Text input>{managementData?.waterAmountPerHours}</Text>
              </View>
              <Text input>ปริมาณน้ำ / วัน</Text>
              <View style={styles.box}>
                <Text input>{managementData?.waterAmountPerDay}</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 12,
                }}
              >
                <Image
                  source={LinkIcon}
                  style={{
                    width: 18,
                    height: 18,
                    marginRight: 8,
                  }}
                />
                <TouchableOpacity>
                  <Text
                    sub1
                    color="secondary2"
                    style={{
                      textDecorationLine: 'underline',
                      textDecorationColor: theme.color.secondary2,
                    }}
                  >
                    ปริมาณการใช้น้ำของพืช
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </Card>
        </ScrollView>
      </Content>
    </Container>
  )
}

export default WaterManagementDetail

const styles = StyleSheet.create({
  box: {
    // weight: '100%',
    height: '44px',
    borderColor: theme.color.black40,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 10,
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 10,
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
  },
  flex: {
    flex: 0.5,
  },
  line: {
    borderColor: theme.color.black6,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    paddingBottom: 10,
    marginBottom: 10,
  },
  smGap: {
    marginBottom: 10,
  },
  mdGap: {
    marginBottom: 24,
  },
  lgGap: {
    marginBottom: 56,
  },
})
