import { Platform, View, Text } from 'react-native'
import React, { lazy, Suspense } from 'react'
import env from '../../../config'

const MapComponent = lazy(() =>
  Platform.OS !== 'web' ? import('./MobileMap') : import('./WebMap')
)
interface Props {
  readonly?: boolean
  value?: {
    latitude: number
    longitude: number
  }
  valueBeforeSubmit?: {
    lat: number
    lng: number
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValueBeforeSubmit?: (v: any) => void

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (e: any) => void
}
export default function MapBody(props: Props): JSX.Element {
  const API_KEY = env().GOOGLE_MAPS_API_KEY

  return (
    <Suspense fallback={<View />}>
      <MapComponent API_KEY={API_KEY} {...props} />
    </Suspense>
    // <View>
    //   <Text>ssss</Text>
    // </View>
  )
}
