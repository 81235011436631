import styled from '@emotion/native'
import Seperator from 'components/Seperator'
import Text from 'components/Text'
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import theme from 'theme/index'
import ModalAddData from './ModalAddData'
import Edit from 'assets/images/icons/edit.png'
import Trash from 'assets/images/icons/trash.png'
import ModalEditData from './ModalEditData'
type State = {
  quantity: number
  name: string
  numberOfArea: number
  index?: number
}
interface Props {
  name: string
  visible: boolean
  setVisible: (value: boolean) => void
  defaultValue?: State[]
}
const FakeInput = styled.View`
  height: 35px;
  justify-content: center;
  margin-top: 4px;
  border-width: 1px;
  border-color: ${theme.input.borderColor};
  border-radius: 0px;
  padding: 0px 8px;
  border-radius: 8px;
`
const styles = StyleSheet.create({
  icon: {
    width: 24,
    height: 24,
  },
})
function FakeForm({ name, visible, setVisible, defaultValue }: Props) {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()
  const [isEdit, setIsEdit] = React.useState(false)
  const [editData, setEditData] = React.useState<State>()
  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue)
    }
  }, [defaultValue])
  const onDeleteList = (index: number) => {
    const value = getValues()[name]
    value.splice(index, 1)
    setValue(name, value)
  }
  const onEdit = () => {
    setIsEdit(true)
  }

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value = [] } }) => {
          return (
            <>
              <View style={{ marginTop: 24 }}>
                <Text sub1 color="secondary2" medium style={{ flex: 0.2 }}>
                  ข้อมูลการใส่ปุ๋ย
                </Text>
                <Seperator />
              </View>
              {value.map((item: State, index: number) => {
                const isLast = index === value.length - 1
                const isFirst = index === 0
                return (
                  <>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        marginTop: 8,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            marginRight: isFirst ? 0 : 16,
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                          onPress={() => {
                            setEditData({ ...item, index })
                            onEdit()
                          }}
                        >
                          <Text sub1 style={{ marginRight: 8 }}>
                            แก้ไข
                          </Text>
                          <Image style={styles.icon} source={Edit} />
                        </TouchableOpacity>
                        {!isFirst && (
                          <TouchableOpacity
                            onPress={() => onDeleteList(index)}
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Text sub1 color="error" style={{ marginRight: 8 }}>
                              ลบ
                            </Text>
                            <Image style={styles.icon} source={Trash} />
                          </TouchableOpacity>
                        )}
                      </View>
                    </View>
                    <Text style={{ marginTop: 12 }} sub1>
                      สูตรปุ๋ยที่ใช้
                    </Text>
                    <FakeInput>
                      <Text sub1 color="black85">
                        {item.name}
                      </Text>
                    </FakeInput>

                    <Text style={{ marginTop: 12 }} sub1>
                      จำนวนปุ๋ย
                    </Text>

                    <FakeInput
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Text sub1 color="black85">
                        {item.quantity}
                      </Text>
                      <Text sub1 color="black85">
                        กก.
                      </Text>
                    </FakeInput>
                    <Text style={{ marginTop: 12 }} sub1>
                      จำนวนไร่
                    </Text>
                    <FakeInput
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Text sub1 color="black85">
                        {item.numberOfArea}
                      </Text>
                      <Text sub1 color="black85">
                        ไร่
                      </Text>
                    </FakeInput>
                    {!isLast && <Seperator style={{ marginVertical: 16 }} />}
                  </>
                )
              })}
              <ModalAddData
                visible={visible}
                setVisible={setVisible}
                onChange={(data) => {
                  onChange([...value, data])
                }}
              />
              <ModalEditData
                visible={isEdit}
                setVisible={setIsEdit}
                defaultValues={editData}
                onChange={(data) => {
                  if (data.index !== undefined) {
                    const value = getValues()[name]
                    value[data?.index] = data
                    onChange(value)
                  }
                }}
              />
            </>
          )
        }}
      />
      {errors[name] ? <Text color="error">{errors[name].message}</Text> : <></>}
    </>
  )
}

export default FakeForm
