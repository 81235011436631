import React from 'react'
import Modal from 'components/Modal/Modal'
import Text from 'components/Text'
import { Image, TouchableOpacity, View } from 'react-native'
import Close from 'assets/images/icons/Close.png'
import { Form, Input, SubmitButton } from 'components/Form'
import dayjs from 'dayjs'
import * as yup from 'yup'
import request, { fetcher } from 'utils/request'
import useSWR from 'swr'
// import InputDateTest from 'components/Form/InputDateTest'
import InputDate from 'components/Form/InputDate'
type Props = {
  onRequestClose?: () => void
  visible: boolean
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
  workAssignmentId: string
  taskId?: string | null
}

interface Dataplan {
  date: string
  plan: string
  ot: string
}
const AddPlanAndOtModal = ({
  visible,
  workAssignmentId,
  onRequestClose,
  setRefresh,
  taskId,
}: Props): JSX.Element => {
  const { data: task } = useSWR(
    () =>
      taskId ? [`/workAssignment/${workAssignmentId}/task/${taskId}`] : null,
    fetcher
  )

  const defaultValue = {
    date: task?.date,
    plan: task?.plan,
    ot: task?.ot,
  }

  const schema = yup.object().shape({
    date: yup.string().required('กรุณากรอกวันที่'),
    plan: yup.string().required('กรุณากรอก Plan'),
    ot: yup.string().required('กรุณากรอก OT'),
  })

  const onSubmit = async (data: Dataplan) => {
    if (taskId) {
      try {
        await request.patch(
          `/workAssignment/${workAssignmentId}/task/${taskId}`,
          {
            date: data.date,
            plan: parseInt(data.plan),
            ot: parseInt(data.ot),
          }
        )
        onRequestClose?.()
        setRefresh((prev) => !prev)
      } catch (e) {
        console.log(JSON.stringify(e, null, 2))
      }
    } else {
      try {
        await request.post(`/workAssignment/${workAssignmentId}/task`, {
          date: data.date,
          plan: parseInt(data.plan),
          ot: parseInt(data.ot),
        })
        onRequestClose?.()
        setRefresh((prev) => !prev)
      } catch (e) {
        console.log(JSON.stringify(e, null, 2))
      }
    }
  }
  return (
    <Modal
      visible={visible}
      //onRequestClose={onRequestClose}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <Text title1 color="black" medium>
          Plan & OT
        </Text>
        <TouchableOpacity
          style={{
            justifyContent: 'center',
          }}
          onPress={onRequestClose}
        >
          <Image
            source={Close}
            style={{
              width: 25,
              height: 25,
            }}
            resizeMode="contain"
          />
        </TouchableOpacity>
      </View>
      <Form enableReinitial defaultValues={defaultValue} schema={schema}>
        {/* <InputDateTest
          name="date"
          label="วันที่"
          testID="date"
          defaultValue={defaultValue?.date}
          iconRight
          style={{ height: 42 }}
        /> */}
        <InputDate
          name="date"
          label="วันที่"
          testID="date"
          defaultValue={defaultValue?.date}
          iconRight
          style={{ height: 42 }}
        />
        <Input
          name="plan"
          label="Plan"
          keyboardType="phone-pad"
          style={{ height: 42 }}
        />
        <Input
          name="ot"
          label="OT"
          keyboardType="phone-pad"
          style={{ height: 42 }}
        />
        <SubmitButton
          testID="plan"
          style={{ marginTop: 16 }}
          title="บันทึก"
          onSubmit={onSubmit}
          color="primary1"
        />
      </Form>
    </Modal>
  )
}
export default AddPlanAndOtModal
