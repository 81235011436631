import {
  View,
  Dimensions,
  Image,
  TouchableOpacity,
  Linking,
} from 'react-native'
import React from 'react'
import { RouteProp } from '@react-navigation/native'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import Content from 'components/Content'
import Card from 'components/Card'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import LinkIcon from 'assets/images/icons/Link.png'
import Text from 'components/Text'
import theme from 'theme/index'
export interface RootObject {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  path: string
  text: string
}
interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<{ params: { id: string; link: RootObject[] } }, 'params'>
}

export default function ManageProtectPlant({
  navigation,
  route,
}: Props): JSX.Element {
  const { link } = route.params
  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <Header
        showBack
        title="ระบบจัดการการอารักขาพืชในเกษตรแปลงใหญ่"
        style={{ paddingBottom: 0 }}
        numberOfLines={2}
        widthText={Dimensions.get('window').width - 60}
      />
      <Content noPadding enabledScroll={false}>
        <Card
          borderRadiusTop
          width="100%"
          style={{ height: '100%', flex: 1, paddingTop: 32, marginTop: 32 }}
        >
          <View
            style={{
              flexDirection: 'column',
              marginTop: 12,
            }}
          >
            {link?.map((item: RootObject, idx: any) => {
              return (
                <View
                  key={idx}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: 12,
                  }}
                >
                  <Image
                    source={LinkIcon}
                    style={{
                      width: 18,
                      height: 18,
                      marginRight: 8,
                    }}
                  />
                  <TouchableOpacity onPress={() => Linking.openURL(item.path)}>
                    <Text
                      sub1
                      color="secondary2"
                      style={{
                        textDecorationLine: 'underline',
                        textDecorationColor: theme.color.secondary2,
                      }}
                    >
                      {item.text}
                    </Text>
                  </TouchableOpacity>
                </View>
              )
            })}
          </View>
        </Card>
      </Content>
    </Container>
  )
}
