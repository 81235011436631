/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  View,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  Image,
  Linking,
} from 'react-native'
import React, { useState } from 'react'
import Container from 'components/Container'
import Header from 'components/Header'
import Background from 'components/Background'
import Content from 'components/Content'
import Card from 'components/Card'
import { Form, Input, SubmitButton } from 'components/Form'
import * as yup from 'yup'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import useSWR from 'swr'
import request, { fetcher } from 'utils/request'
import { RouteProp } from '@react-navigation/native'
import InputDate from 'components/Form/InputDate'
import dayjs from 'dayjs'
import Text from 'components/Text'
import theme from 'theme/index'
import LinkIcon from 'assets/images/icons/Link.png'
import DropDownForm from 'components/Form/DropDownForm'
import BambooAgeDetail from './BambooAgeDetail'
export interface RootObject {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  path: string
  text: string
}
export default function AddWaterManagement({
  navigation,
  route,
}: {
  navigation: StackNavigationHelpers
  route: RouteProp<{ params: { id: string; link: RootObject[] } }, 'params'>
}): JSX.Element {
  const { id, link } = route.params
  const { data: farmData } = useSWR(
    `/workAssignment/${id}/waterManagement/farm`,
    fetcher
  )
  const [plantItem, setPlantItem] = useState<any>()

  const schema = yup.object().shape({
    date: yup.string().required('กรุณากรอกวันที่'),
    age: yup.string().nullable(),
    waterSystem: yup.string().required('กรุณาเลือกระบบ'),
    waterPressure: yup.string().required('กรุณาเลือกแรงดัน'),
    waterFlowRate: yup.string().required('กรุณาเลือกอัตราการไหล'),
    waterAmountPerHours: yup.string().required('กรุณากรอกเวลาให้น้ำ/ชม.'),
    waterAmountPerDay: yup.string().required('กรุณากรอกปริมาณน้ำ/วัน'),
  })

  const onSubmit = async (data: {
    date: string
    age: string
    waterSystem: string
    waterPressure: string
    waterFlowRate: string
    waterAmountPerHours: string
    waterAmountPerDay: string
  }) => {
    try {
      await request.post(
        `/workAssignment/${id}/waterManagement/plantEnvironment${
          plantItem ? `?plantEnvironmentId=${plantItem?.id}` : ''
        }`,
        {
          date: dayjs(data.date).format('YYYY-MM-DD'),
          waterSystem: data.waterSystem,
          waterPressure: data.waterPressure,
          waterFlowRate: data.waterFlowRate,
          waterAmountPerHours: +data.waterAmountPerHours,
          waterAmountPerDay: +data.waterAmountPerDay,
        }
      )
      navigation.goBack()
    } catch (e) {
      console.log(e)
    }
  }
  const systemOption = [
    { label: 'สปริงเกลอร์', value: 'springer' },
    { label: 'มินิสปริงเกลอร์', value: 'mini springer' },
    { label: 'ไมโครสเปรย์และเจ็ท', value: 'microspray and jet' },
    { label: 'น้ำหยด', value: 'water drop' },
  ]
  const systemForce = [
    { label: 'ต่ำ', value: 'low' },
    { label: 'ปานกลาง', value: 'medium' },
    { label: 'สูง', value: 'high' },
  ]

  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <Header
        showBack
        title={'ระบบจัดการน้ำ\nในเกษตรแปลงใหญ่'}
        widthText={'90%'}
      />
      <Content enabledScroll={false} noPadding>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <Card
            borderRadiusTop
            style={{ justifyContent: 'space-between', flex: 1, marginTop: 32 }}
          >
            <Form
              schema={schema}
              defaultValues={{
                date: dayjs(),
                age: '',
                waterSystem: '',
                waterPressure: '',
                waterFlowRate: '',
                waterAmountPerHours: '',
                waterAmountPerDay: '',
              }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <View>
                <InputDate name="date" label="วันที่" style={styles.mdGap} />
                <View style={styles.lgGap}>
                  <Text color="secondary2" sub1 style={styles.line}>
                    ข้อมูลสภาพอากาศ
                  </Text>
                  <View style={[styles.row, styles.line]}>
                    <Text sub1 style={styles.flex}>
                      แปลง
                    </Text>
                    <Text sub1 color="black85" style={styles.flex}>
                      {farmData?.farm?.name}
                    </Text>
                  </View>
                  <View style={[styles.row, styles.line]}>
                    <Text sub1 style={styles.flex}>
                      ข้อมูลพยากรณ์อากาศ
                    </Text>
                    <View style={styles.flex}>
                      <Text sub1 color="black85">
                        {'ต่ำสุด ' +
                          (farmData?.environmentDatas?.weatherForecastLow ||
                            0) +
                          ' °C'}
                      </Text>
                      <Text sub1 color="black85">
                        {'สูงสุด ' +
                          (farmData?.environmentDatas?.weatherForecastHigh ||
                            0) +
                          ' °C'}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <Text sub1 style={styles.flex}>
                      ปริมาณน้ำฝน
                    </Text>
                    <Text sub1 color="black85" style={styles.flex}>
                      {farmData?.areaDatas?.rainfall || 0} มม.
                    </Text>
                  </View>
                </View>
                <View style={styles.lgGap}>
                  <Text color="secondary2" sub1 style={[styles.line]}>
                    ข้อมูลปริมาณกักเก็บน้ำ
                  </Text>
                  <BambooAgeDetail id={id} setPlantItem={setPlantItem} />
                </View>
                <View style={styles.mdGap}>
                  <Text color="secondary2" sub1 style={styles.line}>
                    ข้อมูลการให้น้ำพืชจริง
                  </Text>
                  <DropDownForm
                    name="waterSystem"
                    label="ระบบ"
                    data={systemOption}
                    placeholder={''}
                  />
                  <DropDownForm
                    name="waterPressure"
                    label="แรงดัน"
                    data={systemForce}
                    placeholder={''}
                  />
                  <DropDownForm
                    name="waterFlowRate"
                    label="อัตราการไหล"
                    data={systemForce}
                    placeholder={''}
                  />
                  <Input name="waterAmountPerHours" label="เวลาให้น้ำ / ชม." />
                  <Input name="waterAmountPerDay" label="ปริมาณน้ำ / วัน" />
                  <View
                    style={{
                      flexDirection: 'column',
                      marginTop: 12,
                    }}
                  >
                    {link?.map((item: RootObject, idx: any) => {
                      return (
                        <View
                          key={idx}
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: 12,
                          }}
                        >
                          <Image
                            source={LinkIcon}
                            style={{
                              width: 18,
                              height: 18,
                              marginRight: 8,
                            }}
                          />
                          <TouchableOpacity
                            onPress={() => Linking.openURL(item.path)}
                          >
                            <Text
                              sub1
                              color="secondary2"
                              style={{
                                textDecorationLine: 'underline',
                                textDecorationColor: theme.color.secondary2,
                              }}
                            >
                              {item.text}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      )
                    })}
                  </View>
                </View>
              </View>
              <View style={{ paddingHorizontal: '8%' }}>
                <SubmitButton title="ตกลง" onSubmit={onSubmit} />
              </View>
            </Form>
          </Card>
        </ScrollView>
      </Content>
    </Container>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  flex: {
    flex: 0.5,
  },
  line: {
    borderBottomColor: theme.color.black6,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    paddingBottom: 10,
    marginBottom: 10,
  },
  smGap: {
    marginBottom: 10,
  },
  mdGap: {
    marginBottom: 24,
  },
  lgGap: {
    marginBottom: 56,
  },
})
