import React from 'react'
import { View, StyleSheet, useWindowDimensions } from 'react-native'
import { StatusBar } from 'expo-status-bar'
import { ThemeProvider } from '@emotion/react'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { setCustomTextInput, setCustomText } from 'react-native-global-props'
import AppLoading from 'expo-app-loading'
import {
  useFonts,
  Kanit_400Regular,
  Kanit_500Medium,
  Kanit_600SemiBold,
  Kanit_700Bold,
} from '@expo-google-fonts/kanit'
import Navigation from './src/navigations'
import theme from './src/theme'
import { LocalizationProvider } from 'contexts/LocalizationContext'
import { AuthProvider } from 'contexts/AuthContext'
import { maxWidth } from './src/utils/constants'
import { DimensionProvider } from 'contexts/DimensionContext'
import { MapLocationProvider } from 'contexts/MapLocationContext'
import buddhaEra from 'dayjs/plugin/buddhistEra'
import dayjs from 'dayjs'
dayjs.extend(buddhaEra)

setCustomText({
  allowFontScaling: false,
})
setCustomTextInput({
  allowFontScaling: false,
})

export default function App(): JSX.Element {
  const [fontsLoaded] = useFonts({
    regular: Kanit_400Regular,
    medium: Kanit_500Medium,
    semiBold: Kanit_600SemiBold,
    bold: Kanit_700Bold,
  })
  const { width } = useWindowDimensions()
  const paddingLeft = width > maxWidth ? (width - maxWidth) / 2 : 0

  if (!fontsLoaded) {
    return <AppLoading />
  }

  return (
    <View style={[styles.container, { paddingLeft }]}>
      <View style={styles.content}>
        <MapLocationProvider>
          <DimensionProvider>
            <ThemeProvider theme={theme}>
              <SafeAreaProvider>
                <StatusBar style="auto" />
                <LocalizationProvider>
                  <AuthProvider>
                    <Navigation />
                  </AuthProvider>
                </LocalizationProvider>
              </SafeAreaProvider>
            </ThemeProvider>
          </DimensionProvider>
        </MapLocationProvider>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
  content: {
    flex: 1,
    maxWidth,
  },
})
