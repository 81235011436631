export const nitrogenOption = [
  { id: 1, title: 'ปานกลาง', value: 'medium' },
  { id: 2, title: 'ต่ำ', value: 'low' },
  { id: 3, title: 'ต่ำมาก', value: 'very low' },
]

export const phosphorusOption = [
  { id: 1, title: 'สูง', value: 'high' },
  { id: 2, title: 'ปานกลาง', value: 'medium' },
  { id: 3, title: 'ต่ำ', value: 'low' },
]

export const potassiumOption = [
  { id: 1, title: 'สูง', value: 'high' },
  { id: 2, title: 'ปานกลาง', value: 'medium' },
]
