import { FlatList, Image, View } from 'react-native'
import React from 'react'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import { useLocalization } from 'contexts/LocalizationContext'
import Text from 'components/Text'
import dayjs from 'dayjs'
import Card from 'components/Card'
import Arrow from 'assets/images/icons/CaretRight.png'
interface Props {
  navigation: StackNavigationHelpers
}
export default function ListProgress({ navigation }: Props): JSX.Element {
  const { t } = useLocalization()
  const date = dayjs().format('D/M/YYYY')
  const dataProgressMock = [
    {
      title: '1. การวางแผนการผลิต',
      date,
      path: 'DetailModule',
    },
    {
      title: '2. การเตรียมพื้นที่ทางการเกษตรแปลงใหญ่',
      date,
      path: 'DetailModule2',
    },
  ]
  return (
    <FlatList
      style={{ paddingHorizontal: 16, backgroundColor: 'white' }}
      data={dataProgressMock}
      keyExtractor={(item) => item.title}
      renderItem={({ item, index }) => {
        return (
          <View style={{ marginBottom: 16 }}>
            <Card
              onPress={() =>
                navigation.navigate(item.path, { title: item.title, id: index })
              }
              style={{
                borderRadius: 8,
                minHeight: 70,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
              borderShadow={10}
              shadowAround
              Pressable
            >
              <View style={{ width: '90%' }}>
                <Text medium numberOfLines={2} lineHeight="20px">
                  {item.title}
                </Text>
                <Text medium color="secondary1">
                  {item.date}
                </Text>
              </View>
              <Image source={Arrow} style={{ width: 26, height: 26 }} />
            </Card>
          </View>
        )
      }}
    />
  )
}
