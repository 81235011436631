import React, { useEffect } from 'react'
import { View, TouchableOpacity } from 'react-native'
import styled from '@emotion/native'
import { useFormContext, Controller } from 'react-hook-form'
import { Item, Label, ErrorMessage } from './components'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import { useMapLocation } from 'contexts/MapLocationContext'
import MapBody from 'components/MapBody'
import { AnimatedRegion, LatLng } from 'react-native-maps'
import MapScreen from 'screens/MapScreen'
import Text from 'components/Text'

interface Props {
  name: string
  line?: boolean
  label?: string
  showOnFocus?: boolean
  themeWhite?: boolean
  frontInput?: boolean
  onChangeInput?: (value: string) => void
  selectionColor?: 'black' | 'white'
  padding?: string
  colorLabel?:
    | 'primary1'
    | 'primary2'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
  backgroundColor?: string
  testID?: string
  itemStyle?: {
    [key: string]: unknown
  }
  errorInside?: boolean
  bold?: boolean
  defaultValue: LatLng | AnimatedRegion | undefined
  ownPositions?: LatLng | AnimatedRegion | undefined
  navigation: StackNavigationHelpers
}

interface ErrorBoxProps {
  errorInside: boolean
}

const ErrorBox = styled.View<ErrorBoxProps>`
  position: ${(props) => props.errorInside && 'absolute'};
  bottom: ${(props) => props.errorInside && '5px'};
  padding: ${(props) => props.errorInside && '8px'};
`

export const Map = ({
  name,
  label,
  itemStyle = {},
  errorInside = false,
  colorLabel = 'black',
  bold,
  frontInput = false,
}: Props): JSX.Element => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext()
  const [visible, setVisible] = React.useState<boolean>(false)
  const { farmLocation, setFarmLocation } = useMapLocation()

  useEffect(() => {
    setValue(name, farmLocation)
  }, [farmLocation])

  return (
    <Item style={{ ...itemStyle }}>
      {label && !frontInput ? (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Label input color={colorLabel} bold={bold ? true : false}>
            {label}
          </Label>
          <TouchableOpacity onPress={() => setVisible(true)}>
            <Text color="primary1" textDecorationLine="underline">
              ค้นหา
            </Text>
          </TouchableOpacity>
        </View>
      ) : null}
      <Controller
        control={control}
        render={({ field: { value, onChange } }) => (
          <>
            <Item
              style={
                frontInput
                  ? { flexDirection: 'row', alignItems: 'center' }
                  : itemStyle
              }
            >
              {label && frontInput ? (
                <Label
                  input
                  color={colorLabel}
                  bold={bold ? true : false}
                  style={{ marginRight: 16, width: 80 }}
                >
                  {label}
                </Label>
              ) : null}
              <TouchableOpacity onPress={() => setVisible(true)}>
                <View
                  style={{
                    flex: 1,
                    position: 'relative',
                  }}
                >
                  <MapBody
                    readonly
                    value={value}
                    valueBeforeSubmit={{
                      lat: value.latitude,
                      lng: value.longitude,
                    }}
                  />
                </View>
              </TouchableOpacity>
              <MapScreen
                visible={visible}
                value={value}
                onChange={onChange}
                onConfirm={(v: any) => {
                  setVisible(false)
                  setFarmLocation(v)
                }}
                onClose={() => setVisible(false)}
              />
            </Item>
            {errors[name] && (
              <ErrorBox errorInside={errorInside}>
                <ErrorMessage error>{errors[name].message}</ErrorMessage>
              </ErrorBox>
            )}
          </>
        )}
        name={name}
      />
    </Item>
  )
}
