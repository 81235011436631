import { View, ViewStyle } from 'react-native'
import React from 'react'
import theme from 'theme/index'

interface Props {
  color?: string
  style?: ViewStyle
}
const Seperator = ({ color, style }: Props): JSX.Element => {
  return (
    <View
      style={{
        borderBottomColor: color ? color : theme.color.black6,
        borderBottomWidth: 1,
        paddingVertical: 6,
        ...style,
      }}
    />
  )
}

export default Seperator
