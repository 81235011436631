import React from 'react'
import * as yup from 'yup'
import Container from 'components/Container'
import Content from 'components/Content'
import Background from 'components/Background'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { useLocalization } from 'contexts/LocalizationContext'
import { RouteProp } from '@react-navigation/native'
import { View, StyleSheet, Platform } from 'react-native'
import theme from 'theme/index'
import { Form, Input, Select, SubmitButton } from 'components/Form'
import { organicMatterOption } from './option'
import request from 'utils/request'
import dayjs from 'dayjs'

export type Props = {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<
    { params: { id: string; data: any; station: string } },
    'params'
  >
}

type FormData = {
  organicMatter: string
  electricalConductivity: string
  ph: string
}

const AnalyseNutrientScreen = ({ navigation, route }: Props): JSX.Element => {
  const { t } = useLocalization()
  const { id, data, station } = route.params

  const nutrientData = data.data[0]

  const defaultValue = {
    organicMatter: nutrientData.organicMatter,
    electricalConductivity: nutrientData.electricalConductivity,
    ph: nutrientData.ph,
  }

  const schema = yup.object().shape({
    organicMatter: yup
      .string()
      .required(
        t(
          'screens.StationNutrientManagementScreen.analyseScreen.required.organicMatter'
        )
      ),
    electricalConductivity: yup
      .number()
      .min(
        0,
        t('screens.StationNutrientManagementScreen.analyseScreen.required.max')
      )
      .max(
        20,
        t('screens.StationNutrientManagementScreen.analyseScreen.required.max')
      )
      .typeError(
        t(
          'screens.StationNutrientManagementScreen.analyseScreen.required.typeError'
        )
      )
      .required(
        t(
          'screens.StationNutrientManagementScreen.analyseScreen.required.electricalConductivity'
        )
      ),
    ph: yup
      .number()
      .required(
        t('screens.StationNutrientManagementScreen.analyseScreen.required.ph')
      ),
  })

  const onSubmit = async (data: FormData) => {
    try {
      await request.post(`/workAssignment/${id}/soilSampleDataLog`, {
        date: dayjs(),
        ph: parseInt(data.ph),
        electricalConductivity: parseInt(data.ph),
        organicMatter: data.organicMatter,
      })
      navigation.navigate('StationNutrient', { id, station })
    } catch (e) {
      console.log(JSON.stringify(e.response, null, 2))
    }
  }

  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <View style={{ width: '100%', alignItems: 'center', height: 130 }}>
        <Header
          showBack
          title={t(
            'screens.StationNutrientManagementScreen.analyseScreen.title'
          )}
          style={{ paddingBottom: 0 }}
        />
      </View>
      <Content
        noPadding
        enabledScroll={true}
        style={{
          backgroundColor: '#fff',
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
        }}
      >
        <Form
          defaultValues={defaultValue}
          schema={schema}
          style={{
            marginTop: 30,
            paddingHorizontal: 30,
            flex: 1,
            justifyContent: 'space-between',
          }}
        >
          <View>
            <Input
              name="electricalConductivity"
              label={t(
                'screens.StationNutrientManagementScreen.analyseScreen.form.EC'
              )}
              style={styles.Input}
              keyboardType="number-pad"
              semiBold
              unitLabel="mS/cm"
              unitLabelStyle={{ marginLeft: 10 }}
              placeholder="0.00 - 20.00"
            />
            <Select
              name="organicMatter"
              label={t(
                'screens.StationNutrientManagementScreen.analyseScreen.form.OM'
              )}
              semiBold
              option={organicMatterOption}
              itemStyle={{ marginBottom: 10 }}
              defaultValue={defaultValue.organicMatter}
            />
            <Input
              name="ph"
              label={t(
                'screens.StationNutrientManagementScreen.analyseScreen.form.ph'
              )}
              semiBold
              style={styles.Input}
              keyboardType="number-pad"
            />
          </View>
          <View style={{ marginTop: 36, marginBottom: 24 }}>
            <SubmitButton
              testID="form.stationPrepareScreen"
              title={t('Buttons.Save')}
              onSubmit={onSubmit}
            />
          </View>
        </Form>
      </Content>
    </Container>
  )
}

const styles = StyleSheet.create({
  viewHead: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 12,
    paddingTop: 12,
  },
  viewLeft: {
    flex: 0.6,
  },
  viewRight: { flex: 0.4 },
  viewBorder: { borderBottomWidth: 1, borderColor: theme.color.black6 },
  formulaHead: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 8,
    paddingTop: 8,
  },
  formula: { flex: 0.35 },
  Input: {
    ...Platform.select({
      web: {
        paddingVertical: 6,
        height: 40,
      },
      default: {
        paddingVertical: 6,
        flex: 1,
      },
    }),
  },
})

export default AnalyseNutrientScreen
