import React, { useEffect, useState } from 'react'
import {
  Keyboard,
  Platform,
  ScrollView,
  TextInputProps,
  View,
} from 'react-native'
import styled, { css } from '@emotion/native'
import { useFormContext, Controller } from 'react-hook-form'
import Text from 'components/Text'

import { Item, Label, ErrorMessage } from './components'
import request from 'utils/request'
import { TouchableOpacity } from 'react-native-gesture-handler'
import theme from '../../theme'

interface Props extends TextInputProps {
  name: string
  line?: boolean
  label?: string
  showOnFocus?: boolean
  themeWhite?: boolean
  frontInput?: boolean

  onChangeInput?: (value: string) => void
  selectionColor?: 'black' | 'white'
  padding?: string
  colorLabel?:
    | 'primary1'
    | 'primary2'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
  backgroundColor?: string
  testID?: string
  itemStyle?: {
    [key: string]: unknown
  }
  errorInside?: boolean
  bold?: boolean
  defaultValue: any
}
interface InputProps {
  line: boolean
  isFocus?: boolean
  themeWhite?: boolean
  padding?: string
}
interface ErrorBoxProps {
  errorInside: boolean
}

const TextInput = styled.TextInput<InputProps>`
  ${({ theme, line, isFocus, themeWhite }) => {
    if (themeWhite) {
      if (isFocus && Platform.OS === 'web') {
        return css`
          border: 1px solid ${theme.color.primary1};
          outline-color: ${theme.color.primary1};
          border-radius: 10px;
          margin-top: 2px;
        `
      } else {
        return `
        border: 1px solid ${theme.color.white};
        border-radius: 10px;
        margin-top: 2px;
        `
      }
    }
    if (line) {
      return `
        border-bottom-width:1px;
        border-bottom-color: ${theme.input.borderColor};
        border-radius: 0px;
      `
    }
    if (isFocus && Platform.OS === 'web') {
      return css`
        border: 1px solid ${theme.color.primary1};
        outline-color: ${theme.color.primary1};
        border-radius: 10px;
        margin-top: 2px;
      `
    }
    if (isFocus && Platform.OS !== 'web') {
      return `
      border: 1px solid ${theme.color.primary1};
      border-radius: 10px;
      margin-top: 2px;
    `
    }
    return `
    border: 1px solid ${theme.color.black22};
    border-radius: 10px;
    margin-top: 2px;
      `
  }}
  background-color: transparent;
  /* font-family: Kanit_400Regular; */
`
const ErrorBox = styled.View<ErrorBoxProps>`
  position: ${(props) => props.errorInside && 'absolute'};
  bottom: ${(props) => props.errorInside && '5px'};
  padding: ${(props) => props.errorInside && '8px'};
`

export const SearchInput = ({
  name,
  line = false,
  label,
  testID,
  itemStyle = {},
  errorInside = false,
  showOnFocus = true,
  colorLabel = 'black',
  selectionColor = 'black',
  style,
  bold,
  backgroundColor,
  frontInput = false,
  defaultValue,
  ...props
}: Props): JSX.Element => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext()
  const [isFocus, setIsFocus] = React.useState(false)
  const [text, setText] = useState<string | undefined>(
    defaultValue
      ? `${defaultValue.firstName} ${defaultValue.lastName}`
      : undefined
  )
  const [searchUser, setSearchUser] = useState<any[]>([])

  useEffect(() => {
    setValue(name, defaultValue.id)
  }, [defaultValue])

  const getSearchUser = async (text: string) => {
    try {
      const res = await request.get('/user/search', {
        params: {
          search: text,
        },
      })
      setSearchUser(res.data.data)
    } catch (e) {
      return console.log(JSON.stringify(e.response, null, 2))
    }
  }
  const isWeb = Platform.OS === 'web'

  return (
    <Item style={{ ...itemStyle }}>
      {label && !frontInput ? (
        <Label input color={colorLabel} bold={bold ? true : false}>
          {label}
        </Label>
      ) : null}
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <Item
              style={
                frontInput
                  ? { flexDirection: 'row', alignItems: 'center' }
                  : itemStyle
              }
            >
              {label && frontInput ? (
                <Label
                  input
                  color={colorLabel}
                  bold={bold ? true : false}
                  style={{ marginRight: 16, width: 80 }}
                >
                  {label}
                </Label>
              ) : null}
              <TextInput
                testID={testID}
                autoCapitalize="none"
                onBlur={() => {
                  onBlur()
                  !isWeb && setIsFocus(false)
                }}
                onChangeText={(value) => {
                  setText(value ? value : undefined)
                  getSearchUser(value)
                  onChange(undefined)
                }}
                value={text}
                onFocus={() => setIsFocus(true)}
                line={line}
                textAlignVertical={props.multiline ? 'top' : 'center'}
                isFocus={showOnFocus ? isFocus : false}
                selectionColor={selectionColor}
                style={[
                  {
                    color: theme.color.secondary2,
                    fontFamily: 'regular',
                    borderBottomLeftRadius:
                      isFocus && text !== undefined ? 0 : 10,
                    borderBottomRightRadius:
                      isFocus && text !== undefined ? 0 : 10,
                    paddingHorizontal: 10,
                    backgroundColor: backgroundColor,
                    paddingVertical: 8,
                    width: Platform.OS === 'web' ? '100%' : 'auto',
                    minHeight:
                      Platform.OS === 'ios' && props.numberOfLines
                        ? 20 * props.numberOfLines
                        : 'auto',
                  },
                  style,
                ]}
                {...props}
              />
              {isFocus && text !== undefined && (
                <ScrollView
                  scrollEnabled={true}
                  nestedScrollEnabled={true}
                  style={{
                    width: '100%',
                    paddingHorizontal: 10,
                    borderRadius: 8,
                    borderWidth: 1,
                    borderTopWidth: 0,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderColor: theme.color.primary1,
                    minHeight: 42,
                    maxHeight: 100,
                    overflow: 'scroll',
                  }}
                >
                  {searchUser?.map((item) => {
                    return (
                      <TouchableOpacity
                        key={item.id}
                        style={{
                          flexDirection: 'row',
                          paddingVertical: 8,
                        }}
                        onPress={() => {
                          setText(`${item.firstName} ${item.lastName}`)
                          onChange(item.id)
                          setIsFocus(false)
                        }}
                      >
                        <View
                          key={item.id}
                          style={{
                            flexDirection: 'column',
                            // backgroundColor: 'red',
                          }}
                        >
                          <Text>{`${item.firstName} ${item.lastName}`}</Text>
                        </View>
                      </TouchableOpacity>
                    )
                  })}
                </ScrollView>
              )}
            </Item>
            {errors[name] && (
              <ErrorBox errorInside={errorInside}>
                <ErrorMessage error>{errors[name].message}</ErrorMessage>
              </ErrorBox>
            )}
          </>
        )}
        name={name}
      />
    </Item>
  )
}
