import React, { useState } from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Text from 'components/Text'
import Background from 'components/Background'
import { FlatList, View, TouchableOpacity, StyleSheet } from 'react-native'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { Provider } from 'react-native-paper'
import Card from 'components/Card'

import { useFocusEffect } from '@react-navigation/native'
import { useLocalization } from 'contexts/LocalizationContext'
import { objWorkStation } from 'utils/staticData'
import dayjs from 'dayjs'
import request from 'utils/request'
export type Props = {
  navigation: BottomTabNavigationHelpers
}
export interface Order {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  orderDate: Date
  documentNumber: string
  customer: string
  status: string
}

export interface Project {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  projectNumber: string
  projectDate: Date
  itemCode: string
  detail: string
  quantity: number
  unit: string
  fundPerUnit: number
  totalFund: number
  dueDate: Date
  status: string
  deliveredDate: Date
  lastedUpdateActualDate?: Date
  farmId: string
  speciesId: string
  orderId: string
  productId: string
  order: Order
}

export interface Datum {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  projectNumber: string
  workAssignmentNumber: string
  farmId: string
  startDate: string
  workStation: string
  workDetail: string
  farmerId: string
  extensionistId: string
  approverId: string
  status: string
  quantity: number
  unit: string
  capacityPerDay: number
  isPublished: boolean
  project: Project
}

export interface RootObject {
  data: Datum[]
  total: number
  page: number
  limit: number
}

const WorksheetScreen = ({ navigation }: Props): JSX.Element => {
  const { t } = useLocalization()
  const limit = 10
  const page = 1
  const [workAssignment, setWorkAssignment] = useState<RootObject>({
    data: [],
    page: 1,
    total: 1,
    limit: 10,
  })
  const fatchWorkAssignment = async () => {
    try {
      const res = await request.get(
        `/user/me/workAssignment?limit=${limit}&page=${page}&isPublished=true`
      )
      setWorkAssignment(res?.data)
    } catch (err) {
      console.log(err)
    }
  }
  const onLoadMore = async () => {
    try {
      if (workAssignment?.total > workAssignment.data.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        try {
          const res = await request.get(
            `/user/me/workAssignment?limit=${limit}&page=${
              workAssignment?.page + 1
            }&isPublished=true`
          )
          const data = {
            data: [...workAssignment.data, ...res.data.data],
            page: workAssignment?.page + 1,
            total: workAssignment.total,
            limit: workAssignment.limit,
          }
          setWorkAssignment(data)
        } catch (err) {
          console.log(err)
        }
      }
    } catch (e) {
      // console.log(e)
    }
  }
  // const { data: workAssignment, mutate } = useSWR(
  //   [`/user/me/workAssignment?limit=${limit}&page=1&isPublished=true`],
  //   fetcher
  // )

  useFocusEffect(
    React.useCallback(() => {
      fatchWorkAssignment()
    }, [])
  )
  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          title={t('screens.WorksheetScreen.title')}
          style={styles.Header}
          alignTitle="center"
        />

        <Card borderRadiusTop width="100%" style={{ height: '100%' }}>
          <Content enabledScroll={false} noPadding>
            <FlatList
              nestedScrollEnabled={true}
              data={workAssignment?.data}
              indicatorStyle="default"
              style={styles.FlatList}
              onEndReached={onLoadMore}
              contentContainerStyle={{ paddingBottom: 60 }}
              renderItem={({ item }) => {
                return (
                  <TouchableOpacity
                    onPress={() =>
                      navigation.navigate('WorksheetDetailScreen', {
                        id: item.id,
                      })
                    }
                  >
                    <View style={styles.viewHead}>
                      <Card style={{ paddingVertical: 25 }}>
                        <View>
                          <View style={styles.viewDisplay}>
                            <View>
                              <Text color="black" sub1 medium>
                                {`${t(
                                  'screens.WorksheetScreen.workAssignmentNumber'
                                )} : ${item.workAssignmentNumber}`}
                              </Text>
                            </View>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <Text
                                color={
                                  item.status === 'pending'
                                    ? 'error'
                                    : item.status === 'inProgress'
                                    ? 'warning'
                                    : 'success'
                                }
                                sub1
                              >
                                {item.status === 'pending'
                                  ? t('screens.WorksheetScreen.pending')
                                  : item.status === 'inProgress'
                                  ? t('screens.WorksheetScreen.inProgress')
                                  : t('screens.WorksheetScreen.completed')}
                              </Text>
                            </View>
                          </View>

                          <View>
                            <Text color={'black85'}>
                              {`สถานีงาน : ${
                                objWorkStation?.[
                                  item.workStation as keyof typeof objWorkStation
                                ]
                              }`}
                            </Text>
                          </View>
                          <View>
                            <Text color={'black85'}>{`วันที่ได้รับงาน : ${dayjs(
                              item.startDate
                            ).format('DD/MM/BB')}`}</Text>
                          </View>
                        </View>
                      </Card>
                    </View>
                  </TouchableOpacity>
                )
              }}
              keyExtractor={(item) => item.id}
            />
          </Content>
        </Card>
      </Container>
    </Provider>
  )
}

const styles = StyleSheet.create({
  Header: {
    paddingBottom: '10%',
  },
  Image: {
    width: 24,
    height: 24,
  },
  viewHead: {
    marginBottom: 10,
  },
  viewDisplay: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  FlatList: {
    marginTop: 15,
    marginBottom: 60,
  },
  Searchbar: {
    marginBottom: 24,
    width: '90%',
    //backgroundColor: 'white',
  },
})

export default WorksheetScreen
