import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'

interface Props {
  children: JSX.Element
}

interface Context {
  tabState: number
  setTabState: Dispatch<SetStateAction<number>>
}

const defaultTabState = 0

const MainTabStateContext = createContext<Context>({
  tabState: defaultTabState,
  setTabState: () => defaultTabState,
})

export const MainTabStateProvider: FC<Props> = ({ children }) => {
  const [tabState, setTabState] = useState(defaultTabState)

  const mainTabStateContext = useMemo(
    () => ({
      tabState,
      setTabState,
    }),
    [tabState]
  )

  return (
    <MainTabStateContext.Provider value={mainTabStateContext}>
      {children}
    </MainTabStateContext.Provider>
  )
}

export const useMainTabState = (): Context => {
  const context = useContext(MainTabStateContext)
  if (!context) {
    throw new Error('useMainTabState can be use in MainTabStateContext only')
  }
  return context
}
