import React, { useState } from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Text from 'components/Text'
import Background from 'components/Background'
import {
  FlatList,
  View,
  TouchableOpacity,
  StyleSheet,
  Image,
} from 'react-native'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { Provider } from 'react-native-paper'
import Card from 'components/Card'

import { useFocusEffect } from '@react-navigation/native'
import { useLocalization } from 'contexts/LocalizationContext'
import { objWorkStation } from 'utils/staticData'
import dayjs from 'dayjs'
import { SearchProjectInput } from './SearchProjectInput'
import SearchIcon from 'assets/images/icons/Search.png'
import { ObjPathStation } from 'utils/PathStation'
import request from 'utils/request'
export type Props = {
  navigation: BottomTabNavigationHelpers
}

export interface Order {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  orderDate: Date
  documentNumber: string
  customer: string
  status: string
}

export interface Project {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  projectNumber: string
  projectDate: Date
  itemCode: string
  detail: string
  quantity: number
  unit: string
  fundPerUnit: number
  totalFund: number
  dueDate: Date
  status: string
  deliveredDate: Date
  lastedUpdateActualDate?: Date
  farmId: string
  speciesId: string
  orderId: string
  productId: string
  order: Order
}

export interface Datum {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  projectNumber: string
  workAssignmentNumber: string
  farmId: string
  startDate: string
  workStation: string
  workDetail: string
  farmerId: string
  extensionistId: string
  approverId: string
  status: string
  quantity: number
  unit: string
  capacityPerDay: number
  isPublished: boolean
  project: Project
}

export interface RootObject {
  data: Datum[]
  total: number
  page: number
  limit: number
}

const WorksheetCompanyScreen = ({ navigation }: Props): JSX.Element => {
  const { t } = useLocalization()
  const limit = 10
  const page = 1
  const [searchQuery, setSearchQuery] = useState('')
  const [projectId, setProjectId] = useState<RootObject>({
    data: [],
    page: 1,
    total: 1,
    limit: 10,
  })
  const fatchProjectId = async () => {
    try {
      if (searchQuery) {
        const res = await request.get(
          `/workAssignment?page=${page}&limit=${limit}&isPublished=true&projectNumber=${searchQuery}`
        )
        setProjectId(res?.data)
      } else {
        const res = await request.get(
          `/workAssignment?page=${page}&limit=${limit}&isPublished=true`
        )
        setProjectId(res?.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onLoadMore = async () => {
    try {
      if (projectId?.total > projectId.data.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        try {
          if (searchQuery) {
            const res = await request.get(
              `/workAssignment?page=${
                projectId?.page + 1
              }&limit=${limit}&isPublished=true&projectNumber=${searchQuery}`
            )
            const data = {
              data: [...projectId.data, ...res.data.data],
              page: projectId?.page + 1,
              total: projectId.total,
              limit: projectId.limit,
            }
            setProjectId(data)
          } else {
            const res = await request.get(
              `/workAssignment?page=${
                projectId?.page + 1
              }&limit=${limit}&isPublished=true`
            )
            const data = {
              data: [...projectId.data, ...res.data.data],
              page: projectId?.page + 1,
              total: projectId.total,
              limit: projectId.limit,
            }
            setProjectId(data)
          }
        } catch (err) {
          console.log(err)
        }
      }
    } catch (e) {
      // console.log(e)
    }
  }
  // const { data: projectId, mutate } = useSWR(
  //   [
  //     searchQuery
  //       ? `/workAssignment?page=${page}&limit=${limit}&isPublished=true&projectNumber=${searchQuery}`
  //       : `/workAssignment?page=${page}&limit=${limit}&isPublished=true`,
  //   ],
  //   fetcher
  // )
  useFocusEffect(
    React.useCallback(() => {
      fatchProjectId()
    }, [searchQuery])
  )
  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          title={t('screens.WorksheetScreen.title')}
          style={styles.Header}
          alignTitle="center"
        />

        <SearchProjectInput setSearchQuery={setSearchQuery} />
        {projectId ? (
          <Card borderRadiusTop width="100%" style={{ height: '80%' }}>
            <Text>เลขที่โปรเจค : {`${searchQuery}`}</Text>
            <Content enabledScroll={false} noPadding>
              <FlatList
                nestedScrollEnabled={true}
                data={projectId?.data}
                indicatorStyle="default"
                style={styles.FlatList}
                onEndReached={onLoadMore}
                contentContainerStyle={{ paddingBottom: 60 }}
                renderItem={({ item }) => {
                  const workStation = item?.workStation
                  return (
                    <TouchableOpacity
                      onPress={() =>
                        navigation.navigate(
                          ObjPathStation[
                            workStation as keyof typeof ObjPathStation
                          ],
                          {
                            id: item.id,
                            data: item,
                            farmId: item?.farmId,
                            //farmData: farm,
                          }
                        )
                      }
                    >
                      <View style={styles.viewHead}>
                        <Card style={{ paddingVertical: 25 }}>
                          <View>
                            <View style={styles.viewDisplay}>
                              <View>
                                <Text color="black" sub1 medium>
                                  {`${t(
                                    'screens.WorksheetScreen.workAssignmentNumber'
                                  )} : ${item.workAssignmentNumber}`}
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <Text
                                  color={
                                    item.status === 'pending'
                                      ? 'error'
                                      : item.status === 'inProgress'
                                      ? 'warning'
                                      : 'success'
                                  }
                                  sub1
                                >
                                  {item.status === 'pending'
                                    ? t('screens.WorksheetScreen.pending')
                                    : item.status === 'inProgress'
                                    ? t('screens.WorksheetScreen.inProgress')
                                    : t('screens.WorksheetScreen.completed')}
                                </Text>
                              </View>
                            </View>

                            <View>
                              <Text color={'black85'}>
                                {`สถานีงาน : ${
                                  objWorkStation?.[
                                    item.workStation as keyof typeof objWorkStation
                                  ]
                                }`}
                              </Text>
                            </View>
                            <View>
                              <Text
                                color={'black85'}
                              >{`วันที่ได้รับงาน : ${dayjs(
                                item.startDate
                              ).format('DD/MM/BB')}`}</Text>
                            </View>
                          </View>
                        </Card>
                      </View>
                    </TouchableOpacity>
                  )
                }}
                keyExtractor={(item) => item.id}
              />
            </Content>
          </Card>
        ) : (
          <Card borderRadiusTop width="100%" style={{ height: '80%' }}>
            <View
              style={{
                height: '80%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                source={SearchIcon}
                style={{
                  width: 60,
                  height: 70,
                  marginRight: 8,
                  resizeMode: 'contain',
                }}
              />
              <Text>กรุณาใส่เลขที่โปรเจค</Text>
              <Text>เพื่อค้นหาข้อมูล</Text>
            </View>
          </Card>
        )}
      </Container>
    </Provider>
  )
}

const styles = StyleSheet.create({
  Header: {
    paddingBottom: '10%',
  },
  Image: {
    width: 24,
    height: 24,
  },
  viewHead: {
    marginBottom: 10,
  },
  viewDisplay: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  FlatList: {
    marginTop: 15,
  },
  Searchbar: {
    marginBottom: 24,
    width: '90%',
    //backgroundColor: 'white',
  },
})

export default WorksheetCompanyScreen
