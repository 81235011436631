import { View, ScrollView } from 'react-native'
import React, { useEffect, useMemo, useState } from 'react'
import Container from 'components/Container'
import Header from 'components/Header'
import Background from 'components/Background'
import Content from 'components/Content'
import Card from 'components/Card'
import { Form, Input, SubmitButton } from 'components/Form'
import * as yup from 'yup'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import useSWR from 'swr'
import request, { fetcher } from 'utils/request'
import { RouteProp } from '@react-navigation/native'
import InputDate from 'components/Form/InputDate'
import dayjs from 'dayjs'
import DropDownForm from 'components/Form/DropDownForm'
import Text from 'components/Text'

export default function EditWaterMeansurementAndAnalysis({
  navigation,
  route,
}: {
  navigation: StackNavigationHelpers
  route: RouteProp<{ params: { id: string; editId: string } }, 'params'>
}): JSX.Element {
  const { id, editId } = route.params
  const { data: analyzeData } = useSWR(
    () => `/workAssignment/${id}/waterAnalyze/${editId}`,
    fetcher
  )

  const { data: soilData } = useSWR(
    `/workAssignment/${id}/waterAnalyze/soilset`,
    fetcher
  )
  const [soilSet, setSoilSet] = useState<
    string | number | boolean | null | undefined
  >('')

  const schema = yup.object().shape({
    date: yup.string().required('กรุณากรอกวันที่'),
    soilSet: yup
      .string()
      .typeError('กรุณาเลือกชุดดิน')
      .required('กรุณาเลือกชุดดิน'),
    FC: yup.string().required('กรุณากรอกจุดอิ่มตัว'),
    PWP: yup.string().required('กรุณากรอกจุดเหี่ยวถาวร'),
    AWC: yup.string().required('กรุณากรอกความจุน้ำ'),
  })
  const soilSetOption = useMemo(() => {
    return soilData?.map((item: { soilSet: string }) => {
      return { label: item.soilSet, value: item.soilSet }
    })
  }, [soilData])

  const areaData = useMemo(() => {
    return soilData?.find((item: { soilSet: string }) => {
      return item.soilSet === soilSet
    })
  }, [soilData, soilSet])

  useEffect(() => {
    if (analyzeData) {
      setSoilSet(analyzeData?.areaData?.soilSet)
    }
  }, [analyzeData, areaData])

  const onSubmit = async (data: {
    date: string
    soilSet: string
    FC: string
    PWP: string
    AWC: string
  }) => {
    try {
      await request.patch(
        `/workAssignment/${id}/waterAnalyze/${editId}/areaData/${areaData?.id}`,
        {
          date: dayjs(data.date).format('YYYY-MM-DD'),
          FC: +data.FC,
          PWP: +data.PWP,
          AWC: +data.AWC,
        }
      )
      navigation.goBack()
    } catch (e) {
      console.log(e)
    }
  }

  if (!analyzeData) {
    return <View></View>
  }
  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <Header
        showBack
        title={'การตรวจวัด\nและวิเคราะห์การใช้น้ำของพืช'}
        widthText={'90%'}
      />
      <Content enabledScroll={false} noPadding>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <Card
            borderRadiusTop
            style={{ justifyContent: 'space-between', flex: 1, marginTop: 32 }}
          >
            <Form
              schema={schema}
              defaultValues={{
                date: analyzeData?.date,
                soilSet: analyzeData?.areaData?.soilSet,
                FC: analyzeData?.FC,
                PWP: analyzeData?.PWP,
                AWC: analyzeData?.AWC,
              }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <View>
                <InputDate
                  name="date"
                  label="วันที่"
                  defaultValue={analyzeData?.date}
                />
                <DropDownForm
                  name="soilSet"
                  label="ชุดดิน"
                  data={soilSetOption}
                  setValueDropdown={setSoilSet}
                  placeholder={''}
                />
                <View style={{ flexDirection: 'row', paddingBottom: 7 }}>
                  <Text color="black" input>
                    ความลาดชัน:
                  </Text>
                  <Text color="secondary2" style={{ paddingLeft: 10 }} input>
                    {areaData ? (areaData?.slope || 0) + ' องศา' : '-'}
                  </Text>
                </View>
                <Input
                  name="FC"
                  label="จุดอิ่มตัวด้วยน้ำของดินหรือความชื้นความจุสนาม (FC)"
                />
                <Input name="PWP" label="จุดเหี่ยวถาวร (PWP)" />
                <Input name="AWC" label="ความจุน้ำใช้ประโยชน์ได้ของดิน (AWC)" />
              </View>
              <View style={{ paddingHorizontal: '8%' }}>
                <SubmitButton title="ตกลง" onSubmit={onSubmit} />
              </View>
            </Form>
          </Card>
        </ScrollView>
      </Content>
    </Container>
  )
}
