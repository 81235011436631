import React from 'react'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { useLocalization } from 'contexts/LocalizationContext'
import { View } from 'react-native'
import { RouteProp } from '@react-navigation/native'
import Container from 'components/Container'
import Content from 'components/Content'
import Background from 'components/Background'
import Header from 'components/Header'
import SectionList from 'components/SectionList'
import Link from 'components/Link'
import FertilizerFormula from 'components/FertilizerFormula'
import Button from 'components/Button'
import Text from 'components/Text'

export type Props = {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<
    { params: { id: string; data: any; station: string } },
    'params'
  >
}

const ResultTestNutrientScreen = ({
  navigation,
  route,
}: Props): JSX.Element => {
  const { t } = useLocalization()
  const { id, data, station } = route.params
  const nutrientData = data?.data[0]

  const formulaValue = [
    { formula: '46-0-0', amount: 40 },
    { formula: '18-46-0', amount: 50 },
    { formula: '0-0-60', amount: 30 },
  ]
  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <View style={{ width: '100%', alignItems: 'center', height: 130 }}>
        <Header
          showBack
          title={t('screens.StationNutrientManagementScreen.testScreen.result')}
          style={{ paddingBottom: 0 }}
        />
      </View>
      <Content
        noPadding
        enabledScroll={true}
        style={{
          backgroundColor: '#fff',
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
        }}
      >
        <View
          style={{
            marginTop: 20,
            paddingHorizontal: 30,
            flex: 1,
            justifyContent: 'space-between',
          }}
        >
          <View>
            <Text sub1 color="secondary2" semiBold>
              {t('screens.StationNutrientManagementScreen.testScreen.InLeaf')}
            </Text>
            <SectionList
              title={t(
                'screens.StationNutrientManagementScreen.testScreen.form.nitrogen'
              )}
              value={nutrientData.nitrogenInLeaf}
            />
            <SectionList
              title={t(
                'screens.StationNutrientManagementScreen.testScreen.form.phosphorus'
              )}
              value={nutrientData.phosphorusInLeaf}
            />
            <SectionList
              title={t(
                'screens.StationNutrientManagementScreen.testScreen.form.potassium'
              )}
              value={nutrientData.potassiumInLeaf}
              style={{ marginBottom: 30 }}
            />
            <Text sub1 color="secondary2" semiBold>
              {t('screens.StationNutrientManagementScreen.testScreen.InSoil')}
            </Text>
            <SectionList
              title={t(
                'screens.StationNutrientManagementScreen.testScreen.form.nitrogen'
              )}
              value={nutrientData.nitrogenInSoil}
            />
            <SectionList
              title={t(
                'screens.StationNutrientManagementScreen.testScreen.form.phosphorus'
              )}
              value={nutrientData.phosphorusInSoil}
            />
            <SectionList
              title={t(
                'screens.StationNutrientManagementScreen.testScreen.form.potassium'
              )}
              value={nutrientData.potassiumInSoil}
              style={{ marginBottom: 30 }}
            />
            <FertilizerFormula
              value={formulaValue}
              style={{ marginBottom: 30 }}
            />
            <Link title="ข้อมูลอัตราการเจริญเติบโต (Growth rate) พืช" />
            <Link title="ข้อมูลพืช" />
            <Link title="คู่มือการให้ธาตุอาหารพืช" />
          </View>
          <View style={{ marginTop: 36, marginBottom: 24 }}>
            <Button
              title={t('Buttons.EditAnalytics')}
              onPress={() =>
                navigation.navigate('TestNutrient', {
                  id,
                  data,
                  station,
                })
              }
            />
          </View>
        </View>
      </Content>
    </Container>
  )
}

export default ResultTestNutrientScreen
