import { Platform, View, ViewStyle } from 'react-native'
import React, { useEffect, useState } from 'react'

import { useThaiAddress } from 'react-thai-address-hook'
import styled, { css } from '@emotion/native'
import Text from 'components/Text'
import { Controller, useFormContext } from 'react-hook-form'
import { ErrorMessage, Item, Label } from './components'
import _ from 'lodash'
import theme from 'theme/index'
interface Props {
  label?: string
  name: string
  keyName: 'amphoe' | 'province' | 'district' | 'zipcode'
  line?: boolean
  style?: ViewStyle
  showOnFocus?: boolean
  themeWhite?: boolean
  setProvince?: (value: string) => void
  province?: string
  selectionColor?: 'black' | 'white'
  padding?: string
  defaultValue?: string
  colorLabel?:
    | 'primary1'
    | 'primary2'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'

  testID?: string
  itemStyle?: {
    [key: string]: unknown
  }
  errorInside?: boolean
}
interface AddressSearch {
  district?: string
  amphoe?: string
  province?: string
  zipcode?: number
}
interface InputProps {
  line: boolean
  isFocus?: boolean
  themeWhite?: boolean
  padding?: string
}
interface ErrorBoxProps {
  errorInside: boolean
}

const TextInput = styled.TextInput<InputProps>`
  ${({ theme, line, isFocus, themeWhite }) => {
    if (line) {
      return `
        border-bottom-width:1px;
        border-bottom-color: ${theme.input.borderColor};
        border-radius: 0px;
      `
    }
    if (isFocus && Platform.OS === 'web') {
      return css`
        border: 1px solid ${theme.color.primary1};
        outline-color: ${theme.color.primary1};
        border-radius: 10px;
        margin-top: 2px;
      `
    }
    if (isFocus && Platform.OS !== 'web') {
      return `
      border: 1px solid ${theme.color.primary1};
      border-radius: 10px;
      margin-top: 2px;
    `
    }
    if (themeWhite) {
      return `
      border: 1px solid ${theme.color.white};
      border-radius: 10px;
      margin-top: 2px;
   
      `
    }
    return `
    border: 1px solid ${theme.color.black40};
    border-radius: 10px;
    margin-top: 2px;
      `
  }}
  background-color: transparent;
  /* font-family: Kanit_400Regular; */
`
const ErrorBox = styled.View<ErrorBoxProps>`
  position: ${(props) => props.errorInside && 'absolute'};
  bottom: ${(props) => props.errorInside && '5px'};
  padding: ${(props) => props.errorInside && '8px'};
`

const ListItem = styled.Pressable`
  padding: 8px;
`

export default function Address({
  name,
  keyName,
  line = false,
  label,
  testID,
  itemStyle = {},
  errorInside = false,
  showOnFocus = true,
  colorLabel = 'black',
  setProvince,
  province,
  selectionColor = 'black',
  style = { paddingVertical: 8 },
  defaultValue,
  ...props
}: Props): JSX.Element {
  const [searchStr, setSearchStr] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selector, setSelector] = useState<AddressSearch[] | any[]>([])
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext()
  const {
    searchAddressByDistrict,
    searchAddressByAmphoe,
    searchAddressByProvince,
    searchAddressByZipcode,
  } = useThaiAddress()
  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue)
    }
  }, [defaultValue])
  useEffect(() => {
    if (keyName === 'district') {
      if (province) {
        const result = _.uniqBy(
          searchAddressByDistrict(searchStr),
          keyName
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ).filter((item: any) => item.province === province)
        setSelector(result)
      } else {
        const result = _.uniqBy(searchAddressByDistrict(searchStr), keyName)
        setSelector(result)
      }
    } else if (keyName === 'amphoe') {
      const result = _.uniqBy(searchAddressByAmphoe(searchStr), keyName)
      setSelector(result)
    } else if (keyName === 'province') {
      const result = _.uniqBy(searchAddressByProvince(searchStr), keyName)
      setSelector(result)
    } else if (keyName === 'zipcode') {
      const result = _.uniqBy(searchAddressByZipcode(searchStr), keyName)
      setSelector(result)
    }
  }, [searchStr])

  const [isFocus, setIsFocus] = React.useState(false)
  return (
    <Item style={{ ...itemStyle }}>
      {label && (
        <Label input color={colorLabel}>
          {label}
        </Label>
      )}
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <Item style={itemStyle}>
              <TextInput
                testID={testID}
                onBlur={() => {
                  onBlur()
                  setIsFocus(false)
                }}
                onChangeText={(value) => {
                  onChange(value)
                  setSearchStr(value)
                }}
                value={value && value.toString()}
                onFocus={() => setIsFocus(true)}
                line={line}
                isFocus={showOnFocus ? isFocus : false}
                selectionColor={selectionColor}
                style={[
                  {
                    paddingHorizontal: 10,
                    fontFamily: 'regular',
                    color: theme.color.secondary2,
                  },
                  style,
                ]}
                {...props}
              />
            </Item>
            {selector.length > 0 && (
              <View
                style={{
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 1,
                  },
                  shadowOpacity: 0.2,
                  shadowRadius: 1.41,
                  borderRadius: 10,
                  paddingRight: 1,
                  elevation: 2,
                }}
              >
                <View
                  style={{
                    zIndex: 2,
                    borderWidth: 1,
                    borderColor: theme.color.black22,
                    borderRadius: 10,
                    backgroundColor: 'white',
                  }}
                >
                  {selector?.map((choice, idx) => {
                    const isLast = selector.length - 1 === idx
                    return (
                      <ListItem
                        style={
                          isLast
                            ? null
                            : {
                                borderBottomColor: theme.color.black22,
                                borderBottomWidth: 1,
                              }
                        }
                        key={idx}
                        onPress={() => {
                          onChange(choice?.[keyName])

                          setProvince?.(choice?.[keyName])

                          setSearchStr('')
                        }}
                      >
                        <Text>{choice?.[keyName]}</Text>
                      </ListItem>
                    )
                  })}
                </View>
              </View>
            )}

            {errors[name] && (
              <ErrorBox errorInside={errorInside}>
                <ErrorMessage>{errors[name].message}</ErrorMessage>
              </ErrorBox>
            )}
          </>
        )}
        name={name}
      />
    </Item>
  )
}
