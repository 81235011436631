/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import styled from '@emotion/native'
import { useFormContext, Controller } from 'react-hook-form'
import Text from 'components/Text'
import { Item, Label, ErrorMessage } from './components'
import theme from 'theme/index'

interface Props {
  name: string
  label?: string
  style?: {
    [key: string]: unknown
  }
  testID?: string
  itemStyle?: {
    [key: string]: unknown
  }
  labelStyle?: {
    [key: string]: unknown
  }
  errorInside?: boolean
  option: any[]
  colorLabel?:
    | 'primary1'
    | 'primary2'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
  bold?: boolean
  flexRow?: boolean
  subFlexRow?: boolean
  column?: number
  setSelectSub?: React.Dispatch<React.SetStateAction<string | undefined>>
  selectSub?: string
  defaultValue?: string
  subDefaultValue?: string
}

interface ErrorBoxProps {
  errorInside: boolean
}

interface RadioProps {
  active: boolean
}

const RadioBorder = styled.View`
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid ${theme.color.black22};
  border-radius: 20px;
  margin-right: 10px;
  background-color: transparent;
`
const RadioCircle = styled.View<RadioProps>`
  width: 13px;
  height: 13px;
  ${({ active }) => {
    if (active) {
      return `
        background-color:  ${theme.color.primary1};
    `
    }
    return `background-color: transparent`
  }}

  border-radius: 15px;
`

const ErrorBox = styled.View<ErrorBoxProps>`
  position: ${(props) => props.errorInside && 'absolute'};
  bottom: ${(props) => props.errorInside && '5px'};
  padding: ${(props) => props.errorInside && '8px'};
`

export const RadioButton = ({
  name,
  label,
  option,
  itemStyle = {},
  labelStyle = {},
  errorInside = false,
  colorLabel = 'black',
  bold,
  flexRow = false,
  column = 2,
  subFlexRow = false,
  setSelectSub,
  defaultValue,
  subDefaultValue,
}: Props): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const [selectItem, setSelectItem] = useState<string | null>(
    defaultValue ? defaultValue : ''
  )
  const [selectTertiary, setSelectTertiary] = useState<string | null>(
    subDefaultValue ? subDefaultValue : ''
  )
  return (
    <Item style={{ ...itemStyle }}>
      {label && (
        <Label
          style={labelStyle}
          input
          color={colorLabel}
          bold={bold ? true : false}
        >
          {label}
        </Label>
      )}
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <View
            style={
              flexRow
                ? { flexDirection: 'row', flexWrap: 'wrap' }
                : { flexDirection: 'column' }
            }
          >
            {option.map((primary) => {
              const active = primary.value === selectItem ? true : false
              return (
                <React.Fragment key={primary.mainId}>
                  <TouchableOpacity
                    style={{
                      height: 40,
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: flexRow ? `${100 / column}%` : '100%',
                    }}
                    onPress={() => {
                      onChange(primary.value)
                      setSelectItem(primary.value)
                      setSelectSub?.('')
                      setSelectTertiary('')
                    }}
                  >
                    <RadioBorder>
                      <RadioCircle active={active} />
                    </RadioBorder>

                    <Text sub1>{primary.label}</Text>
                  </TouchableOpacity>
                  <View
                    key={`main${primary.mainId}`}
                    style={
                      subFlexRow
                        ? { flexDirection: 'row', flexWrap: 'wrap' }
                        : { flexDirection: 'column' }
                    }
                  >
                    {primary.item &&
                      primary.item.map((secondary: any) => {
                        const active = secondary.value === value ? true : false
                        return (
                          <React.Fragment key={secondary.id}>
                            <TouchableOpacity
                              style={{
                                height: 40,
                                flexDirection: 'row',
                                alignItems: 'center',
                                width: '50%',
                                paddingLeft: 30,
                              }}
                              onPress={() => {
                                onChange(secondary.value)
                                setSelectItem(primary.value)
                                setSelectTertiary('')
                                setSelectSub?.('')
                              }}
                            >
                              <RadioBorder>
                                <RadioCircle active={active} />
                              </RadioBorder>
                              <Text sub1>{secondary.label}</Text>
                            </TouchableOpacity>
                            <View
                              key={`item${secondary.id}`}
                              style={{ flexDirection: 'row', flexWrap: 'wrap' }}
                            >
                              {secondary.subItem &&
                                secondary.subItem.map((tertiary: any) => {
                                  const active =
                                    tertiary.subId === selectTertiary
                                      ? true
                                      : false
                                  return (
                                    <TouchableOpacity
                                      key={tertiary.subId}
                                      style={{
                                        height: 40,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        width: `${100 / 2}%`,
                                        paddingLeft: 60,
                                      }}
                                      onPress={() => {
                                        onChange(secondary.value)
                                        setSelectItem(primary.value)
                                        setSelectTertiary(tertiary.subId)
                                        setSelectSub?.(tertiary.value)
                                      }}
                                    >
                                      <RadioBorder>
                                        <RadioCircle active={active} />
                                      </RadioBorder>
                                      <Text sub1>{tertiary.label}</Text>
                                    </TouchableOpacity>
                                  )
                                })}
                            </View>
                          </React.Fragment>
                        )
                      })}
                  </View>
                </React.Fragment>
              )
            })}
            {errors[name] && (
              <ErrorBox errorInside={errorInside}>
                <ErrorMessage>{errors[name].message}</ErrorMessage>
              </ErrorBox>
            )}
          </View>
        )}
        name={name}
      />
    </Item>
  )
}
