/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Background from 'components/Background'
import { View, StyleSheet, Platform, ActivityIndicator } from 'react-native'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { Provider } from 'react-native-paper'
import Card from 'components/Card'
import { Form, SubmitButton, Input } from 'components/Form'
import InputDate from 'components/Form/InputDate'
//import CheckBoxForm from 'components/Form/CheckBoxForm'
import * as yup from 'yup'
// import request from 'utils/request'
import useSWR from 'swr'
import { fetcher } from '../../utils/request'
import { useLocalization } from 'contexts/LocalizationContext'
import { RouteProp } from '@react-navigation/native'
import Text from 'components/Text'
import theme from 'theme/index'
import request from 'utils/request'
import DropDownForm from 'components/Form/DropDownForm'
import dayjs from 'dayjs'

export type Props = {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<{ params: { PjId: string; PoId: string } }, 'params'>
}

type FormData = {
  projectNumber: string
  projectDate: string
  itemCode: string
  detail: string
  speciesId: string
  quantity: number
  unit: string
  fundPerUnit: number
  totalFund: number
  dueDate: string
  deliveredDate: string
  purchaseOrderId: string
  farmId: string
}

const ProjectDetailEditScreen = ({ navigation, route }: Props): JSX.Element => {
  const { t } = useLocalization()
  const { PjId, PoId } = route.params

  const limit = 10
  const { data } = useSWR(
    () => (PjId ? [`/project/${PjId}?limit=${limit}&page=1`] : null),
    fetcher
  )
  const { data: farm } = useSWR([`/farm?limit=${limit}&page=1`], fetcher)
  const { data: species } = useSWR(
    () => [`/plantData?page=1&limit=${limit}`],
    fetcher
  )
  const { data: purchaseOrder } = useSWR(
    () => [`/purchaseOrder/${PoId}`],
    fetcher
  )

  const defaultValue = {
    documentNumber: purchaseOrder?.documentNumber || '',
    projectNumber: data?.projectNumber || '',
    projectDate: dayjs(data?.projectDate).format('YYYY-MM-DD'),
    quantity: data?.quantity || '',
    itemCode: data?.itemCode || '',
    detail: data?.detail || '',
    unit: data?.unit || '',
    fundPerUnit: data?.fundPerUnit || '',
    totalFund: data?.totalFund || '',
    dueDate: dayjs(data?.dueDate).format('YYYY-MM-DD'),
    serialNumber: data?.serialNumber || '',
    deliveredDate: dayjs(data?.deliveredDate).format('YYYY-MM-DD'),
    speciesId: data?.speciesId || '',
    farmId: data?.farmId || '',
  }

  const schema = yup.object().shape({
    projectNumber: yup
      .string()
      .nullable()
      .required(t('screens.ProjectScreen.form.required.projectNumber')),
    projectDate: yup
      .string()
      .nullable()
      .required(t('screens.ProjectScreen.form.required.projectDate')),
    farmId: yup
      .string()
      .nullable()
      .required(t('screens.ProjectScreen.form.required.farmId')),
    itemCode: yup
      .string()
      .nullable()
      .required(t('screens.ProjectScreen.form.required.itemCode')),
    detail: yup
      .string()
      .nullable()
      .required(t('screens.ProjectScreen.form.required.detail')),
    speciesId: yup
      .string()
      .nullable()
      .required(t('screens.ProjectScreen.form.required.speciesId')),
    quantity: yup
      .number()
      .typeError('กรุณากรอกจำนวนเป็นตัวเลข')
      .nullable()
      .required(t('screens.ProjectScreen.form.required.quantity')),
    unit: yup
      .string()
      .nullable()
      .required(t('screens.ProjectScreen.form.required.unit')),
    fundPerUnit: yup
      .number()
      .typeError('กรุณากรอกต้นทุนต่อหน่วยเป็นตัวเลข')
      .nullable()
      .required(t('screens.ProjectScreen.form.required.fundPerUnit')),
    totalFund: yup
      .number()
      .typeError('กรุณากรอกต้นทุนรวมเป็นตัวเลข')
      .nullable()
      .required(t('screens.ProjectScreen.form.required.totalFund')),
    dueDate: yup
      .string()
      .nullable()
      .required(t('screens.ProjectScreen.form.required.dueDate')),
    deliveredDate: yup
      .string()
      .nullable()
      .required(t('screens.ProjectScreen.form.required.deliveredDate')),
  })

  const onSubmit = async (data: FormData) => {
    if (PjId) {
      try {
        await request.patch(`/project/${PjId}`, {
          projectNumber: data?.projectNumber,
          projectDate: dayjs(data?.projectDate).toISOString(),

          itemCode: data?.itemCode,
          detail: data?.detail,
          speciesId: data?.speciesId,
          quantity: +data?.quantity,
          unit: data?.unit,
          fundPerUnit: +data?.fundPerUnit,
          totalFund: +data?.totalFund,
          dueDate: dayjs(data?.dueDate).toISOString(),
          deliveredDate: dayjs(data?.deliveredDate).toISOString(),
          farmId: data?.farmId,
          purchaseOrderId: PoId,
        })
        navigation.navigate('Project', {
          PoId: PoId,
        })
      } catch (e: any) {
        console.log(JSON.stringify(e.response, null, 2))
      }
    } else {
      try {
        await request.post(`/project`, {
          projectNumber: data?.projectNumber,
          projectDate: dayjs(data?.projectDate).toISOString(),
          itemCode: data?.itemCode,
          detail: data?.detail,
          speciesId: data?.speciesId,
          quantity: +data?.quantity,
          unit: data?.unit,
          fundPerUnit: +data?.fundPerUnit,
          totalFund: +data?.totalFund,
          dueDate: dayjs(data?.dueDate).toISOString(),
          deliveredDate: dayjs(data?.deliveredDate).toISOString(),
          farmId: data?.farmId,
          purchaseOrderId: PoId,
        })
        navigation.navigate('Project', {
          PoId: PoId,
        })
      } catch (e: any) {
        console.log(JSON.stringify(e.response, null, 2))
      }
    }
  }

  if (PjId && !data && !purchaseOrder && !farm) {
    return (
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={
            PjId
              ? t('screens.ProjectScreen.edit')
              : t('screens.ProjectScreen.create')
          }
          alignTitle="flex-start"
        />
        <View style={{ marginTop: 70, marginBottom: 20 }}></View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size="large" color={theme.color.primary1} />
          <Text center style={{ marginTop: 16 }} h2 color="primary2">
            Loading...
          </Text>
        </View>
      </Container>
    )
  }

  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={
            PjId
              ? t('screens.ProjectScreen.edit')
              : t('screens.ProjectScreen.create')
          }
          alignTitle="flex-start"
        />
        <View style={{ marginTop: 70, marginBottom: 20 }}></View>
        <Content noPadding enabledScroll={false}>
          <Card
            borderRadiusTop
            style={{
              alignSelf: 'flex-end',
              flex: 1,
              borderBottomStartRadius: 0,
              borderBottomEndRadius: 0,
              padding: 0,
              marginTop: 16,
            }}
          >
            <Content
              noPadding
              // enabledScroll={page === 1 ? true : false}
              style={{
                flex: 1,
              }}
            >
              <Form
                enableReinitial
                defaultValues={defaultValue}
                schema={schema}
                style={{
                  paddingTop: 20,
                  paddingHorizontal: 16,
                  flex: 1,
                  justifyContent: 'space-between',
                }}
              >
                <Input
                  editable={PjId ? true : false}
                  name="documentNumber"
                  label={t('screens.ProjectScreen.form.documentNumber')}
                  style={styles.Input}
                />
                <Input
                  editable={PjId ? true : false}
                  name="projectNumber"
                  label={t('screens.ProjectScreen.form.projectNumber')}
                  style={styles.Input}
                />
                <InputDate
                  enableReinitial
                  name="projectDate"
                  label={t('screens.ProjectScreen.form.projectDate')}
                  testID="projectDate"
                  style={{ height: 42 }}
                  defaultValue={defaultValue?.projectDate}
                />

                <DropDownForm
                  name="farmId"
                  label={t('screens.ProjectScreen.form.factory')}
                  data={(farm?.data || []).map(
                    (item: { name: string; id: string }) => {
                      return { label: item.name, value: item.id }
                    }
                  )}
                  placeholder={'เลือกแปลง/โรงงาน'}
                />
                <Input
                  name="itemCode"
                  label={t('screens.ProjectScreen.form.itemCode')}
                  style={styles.Input}
                />
                <Input
                  name="detail"
                  multiline
                  numberOfLines={6}
                  label={t('screens.ProjectScreen.form.detail')}
                  style={styles.Input}
                />
                <DropDownForm
                  name="speciesId"
                  label={t('screens.ProjectScreen.form.species')}
                  data={(species?.data || []).map(
                    (item: { brandName: string; id: string }) => {
                      return { label: item.brandName, value: item.id }
                    }
                  )}
                  //setValueDropdown={setValueDropdown}
                  placeholder={'เลือกสายพันธุ์'}
                />

                {/* <Select
                  name="species"
                  label={t('screens.ProjectScreen.form.species')}
                  option={(species?.data || []).map(
                    (item: { brandName: string; id: string }) => {
                      console.log('ไผ่', item.brandName)
                      console.log('id', item.id)
                      return { title: item.brandName, value: item.id }
                    }
                  )}
                  placeholder="เลือกสายพันธุ์"
                /> */}
                <Input
                  name="quantity"
                  label={t('screens.ProjectScreen.form.quantity')}
                  style={styles.Input}
                />
                <Input
                  name="unit"
                  label={t('screens.WorkAssignmentScreen.form.unit')}
                  style={styles.Input}
                />

                <Input
                  name="fundPerUnit"
                  label={t('screens.ProjectScreen.form.fundPerUnit')}
                  style={styles.Input}
                />
                <Input
                  name="totalFund"
                  label={t('screens.ProjectScreen.form.totalFund')}
                  style={styles.Input}
                />
                <InputDate
                  name="dueDate"
                  label={t('screens.ProjectScreen.form.dueDate')}
                  testID="dueDate"
                  style={{ height: 42 }}
                  defaultValue={defaultValue?.dueDate}
                />
                {/* <Input
                  name="serialNumber"
                  label={t('screens.ProjectScreen.form.serialNumber')}
                  style={styles.Input}
                />
                <CheckBoxForm
                  isGroup={false}
                  label={t('screens.ProjectScreen.form.status')}
                  data={mockDataCheckbox}
                  name="status"
                  defaultValue={defaultValue.status}
                  enableReinitial
                /> */}
                <InputDate
                  name="deliveredDate"
                  label={t('screens.ProjectScreen.form.deliveredDate')}
                  testID="deliveredDate"
                  style={{ height: 42 }}
                  defaultValue={defaultValue?.deliveredDate}
                />
                <InputDate
                  name="updateDate"
                  label={t('screens.ProjectScreen.form.updateDate')}
                  testID="updateDate"
                  style={{ height: 42 }}
                  defaultValue={defaultValue?.deliveredDate}
                />
                <View style={{ marginTop: 56, marginBottom: 24 }}>
                  <SubmitButton
                    testID="form.createCompany"
                    title={t('Buttons.Save')}
                    onSubmit={onSubmit}
                  />
                </View>
              </Form>
            </Content>
          </Card>
        </Content>
      </Container>
    </Provider>
  )
}

const styles = StyleSheet.create({
  Input: {
    ...Platform.select({
      web: {
        paddingVertical: 6,
        height: 40,
      },
      default: {
        paddingVertical: 6,
        flex: 1,
      },
    }),
  },
})

export default ProjectDetailEditScreen
