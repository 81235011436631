import { Platform, View } from 'react-native'
import React from 'react'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import { RouteProp } from '@react-navigation/native'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import { useLocalization } from 'contexts/LocalizationContext'
import Text from 'components/Text'
import StationFirstStep from 'components/StationFirstStep'
import Button from 'components/Button'

interface Props {
  route: RouteProp<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { params: { id: string; data: any; farmData: any } },
    'params'
  >
  navigation: StackNavigationHelpers
}
export default function StationPostHarvestScreen({
  route,
  navigation,
}: Props): JSX.Element {
  const { t } = useLocalization()
  const { data } = route.params
  const FooterRender = () => {
    return (
      <View style={{ alignItems: 'center' }}>
        <Button
          title="ระบบจัดการการป้องกัน และรักษาเนื้อไม้ "
          arrowBack
          onPress={() =>
            navigation.navigate('ProtectionSystemDetail', {
              assignmentId: data?.id,
              framData: route.params,
            })
          }
          style={{
            width: Platform.OS === 'web' ? '100%' : '100%',
            marginBottom: 8,
          }}
        />
        <Button
          title="ระบบจัดการการขนส่ง"
          arrowBack
          onPress={() =>
            navigation.navigate('DeliveryManagementDetail', {
              assignmentId: data?.id,
              projectNumber: data?.projectNumber,
            })
          }
          style={{
            width: Platform.OS === 'web' ? '100%' : '100%',

            marginBottom: 8,
          }}
        />
      </View>
    )
  }
  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <View style={{ width: '100%', alignItems: 'center', height: 130 }}>
        <Header
          showBack
          title={t('screens.StationNutrientManagementScreen.header')}
          style={{ paddingBottom: 0 }}
        />
        <Text
          title1
          color="white"
          style={{ width: '70%', textAlign: 'center', lineHeight: 30 }}
        >
          สถานีงาน : การบริหารจัดการหลังการเก็บเกี่ยว
        </Text>
      </View>
      <StationFirstStep
        dataAssignment={{
          assignmentId: data?.workAssignmentNumber,
          refId: data?.documentNumber || data?.project?.order?.documentNumber,
          projectId: data?.projectNumber,
        }}
        FooterComponent={<FooterRender />}
      />
    </Container>
  )
}
