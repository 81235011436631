import React, { Dispatch, SetStateAction } from 'react'
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native'
import dayjs from 'dayjs'
import Text from 'components/Text'
import ArrowLeftIcon from 'assets/images/icons/ArrowLeft.png'
import ArrowRightIcon from 'assets/images/icons/ArrowRight.png'
interface Props {
  focusDate: Date
  setFocusDate: Dispatch<SetStateAction<Date>>
  setCurrentMonth: Dispatch<React.SetStateAction<number>>
  setCurrentYear: Dispatch<React.SetStateAction<number>>
}

const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginTop: 15,
    marginBottom: 10,
  },
  mainRow: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  hearderRow: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // marginBottom: 8,
  },
  icon: {
    height: 12,
  },
  gap: {
    minWidth: 15,
  },
  dayRow: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dayCol: {
    flex: 1,
  },
})

const MonthHeader = ({
  focusDate,
  setFocusDate,
  setCurrentMonth,
  setCurrentYear,
}: Props): JSX.Element => {
  const dayjsDate = dayjs(focusDate)

  const onPressBackward = () => {
    const newDate = dayjsDate.subtract(1, 'month').startOf('month').toDate()
    const currentMonth = newDate.getMonth() + 1
    const currentYear = newDate.getFullYear()
    setCurrentMonth(currentMonth)
    setCurrentYear(currentYear)
    setFocusDate(newDate)
  }
  const onPressForward = () => {
    const newDate = dayjsDate.add(1, 'month').startOf('month').toDate()
    const currentMonth = newDate.getMonth() + 1
    const currentYear = newDate.getFullYear()
    setCurrentMonth(currentMonth)
    setCurrentYear(currentYear)
    setFocusDate(newDate)
  }

  return (
    <View style={styles.container}>
      <View style={styles.mainRow}>
        <View style={styles.hearderRow}>
          <TouchableOpacity onPress={onPressBackward}>
            <Image
              source={ArrowLeftIcon}
              resizeMode="contain"
              style={styles.icon}
            />
          </TouchableOpacity>
          <View style={styles.gap} />
          <Text bold fontSize="16px">
            {dayjsDate.format('MMMM')}{' '}
            {dayjsDate.locale('th').add(543, 'year').format('YY')}
          </Text>
          <View style={styles.gap} />
          <TouchableOpacity onPress={onPressForward}>
            <Image
              source={ArrowRightIcon}
              resizeMode="contain"
              style={styles.icon}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.dayRow}>
        {weekDays.map((item, index) => {
          return (
            <View key={index} style={styles.dayCol}>
              <Text
                center
                fontSize="11px"
                lineHeight="18px"
                color={item === 'Sun' ? 'error' : 'black65'}
              >
                {item}
              </Text>
            </View>
          )
        })}
      </View>
    </View>
  )
}

export default MonthHeader
