import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import React from 'react'
import Container from 'components/Container'
import Background from 'components/Background'
import Content from 'components/Content'
import Header from 'components/Header'
import { useLocalization } from 'contexts/LocalizationContext'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import Farmer from 'assets/images/Farmer.png'
import Company from 'assets/images/Company.png'
import Promoter from 'assets/images/Promoter.png'
import Text from 'components/Text'
import { useDimension } from 'contexts/DimensionContext'
interface Props {
  navigation: StackNavigationHelpers
}

export default function SelectRoleScreen({ navigation }: Props): JSX.Element {
  const { t } = useLocalization()
  const { height: heightScreen } = useDimension()
  const width = 520 * 0.3
  const height = 520 * 0.3
  const staticRoles = [
    {
      role: t('screens.SelectRoleScreen.farmer'),
      image: Farmer,
      onPress: () =>
        navigation.navigate('CreateProfile', {
          role: 'farmer',
          setting: 'create',
        }),
    },
    {
      role: t('screens.SelectRoleScreen.extensionist'),
      image: Promoter,
      onPress: () =>
        navigation.navigate('CreateProfile', {
          role: 'extensionist',
          setting: 'create',
        }),
    },
  ]

  return (
    <Container>
      <Background typeBackground="BgWithCircle" />
      <Header title={t('screens.SelectRoleScreen.title')} />
      <Content>
        <View style={{ height: heightScreen - 220, justifyContent: 'center' }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',

              alignItems: 'center',
            }}
          >
            {staticRoles.map((item, idx) => {
              return (
                <View key={idx} style={{ alignItems: 'center' }}>
                  <TouchableOpacity onPress={item.onPress}>
                    <Image
                      source={item.image}
                      style={[styles.image, { width, height }]}
                    />
                  </TouchableOpacity>
                  <Text sub1 color="white" style={{ paddingVertical: 8 }}>
                    {item.role}
                  </Text>
                </View>
              )
            })}
          </View>
          <View style={{ alignItems: 'center' }}>
            <TouchableOpacity
              onPress={() =>
                navigation.navigate('CreateProfile', {
                  role: 'company',
                  setting: 'create',
                })
              }
            >
              <Image
                source={Company}
                style={[styles.image, { width, height }]}
              />
            </TouchableOpacity>
            <Text sub1 color="white" style={{ paddingVertical: 8 }}>
              {t('screens.SelectRoleScreen.company')}
            </Text>
          </View>
        </View>
      </Content>
    </Container>
  )
}
const styles = StyleSheet.create({
  image: {
    borderRadius: (520 * 0.3) / 2,
  },
})
