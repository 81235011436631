/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Background from 'components/Background'
import { View, ActivityIndicator, StyleSheet, ScrollView } from 'react-native'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { Provider } from 'react-native-paper'
import Card from 'components/Card'
import { RouteProp } from '@react-navigation/native'
import useSWR from 'swr'
import { fetcher } from '../../utils/request'
import Text from 'components/Text'
import theme from 'theme/index'
import ProgressLine from 'components/ProgressLine'
import { useLocalization } from 'contexts/LocalizationContext'
import dayjs from 'dayjs'
import { ObjPathStation } from 'utils/PathStation'
import { useFocusEffect } from '@react-navigation/native'
export type Props = {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<{ params: { id: string } }, 'params'>
}

const WorksheetDetailScreen = ({ navigation, route }: Props): JSX.Element => {
  const { t } = useLocalization()
  const { id } = route.params

  const { data: workAssignment, mutate } = useSWR(
    [`/user/me/workAssignment/${id}`],
    fetcher
  )
  useFocusEffect(
    React.useCallback(() => {
      mutate(`/user/me/workAssignment/${id}`)
    }, [mutate])
  )

  const { data: farm } = useSWR(
    () =>
      workAssignment.farmId ? [`/user/me/farm/${workAssignment.farmId}`] : null,
    fetcher
  )

  const workStation = workAssignment?.workStation
  if (id && !workAssignment) {
    return (
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={t('screens.WorksheetDetailScreen.title')}
          alignTitle="flex-start"
        />
        <View style={{ marginTop: 70, marginBottom: 20 }}></View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size="large" color={theme.color.primary1} />
          <Text center style={{ marginTop: 16 }} h2 color="primary2">
            Loading...
          </Text>
        </View>
      </Container>
    )
  }

  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={t('screens.WorksheetDetailScreen.title')}
          alignTitle="flex-start"
        />
        <View style={styles.headerView}>
          <Card>
            <View style={styles.viewProgress}>
              <Text sub1 medium>
                {t('screens.WorksheetDetailScreen.progress')}
              </Text>
              <Text sub1 medium>
                {`${(
                  ((workAssignment?.actual + workAssignment?.ot) /
                    workAssignment?.quantity) *
                  100
                ).toFixed(2)}%`}
              </Text>
            </View>
            <ProgressLine
              value={`${(
                ((workAssignment?.actual + workAssignment?.ot) /
                  workAssignment?.quantity) *
                100
              ).toFixed(2)}%`}
              isLinearGradient={true}
              height={25}
            />
          </Card>
        </View>
        <Card borderRadiusTop width="100%" style={{ height: '100%', flex: 1 }}>
          <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
            <Content noPadding style={{ paddingBottom: 20 }}>
              <View style={styles.viewHead}>
                <View style={styles.viewLeft}>
                  <Text sub1>{t('screens.WorksheetDetailScreen.poId')}</Text>
                </View>
                <View style={styles.viewRight}>
                  <Text color="black85" sub1>
                    {workAssignment?.documentNumber}
                    {/* must be workAssignment.PurchaseOrder  but await api */}
                  </Text>
                </View>
              </View>
              <View style={styles.viewHead}>
                <View style={styles.viewLeft}>
                  <Text sub1>
                    {t('screens.WorksheetDetailScreen.projectNumber')}
                  </Text>
                </View>
                <View style={styles.viewRight}>
                  <Text color="black85" sub1>
                    {workAssignment?.projectNumber}
                  </Text>
                </View>
              </View>
              <View style={styles.viewHead}>
                <View style={styles.viewLeft}>
                  <Text sub1>
                    {t('screens.WorksheetDetailScreen.wrokSheetNumber')}
                  </Text>
                </View>
                <View style={styles.viewRight}>
                  <Text color="black85" sub1>
                    {`${workAssignment?.workAssignmentNumber}`}
                  </Text>
                </View>
              </View>
              <View style={styles.viewHead}>
                <View style={styles.viewLeft}>
                  <Text sub1>{t('screens.WorksheetDetailScreen.date')}</Text>
                </View>
                <View style={styles.viewRight}>
                  <Text color="black85" sub1>
                    {`${dayjs(workAssignment?.startDate).format(
                      'DD/MM/BBBB'
                    )} - ${dayjs(workAssignment?.endDate).format(
                      'DD/MM/BBBB'
                    )}`}
                  </Text>
                </View>
              </View>
              <View style={styles.viewHead}>
                <View style={styles.viewLeft}>
                  <Text sub1>
                    {t('screens.WorksheetDetailScreen.sumWorkingDate')}
                  </Text>
                </View>
                <View style={styles.viewRight}>
                  <Text color="black85" sub1>
                    {`${workAssignment?.capacityPerDay} วัน`}
                  </Text>
                </View>
              </View>
              <View style={styles.viewHead}>
                <View style={styles.viewLeft}>
                  <Text sub1>
                    {t('screens.WorksheetDetailScreen.farmerId')}
                  </Text>
                </View>
                <View style={styles.viewRight}>
                  <Text color="black85" sub1>
                    {`${workAssignment?.approver?.firstName} ${workAssignment?.approver?.lastName}`}
                  </Text>
                </View>
              </View>
              <View style={styles.viewHead}>
                <View style={styles.viewLeft}>
                  <Text sub1>{t('screens.WorksheetDetailScreen.farmId')}</Text>
                </View>
                <View style={styles.viewRight}>
                  <Text color="black85" sub1>
                    {farm?.name}
                  </Text>
                </View>
              </View>
              <View style={styles.viewHead}>
                <View style={styles.viewLeft}>
                  <Text sub1>{t('screens.WorksheetDetailScreen.code')}</Text>
                </View>
                <View style={styles.viewRight}>
                  <Text color="black85" sub1>
                    {workAssignment?.code}
                  </Text>
                </View>
              </View>
              <View style={styles.viewHead}>
                <View style={styles.viewLeft}>
                  <Text sub1>
                    {t('screens.WorksheetDetailScreen.productDetail')}
                  </Text>
                </View>
                <View style={styles.viewRight}>
                  <Text color="black85" sub1>
                    {workAssignment?.productDetail}
                  </Text>
                </View>
              </View>
              <View style={styles.viewHead}>
                <View style={styles.viewLeft}>
                  <Text sub1>
                    {t('screens.WorksheetDetailScreen.workDetail')}
                  </Text>
                </View>
                <View style={styles.viewRight}>
                  <Text color="black85" sub1>
                    {workAssignment?.workDetail}
                  </Text>
                </View>
              </View>
              <View style={styles.viewBorder} />
              <View style={{ marginBottom: 10 }}>
                <Card
                  Pressable={true}
                  activeOpacity={0.8}
                  onPress={() =>
                    navigation.navigate('WorkAssignmentReport', {
                      PoId: null,
                      PjId: null,
                      id: workAssignment.id,
                      type: 'schedule',
                    })
                  }
                >
                  <Text medium sub1>
                    {t('screens.WorksheetDetailScreen.workschedule')}
                  </Text>
                </Card>
              </View>
              <View style={{ marginBottom: 10 }}>
                <Card
                  Pressable={true}
                  activeOpacity={0.8}
                  onPress={() =>
                    navigation.navigate(
                      ObjPathStation[
                        workStation as keyof typeof ObjPathStation
                      ],
                      {
                        id: workAssignment.id,
                        data: workAssignment,
                        farmData: farm,
                        farmId: workAssignment.farmId,
                      }
                    )
                  }
                >
                  <Text medium sub1>
                    {t('screens.WorksheetDetailScreen.infoWorkStation')}
                  </Text>
                </Card>
              </View>
              <View style={{ marginBottom: 10 }}>
                <Card
                  Pressable={true}
                  activeOpacity={0.8}
                  onPress={() =>
                    navigation.navigate('WorksheetSummaryScreen', {
                      id: workAssignment.id,
                      data: workAssignment,
                    })
                  }
                >
                  <Text medium sub1>
                    {t('screens.WorksheetDetailScreen.summarize')}
                  </Text>
                </Card>
              </View>
            </Content>
          </ScrollView>
        </Card>
      </Container>
    </Provider>
  )
}

const styles = StyleSheet.create({
  headerView: { width: '90%', marginTop: 20, marginBottom: 20 },
  viewProgress: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 6,
  },
  viewHead: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  viewLeft: {
    flex: 0.4,
  },
  viewRight: {
    flex: 0.6,
    backgroundColor: '#EFEFEF',
    borderRadius: 5,
    paddingHorizontal: 10,
  },
  viewBorder: {
    borderWidth: 1,
    borderColor: theme.color.black6,
    marginTop: 10,
    marginBottom: 20,
  },
})

export default WorksheetDetailScreen
