/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense, lazy } from 'react'

import { Platform, TextInputProps, View, ViewStyle } from 'react-native'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

interface Props extends TextInputProps {
  name: string
  line?: boolean
  label?: string
  style?: ViewStyle
  isLeaveForm?: boolean
  secureTextEntry?: boolean
  forgetPassword?: JSX.Element
  testID?: string
  itemStyle?: {
    [key: string]: unknown
  }
  iconLeft?: boolean
  iconRight?: boolean
  errorInside?: boolean
  minimumDate?: Date
  setSelectedDate?: (date?: string) => void
  defaultValue?: any
  enableReinitial?: boolean
  backgroundColor?: string
  textColor?: string
}

const InputDateComponent = lazy(() =>
  Platform.OS !== 'web' ? import('./InputDateMobile') : import('./InputDateWeb')
)

export default function InputDate(props: Props): JSX.Element {
  return (
    <Suspense fallback={<View />}>
      <InputDateComponent {...props} />
    </Suspense>
  )
}
