import React, { Dispatch, Fragment, SetStateAction, useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'
import { Calendar, ICalendarEventBase } from 'react-native-big-calendar'
import dayjs from 'dayjs'
import Text from 'components/Text'
import theme from 'theme/index'
import MonthHeader from './MonthHeader'
import { getEventColor, CalendarType } from 'utils/calendarType'
import { AssignmentTask } from 'src/interface'

interface Props {
  focusDate: Date
  setFocusDate: Dispatch<SetStateAction<Date>>
  setCurrentMonth: Dispatch<React.SetStateAction<number>>
  setCurrentYear: Dispatch<React.SetStateAction<number>>
  assignmentTask: AssignmentTask[]
  setSelectTaskId: Dispatch<React.SetStateAction<string | null>>
  setReportVisible: Dispatch<React.SetStateAction<boolean>>
  setScheduleVisible: Dispatch<React.SetStateAction<boolean>>
  type: 'report' | 'schedule'
}

interface StylesProps {
  eventColor?: string
  isToday?: boolean
  eventType?: string
  isSunday?: boolean
  isNextMonth?: boolean
}
interface T extends ICalendarEventBase {
  type: CalendarType
  id: string
  P: string
  A: string
  OT: string
}

const styles = ({
  eventColor,
  isToday,
  isSunday,
  isNextMonth,
}: StylesProps = {}) =>
  StyleSheet.create({
    cell: {
      borderColor: 'transparent',
      overflow: 'hidden',
    },
    cellText: {
      fontSize: 10,
      lineHeight: 17,
      width: 18,
      height: 18,
      borderRadius: 9,
      color: isNextMonth
        ? '#00000033'
        : isToday
        ? '#ffffff'
        : isSunday
        ? '#F64A4A'
        : theme.color.black90,
      backgroundColor: isToday ? theme.color.primary1 : 'transparent',
      alignSelf: 'center',
      overflow: 'hidden',
    },
    body: {
      borderColor: 'transparent',
    },
    event: {
      marginTop: 3,
      paddingHorizontal: 4,
      opacity: 1,
      color: eventColor,
    },
  })

const checkIsToday = (date: Date | undefined) => {
  if (!date) {
    return false
  }
  return dayjs(date).startOf('day').isSame(dayjs().startOf('day'))
}

const MonthCalendar = ({
  focusDate,
  setFocusDate,
  setCurrentMonth,
  setCurrentYear,
  assignmentTask,
  setSelectTaskId,
  setReportVisible,
  setScheduleVisible,
  type,
}: Props): JSX.Element => {
  // const [modalVisible, setModalVisible] = React.useState(false)
  // const [modalDate, setModalDate] = React.useState<Date | undefined>(undefined)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const events: any = useMemo(() => {
    const event = (assignmentTask || []).map((item) => {
      return {
        id: item?.id,
        start: new Date(item.date),
        end: new Date(item.date),
        P: `P: ${item?.plan}`,
        A: `A: ${item?.actual}`,
        OT: `OT: ${item?.ot}`,
      }
    })
    return event
  }, [assignmentTask])

  const onPressCell = (date: Date) => {
    setFocusDate(date)
    // setModalDate(date)
    // setModalVisible(true)
  }

  const onPressEvent = (event: T) => {
    setSelectTaskId(event.id)

    if (type === 'report') {
      setReportVisible((prev) => !prev)
    } else if (type === 'schedule') {
      setScheduleVisible((prev) => !prev)
    }
    setFocusDate(event.start)
    // setMode('day')
  }

  const renderEvent = (event: T) => {
    return (
      <>
        <Text
          numberOfLines={1}
          fontSize="10px"
          lineHeight="12px"
          color="black90"
          style={styles({ eventColor: getEventColor('P') }).event}
        >
          {event.P}
        </Text>
        <Text
          numberOfLines={1}
          fontSize="10px"
          lineHeight="12px"
          color="black90"
          style={styles({ eventColor: getEventColor('A') }).event}
        >
          {event.A}
        </Text>
        <Text
          numberOfLines={1}
          fontSize="10px"
          lineHeight="12px"
          color="black90"
          style={styles({ eventColor: getEventColor('OT') }).event}
        >
          {event.OT}
        </Text>
      </>
    )
  }

  const calendarCellTextStyle = (date: Date | undefined) => {
    const isSunday = dayjs(date).day() === 0
    const isNextMonth = dayjs(date).month() !== dayjs(focusDate).month()

    const isToday = checkIsToday(date)
    return isNextMonth
      ? styles({ isNextMonth }).cellText
      : isSunday
      ? styles({ isSunday }).cellText
      : styles({ isToday }).cellText
  }
  return (
    <Fragment>
      <Calendar
        renderHeaderForMonthView={() => {
          return (
            <MonthHeader
              focusDate={focusDate}
              setFocusDate={setFocusDate}
              setCurrentMonth={setCurrentMonth}
              setCurrentYear={setCurrentYear}
            />
          )
        }}
        mode="month"
        date={focusDate}
        events={events}
        height={Platform.OS === 'web' ? 500 : 400}
        renderEvent={renderEvent}
        eventMinHeightForMonthView={10}
        onPressEvent={onPressEvent}
        swipeEnabled={false}
        onPressCell={onPressCell}
        calendarCellTextStyle={calendarCellTextStyle}
        bodyContainerStyle={styles().body}
        maxVisibleEventCount={3}
      />
      {/* <Modal
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <TouchableOpacity
          style={{ position: 'absolute', right: 8, top: 8 }}
          onPress={() => setModalVisible(false)}
        >
          <Image source={CloseIcon} style={{ width: 20, height: 20 }} />
        </TouchableOpacity>
        <View>
          <Text>{`วันที่: ${dayjs(modalDate).format('DD/MM/BB')}`}</Text>
          <Text>{`Plan: ${dayjs(modalDate).format('DD/MM/BB')}`}</Text>
          <Text>{`Actual: ${dayjs(modalDate).format('DD/MM/BB')}`}</Text>
        </View>
      </Modal> */}
    </Fragment>
  )
}

export default MonthCalendar
