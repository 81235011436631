import { View, NativeScrollEvent, FlatList } from 'react-native'
import React from 'react'
import Content from 'components/Content'
import Card from 'components/Card'
import Text from 'components/Text'
import theme from 'theme/index'
import ListDateCard from 'components/ListDateCard'
import _ from 'lodash'
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { date: string; item: any }[]
  FooterComponent?: React.ReactNode
  onEdit?: (id: string) => void
  onDelete?: (id: string) => void
  onLoadMore?: () => void
  onPressList?: (id: string) => void
}
const StationSecondStep = ({
  data = [],
  FooterComponent,
  onDelete,
  onEdit,
  onLoadMore,
  onPressList,
}: Props): JSX.Element => {
  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: NativeScrollEvent) => {
    const paddingToBottom = 16
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    )
  }
  return (
    <Content noPadding enabledScroll={false}>
      {/* <View style={{ height: height }}> */}
      <Card
        borderRadiusTop
        width="100%"
        style={{
          //height: '100%',
          flex: 1,
          paddingTop: 32,
          marginTop: 32,
          //backgroundColor: 'red',
        }}
      >
        {/* <ScrollView
          contentContainerStyle={{ flexGrow: 1 }}
          scrollEventThrottle={200}
          onScroll={({ nativeEvent }) => {
            if (isCloseToBottom(nativeEvent)) {
              onLoadMore?.(data?.length || 0)
            }
          }}
          style={{
            height: height,
          }}
        >
          <View
            style={{
              justifyContent: 'space-between',
              flex: 1,
              paddingBottom: 20,
            }}
          >
            <View>
              <Text sub1 medium>
                วันที่
              </Text>
              <View
                style={{
                  borderBottomColor: theme.color.black6,
                  borderBottomWidth: 1,
                  paddingVertical: 6,
                }}
              />
              {(_.isArray(data) ? data : []).map((item, index) => {
                return (
                  <ListDateCard
                    item={item}
                    key={index}
                    date={item.date}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    onPressList={onPressList}
                  />
                )
              })}
            </View>
          </View>
        </ScrollView> */}

        <Text sub1 medium>
          วันที่
        </Text>
        <View
          style={{
            borderBottomColor: theme.color.black6,
            borderBottomWidth: 1,
            paddingVertical: 6,
          }}
        />

        <FlatList
          nestedScrollEnabled={true}
          data={data}
          onEndReached={() => onLoadMore?.()}
          onEndReachedThreshold={0.2}
          indicatorStyle="default"
          style={{ marginTop: 15 }}
          contentContainerStyle={{ paddingBottom: 0 }}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => {
            return (
              <ListDateCard
                item={item}
                key={item.id}
                date={item.date}
                onDelete={onDelete}
                onEdit={onEdit}
                onPressList={onPressList}
              />
            )
          }}
        />
        <View>{FooterComponent}</View>
      </Card>
      {/* </View> */}
    </Content>
  )
}

export default StationSecondStep
