/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import request from 'utils/request'

interface Props {
  children: JSX.Element
}

interface State {
  userToken: null | string
  userRole: string | null
  user: any
  link: any
}

type Action =
  | {
      type: 'RESTORE_TOKEN'
      token: string | null
      role: string | null
    }
  | {
      type: 'LOGIN'
      token: string | null
      role: string | null
    }
  | {
      type: 'USER'
      user: any
      link: any
      role: string | null
    }
  | {
      type: 'LOGOUT'
    }

interface Context {
  authContext: {
    getMe: () => Promise<void>
    login: (data: { email: string; password: string }) => Promise<void>
    logout: () => Promise<void>
    register: (data: { email: string; password: string }) => Promise<any>
  }
  state: State
}

const initialState = {
  userToken: null,
  userRole: null,
  user: null,
  link: null,
}

const AuthContext = React.createContext<Context>({
  authContext: {
    getMe: Promise.resolve,
    login: Promise.resolve,
    logout: Promise.resolve,
    register: Promise.resolve,
  },
  state: initialState,
})

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const reducer = (prevState: State, action: Action): State => {
    switch (action.type) {
      case 'RESTORE_TOKEN':
        return {
          ...prevState,
          userToken: action.token,
          userRole: action.role,
        }
      case 'LOGIN':
        return {
          ...prevState,
          userToken: action.token,
          userRole: action.role,
        }
      case 'LOGOUT':
        return {
          ...prevState,
          userToken: null,
          userRole: null,
          user: {},
        }
      case 'USER':
        return {
          ...prevState,
          user: action.user,
          link: action.link,
          userRole: action.role,
        }
      default:
        return prevState
    }
  }
  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(
    reducer,
    initialState
  )

  React.useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      let userToken = null
      let userRole = null
      try {
        userToken = await AsyncStorage.getItem('userToken')
        userRole = await AsyncStorage.getItem('userRole')
      } catch (e) {
        // Restoring token failed
      }
      // After restoring token, we may need to validate it in production apps

      // This will switch to the App screen or Auth screen and this loading
      // screen will be unmounted and thrown away.
      dispatch({ type: 'RESTORE_TOKEN', token: userToken, role: userRole })
    }

    bootstrapAsync()
  }, [])

  const authContext = React.useMemo(
    () => ({
      getMe: async () => {
        try {
          const result = await request.get('/user/me')
          await AsyncStorage.setItem('userRole', result.data.role || '')
          const resultLink = await request.get('/link')
          await AsyncStorage.setItem('link', JSON.stringify(resultLink.data) || '')
          dispatch({
            type: 'USER',
            user: result.data,
            link: resultLink.data,
            role: result.data.role,
          })
        } catch (e) {
          console.log(e)
          throw e
        }
      },
      login: async (data: { email: string; password: string }) => {
        // In a production app, we need to send some data (usually username, password) to server and get a token
        // We will also need to handle errors if sign in failed
        // After getting token, we need to persist the token using `AsyncStorage`
        // In the example, we'll use a dummy token
        if (!data) throw new Error()
        try {
          const res = await request.post('/user/login', data)
          await AsyncStorage.setItem('userToken', res.data.token)
          await AsyncStorage.setItem('userRole', res.data.role || '')
          dispatch({
            type: 'LOGIN',
            token: res.data.token,
            role: res.data.role,
          })
        } catch (e) {
          console.log(JSON.stringify(e, null, 2))
          throw e
        }
      },
      logout: async () => {
        try {
          await AsyncStorage.removeItem('userToken')
          await AsyncStorage.removeItem('userRole')
          dispatch({ type: 'LOGOUT' })
        } catch (e) {
          console.log(e)
          throw e
        }
      },
      register: async (data: { email: string; password: string }) => {
        if (!data) throw new Error()
        try {
          const res = await request.post('/user', data)
          return res
        } catch (e) {
          console.log(JSON.stringify(e, null, 2))
          throw e
        }
      },
    }),
    []
  )

  return (
    <AuthContext.Provider value={{ authContext, state }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): Context => {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth can be use in AuthContext only')
  }
  return context
}
