/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Container from 'components/Container'
import Background from 'components/Background'
import Content from 'components/Content'
import Header from 'components/Header'
import { View } from 'react-native'
import { Form, SubmitButton } from 'components/Form'
// import { useLocalization } from 'contexts/LocalizationContext'
import * as yup from 'yup'
import Card from 'components/Card'
import request from 'utils/request'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import { RouteProp } from '@react-navigation/native'
import dayjs from 'dayjs'
import InputDate from 'components/Form/InputDate'
import { useLocalization } from 'contexts/LocalizationContext'

type Props = {
  navigation: StackNavigationHelpers
  route: RouteProp<
    {
      params: {
        id: string
        startDate: string
        endDate: string
        startUsageDate: string
        endUsageDate: string
      }
    },
    'params'
  >
}

const UsageReserveScreen = ({ navigation, route }: Props): JSX.Element => {
  const { t } = useLocalization()
  const { id, startDate, endDate, startUsageDate, endUsageDate } = route.params

  const schemaCompany = yup.object().shape(
    {
      startUsageDate: yup
        .date()
        .when(
          'endUsageDate',
          (endUsageDate, schema) =>
            endUsageDate &&
            schema?.max(
              endUsageDate,
              t(`screens.MachineryScreen.form.startDateError`) +
                `${dayjs(endUsageDate).format('DD-MM-YYYY')}`
            )
        )
        .min(
          startDate,
          `วันใช้งานต้องอยู่ระหว่างวันจอง ` +
            `${dayjs(startDate).format('DD-MM-YYYY')} ถึง ${dayjs(
              endDate
            ).format('DD-MM-YYYY')}`
        )
        .max(
          endDate,
          `วันใช้งานต้องอยู่ระหว่างวันจอง ` +
            `${dayjs(startDate).format('DD-MM-YYYY')} ถึง ${dayjs(
              endDate
            ).format('DD-MM-YYYY')}`
        ),
      endUsageDate: yup
        .date()
        .when(
          'startUsageDate',
          (startUsageDate, schema) =>
            startUsageDate &&
            schema?.min(
              startUsageDate,
              t(`screens.MachineryScreen.form.endDateError`) +
                `${dayjs(startUsageDate).format('DD-MM-YYYY')}`
            )
        )
        .min(
          startDate,
          `วันใช้งานต้องอยู่ระหว่างวันจอง ` +
            `${dayjs(startDate).format('DD-MM-YYYY')} ถึง ${dayjs(
              endDate
            ).format('DD-MM-YYYY')}`
        )
        .max(
          endDate,
          `วันใช้งานต้องอยู่ระหว่างวันจอง ` +
            `${dayjs(startDate).format('DD-MM-YYYY')} ถึง ${dayjs(
              endDate
            ).format('DD-MM-YYYY')}`
        ),
    },
    [['startUsageDate', 'endUsageDate']]
  )
  const defaultValue = {
    startUsageDate: startUsageDate,
    endUsageDate: endUsageDate,
  }

  const onSubmit = async (values: any) => {
    const { startUsageDate, endUsageDate } = values
    try {
      await request.patch(`/toolStore/usage/${id}`, {
        startUsageDate: dayjs(startUsageDate).format('YYYY-MM-DD'),
        endUsageDate: dayjs(endUsageDate).format('YYYY-MM-DD'),
      })
      navigation.navigate('Machinery')
    } catch (e) {
      console.log(JSON.stringify(e, null, 2))
    }
  }

  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <Header title={'วันที่ใช้งานจริง'} showBack />
      <Content noPadding enabledScroll={false}>
        <Card
          borderRadiusTop
          style={{
            alignSelf: 'flex-end',
            flex: 1,
            borderBottomStartRadius: 0,
            borderBottomEndRadius: 0,
            padding: 0,
            marginTop: 16,
          }}
        >
          <Content
            noPadding
            enabledScroll={false}
            style={{
              flex: 1,
            }}
          >
            <Form
              defaultValues={defaultValue}
              schema={schemaCompany}
              style={{
                paddingVertical: 20,
                paddingHorizontal: 16,
                flex: 1,
                justifyContent: 'space-between',
              }}
            >
              <View>
                <InputDate
                  name="startUsageDate"
                  label={'วันที่เริ่มต้นการใช้งาน'}
                  defaultValue={defaultValue.startUsageDate}
                  style={{ height: 42 }}
                  minimumDate={new Date(startDate)}
                  // maximumDate={new Date(endDate)}
                />
                <InputDate
                  name="endUsageDate"
                  label={'วันที่สิ้นสุดการใช้งาน'}
                  defaultValue={defaultValue.endUsageDate}
                  style={{ height: 42 }}
                  minimumDate={new Date(startDate)}
                  // maximumDate={new Date(endDate)}
                />
              </View>
              <View>
                <SubmitButton
                  testID="form.createCompany"
                  title={'บันทึก'}
                  onSubmit={onSubmit}
                />
              </View>
            </Form>
          </Content>
        </Card>
      </Content>
    </Container>
  )
}

export default UsageReserveScreen
