import Text from 'components/Text'
import React, { useEffect } from 'react'
import { Image, LogBox, TouchableOpacity, View, ViewStyle } from 'react-native'
import Collapsible, { CollapsibleProps } from 'react-native-collapsible'
import CaretCircleDown from 'assets/images/icons/CaretCircleDown.png'
import CaretCircleUp from 'assets/images/icons/CaretCircleUp.png'
import theme from '../../theme'
interface Props extends CollapsibleProps {
  children: JSX.Element | JSX.Element[]
  isCollapsed: boolean
  title?: string
  onPress?: () => void
  style?: ViewStyle
  widthText?: string
  count?: number
}
export default function Collapse({
  isCollapsed = false,
  children,
  title = 'title',
  onPress,
  style,
  widthText,
  count,
  ...props
}: Props): JSX.Element {
  useEffect(() => {
    LogBox.ignoreLogs(['componentWillReceiveProps'])
  }, [])
  return (
    <View style={style}>
      <TouchableOpacity
        onPress={onPress}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          zIndex: 100,
        }}
      >
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text
            sub1
            medium
            style={{ width: widthText ? widthText : widthText }}
          >
            {title}
          </Text>
          {count !== 0 && count !== undefined && (
            <View
              style={{
                backgroundColor: theme.color.error,
                marginLeft: 10,
                width: 25,
                borderRadius: 25,
                alignItems: 'center',
              }}
            >
              <Text color="white" medium>
                {count}
              </Text>
            </View>
          )}
        </View>
        <Image
          source={isCollapsed ? CaretCircleDown : CaretCircleUp}
          style={{ width: 18, height: 18, marginRight: 8, marginTop: 4 }}
          resizeMode="contain"
        />
      </TouchableOpacity>

      <Collapsible collapsed={isCollapsed} {...props}>
        {children}
      </Collapsible>
    </View>
  )
}
