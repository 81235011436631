import { RouteProp, useFocusEffect } from '@react-navigation/native'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import Background from 'components/Background'
import Button from 'components/Button'
import Container from 'components/Container'
import Header from 'components/Header'
import StationSecondStep from 'components/StationSecondStep'
import request from 'utils/request'
import React, { useState } from 'react'
import { Dimensions, View, Platform } from 'react-native'
import { Provider } from 'react-native-paper'
import ModalAlert from 'components/ModalAlert'
import PlusIcon from 'assets/images/icons/Plus.png'
interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<{ params: { id: string; farmId: string } }, 'params'>
}
function StationPlantGreenhouseDetail({
  route,
  navigation,
}: Props): JSX.Element {
  const { id, farmId } = route.params
  const [visible, setVisible] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const limit = 6
  const page = 1
  const [data, setData] = useState<any>({
    data: [],
    page: 1,
    total: 1,
    limit: 10,
  })
  const fatchData = async () => {
    try {
      const res = await request.get(
        `/workAssignment/${id}/seedling-logs?page=${page}&limit=${limit}`
      )
      setData(res?.data)
    } catch (err) {
      console.log(err)
    }
  }
  useFocusEffect(
    React.useCallback(() => {
      fatchData()
    }, [refresh])
  )

  const onLoadMore = async () => {
    try {
      if (data.data.length < data.total) {
        const res = await request.get(
          `/workAssignment/${id}/seedling-logs?page=${
            data?.page + 1
          }&limit=${limit}`
        )
        const value = {
          data: [...data.data, ...res.data.data],
          page: data?.page + 1,
          total: data.total,
          limit: data.limit,
        }
        setData(value)
      } else {
        return null
      }
    } catch (e) {
      console.log(e)
    }
  }
  // const { data: seedling, mutate } = useSWR(
  //   [`/workAssignment/${id}/seedling-logs?page=1&limit=${limit}`, refresh],
  //   fetcher
  // )
  const onDelete = async (deleteId: string) => {
    try {
      await request.delete(`/workAssignment/${id}/seedling-logs/${deleteId}`)
      setVisible(true)
      setRefresh((prev) => !prev)
    } catch (e) {
      console.log(e)
    }
  }
  const onEdit = (editId: string) => {
    navigation.navigate('EditPlantGreenhouse', {
      id,
      editId,
      farmId,
    })
  }

  const RenderFooter = () => {
    return (
      <View style={{ marginTop: 32, width: '100%', alignItems: 'center' }}>
        <Button
          title="เพิ่มข้อมูล"
          icon={PlusIcon}
          style={{ width: Platform.OS === 'web' ? '90%' : '90%' }}
          onPress={() =>
            navigation.navigate('AddPlantGreenhouse', { id, farmId })
          }
        />
      </View>
    )
  }

  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        {/* <View style={{ height: height, width: '100%' }}> */}
        <Header
          showBack
          title={'กอ ID / ลำ ID'}
          style={{ paddingBottom: 0 }}
          numberOfLines={2}
          widthText={Dimensions.get('window').width - 60}
          componentBelowHeader={<RenderFooter />}
        />

        <StationSecondStep
          data={data?.data}
          onLoadMore={onLoadMore}
          onDelete={onDelete}
          onEdit={onEdit}
          //FooterComponent={<RenderFooter />}
        />
        {/* </View> */}
      </Container>
      <ModalAlert
        visible={visible}
        setVisibile={setVisible}
        textDesc="ลบสำเร็จ"
      />
    </Provider>
  )
}

export default StationPlantGreenhouseDetail
