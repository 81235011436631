import React from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Text from 'components/Text'
import Header from 'components/Header'
import Background from 'components/Background'
import {
  View,
  TouchableOpacity,
  Image,
  Platform,
  StyleSheet,
} from 'react-native'
import User from 'assets/images/icons/User.png'
import SignOut from 'assets/images/icons/SignOut.png'
import Indentify from 'assets/images/icons/Identification.png'
import Machinery from 'assets/images/icons/Truck_primary.png'
import { useAuth } from 'contexts/AuthContext'
import styled from '@emotion/native'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
// import { useLocalization } from 'contexts/LocalizationContext'

export type TouchableProps = {
  color?:
    | 'primary1'
    | 'primary2'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
}

const Touchable = styled(TouchableOpacity)<TouchableProps>`
  background-color: ${(props) => props.theme.color[props.color || 'primary1']};
  border-width: 1px;
  border-color: ${(props) => props.theme.color[props.color || 'primary1']};
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 48%;
  margin-bottom: 16px;
  min-height: 125px;
`

export type Props = {
  navigation: BottomTabNavigationHelpers
}
const styles = StyleSheet.create({
  cardShadow: {
    ...Platform.select({
      web: {
        shadowColor: '#000000a1',
        shadowOffset: {
          width: 2,
          height: 1,
        },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,

        elevation: 24,
      },
      default: {
        shadowColor: '#000000a1',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 2,
        paddingBottom: 2,
        paddingRight: 2,
      },
    }),
  },
})

const SettingsScreen = ({ navigation }: Props): JSX.Element => {
  // const { t } = useLocalization()
  const {
    state: { user, userRole },
    authContext: { logout },
  } = useAuth()
  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <Header title="ตั้งค่า" alignTitle="flex-start" />
      <Content enabledScroll={false}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: 50,
            flexWrap: 'wrap',
          }}
        >
          <Touchable
            onPress={() =>
              navigation.navigate('CreateProfile', {
                role: user?.role,
                setting: 'edit',
              })
            }
            style={styles.cardShadow}
          >
            <Image
              source={User}
              style={{
                width: 35,
                height: 35,
              }}
            />
            <Text
              color="white"
              sub1
              style={{ paddingTop: 10, textAlign: 'center' }}
            >
              โปรไฟล์
            </Text>
          </Touchable>
          {userRole === 'farmer' && (
            <Touchable
              onPress={() => navigation.navigate('Register', { user })}
              color="white"
              style={styles.cardShadow}
            >
              <Image
                source={Indentify}
                style={{
                  width: 35,
                  height: 35,
                }}
              />
              <Text
                color="primary1"
                sub1
                style={{ paddingTop: 10, textAlign: 'center' }}
              >
                ลงทะเบียนเกษตรกร
              </Text>
            </Touchable>
          )}
          {userRole === 'farmer' && (
            <Touchable
              onPress={() => navigation.navigate('FarmerMachinery')}
              color="white"
              style={styles.cardShadow}
            >
              <Image
                source={Machinery}
                style={{
                  width: 35,
                  height: 35,
                }}
              />
              <Text
                color="primary1"
                sub1
                style={{ paddingTop: 10, textAlign: 'center' }}
              >
                เครื่องจักรกลการเกษตร
              </Text>
            </Touchable>
          )}
          <Touchable color="white" onPress={logout} style={styles.cardShadow}>
            <Image
              source={SignOut}
              style={{
                width: 35,
                height: 35,
              }}
            />
            <Text
              color="primary1"
              sub1
              style={{ paddingTop: 10, textAlign: 'center' }}
            >
              ออกจากระบบ
            </Text>
          </Touchable>
        </View>
      </Content>
    </Container>
  )
}

export default SettingsScreen
