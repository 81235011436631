export const maxWidth = 768
export enum PestRecordType {
  DISEASE_KO = 'diseaseKo',
  DISEASE_LUM = 'diseaseLum',
  DISEASE_NOR = 'diseaseNor',
  DISEASE_LEAF = 'diseaseLeaf',
  INSECT = 'insect',
  ANIMAL = 'animal',
  WEED = 'weed',
}
