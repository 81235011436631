import React from 'react'
import { View, TouchableOpacity, Platform } from 'react-native'
import styled from '@emotion/native'
import { useFormContext, Controller } from 'react-hook-form'
import { AnimatedRegion, LatLng } from 'react-native-maps'
import MapScreen from 'screens/MapScreen'
import Text from 'components/Text'
import { ErrorMessage, Item, Label } from 'components/Form/components'
import theme from 'theme/index'

interface Props {
  name: string
  line?: boolean
  label?: string
  showOnFocus?: boolean
  themeWhite?: boolean
  frontInput?: boolean
  onChangeInput?: (value: string) => void
  selectionColor?: 'black' | 'white'
  padding?: string
  colorLabel?:
    | 'primary1'
    | 'primary2'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
  backgroundColor?: string
  testID?: string
  itemStyle?: {
    [key: string]: unknown
  }
  index?: number
  nameList?: string
  errorName?: string

  errorInside?: boolean
  bold?: boolean
  defaultValue?: LatLng | AnimatedRegion | undefined
  ownPositions?: LatLng | AnimatedRegion | undefined
}

interface ErrorBoxProps {
  errorInside: boolean
}

const ErrorBox = styled.View<ErrorBoxProps>`
  position: ${(props) => props.errorInside && 'absolute'};
  bottom: ${(props) => props.errorInside && '5px'};
  padding: ${(props) => props.errorInside && '8px'};
`

export const InputMap = ({
  name,
  label,
  itemStyle = {},
  errorInside = false,
  colorLabel = 'black',
  bold,
  frontInput = false,
  errorName = '',
  nameList = '',
  index = 0,
}: Props): JSX.Element => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext()
  const [visible, setVisible] = React.useState<boolean>(false)

  return (
    <Item style={{ ...itemStyle }}>
      {label && (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Label input color={colorLabel} bold={bold ? true : false}>
            {label}
          </Label>
        </View>
      )}
      <Controller
        control={control}
        render={({ field: { value, onChange } }) => (
          <>
            <Item
              style={
                frontInput
                  ? { flexDirection: 'row', alignItems: 'center' }
                  : itemStyle
              }
            >
              <TouchableOpacity onPress={() => setVisible(true)}>
                <View
                  style={[
                    {
                      paddingHorizontal: 10,
                      borderRadius: 10,
                      borderColor: theme.color.black40,
                      borderWidth: 1,
                      paddingVertical: 8,

                      width: Platform.OS === 'web' ? '100%' : '100%',
                      minHeight: 38.8,
                    },
                  ]}
                >
                  {value ? (
                    <Text>{`${value?.latitude},${value?.longitude}`}</Text>
                  ) : (
                    <Text color="black40">ละติจูด,ลองจิจูด</Text>
                  )}
                </View>
              </TouchableOpacity>
              <MapScreen
                visible={visible}
                value={value}
                onChange={onChange}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onConfirm={(v: any) => {
                  setVisible(false)
                  onChange(v)
                }}
                onClose={() => setVisible(false)}
              />
            </Item>
            {errors?.[nameList]?.[index]?.[errorName] && (
              <ErrorBox errorInside={errorInside}>
                <ErrorMessage error>
                  {errors?.[nameList]?.[index]?.[errorName].message}
                </ErrorMessage>
              </ErrorBox>
            )}
          </>
        )}
        name={name}
      />
    </Item>
  )
}
