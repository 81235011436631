import React from 'react'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { useLocalization } from 'contexts/LocalizationContext'
import { View } from 'react-native'
import { RouteProp } from '@react-navigation/native'
import Container from 'components/Container'
import Content from 'components/Content'
import Background from 'components/Background'
import Header from 'components/Header'
import SectionList from 'components/SectionList'
import Link from 'components/Link'
import FertilizerFormula from 'components/FertilizerFormula'
import Button from 'components/Button'

export type Props = {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<
    { params: { id: string; data: any; station: string } },
    'params'
  >
}

const ResultPreparePlotScreen = ({ navigation, route }: Props): JSX.Element => {
  const { t } = useLocalization()
  const { id, data, station } = route.params
  const formulaValue = [
    { formula: '46-0-0', amount: 40 },
    { formula: '18-46-0', amount: 50 },
    { formula: '0-0-60', amount: 30 },
  ]
  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <View style={{ width: '100%', alignItems: 'center', height: 130 }}>
        <Header
          showBack
          title={t('screens.StationPreparePlotScreen.result')}
          style={{ paddingBottom: 0 }}
        />
      </View>
      <Content
        noPadding
        enabledScroll={true}
        style={{
          backgroundColor: '#fff',
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
        }}
      >
        <View style={{ marginTop: 20, paddingHorizontal: 30 }}>
          <SectionList
            title={t('screens.StationPreparePlotScreen.form.nitrogen')}
            value={data.nitrogen}
          />
          <SectionList
            title={t('screens.StationPreparePlotScreen.form.phosphorus')}
            value={data.phosphorus}
          />
          <SectionList
            title={t('screens.StationPreparePlotScreen.form.potassium')}
            value={data.potassium}
          />
          <SectionList
            title={t('screens.StationPreparePlotScreen.form.ph')}
            value={data.ph}
            style={{ marginBottom: 30 }}
          />
          <FertilizerFormula
            value={formulaValue}
            style={{ marginBottom: 30 }}
          />
          <Link title="คู่มือการปรับปรุงดิน" />
          <View style={{ marginTop: 36, marginBottom: 24 }}>
            <Button
              title={t('Buttons.EditAnalytics')}
              onPress={() =>
                navigation.navigate('AnalysePreparePlot', {
                  id,
                  data,
                  station,
                })
              }
            />
          </View>
        </View>
      </Content>
    </Container>
  )
}

export default ResultPreparePlotScreen
