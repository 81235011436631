/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  View,
  ScrollView,
  TouchableOpacity,
  Image,
  Linking,
} from 'react-native'
import React, { useState } from 'react'
import Container from 'components/Container'
import Card from 'components/Card'
import Background from 'components/Background'
import Header from 'components/Header'
import { Form, Input, SubmitButton } from 'components/Form'
import * as yup from 'yup'
import theme from 'theme/index'
import Content from 'components/Content'
import dayjs from 'dayjs'
import InputDate from 'components/Form/InputDate'
import CheckBox from 'components/CheckBox'
import Text from 'components/Text'
import { useLocalization } from 'contexts/LocalizationContext'
import { RouteProp } from '@react-navigation/native'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import request from 'utils/request'
import LinkIcon from 'assets/images/icons/Link.png'
export interface RootObject {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  path: string
  text: string
}
interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    {
      params: {
        assignmentId: string
        lumIds: any
        framData: any
        link: RootObject[]
      }
    },
    'params'
  >
}
interface FormData {
  date: string
  lumIds: number[]
}
const StationHarvestingAdd = ({ navigation, route }: Props): JSX.Element => {
  const { t } = useLocalization()
  const { assignmentId, lumIds, framData, link } = route.params
  const [checkBoxChecked, setCheckBoxChecked] = useState<number[]>([])
  const [unSelectedLumIds, setUnSelectedLumIds] = useState<number[]>(lumIds)
  const schema = yup.object().shape({})

  const onSubmit = async (values: FormData) => {
    try {
      await request.post(`/workAssignment/${assignmentId}/harvestLogs`, {
        date: dayjs(values.date).toISOString(),
        assignmentId: assignmentId,
        lumIds: checkBoxChecked,
        unSelectedLumIds: unSelectedLumIds,
      })
      navigation.navigate('StationHarvestingList', {
        assignmentId: assignmentId,
        framData: framData,
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(JSON.stringify(e.response, null, 2))
    }
  }

  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <Header
        showBack
        title={t('screens.StationHarvestingScreen.detail.title')}
        numberOfLines={2}
        widthText={'90%'}
      />
      <Content enabledScroll={false} noPadding>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <Card
            borderRadiusTop
            style={{ justifyContent: 'space-between', flex: 1, marginTop: 32 }}
          >
            <Form
              schema={schema}
              defaultValues={{
                date: dayjs(),
                name: framData?.farmData.name,
                workDetail: framData?.data?.workDetail,
              }}
            >
              <InputDate
                label={t('screens.StationHarvestingScreen.detail.date')}
                name="date"
                iconRight
              />
              <Input
                label={t('screens.StationHarvestingScreen.detail.plot')}
                name="name"
                editable
              />
              <Input
                label={t('screens.StationHarvestingScreen.detail.detail')}
                name="workDetail"
                editable
              />
              <Text sub1>
                {t('screens.StationHarvestingScreen.detail.harvest')}
              </Text>
              <View>
                {lumIds.map((item: any, index: number) => {
                  return (
                    <View
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        paddingTop: 10,
                      }}
                    >
                      <CheckBox
                        onChange={() => {
                          if (checkBoxChecked.includes(item)) {
                            setCheckBoxChecked((prev) =>
                              prev.filter((value) => item !== value)
                            )
                            setUnSelectedLumIds((prev) => [...prev, item])
                          } else {
                            setCheckBoxChecked((prev) => [...prev, item])
                            setUnSelectedLumIds((prev) =>
                              prev.filter((value) => item !== value)
                            )
                          }
                        }}
                        checkBoxColor={'black40'}
                        isChecked={checkBoxChecked.includes(item)}
                        style={{
                          borderRadius: 4,
                        }}
                      />
                      <Text>{item}</Text>
                    </View>
                  )
                })}
              </View>
              <View
                style={{
                  flexDirection: 'column',
                  marginTop: 12,
                }}
              >
                {link?.map((item: RootObject, idx: any) => {
                  return (
                    <View
                      key={idx}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 12,
                      }}
                    >
                      <Image
                        source={LinkIcon}
                        style={{
                          width: 18,
                          height: 18,
                          marginRight: 8,
                        }}
                      />
                      <TouchableOpacity
                        onPress={() => Linking.openURL(item.path)}
                      >
                        <Text
                          sub1
                          color="secondary2"
                          style={{
                            textDecorationLine: 'underline',
                            textDecorationColor: theme.color.secondary2,
                          }}
                        >
                          {item.text}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )
                })}
              </View>
              <View style={{ marginTop: 32, paddingHorizontal: '8%' }}>
                <SubmitButton
                  title={t('screens.StationHarvestingScreen.detail.save')}
                  onSubmit={onSubmit}
                />
              </View>
            </Form>
          </Card>
        </ScrollView>
      </Content>
    </Container>
  )
}

export default StationHarvestingAdd
