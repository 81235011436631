import React, { useEffect } from 'react'
import {
  Dimensions,
  Image,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import Button from 'components/Button'
import { useLocalization } from 'contexts/LocalizationContext'
import MapBody from 'components/MapBody'
import Modal from 'components/Modal/Modal'
import Text from 'components/Text'
import { Input } from 'components/Form/Input'
import Close from 'assets/images/icons/Close.png'
import useDebounce from 'utils/debounceCustom'
const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    height: Dimensions.get('window').height * 0.65,
    width: Platform.OS === 'web' ? 550 : Dimensions.get('window').width * 0.9,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
})
import env from '../../../config'
import axios from 'axios'

type Props = {
  visible?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (e: any) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConfirm: (v: any) => void
  onClose: () => void
  value: {
    latitude: number
    longitude: number
  }
}
const API_KEY = env().GOOGLE_MAPS_API_KEY
const baseUrlGoogle =
  'https://maps.googleapis.com/maps/api/place/findplacefromtext/json'
export default function MapScreen({
  visible = false,
  onConfirm,
  onClose,
  value,
}: Props) {
  const { t } = useLocalization()
  const [searchValue, setSearchValue] = React.useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [valueBeforeSubmit, setValueBeforeSubmit] = React.useState<any>(null)
  const ProxyUrl = (url: string) => {
    const encodedUrl = encodeURIComponent(url)
    const proxy = `https://proxy-cors.tools.dev.witsawa.com/cors-proxy?url=${encodedUrl}`

    return proxy
  }
  const [address, setAddress] = React.useState<string>('')
  const debounceValue = useDebounce(searchValue, 1000)
  useEffect(() => {
    if (value) {
      setValueBeforeSubmit({
        lat: value.latitude,
        lng: value.longitude,
      })
    }
  }, [])

  useEffect(() => {
    const getAddressGoogle = async () => {
      try {
        const res = await axios.get(
          `${baseUrlGoogle}?input=${debounceValue}&inputtype=textquery&fields=formatted_address,name,geometry&key=${API_KEY}&language=th&region=TH`
        )

        setAddress(res.data.candidates[0].formatted_address)
        setValueBeforeSubmit(res.data.candidates[0].geometry.location)
      } catch (e) {
        console.log(e.response)
      }
    }
    const getAddressGoogleWeb = async () => {
      try {
        const res = await axios.get(
          ProxyUrl(
            `${baseUrlGoogle}?input=${encodeURI(
              debounceValue
            )}&inputtype=textquery&fields=formatted_address,name,geometry&key=${API_KEY}&language=th&region=TH`
          )
        )
        setAddress(res.data.candidates[0].formatted_address)
        setValueBeforeSubmit(res.data.candidates[0].geometry.location)
      } catch (e) {
        console.log(e.response)
      }
    }
    if (debounceValue) {
      if (Platform.OS === 'web') {
        getAddressGoogleWeb()
      } else {
        getAddressGoogle()
      }
    }
  }, [debounceValue])

  return (
    <Modal visible={visible} disableOnBlur={true}>
      <View style={styles.container}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text title1 medium>
            พิกัด
          </Text>
          <TouchableOpacity onPress={onClose}>
            <Image source={Close} style={{ width: 24, height: 24 }} />
          </TouchableOpacity>
        </View>
        <View style={{ flex: 1 }}>
          <Input
            name=""
            label="ค้นหาที่อยู่"
            withOutForm
            value={searchValue}
            onChangeInput={(v) => setSearchValue(v)}
          />
          <MapBody
            value={value}
            valueBeforeSubmit={valueBeforeSubmit}
            setValueBeforeSubmit={(v) => setValueBeforeSubmit(v)}
          />
        </View>
        {address ? (
          <Text style={{ marginBottom: 16 }}>{address}</Text>
        ) : (
          <View />
        )}
        <Button
          onPress={() => {
            onConfirm({
              latitude: valueBeforeSubmit.lat,
              longitude: valueBeforeSubmit.lng,
            })
          }}
          style={{ marginBottom: 20, marginTop: 0 }}
          title={t('Buttons.Save')}
        />
      </View>
    </Modal>
  )
}
