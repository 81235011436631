import {
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  Linking,
} from 'react-native'
import React, { useState } from 'react'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import Content from 'components/Content'
import Card from 'components/Card'
import dayjs, { Dayjs } from 'dayjs'
import * as yup from 'yup'
import { Form, Input, SubmitButton } from 'components/Form'
import Text from 'components/Text'
import theme from 'theme/index'
import ModalAlert from 'components/ModalAlert'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import { RouteProp } from '@react-navigation/native'
import InputDate from 'components/Form/InputDate'
import LinkIcon from 'assets/images/icons/Link.png'
import InputTimeForm from 'components/Form/InputTimeForm'
import request from 'utils/request'
export interface RootObject {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  path: string
  text: string
}
interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<
    { params: { assignmentId: string; link: RootObject[] } },
    'params'
  >
}
interface FormData {
  date: Dayjs
  time: {
    hour: number
    minute: number
  }
  koId: string
  lumId: string
  height: number
  thickness: number
  diameter: number
  grade: string
  medicineStep: string
}
const AddProtectionSystem = ({ navigation, route }: Props) => {
  const { assignmentId, link } = route.params

  const schema = yup.object().shape({
    date: yup.string(),
    time: yup.object().shape({
      hour: yup.string(),
      minute: yup.string(),
    }),
    koId: yup.string().required('กรุณากรอก กอ ID'),
    lumId: yup.string().required('กรุณากรอก ลำ ID'),
    height: yup.number().nullable().required('กรุณากรอกความสูง'),
    thickness: yup.number().nullable().required('กรุณากรอกความหนา'),
    diameter: yup.number().nullable().required('กรุณากรอกเส้นผ่าศูนย์กลาง'),
    grade: yup.string().required('กรุณากรอกข้อมูล'),
    medicineStep: yup.string().required('กรุณากรอกข้อมูล'),
  })
  const [isSuccess, setIsSuccess] = useState(false)
  const onSubmitForm = async (data: FormData) => {
    try {
      await request.post(`/workAssignment/${assignmentId}/afterHarvestWood`, {
        date: dayjs(data.date)
          .set('hour', data.time.hour)
          .set('minute', data.time.minute)
          .toISOString(),
        koId: data.koId,
        lumId: data.lumId,
        height: data.height,
        thickness: data.thickness,
        diameter: data.diameter,
        grade: data.grade,
        medicineStep: data.medicineStep,
      })
      setIsSuccess(true)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(JSON.stringify(e.response, null, 2))
    }
  }
  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <Header
        showBack
        title={'ระบบจัดการการป้องกันและรักษาเนื้อไม้'}
        numberOfLines={2}
        widthText={'90%'}
      />
      <Content enabledScroll={false} noPadding>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <Card
            borderRadiusTop
            style={{ justifyContent: 'space-between', flex: 1, marginTop: 32 }}
          >
            <Form
              schema={schema}
              defaultValues={{
                date: dayjs(),
                time: {
                  hour: dayjs().hour(),
                  minute: dayjs().minute(),
                },
              }}
              style={{ width: '100%' }}
            >
              <InputDate name="date" label="วันที่" iconRight />
              <InputTimeForm label="เวลา" name="time" />
              <Input label="กอ ID" name="koId" />
              <Input label="ลำ ID" name="lumId" />
              <Input
                label="ขนาดความสูง"
                name="height"
                unitLabelInside
                unitLabel="เมตร"
                style={{ width: '100%' }}
              />
              <Input
                label="ความหนาของไผ่"
                name="thickness"
                unitLabelInside
                unitLabel="นิ้ว"
                style={{ width: '100%' }}
              />
              <Input
                label="เส้นผ่าศูนย์กลางขอบนอก"
                name="diameter"
                unitLabelInside
                unitLabel="นิ้ว"
                style={{ width: '100%' }}
              />
              <Input label="เกรด" name="grade" />
              <Input
                label="วิธีการให้ยารักษาเนื้อไม้"
                name="medicineStep"
                multiline
                numberOfLines={4}
              />
              <View
                style={{
                  flexDirection: 'column',
                  marginTop: 12,
                }}
              >
                {link?.map((item: RootObject, idx: any) => {
                  return (
                    <View
                      key={idx}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 12,
                      }}
                    >
                      <Image
                        source={LinkIcon}
                        style={{
                          width: 18,
                          height: 18,
                          marginRight: 8,
                        }}
                      />
                      <TouchableOpacity
                        onPress={() => Linking.openURL(item.path)}
                      >
                        <Text
                          sub1
                          color="secondary2"
                          style={{
                            textDecorationLine: 'underline',
                            textDecorationColor: theme.color.secondary2,
                          }}
                        >
                          {item.text}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )
                })}
              </View>
              <View style={{ marginTop: 32, paddingHorizontal: '8%' }}>
                <SubmitButton title="ตกลง" onSubmit={onSubmitForm} />
              </View>
            </Form>
          </Card>
        </ScrollView>
      </Content>
      <ModalAlert
        visible={isSuccess}
        setVisibile={setIsSuccess}
        textDesc="เพิ่มข้อมูลสำเร็จ"
        onRequestClose={() =>
          navigation.navigate('ProtectionSystemDetail', {
            assignmentId: assignmentId,
          })
        }
      />
    </Container>
  )
}

export default AddProtectionSystem
