import { useAuth } from 'contexts/AuthContext'
import env from '../../config'
const config = env()

export const getImage = (imageFileName: string | undefined): string | null => {
  if (imageFileName) {
    return `${config.API_URL}/container/download/${imageFileName}`
  }
  return null
}
export const useFile = () => {
  const {
    state: { userToken },
  } = useAuth()
  const getImage = (imageFileName: string | undefined) => {
    try {
      return imageFileName
        ? `${config.API_URL}/container/download/${imageFileName}?access_token=${userToken}`
        : null
    } catch (e) {
      console.log(e)
    }
  }

  return { getImage }
}
