import { Image, Platform, TouchableOpacity, View } from 'react-native'
import React from 'react'
import Text from 'components/Text'
import Modal from 'components/Modal/Modal'
import Close from 'assets/images/icons/Close.png'
import { Form, Input, SubmitButton } from 'components/Form'
import * as yup from 'yup'
interface FormData {
  fertilizer: string
  amount: string
  rai: string
}
interface Props {
  visible: boolean
  setVisible: (visible: boolean) => void
  onChange: (value: FormData) => void
}
const ModalAddData = ({
  visible,
  setVisible,
  onChange,
}: Props): JSX.Element => {
  const schema = yup.object().shape({
    name: yup.string().required('กรุณากรอกสูตรปุ๋ยที่ใช้'),
    quantity: yup.string().required('กรุณากรอกจำนวนปุ๋ย'),
    numberOfArea: yup.string().required('กรุณากรอกจำนวนไร่'),
  })
  const onSubmit = (data: FormData) => {
    onChange(data)
    setVisible(false)
  }
  return (
    <Modal
      visible={visible}
      onRequestClose={() => setVisible(false)}
      width={Platform.OS === 'web' ? '40%' : '90%'}
      disableOnBlur
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text medium title1>
          เพิ่มข้อมูล
        </Text>
        <TouchableOpacity onPress={() => setVisible(false)}>
          <Image source={Close} style={{ width: 24, height: 24 }} />
        </TouchableOpacity>
      </View>
      <Form style={{ marginTop: 24 }} schema={schema} defaultValues={{}}>
        <Input name="name" label="สูตรปุ๋ยที่ใช้" unitLabelInside />
        <Input
          name="quantity"
          label="จำนวนปุ๋ย"
          keyboardType="numeric"
          unitLabelInside
          unitLabel="กก."
          maxLength={7}
        />
        <Input
          name="numberOfArea"
          label="จำนวนไร่"
          keyboardType="numeric"
          unitLabelInside
          unitLabel="ไร่"
          maxLength={4}
        />
        <View>
          <SubmitButton title="เพิ่มข้อมูล" onSubmit={onSubmit} />
        </View>
      </Form>
    </Modal>
  )
}

export default ModalAddData
