import { View, TouchableOpacity, FlatList, Platform } from 'react-native'
import React, { lazy, Suspense, useEffect } from 'react'

const InputTimeComponent = lazy(() =>
  Platform.OS === 'web' ? import('./InputTimeWeb') : import('./InputTimeMobile')
)
export interface PropsInputTime {
  onChange: (value: { hour: string; minute: string }) => void
  value?: {
    hour?: string
    minute?: string
  }
}

const InputTime = (props: PropsInputTime): JSX.Element => {
  return (
    <Suspense fallback={<View />}>
      <InputTimeComponent {...props} />
    </Suspense>
  )
}

export default InputTime
