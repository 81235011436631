import React from 'react'
import { TouchableOpacity, View, Image, ViewStyle } from 'react-native'
import CheckBoxIcon from 'assets/images/icons/CheckBox.png'

import CheckBlackIcon from 'assets/images/icons/CheckBlack.png'

import theme from 'theme/index'

export type Props = {
  testID?: string
  iconBlack?: boolean

  isChecked?: boolean
  checkBoxColor?: 'primary1' | 'error' | 'success' | 'black40' | 'secondary2'
  onChange?: () => void
  style?: ViewStyle
}

const CheckBox = ({
  isChecked,
  checkBoxColor = 'black40',
  onChange,
  style,
  iconBlack = false,
}: Props): JSX.Element => {
  const checkedColor = iconBlack ? 'white' : theme.color.success
  const checkedBorder = iconBlack
    ? theme.color[checkBoxColor]
    : theme.color.success
  return (
    <TouchableOpacity
      onPress={() => {
        onChange?.()
      }}
    >
      <View
        style={{
          height: 24,
          width: 24,
          borderWidth: 1,
          marginRight: 12,
          borderColor: isChecked ? checkedBorder : theme.color[checkBoxColor],
          borderRadius: 6,
          backgroundColor: isChecked ? checkedColor : theme.color.white,
          alignItems: 'center',
          justifyContent: 'center',
          ...style,
        }}
      >
        {isChecked && (
          <Image
            source={iconBlack ? CheckBlackIcon : CheckBoxIcon}
            style={{
              width: 15,
              height: 15,
            }}
            resizeMode="contain"
          />
        )}
      </View>
    </TouchableOpacity>
  )
}

export default CheckBox
