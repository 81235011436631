import {
  View,
  ScrollView,
  Platform,
  StyleSheet,
  ActivityIndicator,
} from 'react-native'
import React, { useState } from 'react'
import { Provider } from 'react-native-paper'
import Container from 'components/Container'
import Header from 'components/Header'
import Background from 'components/Background'
import Content from 'components/Content'
import Card from 'components/Card'
import { Form, SubmitButton, Input } from 'components/Form'
import * as yup from 'yup'
// import { Grid } from 'antd-mobile'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import { RouteProp } from '@react-navigation/native'
import { useLocalization } from 'contexts/LocalizationContext'
import InputDate from 'components/Form/InputDate'
import dayjs from 'dayjs'
import ModalAlert from 'components/ModalAlert'
import useSWR from 'swr'
import request, { fetcher } from 'utils/request'
import RecordsForm from './RecordsForm'
import Text from 'components/Text'
import theme from 'theme/index'
type FormData = {
  koId: string
  lumIds: string[]
  lat: number
  lng: number
}
type ArrayFormData = {
  date: string
  zone: string
  assignmentId: string
  records: FormData[]
}

export default function AddStationBambooPlant({
  navigation,
  route,
}: {
  navigation: StackNavigationHelpers
  route: RouteProp<{ params: { id: string } }, 'params'>
}): JSX.Element {
  const { t } = useLocalization()
  const { id } = route.params
  const { data: farm } = useSWR([`/workAssignment/${id}`], fetcher)
  const schema = yup.object().shape({
    date: yup.string().required('กรุณากรอกวันที่'),
    zone: yup.string().required('กรุณากรอกโซน'),
    convert: yup.string().required('กรุณากรอกแปลง'),
    records: yup.array().of(
      yup.object().shape({
        lumIds: yup.string().required('กรุณากรอก ลำ ID'),
        koId: yup.string().required('กรุณากรอก กอ ID'),
      })
    ),
  })
  const [isSuccess, setIsSuccess] = useState(false)

  const onSubmitForm = async (data: ArrayFormData) => {
    try {
      const record = data?.records.map((item: any) => {
        const dataRecords = {
          koId: item?.koId,
          lumIds: [item?.lumIds],
          lat: item?.position?.latitude,
          lng: item?.position?.longitude,
        }
        return dataRecords
      })
      await request.post(`/workAssignment/${id}/plantinglogs`, {
        date: data?.date,
        zone: data?.zone,
        assignmentId: id,
        records: record,
      })
      setIsSuccess(true)
    } catch (e) {
      console.log(e)
    }
  }
  const d = new Date()

  const formDefaultValue = {
    date: dayjs(d),
    zone: '',
    convert: farm?.farm?.name || '',
    records: [
      {
        koId: '',
        lumIds: '',
        position: {
          latitude: 13.736717,
          longitude: 100.523186,
        },
      },
    ],
  }

  if (id && !farm) {
    return (
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header showBack title="กอ ID / ลำ ID" widthText={'90%'} />
        <View style={{ marginTop: 70, marginBottom: 20 }}></View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size="large" color={theme.color.primary1} />
          <Text center style={{ marginTop: 16 }} h2 color="primary2">
            Loading...
          </Text>
        </View>
      </Container>
    )
  }
  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header showBack title="กอ ID / ลำ ID" widthText={'90%'} />
        <Content enabledScroll={false} noPadding>
          <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
            <Card
              borderRadiusTop
              style={{
                justifyContent: 'space-between',
                flex: 1,
                marginTop: 32,
              }}
            >
              <Form
                schema={schema}
                defaultValues={formDefaultValue}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                }}
              >
                <View>
                  <InputDate label="วันที่" name="date" iconRight />

                  <Input
                    name="zone"
                    label={t('screens.StationBambooPlant.form.zone')}
                    style={styles.Input}
                  />
                  <Input
                    name="convert"
                    label={t('screens.StationBambooPlant.form.convert')}
                    style={styles.Input}
                    editable
                  />
                </View>
                <RecordsForm />
                <View style={{ paddingHorizontal: '8%' }}>
                  <SubmitButton title="ตกลง" onSubmit={onSubmitForm} />
                </View>
              </Form>
            </Card>
          </ScrollView>
        </Content>
        <ModalAlert
          visible={isSuccess}
          setVisibile={setIsSuccess}
          textDesc="เพิ่มข้อมูลสำเร็จ"
          onRequestClose={() => navigation.goBack()}
        />
      </Container>
    </Provider>
  )
}
const styles = StyleSheet.create({
  Input: {
    ...Platform.select({
      web: {
        paddingVertical: 6,
        height: 40,
      },
      default: {
        paddingVertical: 6,
        flex: 1,
      },
    }),
  },
})
