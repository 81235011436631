import React, { useEffect, useState } from 'react'
import {
  Keyboard,
  Platform,
  ScrollView,
  TextInputProps,
  View,
  Image,
} from 'react-native'
import styled, { css } from '@emotion/native'
import { useFormContext, Controller } from 'react-hook-form'
import { Item, Label, ErrorMessage } from './components'
import Text from 'components/Text'
import request from 'utils/request'
import { TouchableOpacity } from 'react-native-gesture-handler'
import theme from '../../theme'
import closeIcon from 'assets/images/X.png'
import Collapse from 'components/Collapse'

interface Props extends TextInputProps {
  name: string
  line?: boolean
  label?: string
  showOnFocus?: boolean
  themeWhite?: boolean
  frontInput?: boolean

  onChangeInput?: (value: string) => void
  selectionColor?: 'black' | 'white'
  padding?: string
  colorLabel?:
    | 'primary1'
    | 'primary2'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
  backgroundColor?: string
  testID?: string
  itemStyle?: {
    [key: string]: unknown
  }
  errorInside?: boolean
  bold?: boolean
  defaultValue?: any
  requestMember: any[]
  role?: 'company' | 'farmer' | 'extensionist'
}
interface InputProps {
  line: boolean
  isFocus?: boolean
  themeWhite?: boolean
  padding?: string
}
interface ErrorBoxProps {
  errorInside: boolean
}

const TextInput = styled.TextInput<InputProps>`
  ${({ theme, line, isFocus, themeWhite }) => {
    if (themeWhite) {
      if (isFocus && Platform.OS === 'web') {
        return css`
          border: 1px solid ${theme.color.primary1};
          outline-color: ${theme.color.primary1};
          border-radius: 10px;
          margin-top: 2px;
        `
      } else {
        return `
        border: 1px solid ${theme.color.white};
        border-radius: 10px;
        margin-top: 2px;
        `
      }
    }
    if (line) {
      return `
        border-bottom-width:1px;
        border-bottom-color: ${theme.input.borderColor};
        border-radius: 0px;
      `
    }
    if (isFocus && Platform.OS === 'web') {
      return css`
        border: 1px solid ${theme.color.primary1};
        outline-color: ${theme.color.primary1};
        border-radius: 10px;
        margin-top: 2px;
      `
    }
    if (isFocus && Platform.OS !== 'web') {
      return `
      border: 1px solid ${theme.color.primary1};
      border-radius: 10px;
      margin-top: 2px;
    `
    }
    return `
    border: 1px solid ${theme.color.black22};
    border-radius: 10px;
    margin-top: 2px;
      `
  }}
  background-color: transparent;
  /* font-family: Kanit_400Regular; */
`
const ErrorBox = styled.View<ErrorBoxProps>`
  position: ${(props) => props.errorInside && 'absolute'};
  bottom: ${(props) => props.errorInside && '5px'};
  padding: ${(props) => props.errorInside && '8px'};
`

export const TagInput = ({
  name,
  line = false,
  label,
  testID,
  itemStyle = {},
  errorInside = false,
  showOnFocus = true,
  colorLabel = 'black',
  selectionColor = 'black',
  style,
  bold,
  backgroundColor,
  frontInput = false,
  defaultValue,
  requestMember,
  role = 'farmer',
  ...props
}: Props): JSX.Element => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext()
  const [isFocus, setIsFocus] = React.useState(false)
  const [text, setText] = useState<string | undefined>()
  const [searchUser, setSearchUser] = useState<any[]>([])
  const [requestUser, setRequestUser] = useState<string[]>([])
  const [tag, setTag] = useState<any[]>(defaultValue || [])
  const [isCollapse, setIsCollapse] = React.useState(false)

  useEffect(() => {
    const reqMemberArray = requestMember?.map((item) => {
      return `${item.firstName} ${item.lastName}`
    })
    setRequestUser(reqMemberArray)
  }, [requestMember])

  useEffect(() => {
    const newIds = tag.map((item) => {
      return item.id
    })
    setValue(name, newIds)
  }, [tag])

  const getSearchUser = async (text: string) => {
    try {
      const res = await request.get('/user/search', {
        params: {
          search: text,
          role,
        },
      })
      setSearchUser(res.data.data)
    } catch (e) {
      return console.log(JSON.stringify(e.response, null, 2))
    }
  }

  const addTag = (value: any) => {
    const isExists = tag.some((item) => item.id === value.id)
    if (!tag.includes(value) && !isExists) {
      setTag((prev) => [...prev, value])
    }
  }

  const deleteTag = (value: string) => {
    if (tag.includes(value)) {
      const newTag = tag.filter((item) => item !== value)
      setTag(newTag)
    }
  }

  return (
    <Item style={{ ...itemStyle }}>
      {label && !frontInput ? (
        <Label input color={colorLabel} bold={bold ? true : false}>
          {label}
        </Label>
      ) : null}
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <Item
              style={
                frontInput
                  ? { flexDirection: 'row', alignItems: 'center' }
                  : itemStyle
              }
            >
              {label && frontInput ? (
                <Label
                  input
                  color={colorLabel}
                  bold={bold ? true : false}
                  style={{ marginRight: 16, width: 80 }}
                >
                  {label}
                </Label>
              ) : null}
              <TextInput
                testID={testID}
                autoCapitalize="none"
                onBlur={() => {
                  onBlur()
                  Platform.OS !== 'web' && setIsFocus(false)
                }}
                onChangeText={(value) => {
                  setText(value ? value : undefined)
                  getSearchUser(value)
                  onChange(undefined)
                }}
                value={text}
                onFocus={() => setIsFocus(true)}
                line={line}
                textAlignVertical={props.multiline ? 'top' : 'center'}
                isFocus={showOnFocus ? isFocus : false}
                selectionColor={selectionColor}
                style={[
                  {
                    color: theme.color.secondary2,
                    fontFamily: 'regular',
                    borderBottomLeftRadius:
                      isFocus && text !== undefined ? 0 : 10,
                    borderBottomRightRadius:
                      isFocus && text !== undefined ? 0 : 10,
                    paddingHorizontal: 10,
                    backgroundColor: backgroundColor,
                    paddingVertical: 8,
                    width: Platform.OS === 'web' ? '100%' : 'auto',
                    minHeight:
                      Platform.OS === 'ios' && props.numberOfLines
                        ? 20 * props.numberOfLines
                        : 'auto',
                  },
                  style,
                ]}
                {...props}
              />
              {isFocus && text !== undefined && (
                <>
                  {Platform.OS === 'web' ? (
                    <div
                      style={{
                        width: '100%',
                        padding: '0 10px',
                        borderRadius: 8,
                        borderWidth: 1,
                        borderTopWidth: 0,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderColor: theme.color.primary1,
                        minHeight: 42,
                        maxHeight: 200,
                        overflow: 'scroll',
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {searchUser?.map((item) => {
                        return (
                          <div
                            key={item.id}
                            style={{
                              padding: '8px 0',
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              e.stopPropagation()

                              const Ids = tag.map((item) => {
                                return item.id
                              })
                              setText(undefined)
                              addTag(item)
                              onChange([...Ids, item.id])
                              setIsFocus(false)
                            }}
                          >
                            <Text>{`${item.firstName} ${item.lastName}`}</Text>
                          </div>
                        )
                      })}
                    </div>
                  ) : (
                    <ScrollView
                      scrollEnabled={true}
                      nestedScrollEnabled={true}
                      style={{
                        width: '100%',
                        paddingHorizontal: 10,
                        borderRadius: 8,
                        borderWidth: 1,
                        borderTopWidth: 0,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderColor: theme.color.primary1,
                        minHeight: 42,
                        maxHeight: Platform.OS === 'web' ? 200 : 100,
                        overflow: 'scroll',
                      }}
                    >
                      {searchUser?.map((item) => {
                        return (
                          <TouchableOpacity
                            key={item.id}
                            style={{
                              flexDirection: 'row',
                              paddingVertical: 8,
                            }}
                            onPress={() => {
                              const Ids = tag.map((item) => {
                                return item.id
                              })
                              setText(undefined)
                              addTag(item)
                              onChange([...Ids, item.id])
                            }}
                          >
                            <View
                              key={item.id}
                              style={{
                                flexDirection: 'column',
                                // backgroundColor: 'red',
                              }}
                            >
                              <Text>{`${item.firstName} ${item.lastName}`}</Text>
                            </View>
                          </TouchableOpacity>
                        )
                      })}
                    </ScrollView>
                  )}
                </>
              )}
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {tag.map((item, index) => {
                  return (
                    <View
                      key={index}
                      style={{
                        alignItems: 'baseline',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}
                    >
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          borderWidth: 1,
                          paddingVertical: 8,
                          paddingHorizontal: 10,
                          borderRadius: 25,
                          borderColor: theme.color.primary1,
                          marginTop: 10,
                          marginRight: 10,
                        }}
                      >
                        <Text sub1 color="primary1" style={{ marginRight: 5 }}>
                          {`${item.firstName} ${item.lastName}`}
                        </Text>
                        <TouchableOpacity onPress={() => deleteTag(item)}>
                          <Image
                            source={closeIcon}
                            style={{ width: 20, height: 20 }}
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                  )
                })}
                {requestUser !== undefined && requestUser.length > 0 && (
                  <View style={{ width: '100%', marginTop: 10 }}>
                    <Collapse
                      isCollapsed={isCollapse}
                      title={'รอการตอบรับ'}
                      onPress={() => setIsCollapse((prev) => !prev)}
                    >
                      {requestUser.map((item, index) => {
                        return (
                          <View
                            key={index}
                            style={{
                              alignItems: 'baseline',
                              display: 'flex',
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                            }}
                          >
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                borderWidth: 1,
                                paddingVertical: 8,
                                paddingHorizontal: 10,
                                borderRadius: 25,
                                borderColor: theme.color.primary1,
                                marginTop: 10,
                                marginRight: 10,
                              }}
                            >
                              <Text
                                sub1
                                color="primary1"
                                style={{ marginRight: 5 }}
                              >
                                {item}
                              </Text>
                            </View>
                          </View>
                        )
                      })}
                    </Collapse>
                  </View>
                )}
              </View>
            </Item>
            {errors[name] && (
              <ErrorBox errorInside={errorInside}>
                <ErrorMessage error>{errors[name].message}</ErrorMessage>
              </ErrorBox>
            )}
          </>
        )}
        name={name}
      />
    </Item>
  )
}
