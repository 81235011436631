import React from 'react'
import { Platform, StyleSheet, TextInputProps, TextStyle } from 'react-native'
import styled, { css } from '@emotion/native'
import { useFormContext, Controller } from 'react-hook-form'
import theme from 'theme/index'
import { Item, Label, ErrorMessage } from './components'
import _ from 'lodash'
interface Props extends TextInputProps {
  unitLabelInside?: boolean
  name: string
  line?: boolean
  label?: string
  showOnFocus?: boolean
  themeWhite?: boolean
  frontInput?: boolean
  unitLabel?: string
  onChangeInput?: (value: string) => void
  selectionColor?: 'black' | 'white'
  padding?: string
  colorLabel?:
    | 'primary1'
    | 'primary2'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
  colorUnit?:
    | 'primary1'
    | 'primary2'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
  backgroundColor?: string
  textColor?: string
  testID?: string
  itemStyle?: {
    [key: string]: unknown
  }
  frontLabelStyle?: TextStyle
  unitLabelStyle?: TextStyle
  errorInside?: boolean
  bold?: boolean
  semiBold?: boolean
  editable?: boolean
  withOutForm?: boolean
}
interface InputProps {
  line: boolean
  isFocus?: boolean
  themeWhite?: boolean
  padding?: string
}
interface ErrorBoxProps {
  errorInside: boolean
}

const TextInput = styled.TextInput<InputProps>`
  ${({ theme, line, isFocus, themeWhite }) => {
    if (themeWhite) {
      if (isFocus && Platform.OS === 'web') {
        return css`
          border: 1px solid ${theme.color.primary1};
          outline-color: ${theme.color.primary1};
          border-radius: 10px;
          margin-top: 2px;
        `
      } else {
        return `
        border: 1px solid ${theme.color.white};
        border-radius: 10px;
        margin-top: 2px;
        `
      }
    }
    if (line) {
      return `
        border-bottom-width:1px;
        border-bottom-color: ${theme.input.borderColor};
        border-radius: 0px;
      `
    }
    if (isFocus && Platform.OS === 'web') {
      return css`
        border: 1px solid ${theme.color.primary1};
        outline-color: ${theme.color.primary1};
        border-radius: 10px;
        margin-top: 2px;
      `
    }
    if (isFocus && Platform.OS !== 'web') {
      return `
      border: 1px solid ${theme.color.primary1};
      border-radius: 10px;
      margin-top: 2px;
    `
    }
    return `
    border: 1px solid ${theme.color.black22};
    border-radius: 10px;
    margin-top: 2px;
      `
  }}
  background-color: transparent;
  color: transparent;
  /* font-family: Kanit_400Regular; */
`
const ErrorBox = styled.View<ErrorBoxProps>`
  position: ${(props) => props.errorInside && 'absolute'};
  bottom: ${(props) => props.errorInside && '5px'};
  padding: ${(props) => props.errorInside && '8px'};
`

export const Input = ({
  name,
  line = false,
  label,
  testID,
  itemStyle = {},
  errorInside = false,
  showOnFocus = true,
  colorLabel = 'black',
  selectionColor = 'black',
  style,
  frontLabelStyle,
  unitLabelStyle,
  bold,
  semiBold,
  backgroundColor,
  textColor = 'black',
  frontInput = false,
  editable = false,
  unitLabel,
  withOutForm = false,
  unitLabelInside = false,
  colorUnit,
  ...props
}: Props): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const [isFocus, setIsFocus] = React.useState(false)

  return withOutForm ? (
    <Item style={{ ...itemStyle }}>
      {label && !frontInput ? (
        <Label
          input
          color={colorLabel}
          bold={bold ? true : false}
          semiBold={semiBold ? true : false}
        >
          {label}
        </Label>
      ) : null}

      <>
        <Item
          style={
            unitLabel
              ? {
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  // marginTop: 5,
                }
              : itemStyle
          }
        >
          {label && frontInput ? (
            <Label
              input
              color={colorLabel}
              bold={bold ? true : false}
              style={frontLabelStyle}
            >
              {label}
            </Label>
          ) : null}
          <TextInput
            testID={testID}
            editable={editable ? false : true}
            onBlur={() => {
              setIsFocus(false)
            }}
            onChangeText={(value) => {
              props.onChangeInput?.(value)
            }}
            onFocus={() => setIsFocus(true)}
            line={line}
            textAlignVertical={props.multiline ? 'top' : 'center'}
            isFocus={showOnFocus ? isFocus : false}
            selectionColor={selectionColor}
            style={[
              {
                fontFamily: 'regular',
                paddingHorizontal: 10,
                backgroundColor: backgroundColor,
                color: textColor,
                paddingVertical: 8,
                width: Platform.OS === 'web' ? '100%' : 'auto',
                minHeight:
                  Platform.OS === 'ios' && props.numberOfLines
                    ? 20 * props.numberOfLines
                    : 'auto',
              },
              style,
            ]}
            {...props}
          />
          {unitLabel ? (
            <Label
              input
              color={colorLabel}
              bold={bold ? true : false}
              style={unitLabelStyle}
            >
              {unitLabel}
            </Label>
          ) : null}
        </Item>
        {_.get(errors, name) && (
          <ErrorBox errorInside={errorInside}>
            <ErrorMessage error>{_.get(errors, name).message}</ErrorMessage>
          </ErrorBox>
        )}
      </>
    </Item>
  ) : (
    <Item style={{ ...itemStyle }}>
      {label && !frontInput ? (
        <Label
          input
          color={colorLabel}
          bold={bold ? true : false}
          semiBold={semiBold ? true : false}
        >
          {label}
        </Label>
      ) : null}
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <Item
              style={
                unitLabel
                  ? {
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      // marginTop: 5,
                    }
                  : itemStyle
              }
            >
              {label && frontInput ? (
                <Label
                  input
                  color={colorLabel}
                  bold={bold ? true : false}
                  style={frontLabelStyle}
                >
                  {label}
                </Label>
              ) : null}
              <TextInput
                testID={testID}
                editable={editable ? false : true}
                onBlur={() => {
                  onBlur()
                  setIsFocus(false)
                }}
                onChangeText={(value) => {
                  onChange(value)
                  props.onChangeInput?.(value)
                }}
                value={value && value.toString()}
                onFocus={() => setIsFocus(true)}
                line={line}
                textAlignVertical={props.multiline ? 'top' : 'center'}
                isFocus={showOnFocus ? isFocus : false}
                selectionColor={selectionColor}
                style={[
                  {
                    color: theme.color.secondary2,
                    fontFamily: 'regular',
                    paddingHorizontal: 10,
                    backgroundColor: backgroundColor,
                    paddingVertical: 8,
                    width: Platform.OS === 'web' ? '100%' : '100%',
                    minHeight:
                      Platform.OS === 'ios' && props.numberOfLines
                        ? 20 * props.numberOfLines
                        : 'auto',
                  },
                  style,
                ]}
                {...props}
              />
              {unitLabel ? (
                <Label
                  input
                  color={colorUnit ? colorUnit : colorLabel}
                  bold={bold ? true : false}
                  style={
                    unitLabelInside
                      ? [styles.unitLabelInside, unitLabelStyle]
                      : unitLabelStyle
                  }
                >
                  {unitLabel}
                </Label>
              ) : null}
            </Item>
            {_.get(errors, name) && (
              <ErrorBox errorInside={errorInside}>
                <ErrorMessage error>{_.get(errors, name).message}</ErrorMessage>
              </ErrorBox>
            )}
          </>
        )}
        name={name}
      />
    </Item>
  )
}

const styles = StyleSheet.create({
  unitLabelInside: {
    position: 'absolute',
    right: 10,
    marginTop: 2,
  },
})
