import React from 'react'
import { View, StyleSheet, Platform, ViewStyle } from 'react-native'
import Text from 'components/Text'
import theme from 'theme/index'

export type Props = {
  title: string
  value: string
  titleColor?:
    | 'error'
    | 'primary1'
    | 'primary2'
    | 'primary3'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'selected'
    | undefined
  style?: ViewStyle
}

const SectionList = ({
  title,
  value,
  titleColor,
  style,
}: Props): JSX.Element => {
  const getStatus = (value: string) => {
    if (/high/.test(value)) {
      return 'สูง'
    } else if (/medium/.test(value)) {
      return 'ปานกลาง'
    } else if (/very low/.test(value)) {
      return 'ต่ำมาก'
    } else if (/low/.test(value)) {
      return 'ต่ำ'
    } else {
      return value
    }
  }

  return (
    <View style={style}>
      <View style={styles.viewHead}>
        <View style={styles.viewLeft}>
          <Text semiBold sub1 color={titleColor}>
            {title}
          </Text>
        </View>
        <View style={styles.viewRight}>
          <Text color="black85" sub1>
            {getStatus(value)}
          </Text>
        </View>
      </View>
      <View style={styles.viewBorder} />
    </View>
  )
}

const styles = StyleSheet.create({
  viewHead: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 12,
    paddingTop: 12,
  },
  viewLeft: {
    flex: 0.6,
  },
  viewRight: { flex: 0.4 },
  viewBorder: { borderBottomWidth: 1, borderColor: theme.color.black6 },
})

export default SectionList
