import { View, Platform } from 'react-native'
import React from 'react'
import { useLocalization } from 'contexts/LocalizationContext'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import { RouteProp } from '@react-navigation/native'
import Button from 'components/Button'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import Text from 'components/Text'
import StationFirstStep from 'components/StationFirstStep'
import { useAuth } from 'contexts/AuthContext'
export interface RootObject {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  path: string
  text: string
}
interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { params: { id: string; data: any; farmData: any } },
    'params'
  >
}

const StationGrowthTrackingScreen = ({
  navigation,
  route,
}: Props): JSX.Element => {
  const { data, farmData } = route.params
  const { t } = useLocalization()
  const {
    state: { link },
  } = useAuth()
  const url: RootObject[] = []
  if (link) {
    link?.map((item: RootObject) => {
      if (
        item.text === 'อัตราการเจริญเติบโตของพืช' ||
        item.text === 'ข้อมูลประมาณการปริมาณ และคุณภาพผลผลิต' ||
        item.text === 'คู่มือการดูแลบำรุงพืช'
      ) {
        return url.push(item)
      }
    })
  }
  // const workAssignment = params?.data
  const FooterRender = () => {
    return (
      <View style={{ alignItems: 'center' }}>
        <Button
          title="ระบบตรวจวัดและวิเคราะห์การเจริญเติบโตของพืช"
          arrowBack
          onPress={() =>
            navigation.navigate('StationGrowthTrackingDetail', {
              assignmentId: data?.id,
              farmData: farmData,
            })
          }
          style={{
            width: Platform.OS === 'web' ? '100%' : '100%',
            marginBottom: 8,
          }}
        />
        <Button
          title="ระบบประมาณการผลผลิตในเกษตรแปลงใหญ่"
          arrowBack
          onPress={() =>
            navigation.navigate('ProductionEstimate', { link: url })
          }
          style={{
            width: Platform.OS === 'web' ? '100%' : '100%',
            marginBottom: 8,
          }}
        />
      </View>
    )
  }
  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <View style={{ width: '100%', alignItems: 'center', height: 130 }}>
        <Header
          showBack
          title={t('screens.StationNutrientManagementScreen.header')}
          style={{ paddingBottom: 0 }}
        />
        <Text
          title1
          color="white"
          style={{ width: '70%', textAlign: 'center', lineHeight: 30 }}
        >
          สถานีงาน : การติดตาม การเจริญเติบโต
        </Text>
      </View>
      <StationFirstStep
        dataAssignment={{
          assignmentId: data?.workAssignmentNumber,
          refId: data?.documentNumber || data?.project?.order?.documentNumber,
          projectId: data?.projectNumber,
        }}
        FooterComponent={<FooterRender />}
      />
    </Container>
  )
}

export default StationGrowthTrackingScreen
