import React from 'react'
import { View } from 'react-native'
import Text from 'components/Text'
import theme from 'theme/index'
import CheckBoxOnce from 'components/Form/CheckBoxOnce'
import { useLocalization } from 'contexts/LocalizationContext'

type Props = {
  display: boolean
}

export const PrivacyPolicy = ({ display }: Props) => {
  const { t } = useLocalization()

  return (
    <View
      style={{
        display: display ? 'flex' : 'none',
        flex: 1,
        justifyContent: 'space-between',
      }}
    >
      <View
        style={{
          borderWidth: 1,
          borderColor: theme.color.black22,
          borderRadius: 10,
          height: '85%',
        }}
      >
        <View style={{ padding: 10 }}>
          <Text style={{ marginBottom: 30 }}>
            ข้าพเจ้าได้อ่านหรือเข้าใจข้อความข้างต้นแล้ว ขอรับรองว่าได้
            แจ้งข้อมูลเป็นจริงครบถ้วน และทราบดีว่าการให้ข้อมูลที่เป็น
            เท็จกับทางราชการ มีโทษตามมาตรา 137 และ มาตรา 267
            แห่งประมวลกฏหมายอาญา
          </Text>
          <Text>
            ทั้งนี้ข้าพเจ้ายินยอมให้เปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าแก่
            หน่วยงานรัฐที่เกี่ยวข้อง และยินยอมให้หน่วยงานของรัฐร้อง ขอ สอบถาม
            และใช้ข้อมูลส่วนบุคคลของข้าพเจ้าจากหน่วย งานของรัฐอื่นที่เกี่ยวข้อง
            อีกทั้ง ยินยอมให้นำข้อมูลในบัตร ประชาชนพร้อมภาพใบหน้าของข้าพจ้าไปใช้
            เพื่อประโยชน์ใน การพิจารณาการเข้าร่วมโครงการรัฐ และ เอกชน
            โดยให้ถือว่า คู่ฉบับและบรรดาสำเนา ภาพถ่าย ข้อมูลอิเล็กทรอนิกส์ หรือ
            โทรสารที่ทำขึ้นจากหนังสือให้ความยินยอมฉบับนี้ เป็นหลักฐาน
            ในการให้ความยินยอมของข้าพเจ้าเช่นเดียวกัน
          </Text>
        </View>
      </View>
      <View>
        <CheckBoxOnce
          label={t('screens.RegisterScreen.form.permission')}
          name="permission"
        />
      </View>
    </View>
  )
}
