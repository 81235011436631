import React, { useState } from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Text from 'components/Text'
import Background from 'components/Background'
import Collapse from 'components/Collapse'
import { useLocalization } from 'contexts/LocalizationContext'
import { Image, FlatList, View, TouchableOpacity } from 'react-native'
import Plus from 'assets/images/icons/Plus.png'
import homeMock from 'assets/images/homeMock.png'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import useSWR from 'swr'
import { fetcher } from '../../utils/request'
import { useAuth } from 'contexts/AuthContext'
import { useFocusEffect } from '@react-navigation/native'
import { Provider } from 'react-native-paper'
import DotMenu from 'components/DotMenu'
import Card from 'components/Card'
import FarmRequest from './FarmRequest'
import { getImage } from 'utils/getImage'
import TokenExpired from 'components/TokenExpired'
import request from 'utils/request'
export type Props = {
  navigation: BottomTabNavigationHelpers
}

export interface User {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  email: string
  role: string
  profileImageUrl?: any
  type: string
  title: string
  firstName: string
  lastName: string
  citizenId: string
  birthDate: string
  address: string
  subDistrict: string
  district: string
  province: string
  postcode: string
  phoneNumber: string
  farmerCode: string
  lineId: string
  unit: string
  companyName: string
  position: string
  corporationName?: any
  taxId?: any
  companyAddress?: any
  companySubDistrict?: any
  companyDistrict?: any
  companyProvince?: any
  companyPostcode?: any
  companyPhoneNumber?: any
  maritalStatus?: any
  mainOccupation?: any
  additionalOccupation?: any
  smartAgricultureSystem?: any
  memberNumber?: any
  gender?: any
  isActivated: boolean
}

export interface FarmMember {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  role: string
  farmId: string
  userId: string
  user: User
}

export interface Datum {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  name: string
  address: string
  subDistrict: string
  district: string
  province: string
  coverImage?: any
  ownership: string
  hasDocumentOfRight?: any
  documentType: string
  documentNumber: string
  documentSheet?: any
  documentAreaSize?: number
  ownerCitizenId: string
  ownerName: string
  acceptorCitizenId: string
  acceptorName: string
  acceptorPosition: string
  irrigableArea: string
  areaZone?: number
  lat: number
  lng: number
  waterSources: string[]
  wateringMethods: string[]
  areaSize: number
  sustainableAgriculture: string
  subSustainableAgriculture?: any
  extensionistId: string
  status: boolean
  statusLand: string
  historyOfUse: string
  typeOfStatusLand: string
  farmMembers: FarmMember[]
}

export interface RootObject {
  data: Datum[]
  total: number
  page: number
  limit: number
}

const HomeScreen = ({ navigation }: Props): JSX.Element => {
  const {
    state: { userToken },
  } = useAuth()
  const { t } = useLocalization()
  const [refresh, setRefresh] = useState(false)
  const limit = 10
  const page = 1
  const [isCollapse, setIsCollapse] = React.useState(true)
  const [data, setData] = useState<RootObject>({
    data: [],
    page: 1,
    total: 1,
    limit: 10,
  })
  const fatchInventory = async () => {
    try {
      const res = await request.get(`/user/me/farm?page=${page}&limit=${limit}`)
      setData(res?.data)
    } catch (err) {
      console.log(err)
    }
  }
  const onLoadMore = async () => {
    try {
      if (data?.total > data.data.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        try {
          const res = await request.get(
            `/user/me/farm?page=${data?.page + 1}&limit=${limit}`
          )
          const data1 = {
            data: [...data.data, ...res.data.data],
            page: data?.page + 1,
            total: data.total,
            limit: data.limit,
          }
          setData(data1)
        } catch (err) {
          console.log(err)
        }
      }
    } catch (e) {
      // console.log(e)
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      fatchInventory()
    }, [refresh])
  )
  const { data: farmReq, mutate: mutationReq } = useSWR(
    '/user/me/farm/requests',
    fetcher
  )
  useFocusEffect(
    React.useCallback(() => {
      mutationReq('/user/me/farm/requests')
    }, [refresh, userToken])
  )

  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header title={t('screens.HomeScreen.title')} alignTitle="flex-start" />
        {!data ? (
          <TokenExpired />
        ) : (
          <>
            <View
              style={{
                height: 100,
                width: '100%',
                paddingLeft: 16,
                paddingRight: 16,
                marginBottom: 16,
              }}
            >
              <TouchableOpacity
                style={{
                  flex: 1,
                  padding: 16,
                  borderWidth: 1,
                  borderColor: 'white',
                  borderRadius: 10,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onPress={() =>
                  navigation.navigate('CreateArea', {
                    farmId: undefined,
                    item: undefined,
                  })
                }
              >
                <Image
                  source={Plus}
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
                <Text color="white" sub1 bold style={{ paddingTop: 10 }}>
                  {t('screens.HomeScreen.createPlace')}
                </Text>
              </TouchableOpacity>
            </View>
            <Content enabledScroll={false}>
              <Card style={{ padding: 10 }}>
                <Collapse
                  isCollapsed={isCollapse}
                  title={'คำเชิญ'}
                  onPress={() => setIsCollapse((prev) => !prev)}
                  count={
                    typeof farmReq === 'object' ? farmReq?.length : undefined
                  }
                >
                  <FarmRequest farmReq={farmReq} setRefresh={setRefresh} />
                </Collapse>
              </Card>
              <FlatList
                nestedScrollEnabled={true}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                data={data?.data?.filter((el: any) => el !== null)}
                onEndReached={onLoadMore}
                indicatorStyle="default"
                style={{ marginTop: 15 }}
                contentContainerStyle={{
                  paddingBottom: 60,

                  position: 'relative',
                }}
                renderItem={({ item, index }) => {
                  const zIndex = data?.data.length - index

                  return (
                    <View
                      style={{
                        paddingBottom: 20,
                        zIndex,
                        position: 'relative',
                      }}
                    >
                      <TouchableOpacity
                        // onPress={() =>
                        //   navigation.navigate('DetailFarmArea', {
                        //     farmId: item.id,
                        //     title: item.name,
                        //   })
                        // }

                        activeOpacity={0.8}
                      >
                        <Image
                          source={
                            item.coverImage
                              ? { uri: getImage(item.coverImage) as string }
                              : homeMock
                          }
                          style={{
                            width: '100%',
                            height: 189,
                            borderRadius: 10,
                            zIndex,
                          }}
                        />
                      </TouchableOpacity>
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: 10,
                          position: 'relative',
                          zIndex: 1000,
                        }}
                      >
                        <Text color="black" sub1 bold>
                          {item.name}
                        </Text>
                        <View>
                          <DotMenu
                            navigation={navigation}
                            farmId={item.id}
                            setRefresh={setRefresh}
                          />
                        </View>
                      </View>
                    </View>
                  )
                }}
                keyExtractor={(item) => item.id}
              />
            </Content>
          </>
        )}
      </Container>
    </Provider>
  )
}

export default HomeScreen
