import { FlatList, TouchableOpacity, Image, StyleSheet } from 'react-native'
import React from 'react'
import { useLocalization } from 'contexts/LocalizationContext'
import Text from 'components/Text'
import { useDimension } from 'contexts/DimensionContext'

import moduleBackground1 from 'assets/images/module1.png'
import moduleBackground2 from 'assets/images/module2.png'
import moduleBackground3 from 'assets/images/module3.png'
import moduleBackground4 from 'assets/images/module4.png'
import moduleBackground5 from 'assets/images/module5.png'
import moduleBackground6 from 'assets/images/module6.png'
import moduleBackground7 from 'assets/images/module7.png'
import moduleBackground8 from 'assets/images/module8.png'
import moduleBackground9 from 'assets/images/module9.png'
import moduleBackground10 from 'assets/images/module10.png'
import moduleDisable1 from 'assets/images/module1_disable.png'
import moduleDisable2 from 'assets/images/module2_disable.png'
import moduleDisable3 from 'assets/images/module3_disable.png'
import moduleDisable4 from 'assets/images/module4_disable.png'
import moduleDisable5 from 'assets/images/module5_disable.png'
import moduleDisable6 from 'assets/images/module6_disable.png'
import moduleDisable7 from 'assets/images/module7_disable.png'
import moduleDisable8 from 'assets/images/module8_disable.png'
import moduleDisable9 from 'assets/images/module9_disable.png'
import moduleDisable10 from 'assets/images/module10_disable.png'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import { RouteProp } from '@react-navigation/native'
import { useAuth } from 'contexts/AuthContext'

interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<{ params: { farmId: string; title: string } }, 'params'>
}
export default function ListModule({ navigation, route }: Props): JSX.Element {
  const { width } = useDimension()
  const { t } = useLocalization()
  const {
    state: { user },
  } = useAuth()
  const moduleData = [
    {
      title: 'screens.DetailFarmScreen.moduleList.module1',
      background: moduleBackground1,
      disable: moduleDisable1,
      path: 'DetailModule',
      permission: ['company', 'extensionist'],
    },
    {
      title: 'screens.DetailFarmScreen.moduleList.module2',
      background: moduleBackground2,
      disable: moduleDisable2,
      path: 'DetailModule2',
      permission: ['company', 'farmer', 'extensionist'],
    },
    {
      title: 'screens.DetailFarmScreen.moduleList.module3',
      background: moduleBackground3,
      disable: moduleDisable3,
      path: 'DetailModule3',
      permission: ['company', 'farmer', 'extensionist'],
    },
    {
      title: 'screens.DetailFarmScreen.moduleList.module4',
      background: moduleBackground4,
      disable: moduleDisable4,
      path: 'DetailModule4',
      permission: ['company', 'farmer', 'extensionist'],
    },
    {
      title: 'screens.DetailFarmScreen.moduleList.module5',
      background: moduleBackground5,
      disable: moduleDisable5,
      path: 'DetailModule5',
      permission: ['company', 'farmer', 'extensionist'],
    },
    {
      title: 'screens.DetailFarmScreen.moduleList.module6',
      background: moduleBackground6,
      disable: moduleDisable6,
      path: 'DetailModule6',
      permission: ['company', 'farmer', 'extensionist'],
    },
    {
      title: 'screens.DetailFarmScreen.moduleList.module7',
      background: moduleBackground7,
      disable: moduleDisable7,
      path: 'DetailModule7',
      permission: ['company', 'farmer', 'extensionist'],
    },
    {
      title: 'screens.DetailFarmScreen.moduleList.module8',
      background: moduleBackground8,
      disable: moduleDisable8,
      path: 'DetailModule8',
      permission: ['company', 'farmer', 'extensionist'],
    },
    {
      title: 'screens.DetailFarmScreen.moduleList.module9',
      background: moduleBackground9,
      disable: moduleDisable9,
      path: 'DetailModule9',
      permission: ['company', 'farmer', 'extensionist'],
    },
    {
      title: 'screens.DetailFarmScreen.moduleList.module10',
      background: moduleBackground10,
      disable: moduleDisable10,
      path: 'DetailModule10',
      permission: ['company', 'farmer', 'extensionist'],
    },
  ]

  return (
    <FlatList
      data={moduleData}
      style={{
        width: '100%',
        paddingHorizontal: 16,
        backgroundColor: 'white',
      }}
      numColumns={2}
      keyExtractor={(item) => item?.title}
      columnWrapperStyle={{ justifyContent: 'space-between' }}
      renderItem={({ item, index }) => {
        const isEven = (index + 1) % 2 === 0
        const isDisable = !item.permission.includes(user.role)
        return (
          <TouchableOpacity
            key={item.title}
            disabled={isDisable}
            onPress={() =>
              navigation.navigate(item.path, {
                title: t(item.title),
                farmId: route.params.farmId,
              })
            }
            style={[
              styled.cardModule,
              { width: width / 2 - 24 },
              isEven ? { paddingLeft: 8 } : { paddingRight: 8 },
            ]}
          >
            <Image
              source={isDisable ? item.disable : item.background}
              style={{
                width: width / 2 - 17,
                height: 109,
                zIndex: 1,
                position: 'absolute',
              }}
              resizeMode="stretch"
            />
            <Text
              color="white"
              style={{ width: width / 2 - 48, zIndex: 2 }}
              center
            >
              {t(item.title)}
            </Text>
          </TouchableOpacity>
        )
      }}
    />
  )
}
const styled = StyleSheet.create({
  cardModule: {
    height: 100,
    paddingHorizontal: 8,
    justifyContent: 'center',
    marginBottom: 16,
    alignItems: 'center',
    borderRadius: 10,
  },
})
