import React from 'react'
import { Image, useWindowDimensions, View, Platform } from 'react-native'
import RNPickerSelect from 'react-native-picker-select'
import theme from 'theme/index'
import CaretCircleDown from 'assets/images/icons/CaretCircleDown.png'

export interface PropsDropdown {
  data: {
    label: string
    value: string | number
  }[]
  height?: number | string
  width?: number | string
  onChange?: ((value?: string | number | undefined) => void) | undefined
  value?: string | number // value State
  placeholder?: { label: string; value: null } | undefined
  borderColor?:
    | 'primary1'
    | 'primary2'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
}
export default function DropDown({
  data = [],
  // height = 44,
  onChange,
  value,
  borderColor = 'black22',
  placeholder,
}: PropsDropdown): JSX.Element {
  const { width, height } = useWindowDimensions()

  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: theme.color[borderColor],
        borderRadius: 10,
        height: 44,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        zIndex: 200,
      }}
    >
      <RNPickerSelect
        onValueChange={(value: string | number) => onChange?.(value)}
        items={data}
        placeholder={placeholder !== undefined ? placeholder : {}}
        value={value}
        useNativeAndroidPickerStyle={false}
        style={{
          iconContainer: {
            flex: 1,
            alignItems: 'center',
            alignSelf: 'center',
            justifyContent: 'center',
            paddingRight: 10,
          },
          inputAndroidContainer: {
            flex: 1,
            width: width - 32,
            display: 'flex',
            justifyContent: 'center',
          },
          inputAndroid: {
            paddingLeft: 10,
            flex: 1,
            color: theme.color.secondary2,
            fontFamily: 'regular',
          },
          inputIOSContainer: {
            justifyContent: 'center',
          },
          inputIOS: {
            color: theme.color.secondary2,
            paddingLeft: 10,
            fontFamily: 'regular',
          },
          inputWeb: {
            color: theme.color.secondary2,
            width: '95%',
            alignSelf: 'center',
            fontFamily: 'regular',
            fontSize: 16,
            borderWidth: 0,
            backgroundColor: 'transparent',
          },
        }}
        Icon={() => {
          return (
            Platform.OS !== 'web' && (
              <Image
                source={CaretCircleDown}
                style={{ width: 13, height: 13 }}
                resizeMode="contain"
              />
            )
          )
        }}
      />
    </View>
  )
}
