/* eslint-disable @typescript-eslint/no-explicit-any */
import { View } from 'react-native'
import React, { useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import Text from 'components/Text'
import PlusIconButton from 'components/PlusIconButton'
import DeleteButton from 'components/DeleteButton'
import ImagePickerMulti from 'components/FormArray/ImagePickerMulti'
import { InputText } from 'components/FormArray/InputText'
import Seperator from 'components/Seperator'

interface Props {
  i: number
}
const FormNestBaiList = ({ i }: Props) => {
  const { control } = useFormContext()
  const { append, remove, fields } = useFieldArray({
    name: `formList[${i}].leafRecords`,
    control,
  })
  useEffect(() => {
    if (fields.length === 0) {
      append({
        leafLength: undefined,
        // id: undefined,
        leafImage: undefined,
      })
    }
  }, [])
  return (
    <View>
      {fields.map((field: any, idx) => {
        return (
          <>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              key={field.id}
            >
              <Text sub1> {`ภาพใบ ชุดที่ ${i + 1}.${idx + 1}`} </Text>
              {idx === 0 ? (
                <PlusIconButton onPress={() => append({ id: undefined })} />
              ) : (
                <DeleteButton onPress={() => remove(idx)} />
              )}
            </View>
            <ImagePickerMulti
              index={i}
              indexNested={idx}
              errorName="image"
              nestNameList="leafRecords"
              nameList="formList"
              name={`formList[${i}].leafRecords[${idx}].leafImage`}
              defaultValue={field.leafImage}
            />
            {/* <InputText
              name={`formList[${i}].leafRecords[${idx}].id`}
              label="ใบ ID"
              index={i}
              indexNested={idx}
              hasScanner
              nameList="formList"
              errorName="id"
              nestNameList="leafRecords"
              colorUnit="black85"
            /> */}
            <InputText
              index={i}
              indexNested={idx}
              nestNameList="leafRecords"
              errorName="height"
              nameList="formList"
              name={`formList[${i}].leafRecords[${idx}].leafLength`}
              unitLabelInside
              belowInput
              showCheckBox
              unitLabel={'เมตร'}
              label="ขนาดความยาว"
              colorUnit="black85"
            />

            {idx !== fields.length - 1 && (
              <Seperator style={{ marginBottom: 16 }} />
            )}
          </>
        )
      })}
    </View>
  )
}

export default FormNestBaiList
