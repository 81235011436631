export const Ownership = [
  {
    mainId: 1,
    label: 'ครัวเรือน',
    value: 'house',
    item: [],
  },
  {
    mainId: 2,
    label: 'เช่า',
    value: 'rent contract',
    item: [
      { id: 1, label: 'มีสัญญา', value: 'rent contract' },
      {
        id: 2,
        label: 'ไม่มีสัญญา',
        value: 'rent no contract',
      },
    ],
  },
  { mainId: 3, label: 'อื่นๆ', value: 'other', item: [] },
]
export const IrrigableArea = [
  { mainId: 1, label: 'ในเขต', value: 'in', item: [] },
  { mainId: 2, label: 'นอกเขต', value: 'out', item: [] },
]

export const areaZone = [
  { mainId: 1, label: '47', value: 47, item: [] },
  { mainId: 2, label: '48', value: 48, item: [] },
]

export const waterSources = [
  {
    mainId: 1,
    label: 'ไม่มีแหล่งน้ำ',
    value: 'no',
    item: [],
  },
  {
    mainId: 2,
    label: 'แหล่งน้ำสาธารณะ',
    value: 'public',
    item: [],
  },
  {
    mainId: 3,
    label: 'แหล่งน้ำของตนเอง',
    value: 'self',
    item: [
      { id: 1, label: 'บ่อน้ำตื้น', value: 'self shallow well' },
      {
        id: 2,
        label: 'บ่อบาดาล',
        value: 'self artesian well',
      },
      {
        id: 3,
        label: 'สระน้ำ',
        value: 'self pond',
      },
    ],
  },
]

export const WateringMethod = [
  {
    mainId: 1,
    label: 'ไม่มีระบบให้น้ำ',
    value: 'no',
    item: [],
  },
  {
    mainId: 2,
    label: 'ระบบให้น้ำทางท่อ',
    value: 'pipe',
    item: [
      { id: 1, label: 'สปริงเกอร์', value: 'springer' },
      {
        id: 2,
        label: 'มินิสปริงเกอร์',
        value: 'mini springer',
      },
      {
        id: 3,
        label: 'น้ำหยด',
        value: 'water drop',
      },
    ],
  },
  {
    mainId: 3,
    label: 'ระบบน้ำแบบอื่นๆ',
    value: 'other',
    item: [],
  },
]

export const sustainableAgricultureOption = [
  {
    mainId: 1,
    label: 'ไม่เป็นเกษตรกรรมยั่งยืน',
    value: 'no',
    item: [],
  },
  {
    mainId: 2,
    label: 'เป็นเกษตรกรรมยั่งยืนในรูปแบบ',
    value: 'mix',
    item: [
      {
        id: 1,
        label: 'เกษตรผสมผสาน',
        value: 'mix',
        subItem: [
          {
            subId: 'GAPmix',
            label: 'GAP',
            value: 'GAP',
          },
          {
            subId: 'PGSmix',
            label: 'PGS',
            value: 'PGS',
          },
          {
            subId: 'othermix',
            label: 'อื่นๆ',
            value: 'other',
          },
        ],
      },
      {
        id: 2,
        label: 'เกษตรอินทรีย์',
        value: 'bio',
        subItem: [
          {
            subId: 'IFOAMbio',
            label: 'IFOAM',
            value: 'IFOAM',
          },
          {
            subId: 'ICERESbio',
            label: 'ICERES',
            value: 'ICERES',
          },
          {
            subId: 'IOASbio',
            label: 'IOAS',
            value: 'IOAS',
          },
          {
            subId: 'otherbio',
            label: 'อื่นๆ',
            value: 'other',
          },
        ],
      },
      {
        id: 3,
        label: 'เกษตรธรรมชาติ',
        value: 'nature',
        subItem: [],
      },
      {
        id: 4,
        label: 'เกษตรทฤษฏีใหม่',
        value: 'new theory',
        subItem: [
          {
            subId: 'GAPnew theory',
            label: 'GAP',
            value: 'GAP',
          },
          {
            subId: 'PGSnew theory',
            label: 'PGS',
            value: 'PGS',
          },
          {
            subId: 'othernew theory',
            label: 'อื่นๆ',
            value: 'other',
          },
        ],
      },
    ],
  },
  {
    mainId: 3,
    label: 'วนเกษตรหรือไร่นาป่าผสม',
    value: 'agroforestry',
    item: [],
  },
]

export const documentTypeData = [
  {
    label: 'โฉนดที่ดิน หรือ น.ส. 4 จ.',
    value: 'โฉนดที่ดิน หรือ น.ส. 4 จ.',
  },
  {
    label: 'หนังสือแสดงสิทธิในที่ดิน น.ส. 3 ก.',
    value: 'หนังสือแสดงสิทธิในที่ดิน น.ส. 3 ก.',
  },
  {
    label: 'หนังสือแสดงสิทธิในที่ดิน น.ส. 3',
    value: 'หนังสือแสดงสิทธิในที่ดิน น.ส. 3',
  },
  {
    label: 'หนังสือแสดงสิทธิในที่ดิน น.ค. 3',
    value: 'หนังสือแสดงสิทธิในที่ดิน น.ค. 3',
  },
  {
    label: 'หนังสือแสดงสิทธิในที่ดิน ส.ป.ก. 4-01',
    value: 'หนังสือแสดงสิทธิในที่ดิน ส.ป.ก. 4-01',
  },
  {
    label: 'อื่นๆ',
    value: 'อื่นๆ',
  },
]
export const statusLandData = [
  {
    label: 'พื้นที่รกร้าง',
    value: 'พื้นที่รกร้าง',
  },
  {
    label: 'พื้นที่ปลูกพืช (ระบุชนิด)',
    value: 'พื้นที่ปลูกพืช (ระบุชนิด)',
  },
  {
    label: 'พื้นที่เลี้ยงสัตว์ (ระบุชนิด)',
    value: 'พื้นที่เลี้ยงสัตว์ (ระบุชนิด)',
  },
  {
    label: 'พื้นที่อยู่อาศัย',
    value: 'พื้นที่อยู่อาศัย',
  },
  {
    label: 'อื่นๆ',
    value: 'อื่นๆ',
  },
]
