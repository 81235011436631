/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Background from 'components/Background'
import { View, StyleSheet, Platform, ActivityIndicator } from 'react-native'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { Provider } from 'react-native-paper'
import Card from 'components/Card'
import { Form, SubmitButton, Input, Select, SearchInput } from 'components/Form'
import InputDate from 'components/Form/InputDate'
import DropDownNew from '../../components/Form/DropDownNew'
//import CheckBoxForm from 'components/Form/CheckBoxForm'
import * as yup from 'yup'
// import request from 'utils/request'
import useSWR from 'swr'
import { fetcher } from '../../utils/request'
import { useLocalization } from 'contexts/LocalizationContext'
import { RouteProp } from '@react-navigation/native'
import Text from 'components/Text'
import theme from 'theme/index'
import request from 'utils/request'
import DropDownForm from 'components/Form/DropDownForm'

export type Props = {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<{ params: { inventoryId: string; id: string } }, 'params'>
}

type FormData = {
  documentNumber: null
  projectNumber: null
  purchaseOrderId: null
  projectDate: null
  quantity: number
  unit: null
  fundPerUnit: number
  totalFund: number
  dueDate: null
  serialNumber: null
  status: null
  deliveredDate: null
  factory: null
  itemCode: null
  detail: null
  species: null
  updateDate: null
}

const MeansOfProductionDetailScreen = ({
  navigation,
  route,
}: Props): JSX.Element => {
  const { t } = useLocalization()

  const { inventoryId, id } = route.params
  const { data } = useSWR(
    () => (inventoryId ? [`/inventory/requests/${inventoryId}`] : null),
    fetcher
  )

  // const { data: type } = useSWR(() => [`/inventory/types`], fetcher)

  // const { data: codeItem } = useSWR(
  //   () => [`/inventory?type=${isType}`],
  //   fetcher
  // )

  const defaultValue = {
    projectDate: data?.date || '',
    type: data?.inventory.type || '',
    productCode: data?.inventory.productCode || '',
    person: data?.inventory.custodian || '',
    quantity: data?.quantity || '',
  }
  const schema = yup.object().shape({})
  const onSubmit = async () => {
    navigation.navigate('MeansOfProductionEdit', {
      id: id,
      inventoryId: inventoryId,
    })
  }

  if (inventoryId && !data) {
    return (
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={
            inventoryId
              ? t('screens.MeansOfProductionScreen.title')
              : t('screens.MeansOfProductionScreen.title')
          }
          alignTitle="flex-start"
        />
        <View style={{ marginTop: 70, marginBottom: 20 }}></View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size="large" color={theme.color.primary1} />
          <Text center style={{ marginTop: 16 }} h2 color="primary2">
            Loading...
          </Text>
        </View>
      </Container>
    )
  }

  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={
            inventoryId
              ? t('screens.MeansOfProductionScreen.title')
              : t('screens.MeansOfProductionScreen.title')
          }
          alignTitle="flex-start"
        />
        <View style={{ marginTop: 70, marginBottom: 20 }}></View>
        <Content noPadding enabledScroll={false}>
          <Card
            borderRadiusTop
            style={{
              alignSelf: 'flex-end',
              flex: 1,
              borderBottomStartRadius: 0,
              borderBottomEndRadius: 0,
              padding: 0,
              marginTop: 16,
            }}
          >
            <Content
              noPadding
              // enabledScroll={page === 1 ? true : false}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Form
                enableReinitial
                defaultValues={defaultValue}
                schema={schema}
                style={{
                  height: '100%',
                  paddingTop: 20,
                  paddingHorizontal: 16,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <View>
                  <InputDate
                    enableReinitial
                    name="projectDate"
                    label={'วันที่เบิก'}
                    testID="projectDate"
                    style={{ height: 42 }}
                    defaultValue={defaultValue.projectDate}
                  />
                  <Input name="type" label={'ประเภท'} style={styles.Input} />
                  <View>
                    <Input
                      name="productCode"
                      label={'รหัสสินค้า'}
                      style={styles.Input}
                    />
                    <View style={{ flexDirection: 'row' }}>
                      <Text>รายละเอียด : </Text>
                      <Text color="error">{data?.inventory.name}</Text>
                    </View>
                  </View>
                  <Input
                    name="person"
                    label={'ผู้รับผิดชอบ'}
                    style={styles.Input}
                  />
                  <Input
                    name="quantity"
                    label={t('screens.ProjectScreen.form.quantity')}
                    style={styles.Input}
                  />
                </View>
                <View style={{ marginTop: 56, marginBottom: 24 }}>
                  <SubmitButton
                    testID="form.createCompany"
                    title={'แก้ไขข้อมูล'}
                    onSubmit={onSubmit}
                  />
                </View>
              </Form>
            </Content>
          </Card>
        </Content>
      </Container>
    </Provider>
  )
}

const styles = StyleSheet.create({
  Input: {
    ...Platform.select({
      web: {
        paddingVertical: 6,
        //height: 40,
      },
      default: {
        paddingVertical: 6,
        flex: 1,
      },
    }),
  },
})

export default MeansOfProductionDetailScreen
