import React, { useState } from 'react'
import {
  Platform,
  ScrollView,
  TextInputProps,
  View,
  Text,
  Image,
} from 'react-native'
import styled, { css } from '@emotion/native'
// import Text from 'components/Text'

import { Item } from '../../components/Form/components'
import request from 'utils/request'
import { TouchableOpacity } from 'react-native-gesture-handler'
import theme from '../../theme'
import SearchIcon from 'assets/images/icons/Search.png'
interface Props extends TextInputProps {
  line?: boolean
  label?: string
  showOnFocus?: boolean
  themeWhite?: boolean
  frontInput?: boolean

  onChangeInput?: (value: string) => void
  selectionColor?: 'black' | 'white'
  padding?: string
  colorLabel?:
    | 'primary1'
    | 'primary2'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
  backgroundColor?: string
  testID?: string
  itemStyle?: {
    [key: string]: unknown
  }
  errorInside?: boolean
  bold?: boolean
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
}
interface InputProps {
  line?: boolean
  isFocus?: boolean
  themeWhite?: boolean
  padding?: string
}

const TextInput = styled.TextInput<InputProps>`
  ${({ theme, line, isFocus, themeWhite }) => {
    if (themeWhite) {
      if (isFocus && Platform.OS === 'web') {
        return css`
          background-color: white;
          border: 1px solid transparent;
          outline-color: transparent;
          border-radius: 10px;
          margin-top: 2px;
        `
      } else {
        return `
        background-color: white;
        border: 1px solid transparent;
        border-radius: 10px;
        margin-top: 2px;
        `
      }
    }
    if (line) {
      return `
        border-bottom-width:1px;
        border-bottom-color: transparent;
        border-radius: 0px;
      `
    }
    if (isFocus && Platform.OS === 'web') {
      return css`
        border: 1px solid transparent;
        outline-color: transparent;
        border-radius: 10px;
        margin-top: 2px;
      `
    }
    if (isFocus && Platform.OS !== 'web') {
      return `
      border: 1px solid transparent;
      border-radius: 10px;
      margin-top: 2px;
    `
    }
    return `
    border: 1px solid transparent;
    border-radius: 10px;
    margin-top: 2px;
      `
  }}
  background-color: transparent;
  /* font-family: Kanit_400Regular; */
`
export const SearchProjectInput = ({
  line = false,
  showOnFocus = true,
  style,
  setSearchQuery,
  ...props
}: Props): JSX.Element => {
  const page = 1
  const limit = 10
  const [isFocus, setIsFocus] = React.useState(false)
  const [text, setText] = useState<string | undefined>('')
  const [searchProject, setSearchProject] = useState<any[]>([])

  const getSearchProject = async (text: string) => {
    try {
      const res = await request.get(
        `/project?page=${page}&limit=${limit}&isCanceled=false&keyword=${text}`
      )
      setSearchProject(res.data.data)
    } catch (e: any) {
      return console.log(JSON.stringify(e.response, null, 2))
    }
  }
  const isWeb = Platform.OS === 'web'
  return (
    <Item
      style={{
        width: '100%',
        paddingHorizontal: 16,
        //backgroundColor: 'white',
      }}
    >
      <View
        style={{
          width: '100%',
          paddingHorizontal: 16,
          paddingVertical: 4,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: 'white',
          borderBottomLeftRadius: isFocus && text !== undefined ? 0 : 10,
          borderBottomRightRadius: isFocus && text !== undefined ? 0 : 10,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          marginTop: 2,
          borderWidth: 1,
          borderColor:
            isFocus && text !== undefined
              ? theme.color.primary1
              : theme.color.black22,
        }}
      >
        <Image
          source={SearchIcon}
          style={{
            width: 20,
            height: 20,
            marginRight: 8,
            //resizeMode: 'contain',
          }}
        />
        <TextInput
          placeholder="เลขที่โปรเจค"
          onChangeText={(value) => {
            setText(value ? value : undefined)
            getSearchProject(value)
            //onChange(undefined)
          }}
          value={text}
          onFocus={() => setIsFocus(true)}
          isFocus={showOnFocus ? isFocus : false}
          line={line}
          onBlur={() => {
            !isWeb && setIsFocus(false)
          }}
          style={[
            {
              // borderBottomLeftRadius: isFocus && text !== undefined ? 0 : 10,
              // borderBottomRightRadius: isFocus && text !== undefined ? 0 : 10,
              //margin: 16,
              //marginHorizontal: 16,
              paddingHorizontal: 16,
              backgroundColor: 'white',
              paddingVertical: 8,
              width: Platform.OS === 'web' ? '100%' : 'auto',
              height: Platform.OS === 'web' ? 40 : 'auto',
              minHeight:
                Platform.OS === 'ios' && props.numberOfLines
                  ? 20 * props.numberOfLines
                  : 'auto',
            },
          ]}
        />
      </View>
      {isFocus && text !== undefined && (
        <ScrollView
          scrollEnabled={true}
          nestedScrollEnabled={true}
          style={{
            width: '100%',
            paddingHorizontal: 10,
            borderRadius: 8,
            borderWidth: 1,
            borderTopWidth: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderColor: theme.color.primary1,
            minHeight: 42,
            maxHeight: 100,
            overflow: 'scroll',
            backgroundColor: 'white',
            zIndex: 100,
          }}
        >
          {searchProject?.map((item) => {
            return (
              <TouchableOpacity
                key={item.id}
                style={{
                  flexDirection: 'row',
                  paddingVertical: 8,
                }}
                onPress={() => {
                  setText(`${item.projectNumber}`)
                  setSearchQuery(`${item.projectNumber}`)
                  //onChange(item.id)
                  setIsFocus(false)
                }}
              >
                <View
                  key={item.id}
                  style={{
                    flexDirection: 'column',
                  }}
                >
                  <Text>{`${item.projectNumber}`}</Text>
                </View>
              </TouchableOpacity>
            )
          })}
        </ScrollView>
      )}
    </Item>
  )
}
