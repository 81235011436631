/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense, lazy } from 'react'

import { Platform, View, ViewStyle } from 'react-native'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

export type Props = {
  navigation?: () => void
  onCancel?: () => void
  onDelete?: () => void
  style?: ViewStyle
}

const DotMenuComponent = lazy(() =>
  Platform.OS !== 'web'
    ? import('./DotMenuListMobile')
    : import('./DotMenuListWeb')
)

export default function DotmenuLiat(props: Props): JSX.Element {
  return (
    <Suspense fallback={<View />}>
      <DotMenuComponent {...props} />
    </Suspense>
  )
}
