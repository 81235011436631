import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import * as Location from 'expo-location'
import { AnimatedRegion, LatLng } from 'react-native-maps'

interface Props {
  children: JSX.Element
}

interface Context {
  ownLocation: LatLng | AnimatedRegion
  getLocationAsync: () => void
  farmLocation: LatLng | AnimatedRegion | undefined
  setFarmLocation: Dispatch<SetStateAction<LatLng | AnimatedRegion | undefined>>
}

const MapLocationContext = React.createContext<Context>({
  ownLocation: { latitude: 0, longitude: 0 },
  getLocationAsync: () => '',
  farmLocation: { latitude: 0, longitude: 0 },
  setFarmLocation: () => '',
})

export const MapLocationProvider: React.FC<Props> = ({ children }) => {
  const [ownLocation, setOwnLocation] = useState<LatLng | AnimatedRegion>({
    latitude: 0,
    longitude: 0,
  })
  const [farmLocation, setFarmLocation] = useState<LatLng | AnimatedRegion>()

  const getLocationAsync = useCallback(async () => {
    const { status } = await Location.requestForegroundPermissionsAsync()
    if (status !== 'granted') {
      console.warn('Permission to access location was denied')
    }

    const location = await Location.getCurrentPositionAsync({})
    if (location) {
      setOwnLocation({
        latitude: +location.coords.latitude,
        longitude: +location.coords.longitude,
      })
    }
  }, [])

  return (
    <MapLocationContext.Provider
      value={{ ownLocation, getLocationAsync, farmLocation, setFarmLocation }}
    >
      {children}
    </MapLocationContext.Provider>
  )
}

export const useMapLocation = (): Context => {
  const context = React.useContext(MapLocationContext)
  if (!context) {
    throw new Error('useLocalization can be use in DimensionContext only')
  }
  return context
}
