import React, { useEffect } from 'react'
import { View, ViewStyle } from 'react-native'
import { AnyObjectSchema } from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValues: Record<string, any>
  children: JSX.Element | JSX.Element[]
  schema: AnyObjectSchema
  style?: ViewStyle
  enableReinitial?: boolean
}

export const Form = ({
  enableReinitial,
  defaultValues = {},
  children,
  schema,
  style,
}: Props): JSX.Element => {
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (enableReinitial) {
      methods.reset(defaultValues)
    }
  }, [defaultValues, enableReinitial])

  return (
    <FormProvider {...methods}>
      <View style={style}>{children}</View>
    </FormProvider>
  )
}
