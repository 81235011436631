import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import React, { useState } from 'react'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import Container from 'components/Container'
import Content from 'components/Content'
import FarmBg from 'assets/images/FarmBg.png'
import Header from 'components/Header'
import { useLocalization } from 'contexts/LocalizationContext'
import Text from 'components/Text'
import theme from 'theme/index'
import { tabData } from './staticData'
import ListModule from './ListModule'
import ListProgress from './ListProgress'
import { useDimension } from 'contexts/DimensionContext'
import { RouteProp } from '@react-navigation/native'

interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<{ params: { farmId: string; title: string } }, 'params'>
}

export default function DetailFarmAreaScreen({
  route,
  navigation,
}: Props): JSX.Element {
  const { width } = useDimension()
  const height = (856 / 1500) * width
  const [image] = useState<string>()
  const [type, setType] = useState<string>('module')
  const { t } = useLocalization()
  return (
    <Container>
      <Header title={route.params.title} showBack />
      <View
        style={{
          height: height - 115,
          justifyContent: 'flex-end',
          alignItems: 'flex-end',

          width: width - 32,
          paddingBottom: 8,
        }}
      />
      <Image
        style={{ height, width, position: 'absolute', top: 0 }}
        source={image ? { uri: image } : FarmBg}
      />
      <Content
        enabledScroll={false}
        noPadding
        style={{ backgroundColor: 'white' }}
      >
        <View style={width > 426 ? styled.tabsTablet : styled.tabs}>
          {tabData?.map((item, idx) => {
            return (
              <TouchableOpacity
                key={idx}
                style={
                  item.value === type ? styled.activeTab : styled.inActiveTab
                }
                onPress={() => setType(item.value)}
              >
                <Text
                  color={item.value === type ? 'white' : 'black'}
                  medium={item.value === type}
                >
                  {t(item.title)}
                </Text>
              </TouchableOpacity>
            )
          })}
        </View>
        {type === 'module' && (
          <ListModule navigation={navigation} route={route} />
        )}
        {type === 'progress' && <ListProgress navigation={navigation} />}
      </Content>
    </Container>
  )
}
const styled = StyleSheet.create({
  tabs: {
    padding: 16,

    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tabsTablet: {
    padding: 16,
    width: '80%',
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
  },
  activeTab: {
    height: 28,
    paddingHorizontal: 16,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.color.primary3,
  },
  inActiveTab: {
    height: 28,
    paddingHorizontal: 16,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
