import React from 'react'
import * as yup from 'yup'
import Container from 'components/Container'
import Content from 'components/Content'
import Background from 'components/Background'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { useLocalization } from 'contexts/LocalizationContext'
import { RouteProp } from '@react-navigation/native'
import { View, StyleSheet, Platform } from 'react-native'
import theme from 'theme/index'
import { Form, Input, Select, SubmitButton } from 'components/Form'
import { nitrogenOption, phosphorusOption, potassiumOption } from './option'
import request from 'utils/request'

export type Props = {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<
    { params: { id: string; data: any; station: string } },
    'params'
  >
}

type FormData = {
  nitrogen: string
  phosphorus: string
  potassium: string
  ph: string
}

const AnalysePreparePlotScreen = ({
  navigation,
  route,
}: Props): JSX.Element => {
  const { t } = useLocalization()
  const { id, data, station } = route.params

  const defaultValue = {
    nitrogen: data.nitrogen,
    phosphorus: data.phosphorus,
    potassium: data.potassium,
    ph: data.ph,
  }
  const schema = yup.object().shape({
    nitrogen: yup
      .string()
      .required(t('screens.StationPreparePlotScreen.required.nitrogen')),
    phosphorus: yup
      .string()
      .required(t('screens.StationPreparePlotScreen.required.phosphorus')),
    potassium: yup
      .string()
      .required(t('screens.StationPreparePlotScreen.required.potassium')),
    ph: yup
      .number()
      .required(t('screens.StationPreparePlotScreen.required.ph')),
  })

  const onSubmit = async (data: FormData) => {
    try {
      await request.post(`/workAssignment/${id}/soilSampleData`, {
        nitrogen: data.nitrogen,
        phosphorus: data.phosphorus,
        potassium: data.potassium,
        ph: parseInt(data.ph),
      })
      navigation.navigate('StationPreparePlot', { id, station })
    } catch (e) {
      console.log(JSON.stringify(e.response, null, 2))
    }
  }

  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <View style={{ width: '100%', alignItems: 'center', height: 130 }}>
        <Header
          showBack
          title={t('screens.StationPreparePlotScreen.title')}
          style={{ paddingBottom: 0 }}
        />
      </View>
      <Content
        noPadding
        enabledScroll={true}
        style={{
          backgroundColor: '#fff',
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
        }}
      >
        <Form
          defaultValues={defaultValue}
          schema={schema}
          style={{ marginTop: 30, paddingHorizontal: 30 }}
        >
          <Select
            name="nitrogen"
            label={t('screens.StationPreparePlotScreen.form.nitrogen')}
            semiBold
            option={nitrogenOption}
            itemStyle={{ marginBottom: 10 }}
            defaultValue={defaultValue.nitrogen}
          />
          <Select
            name="phosphorus"
            label={t('screens.StationPreparePlotScreen.form.phosphorus')}
            semiBold
            option={phosphorusOption}
            itemStyle={{ marginBottom: 10 }}
            defaultValue={defaultValue.phosphorus}
          />
          <Select
            name="potassium"
            label={t('screens.StationPreparePlotScreen.form.potassium')}
            semiBold
            option={potassiumOption}
            itemStyle={{ marginBottom: 10 }}
            defaultValue={defaultValue.potassium}
          />
          <Input
            name="ph"
            label={t('screens.StationPreparePlotScreen.form.ph')}
            style={styles.Input}
            keyboardType="number-pad"
            semiBold
          />
          <View style={{ marginTop: 36, marginBottom: 24 }}>
            <SubmitButton
              testID="form.stationPrepareScreen"
              title={t('Buttons.Save')}
              onSubmit={onSubmit}
            />
          </View>
        </Form>
      </Content>
    </Container>
  )
}

const styles = StyleSheet.create({
  viewHead: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 12,
    paddingTop: 12,
  },
  viewLeft: {
    flex: 0.6,
  },
  viewRight: { flex: 0.4 },
  viewBorder: { borderBottomWidth: 1, borderColor: theme.color.black6 },
  formulaHead: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 8,
    paddingTop: 8,
  },
  formula: { flex: 0.35 },
  Input: {
    ...Platform.select({
      web: {
        paddingVertical: 6,
        height: 40,
      },
      default: {
        paddingVertical: 6,
        flex: 1,
      },
    }),
  },
})

export default AnalysePreparePlotScreen
