import 'react-native-gesture-handler'
import * as React from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { useAuth } from 'contexts/AuthContext'
import AuthStack from './AuthStack'
import AppStack from './AppStack'
import { useMapLocation } from 'contexts/MapLocationContext'
import jwt_decode from 'jwt-decode'
const Navigation = (): JSX.Element => {
  const {
    state: { userToken, userRole },
    authContext: { getMe, logout },
  } = useAuth()
  const { getLocationAsync } = useMapLocation()

  const isAuth = !!userToken

  React.useEffect(() => {
    if (isAuth) {
      getMe()
      getLocationAsync()
    }
  }, [isAuth])
  React.useEffect(() => {
    if (userToken && isAuth) {
      // console.log('token', userToken)
      const interval = setInterval(() => {
        const decode: any = userToken && jwt_decode(userToken)
        const isExpired = new Date().getTime() >= decode.exp * 1000
        // const isExpired = new Date().getTime() >= decode.iat * 1000 + 60000
        // console.log({ decode })
        // console.log(new Date().getTime() + 1600000, decode.exp * 1000)

        if (isExpired) {
          logout()
        }
      }, 10000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [userToken, isAuth])
  return (
    <NavigationContainer>
      {isAuth ? <AppStack role={userRole} /> : <AuthStack />}
    </NavigationContainer>
  )
}

export default Navigation
