import React from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

export type Props = {
  enabledScroll?: boolean
  noPadding?: boolean
  children: React.ReactNode
  style?: ViewStyle
}

const Content = ({
  noPadding = false,
  enabledScroll = true,
  children,
  style,
}: Props): JSX.Element => {
  if (enabledScroll) {
    return (
      <KeyboardAwareScrollView
        style={[
          styles.contentStyle,
          noPadding ? styles.noPaddingStyle : {},
          style,
        ]}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        {children}
      </KeyboardAwareScrollView>
    )
  }
  return (
    <View
      style={[
        styles.contentStyle,
        noPadding ? styles.noPaddingStyle : {},
        style,
      ]}
    >
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  contentStyle: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    flex: 1,
  },
  noPaddingStyle: {
    paddingLeft: 0,
    paddingRight: 0,
  },
})

export default Content
