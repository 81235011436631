import React from 'react'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { useLocalization } from 'contexts/LocalizationContext'
import { RouteProp } from '@react-navigation/native'
import { Platform, View } from 'react-native'
import Text from 'components/Text'
import Button from 'components/Button'

import StationFirstStep from 'components/StationFirstStep'

export type Props = {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<
    {
      params: {
        id: string
        data: {
          workAssignmentId: string
          workStation: string
          id: string
          documentId: string
        }
      }
    },
    'params'
  >
}

const StationPreparePlotScreen = ({
  navigation,
  route,
}: Props): JSX.Element => {
  const { t } = useLocalization()
  const { id, data } = route.params

  const RenderFooter = () => {
    return (
      <View style={{ alignItems: 'center' }}>
        <Button
          style={{ width: Platform.OS === 'web' ? '100%' : '100%' }}
          arrowBack
          onPress={() =>
            navigation.navigate('StationPreparePlotDetail', { id, data })
          }
          title="ปริมาณธาตุอาหารและฮอร์โมน (ดิน)"
        />
      </View>
    )
  }

  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <View style={{ width: '100%', alignItems: 'center', height: 130 }}>
        <Header
          showBack
          title={t('screens.StationPreparePlotScreen.header')}
          style={{ paddingBottom: 0 }}
        />
        <Text
          title1
          color="white"
          style={{ width: '70%', textAlign: 'center', lineHeight: 30 }}
        >
          {t(`screens.StationPreparePlotScreen.preparePlot`)}
        </Text>
      </View>

      <StationFirstStep
        dataAssignment={{
          assignmentId: data?.workAssignmentNumber,
          projectId: data?.projectNumber,
          refId: data?.documentNumber || data?.project?.order?.documentNumber,
        }}
        // isLoading={!soilSampleData}
        FooterComponent={<RenderFooter />}
      />
    </Container>
  )
}

export default StationPreparePlotScreen
