import { View } from 'react-native'
import React from 'react'
import { InputMap } from 'components/FormArray/InputMap'
import FormKorList from './FormKorList'
import FormLumList from './FormLumList'
import FormLeafList from './FormLeafList'
import FormNorList from './FormNorList'
import FormInsectList from './FormInsectList'
import FormAnimalList from './FormAnimalList'
import FormWeedList from './FormWeedList'

const FormList = (): JSX.Element => {
  return (
    <View style={{ padding: 16 }}>
      <InputMap name={`position`} label="พิกัด" />
      <FormKorList />
      <FormLumList />
      <FormNorList />
      <FormLeafList />
      <FormInsectList />
      <FormAnimalList />
      <FormWeedList />
    </View>
  )
}

export default FormList
