export const ObjPathStation = {
  preparePlot: 'StationPreparePlotScreen',
  prepareSeeding: 'StationPlantGreenhouseScreen',
  planting: 'StationBambooPlantScreen',
  nutrientManagement: 'StationNutrientManagementScreen',
  waterManagement: 'StationWaterManagementScreen',
  plantProtection: 'StationProtectionPlantScreen',
  growthTracking: 'StationGrowthTrackingScreen',
  harvesting: 'StationHarvestingScreen',
  postHarvesting: 'StationPostHarvestScreen',
}
