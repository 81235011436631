import React, { useMemo } from 'react'
import { useWindowDimensions } from 'react-native'
import { maxWidth } from 'utils/constants'

interface Props {
  children: JSX.Element
}

interface Context {
  width: number
  height: number
}

const DimensionContext = React.createContext<Context>({
  width: 0,
  height: 0,
})

export const DimensionProvider: React.FC<Props> = ({ children }) => {
  const dimension = useWindowDimensions()
  const dimensionContext = useMemo(
    () => ({
      width: dimension.width > maxWidth ? maxWidth : dimension.width,
      height: dimension.height,
    }),
    [dimension]
  )

  return (
    <DimensionContext.Provider value={dimensionContext}>
      {children}
    </DimensionContext.Provider>
  )
}

export const useDimension = (): Context => {
  const context = React.useContext(DimensionContext)
  if (!context) {
    throw new Error('useLocalization can be use in DimensionContext only')
  }
  return context
}
