/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import Container from 'components/Container'
import Background from 'components/Background'
import Content from 'components/Content'
import Header from 'components/Header'
import { View } from 'react-native'
import {
  Form,
  Input,
  SearchInput,
  SubmitButton,
  SeachInputMachinery,
} from 'components/Form'
import InputDate from 'components/Form/InputDate'
import { useLocalization } from 'contexts/LocalizationContext'
import * as yup from 'yup'
import Card from 'components/Card'
import request from 'utils/request'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import DropDownForm from 'components/Form/DropDownForm'
import { useAuth } from 'contexts/AuthContext'
import TokenExpired from 'components/TokenExpired'
import dayjs from 'dayjs'

type Props = {
  navigation: StackNavigationHelpers
  // route: RouteProp<{ params: { user: any } }, 'params'>
}

const requestReasonOption = [
  {
    label: 'ให้น้ำแปลงเฉพาะ',
    value: 'ให้น้ำแปลงเฉพาะ',
  },
  {
    label: 'ใส่ขี้หม้อกรอง',
    value: 'ใส่ขี้หม้อกรอง',
  },
  {
    label: 'ปรับพื้นที่',
    value: 'ปรับพื้นที่',
  },
  {
    label: 'ฉีดพ่นสารกำจัดเพลี้ยอ่อนไผ่',
    value: 'ฉีดพ่นสารกำจัดเพลี้ยอ่อนไผ่',
  },
  {
    label: 'ฉีดพ่นปุ๋ย',
    value: 'ฉีดพ่นปุ๋ย',
  },
  {
    label: 'ให้น้ำแปลง',
    value: 'ให้น้ำแปลง',
  },
]

const ReserveMachineScreen = ({ navigation }: Props): JSX.Element => {
  const { t } = useLocalization()
  const {
    state: { user },
  } = useAuth()
  const [minimumDate, setMinimumDate] = useState<any>()
  const [data, setData] = useState({
    toolStore: {
      data: [],
      total: 0,
    },
    farmId: {
      data: [],
      total: 0,
    },
    itemCode: [],
  })
  const [machineryName, setMachineryName] = useState<
    string | number | boolean | undefined | null
  >()

  const schemaCompany = yup.object().shape(
    {
      name: yup
        .string()
        .nullable()
        .required(t('screens.MachineryScreen.form.name')),
      toolId: yup
        .string()
        .nullable()
        .required(t('screens.MachineryScreen.form.toolId')),
      startDate: yup
        .date()
        .when(
          'endDate',
          (endDate, schema) =>
            endDate &&
            schema?.max(
              endDate,
              t(`screens.MachineryScreen.form.startDateError`) +
                `${dayjs(endDate).format('YYYY-MM-DD')}`
            )
        )
        .required(t('screens.MachineryScreen.form.startDate')),
      endDate: yup
        .date()
        .when(
          'startDate',
          (startDate, schema) =>
            startDate &&
            schema?.min(
              startDate,
              t(`screens.MachineryScreen.form.endDateError`) +
                `${dayjs(startDate).format('YYYY-MM-DD')}`
            )
        )
        .required(t('screens.MachineryScreen.form.endDate')),
      farmId: yup.string().required(t('screens.MachineryScreen.form.farmId')),
      requestReason: yup
        .string()
        .required(t('screens.MachineryScreen.form.reason')),
    },
    [['startDate', 'endDate']]
  )
  const defaultValue = {}

  useEffect(() => {
    const getDataSelect = async () => {
      try {
        const getToolStore = await request
          .get(`/toolStore/names`)
          .then((res) => res.data)
        const getFarmId = await request
          .get(`/user/me/farm?page=1&limit=999`)
          .then((res) => res.data)
        const [toolStore, farmId] = await Promise.all([getToolStore, getFarmId])

        setData((prev) => ({ ...prev, toolStore, farmId }))
      } catch (e) {
        console.log(e)
      }
    }
    getDataSelect()
  }, [])
  useEffect(() => {
    const getItemCode = async () => {
      try {
        const res = await request
          .get(`/toolStore?page=1&limit=999&keyword=${machineryName}`)
          .then((res) => res.data)
        await console.log('machineryName', machineryName)
        await console.log('res', res)
        setData((prev) => ({ ...prev, itemCode: res.data }))
      } catch (e) {
        console.log(e)
      }
    }
    if (machineryName) {
      getItemCode()
    }
  }, [machineryName])

  const onSubmit = async (values: any) => {
    const { toolId, startDate, endDate, farmId, requestReason } = values

    try {
      await request.post(`/toolStore/request`, {
        toolId,
        startDate: dayjs(startDate).toISOString(),
        endDate: dayjs(endDate).toISOString(),
        farmId,
        requestReason,
      })
      navigation.navigate('Machinery')
      // eslint-disable-next-line prettier/prettier
    } catch (e: any) {
      if (e.response.data.message === 'Overlap Time') {
        alert('ช่วงเวลาที่คุณเลือกไม่สามารถจองได้ กรุณาจองช่วงเวลาอื่น')
      } else {
        alert('ดำเนินการผิดพลาด')
      }
    }
  }

  const { farmId, itemCode } = data

  // const machineryOption =
  //   toolStore?.data?.map((item: { name: string }) => {
  //     return { label: item?.name, value: item?.name }
  //   }) || []
  const codeOption =
    itemCode.map((item: { itemCode: string; id: string }) => {
      return { label: item?.itemCode, value: item?.id }
    }) || []
  const farmOption =
    farmId?.data?.map((item: { name: string; id: string }) => {
      return { label: item?.name, value: item?.id }
    }) || []

  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <Header title={t('screens.ReserveMachineryScreen.title')} showBack />
      <Content noPadding enabledScroll={false}>
        <Card
          borderRadiusTop
          style={{
            alignSelf: 'flex-end',
            flex: 1,
            borderBottomStartRadius: 0,
            borderBottomEndRadius: 0,
            padding: 0,
            marginTop: 16,
          }}
        >
          {user === null || !user || Object.keys(user).length === 0 ? (
            <TokenExpired />
          ) : (
            <Content
              noPadding
              enabledScroll={true}
              style={{
                flex: 1,
              }}
            >
              <Form
                defaultValues={defaultValue}
                schema={schemaCompany}
                style={{
                  paddingTop: 20,
                  paddingHorizontal: 16,
                  flex: 1,
                  justifyContent: 'space-between',
                }}
              >
                <View>
                  <SeachInputMachinery
                    name="name"
                    label={t('screens.ReserveMachineryScreen.form.machine')}
                    placeholder={'เลือกเครื่องจักร'}
                    onChangeInput={setMachineryName}
                  />
                  <DropDownForm
                    name="toolId"
                    label={'รหัส'}
                    data={codeOption}
                    placeholder={'เลือกรหัส'}
                  />

                  <InputDate
                    label={'วันที่เริ่มต้นการใช้งาน'}
                    minimumDate={new Date()}
                    testID="date"
                    name="startDate"
                    setSelectedDate={(value) => {
                      setMinimumDate(value)
                    }}
                    style={{ height: 42 }}
                  />
                  <InputDate
                    name="endDate"
                    minimumDate={minimumDate ? minimumDate : new Date()}
                    label={'วันที่สิ้นสุดการใช้งาน'}
                    testID="date"
                    style={{ height: 42 }}
                  />
                  <DropDownForm
                    name="requestReason"
                    label={'ประเภทการใช้งาน'}
                    data={requestReasonOption}
                    placeholder={'เลือก'}
                  />
                  <DropDownForm
                    name="farmId"
                    label={'ชื่อพื้นที่'}
                    data={farmOption}
                    placeholder={'ระบุชื่อพื้นที่'}
                  />
                </View>
                <View style={{ marginTop: 56, marginBottom: 24 }}>
                  <SubmitButton
                    testID="form.createCompany"
                    title={'จอง'}
                    onSubmit={onSubmit}
                  />
                </View>
              </Form>
            </Content>
          )}
        </Card>
      </Content>
    </Container>
  )
}

export default ReserveMachineScreen
