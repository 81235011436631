import { View, StyleSheet, Animated, ViewStyle } from 'react-native'
import React from 'react'
import theme from 'theme/index'
import { LinearGradient } from 'expo-linear-gradient'
import Text from 'components/Text'

interface Props {
  height?: number
  barColor?: string
  value: string
  isLinearGradient?: boolean
  style?: ViewStyle
}
export default function ProgressLine({
  height = 20,
  value,
  barColor = theme.color.success,
  isLinearGradient = false,
  style,
}: Props): JSX.Element {
  return (
    <View
      style={[isLinearGradient ? styles.background : null, { height }, style]}
    >
      {isLinearGradient ? (
        <LinearGradient
          colors={['#009DDC', '#005596FC']}
          start={{ x: 0.8, y: 0.5 }}
          style={[
            StyleSheet.absoluteFill,
            { backgroundColor: barColor, width: value, borderRadius: 10 },
          ]}
        />
      ) : (
        <Animated.View
          style={[
            StyleSheet.absoluteFill,
            {
              backgroundColor: barColor,
              width: value,
              borderRadius: 10,
              justifyContent: 'center',
              alignItems: 'flex-end',
              paddingHorizontal: 8,
            },
          ]}
        >
          <Text color="white" medium lineHeight="20px">
            {value}
          </Text>
        </Animated.View>
      )}
    </View>
  )
}
const styles = StyleSheet.create({
  background: {
    borderRadius: 20,
    backgroundColor: 'rgba(237, 237, 237, 1)',
  },
})
