import { View, ActivityIndicator } from 'react-native'
import React from 'react'
import useSWR from 'swr'
import { fetcher } from 'utils/request'
import Seperator from 'components/Seperator'
import Text from 'components/Text'
import theme from 'theme/index'

interface Props {
  id: string
  type?: string
}
const ResultAnalysis = ({ id, type = 'soil' }: Props): JSX.Element => {
  console.log('id', id)
  const { data: soilData } = useSWR(
    type === 'soil'
      ? `/workAssignment/${id}/plantSoilNutrient`
      : `/workAssignment/${id}/plantLeafNutrient`,
    fetcher
  )
  const resultData = !soilData
    ? []
    : [
        {
          label: 'pH',
          value: soilData.pH,
        },
        {
          label: 'O.M.',
          value: soilData.oM,
        },
        {
          label: 'Total N',
          value: soilData.totalN,
        },
        {
          label: 'Avai.P',
          value: soilData.avaiP,
        },
        {
          label: 'Avai.K',
          value: soilData.avaiK,
        },
        {
          label: 'Ca',
          value: soilData.Ca,
        },
        {
          label: 'Mg',
          value: soilData.Mg,
        },
        {
          label: 'K',
          value: soilData.K,
        },
        {
          label: 'Na',
          value: soilData.Na,
        },
        {
          label: 'CEC',
          value: soilData.CEC,
        },
        {
          label: 'BS',
          value: soilData.BS,
        },
      ]
  return (
    <>
      <View>
        <Text
          sub1
          medium
          style={{ paddingHorizontal: 16, marginTop: 16 }}
          color="secondary2"
        >
          ผลวิเคราะห์
        </Text>
      </View>
      {!soilData ? (
        <ActivityIndicator size="large" color={theme.color.primary1} />
      ) : (
        <>
          {resultData.map((item, index) => {
            return (
              <>
                <Seperator />
                <View
                  key={index}
                  style={{
                    paddingVertical: 8,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingHorizontal: 24,
                  }}
                >
                  <View>
                    <Text medium sub1>
                      {item.label}
                    </Text>
                  </View>
                  <View>
                    <Text sub1>{item.value}</Text>
                  </View>
                </View>
                {index === resultData.length - 1 && <Seperator />}
              </>
            )
          })}
        </>
      )}
      <View style={{ marginTop: 16 }}>
        <Text sub1 color="secondary2" medium>
          สรุปความสมบูรณ์
        </Text>
        <Seperator />
        <Text sub1>ดินขาดธาตุอาหารบางธาตุ เช่น จุลธาตุ และ ธาตุเหล็ก</Text>
        {/* รอ Api */}
      </View>
      <View
        style={{
          marginTop: 16,
        }}
      >
        <Text sub1 color="secondary2" medium>
          คำแนะนำ
        </Text>
        <Seperator />
        <Text sub1>ควรใส่ยาบำรุงดิน ชนิด AAA</Text>
        {/* รอ Api */}
      </View>
      <View style={{ marginTop: 16 }}>
        <View style={{ flexDirection: 'row' }}>
          <Text sub1 color="secondary2" medium style={{ flex: 0.2 }}>
            ใช้ปุ๋ยสูตร
          </Text>
          <Text sub1 color="secondary2" medium style={{ flex: 0.8 }}>
            จำนวน
          </Text>
        </View>
        <Seperator />

        {(soilData?.nutrients || []).map(
          (item: { quantity: number; name: string }) => {
            return (
              <>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingTop: 6,
                  }}
                >
                  <Text sub1 style={{ flex: 0.2 }}>
                    {item.name}
                  </Text>
                  <Text sub1 style={{ flex: 0.8 }}>
                    {`${item.quantity} กิโลกรัม`}
                  </Text>
                </View>
                <Seperator style={{ paddingTop: 0 }} />
              </>
            )
          }
        )}
      </View>
    </>
  )
}

export default ResultAnalysis
