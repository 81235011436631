import { StyleSheet, TouchableOpacity, View } from 'react-native'
import React, { useState } from 'react'
import Card from 'components/Card'
import dayjs from 'dayjs'
import Text from 'components/Text'

import DotMenuLiat from 'components/DotMenuLiat'
interface Props {
  onDelete?: (id: string) => void
  onEdit?: (id: string) => void
  onPressList?: (id: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any
  date?: string
}
const ListDateCard = ({
  item,
  onDelete,
  onEdit,
  date,
  onPressList,
}: Props): JSX.Element => {
  const [visible, setVisible] = useState(false)
  const openMenu = () => setVisible(true)
  const closeMenu = () => setVisible(false)
  return (
    <View style={{ marginTop: 8, width: '100%', alignItems: 'center' }}>
      <Card
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        width="98%"
      >
        <TouchableOpacity
          style={{ flex: 0.8 }}
          onPress={() => onPressList?.(item.id)}
        >
          <Text sub1>{dayjs(date).format('DD/MM/BB')}</Text>
        </TouchableOpacity>
        <DotMenuLiat
          navigation={() => {
            onEdit?.(item.id)
          }}
          onDelete={() => {
            onDelete?.(item.id)
          }}
        />
      </Card>
    </View>
  )
}

export default ListDateCard
