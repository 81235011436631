import { View, Text, ViewStyle } from 'react-native'
import React, { useEffect } from 'react'
import { ErrorMessage, Item, Label } from './components'
import { Controller, useFormContext } from 'react-hook-form'
import InputTime from 'components/InputTime'
import styled from '@emotion/native'

interface Props {
  name: string
  label?: string
  itemStyle?: ViewStyle
  isLeaveForm?: boolean
}
interface ErrorBoxProps {
  errorLong: boolean
}

const ErrorBox = styled.View<ErrorBoxProps>``
export default function InputTimeForm({
  name,
  label,
  isLeaveForm,
  itemStyle,
}: Props): JSX.Element {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext()

  return (
    <Item style={{ ...itemStyle }}>
      {label && (
        <Label
          input={isLeaveForm ? false : true}
          color={isLeaveForm ? 'black65' : 'black'}
        >
          {label}
        </Label>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <InputTime onChange={onChange} value={value} />
            </>
          )
        }}
      />
      {errors[name]?.hour && (
        <ErrorBox errorLong={false} style={{ position: 'relative' }}>
          <ErrorMessage>{errors[name].hour.message}</ErrorMessage>
        </ErrorBox>
      )}
      {errors[name]?.minute && (
        <ErrorBox errorLong={false} style={{ position: 'relative' }}>
          <ErrorMessage>{errors[name].minute.message}</ErrorMessage>
        </ErrorBox>
      )}
    </Item>
  )
}
