/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import DetailScreen from 'screens/DetailScreen'
import MainTab from './MainTab'
import SelectRoleScreen from 'screens/SelectRoleScreen'
import CreateProfileScreen from 'screens/CreateProfileScreen'
import CreateAreaScreen from 'screens/CreateAreaScreen'
import WorksheetDetailScreen from 'screens/WorksheetDetailScreen'
import WorksheetSummaryScreen from 'screens/WorksheetSummaryScreen'
import DetailFarmAreaScreen from 'screens/DetailFarmAreaScreen'

import RegisterScreen from 'screens/RegisterScreen'
import ReserveMachineScreen from 'screens/ReserveMachineScreen'
import UsageReserveScreen from 'screens/ReserveMachineScreen/usage'
import FarmerMachineryScreen from 'screens/FarmerMachineryScreen'
// import PurchaseOrderScreen from 'screens/PurchaseOrderScreen'
import PurchaseOrderDetailEditScreen from 'screens/PurchaseOrderDetailEditScreen'
import ProjectScreen from 'screens/ProjectScreen'
import ProjectDetailEditScreen from 'screens/ProjectDetailEditScreen'
import WorkAssignmentScreen from 'screens/WorkAssignmentScreen'
import WorkAssignmentDetailEditScreen from 'screens/WorkAssignmentDetailEditScreen'
import WorkAssignmentReportScreen from 'screens/WorkAssignmentReportScreen'
import StationPreparePlotScreen from 'screens/StationPreparePlotScreen'
import AnalysePreparePlotScreen from 'screens/AnalysePreparePlotScreen'
import ResultPreparePlotScreen from 'screens/ResultPreparePlotScreen'
import AnalyseNutrientScreen from 'screens/AnalyseNutrientScreen'
import ResultNutrientScreen from 'screens/ResultNutrientScreen'

import TestNutrientScreen from 'screens/TestNutrientScreen'
import ResultTestNutrientScreen from 'screens/ResultTestNutrientScreen'
import MeansOfProductionScreen from 'screens/MeansOfProductionScreen'
import MeansOfProductionEditScreen from 'screens/MeansOfProductionEditScreen'
import MeansOfProductionDetailScreen from 'screens/MeansOfProductionDetailScreen'
import StationPreparePlotDetail from 'screens/StationPreparePlotScreen/Detail'
import AddPreparePlot from 'screens/StationPreparePlotScreen/AddPreparePlot'
import StationNutrientManagementScreen from 'screens/StationNutrientManagementScreen'
import StationNutrientManagementDetail from 'screens/StationNutrientManagementScreen/Detail'
import StationPlantGreenhouseScreen from 'screens/StationPlantGreenhouseScreen'
import StationPlantGreenhouseDetail from 'screens/StationPlantGreenhouseScreen/Detail'
import EditPlantGreenhouse from 'screens/StationPlantGreenhouseScreen/EditPlantGreenhouse'
import StationBambooPlantScreen from 'screens/StationBambooPlantScreen'
import StationBambooPlantDetail from 'screens/StationBambooPlantScreen/detail'
import EditStationBambooPlant from 'screens/StationBambooPlantScreen/EditStationBambooPlant'
import AddPlantGreenhouse from 'screens/StationPlantGreenhouseScreen/AddPlantGreenhouse'
import AddStationBambooPlant from 'screens/StationBambooPlantScreen/AddStationBambooPlant'
import AddNutrientManagement from 'screens/StationNutrientManagementScreen/AddNutrientManagement'
import PestForecasting from 'screens/StationProtectionPlantScreen/PestForecasting'
import ManageProtectPlant from 'screens/StationProtectionPlantScreen/ManageProtectPlant'
import StationAnalysisMeasurementDetail from 'screens/StationProtectionPlantScreen/Detail'
import AddAnalysisMeasurement from 'screens/StationProtectionPlantScreen/AddAnalysisMeasurement'
import StationProtectionPlantScreen from 'screens/StationProtectionPlantScreen'
import StationWaterManagementScreen from 'screens/StationWaterManagementScreen'
import AddWaterMeansurementAndAnalysis from 'screens/StationWaterManagementScreen/AddWaterMeansurementAndAnalysis'
import WaterMeansurementAndAnalysis from 'screens/StationWaterManagementScreen/WaterMeansurementAndAnalysis'
import WaterManagement from 'screens/StationWaterManagementScreen/WaterManagement'
import AddWaterManagement from 'screens/StationWaterManagementScreen/AddWaterManagement'
import EditPreparePlot from 'screens/StationPreparePlotScreen/EditPreparePlot'
import EditNutrientManagement from 'screens/StationNutrientManagementScreen/EditNutrientManagement'
import StationGrowthTrackingScreen from 'screens/StationGrowthTrackingScreen'
import AddGrowthTracking from 'screens/StationGrowthTrackingScreen/AddGrowthTracking'
import StationGrowthTrackingDetail from 'screens/StationGrowthTrackingScreen/Detail'
import EditGrowthTracking from 'screens/StationGrowthTrackingScreen/EditGrowthTracking'
import ProductionEstimate from 'screens/StationGrowthTrackingScreen/ProductionEstimate'
import DeliveryManagementDetail from 'screens/StationPostHarvestingScreen/DeliveryManagementDetail'
import AddDeliveryManagement from 'screens/StationPostHarvestingScreen/DeliveryManagementDetail/AddDeliveryManagement'
import EditDeliveryManagement from 'screens/StationPostHarvestingScreen/DeliveryManagementDetail/EditDeliveryManagement'
import AddProtectionSystem from 'screens/StationPostHarvestingScreen/ProtectionSystemDetail/AddProtectionSystem'
import EditProtectionSystem from 'screens/StationPostHarvestingScreen/ProtectionSystemDetail/EditProtectionSystem'
import ProtectionSystemDetail from 'screens/StationPostHarvestingScreen/ProtectionSystemDetail'
import StationPostHarvestScreen from 'screens/StationPostHarvestingScreen'
import StationHarvestingScreen from 'screens/StationHarvestingScreen'
import StationHarvestingList from 'screens/StationHarvestingScreen/List'
import StationHarvestingAdd from 'screens/StationHarvestingScreen/AddHarvesting'
import StationHarvestingEdit from 'screens/StationHarvestingScreen/EditHarvesting'

import EditAnalysisMeasurement from 'screens/StationProtectionPlantScreen/EditAnalysisMeasurement'
import EditWaterManagement from 'screens/StationWaterManagementScreen/EditWaterManagement'
import EditWaterMeansurementAndAnalysis from 'screens/StationWaterManagementScreen/EditWaterMeansurementAndAnalysis'
import WaterManagementDetail from 'screens/StationWaterManagementScreen/WaterManagementDetail'
export type StackParamList = {
  Role: undefined
  MainTab: undefined
  Details: undefined
  CreateProfile: { role: 'company' | 'farmer' | 'extensionist' }
  CreateArea: undefined
  WorksheetDetailScreen: { id: string }
  WorksheetSummaryScreen: { id: string }
  DetailFarmArea: undefined
  DetailModule: { id: string; title: string }
  DetailModule2: { id: string; title: string }
  DetailModule3: { id: string; title: string }
  DetailModule4: { id: string; title: string }
  DetailModule5: { id: string; title: string }
  DetailModule6: { id: string; title: string }
  DetailModule7: { id: string; title: string }
  DetailModule8: { id: string; title: string }
  DetailModule9: { id: string; title: string }
  DetailModule10: { id: string; title: string }
  InfoModuleOne: { title: string; value: string }
  InfoModuleTwo: { title: string; value: string }
  InfoModuleThree: { title: string; value: string }
  InfoModuleFour: { title: string; value: string }
  InfoModuleFive: { title: string; value: string }
  InfoModuleSix: { title: string; value: string }
  InfoModuleSeven: { title: string; value: string }
  InfoModuleEight: { title: string; value: string }
  InfoModuleNine: { title: string; value: string }
  InfoModuleTen: { title: string; value: string }
  Map: undefined
  Register: undefined
  ReserveMachine: undefined
  UsageReserve: undefined
  FarmerMachinery: undefined
  PurchaseOrder: undefined
  PurchaseOrderDetail: undefined
  PurchaseOrderDetailEdit: { PoId: string }
  Project: { PoId: string }
  ProjectDetail: { PoId: string }
  ProjectDetailEdit: { PjId: string; PoId: string }
  WorkAssignment: { PoId: string; PjId: string }
  WorkAssignmentDetail: { PoId: string; PjId: string }
  WorkAssignmentDetailEdit: { PoId: string; PjId: string }
  WorkAssignmentReport: { PoId: string; PjId: string }
  AnalysePreparePlot: { id: string; data: any; station: string }
  ResultPreparePlot: { id: string; data: any; station: string }

  // StationAssign
  StationNutrientManagementScreen: { id: string; station: string }
  StationPreparePlotScreen: { id: string; station: string }
  StationPlantGreenhouseScreen: { id: string; station: string }
  StationProtectionPlantScreen: { id: string; station: string }
  StationBambooPlantScreen: { id: string; station: string }
  StationWaterManagementScreen: { id: string; station: string }
  StationGrowthTrackingScreen: { id: string; station: string }
  StationPostHarvestScreen: { id: string; station: string }
  StationHarvestingScreen: { id: string; station: string }
  StationHarvestingList: { id: string }
  StationHarvestingAdd: { assignmentId: string; lumIds: any }
  StationHarvestingEdit: { id: string; assignmentId: string; lumIds: any }

  // DetailStation
  StationPreparePlotDetail: { id: string }
  StationNutrientManagementDetail: { id: string; type: string }
  StationAnalysisMeasurementDetail: { id: string }
  StationPlantGreenhouseDetail: { id: string }
  StationBambooPlantDetail: { id: string }
  WaterMeansurementAndAnalysis: { id: string }
  WaterManagement: { id: string }
  WaterManagementDetail: { id: string }
  StationGrowthTrackingDetail: { assignmentId: string }
  // PostHarvest
  DeliveryManagementDetail: { id: string }
  ProtectionSystemDetail: { id: string }

  // Add
  AddPreparePlot: undefined
  AddNutrientManagement: { type: string }
  AddAnalysisMeasurement: undefined
  AddPlantGreenhouse: undefined
  AddStationBambooPlant: undefined
  AddWaterMeansurementAndAnalysis: undefined
  AddWaterManagement: undefined
  AddGrowthTracking: { assignmentId: string }
  AddDeliveryManagement: { id: string }
  AddProtectionSystem: { id: string }
  //Edit
  EditPreparePlot: { id: string }
  EditNutrientManagement: { id: string; type: string }
  EditGrowthTracking: { id: string }
  EditDeliveryManagement: { id: string }
  EditProtectionSystem: { id: string }
  EditAnalysisMeasurement: { id: string; editId: string; farmName: string }
  EditPlantGreenhouse: { id: string }
  EditStationBambooPlant: { id: string }
  EditWaterManagement: { id: string; editId: string }
  EditWaterMeansurementAndAnalysis: { id: string; editId: string }
  //Other StationScreen
  ManageProtectPlant: undefined
  PestForecasting: undefined
  ProductionEstimate: undefined

  AnalyseNutrient: { id: string; data: any; station: string }
  ResultNutrient: { id: string; data: any; station: string }
  TestNutrient: { id: string; data: any; station: string }
  ResultTestNutrient: { id: string; data: any; station: string }
  MeansOfProduction: { PoId: string; PjId: string }
  MeansOfProductionEdit: { PoId: string; PjId: string }
  MeansOfProductionDetail: { PoId: string; PjId: string }
}

type Props = {
  role: string | null
}

const Stack = createStackNavigator<StackParamList>()
const AppStack = ({ role }: Props): JSX.Element => {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={role !== null ? 'MainTab' : 'Role'}
      // initialRouteName={'StationPostHarvestScreen'}
    >
      <Stack.Screen name="Role" component={SelectRoleScreen} />
      <Stack.Screen name="CreateProfile" component={CreateProfileScreen} />
      <Stack.Screen name="MainTab" component={MainTab} />
      <Stack.Screen name="Details" component={DetailScreen} />
      <Stack.Screen
        name="WorksheetDetailScreen"
        component={WorksheetDetailScreen}
      />
      <Stack.Screen
        name="WorksheetSummaryScreen"
        component={WorksheetSummaryScreen}
      />

      <Stack.Screen name="CreateArea" component={CreateAreaScreen} />
      <Stack.Screen name="DetailFarmArea" component={DetailFarmAreaScreen} />

      <Stack.Screen name="Register" component={RegisterScreen} />
      <Stack.Screen name="ReserveMachine" component={ReserveMachineScreen} />
      <Stack.Screen name="UsageReserve" component={UsageReserveScreen} />
      <Stack.Screen name="FarmerMachinery" component={FarmerMachineryScreen} />

      <Stack.Screen
        name="PurchaseOrderDetailEdit"
        component={PurchaseOrderDetailEditScreen}
      />
      <Stack.Screen name="Project" component={ProjectScreen} />
      <Stack.Screen
        name="ProjectDetailEdit"
        component={ProjectDetailEditScreen}
      />
      <Stack.Screen name="WorkAssignment" component={WorkAssignmentScreen} />
      <Stack.Screen
        name="WorkAssignmentDetailEdit"
        component={WorkAssignmentDetailEditScreen}
      />
      <Stack.Screen
        name="WorkAssignmentReport"
        component={WorkAssignmentReportScreen}
      />

      <Stack.Screen
        name="AnalysePreparePlot"
        component={AnalysePreparePlotScreen}
      />
      <Stack.Screen
        name="ResultPreparePlot"
        component={ResultPreparePlotScreen}
      />
      <Stack.Screen name="AnalyseNutrient" component={AnalyseNutrientScreen} />
      <Stack.Screen name="ResultNutrient" component={ResultNutrientScreen} />
      <Stack.Screen name="TestNutrient" component={TestNutrientScreen} />
      <Stack.Screen
        name="ResultTestNutrient"
        component={ResultTestNutrientScreen}
      />
      <Stack.Screen
        name="MeansOfProduction"
        component={MeansOfProductionScreen}
      />
      <Stack.Screen
        name="MeansOfProductionEdit"
        component={MeansOfProductionEditScreen}
      />
      <Stack.Screen
        name="MeansOfProductionDetail"
        component={MeansOfProductionDetailScreen}
      />
      {/*  Station is here */}
      <Stack.Group>
        {/*  Station สถานีงาน : เตรียมดิน */}
        <Stack.Screen
          name="StationPreparePlotScreen"
          component={StationPreparePlotScreen}
        />
        <Stack.Screen
          name="StationPreparePlotDetail"
          component={StationPreparePlotDetail}
        />
        <Stack.Screen name="EditPreparePlot" component={EditPreparePlot} />
        <Stack.Screen name="AddPreparePlot" component={AddPreparePlot} />
        {/*  Station สถานีงาน : การจัดการ ธาตุอาหารพืช */}
        <Stack.Screen
          name="StationNutrientManagementScreen"
          component={StationNutrientManagementScreen}
        />
        <Stack.Screen
          name="StationNutrientManagementDetail"
          component={StationNutrientManagementDetail}
        />
        <Stack.Screen
          name="AddNutrientManagement"
          component={AddNutrientManagement}
        />
        <Stack.Screen
          name="EditNutrientManagement"
          component={EditNutrientManagement}
        />
        {/*  Station สถานีงาน : การอารักขาพืช */}
        <Stack.Screen
          name="StationProtectionPlantScreen"
          component={StationProtectionPlantScreen}
        />
        <Stack.Screen
          name="StationAnalysisMeasurementDetail"
          component={StationAnalysisMeasurementDetail}
        />
        <Stack.Screen
          name="AddAnalysisMeasurement"
          component={AddAnalysisMeasurement}
        />
        <Stack.Screen
          name="EditAnalysisMeasurement"
          component={EditAnalysisMeasurement}
        />
        <Stack.Screen name="PestForecasting" component={PestForecasting} />
        {/* Station สถานีงาน : การจัดการน้ำ */}
        <Stack.Screen
          name="StationWaterManagementScreen"
          component={StationWaterManagementScreen}
        />
        <Stack.Screen
          name="WaterMeansurementAndAnalysis"
          component={WaterMeansurementAndAnalysis}
        />
        <Stack.Screen
          name="AddWaterMeansurementAndAnalysis"
          component={AddWaterMeansurementAndAnalysis}
        />
        <Stack.Screen
          name="EditWaterMeansurementAndAnalysis"
          component={EditWaterMeansurementAndAnalysis}
        />
        <Stack.Screen name="WaterManagement" component={WaterManagement} />
        <Stack.Screen
          name="WaterManagementDetail"
          component={WaterManagementDetail}
        />
        <Stack.Screen
          name="AddWaterManagement"
          component={AddWaterManagement}
        />
        <Stack.Screen
          name="EditWaterManagement"
          component={EditWaterManagement}
        />

        <Stack.Screen
          name="ManageProtectPlant"
          component={ManageProtectPlant}
        />

        {/* สถานีงาน : การติดตาม การเจริญเติบโต */}
        <Stack.Screen
          name="StationGrowthTrackingScreen"
          component={StationGrowthTrackingScreen}
        />
        <Stack.Screen
          name="StationGrowthTrackingDetail"
          component={StationGrowthTrackingDetail}
        />
        {/*  Station สถานีงาน : การจัดการกล้าพันธุ์ในโรงเรือน */}
        <Stack.Screen name="AddGrowthTracking" component={AddGrowthTracking} />
        <Stack.Screen
          name="EditGrowthTracking"
          component={EditGrowthTracking}
        />
        <Stack.Screen
          name="ProductionEstimate"
          component={ProductionEstimate}
        />

        {/* สถานีงาน : ....*/}
        <Stack.Screen
          name="StationPlantGreenhouseScreen"
          component={StationPlantGreenhouseScreen}
        />
        <Stack.Screen
          name="StationPlantGreenhouseDetail"
          component={StationPlantGreenhouseDetail}
        />
        <Stack.Screen
          name="AddPlantGreenhouse"
          component={AddPlantGreenhouse}
        />
        <Stack.Screen
          name="EditPlantGreenhouse"
          component={EditPlantGreenhouse}
        />
        {/* station สถานีงาน : การเพาะปลูกไผ่ ลงในพื้นที่เกษตรแปลงใหญ่" */}
        <Stack.Screen
          name="StationBambooPlantScreen"
          component={StationBambooPlantScreen}
        />
        <Stack.Screen
          name="StationBambooPlantDetail"
          component={StationBambooPlantDetail}
        />
        <Stack.Screen
          name="AddStationBambooPlant"
          component={AddStationBambooPlant}
        />
        <Stack.Screen
          name="EditStationBambooPlant"
          component={EditStationBambooPlant}
        />
        {/* สถานีงาน :การบริหารจัดการหลังการเก็บเกี่ยว */}
        <Stack.Screen
          name="StationPostHarvestScreen"
          component={StationPostHarvestScreen}
        />
        <Stack.Screen
          name="StationHarvestingScreen"
          component={StationHarvestingScreen}
        />
        <Stack.Screen
          name="StationHarvestingList"
          component={StationHarvestingList}
        />
        <Stack.Screen
          name="StationHarvestingAdd"
          component={StationHarvestingAdd}
        />
        <Stack.Screen
          name="StationHarvestingEdit"
          component={StationHarvestingEdit}
        />
        {/* ระบบจัดการการขนส่ง */}
        <Stack.Screen
          name="DeliveryManagementDetail"
          component={DeliveryManagementDetail}
        />
        <Stack.Screen
          name="AddDeliveryManagement"
          component={AddDeliveryManagement}
        />
        <Stack.Screen
          name="EditDeliveryManagement"
          component={EditDeliveryManagement}
        />
        {/* ระบบจัดการการป้องกันและรักษาเนื้อไม้  */}
        <Stack.Screen
          name="ProtectionSystemDetail"
          component={ProtectionSystemDetail}
        />
        <Stack.Screen
          name="AddProtectionSystem"
          component={AddProtectionSystem}
        />
        <Stack.Screen
          name="EditProtectionSystem"
          component={EditProtectionSystem}
        />
      </Stack.Group>
    </Stack.Navigator>
  )
}

export default AppStack
