/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  View,
  Dimensions,
  ScrollView,
  Image,
  TouchableOpacity,
  Linking,
} from 'react-native'
import React from 'react'
import Container from 'components/Container'
import Header from 'components/Header'
import Background from 'components/Background'
import Content from 'components/Content'
import Card from 'components/Card'
import { Form, Input, SubmitButton } from 'components/Form'
import dayjs, { Dayjs } from 'dayjs'
import InputDate from 'components/Form/InputDate'
import InputTimeForm from 'components/Form/InputTimeForm'
import Text from 'components/Text'
import theme from 'theme/index'
import * as yup from 'yup'
import LinkIcon from 'assets/images/icons/Link.png'
import FormArrayTracking from './FormArrayTracking'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import { RouteProp } from '@react-navigation/native'
import request from 'utils/request'
export interface RootObject {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  path: string
  text: string
}
interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<
    { params: { assignmentId: string; farmData: any; link: RootObject[] } },
    'params'
  >
}
interface FormData {
  date: Dayjs
  time: {
    hour: number
    minute: number
  }
  formList: {
    koId?: string | undefined
    koHeight?: number | null
    lumRecords?: {
      id: string | undefined
    }[]
    norRecords?: {
      id: string | undefined
    }[]
    leafRecords?: {
      id: string | undefined
    }[]
  }[]
}

export default function AddGrowthTracking({
  navigation,
  route,
}: Props): JSX.Element {
  const { assignmentId, farmData, link } = route.params

  const onSubmit = async (values: FormData) => {
    try {
      await request.post(`/workAssignment/${assignmentId}/growthLogs`, {
        date: dayjs(values.date)
          .set('hour', values.time.hour)
          .set('minute', values.time.minute)
          .toISOString(),
        assignmentId: assignmentId,
        koDataSource: values.formList[0].koHeight === null ? 'manual' : 'iot',
        records: values.formList.map((item: any) => {
          return {
            koImage: item.koImage,
            koId: item.koId,
            koHeight: item.koHeight,
            koDataSource: item.koHeight === null ? 'manual' : 'iot',
            lumRecords: item.lumRecords.map((lumRecords: any) => {
              return {
                lumImage: lumRecords.lumImage,
                lumId: lumRecords.lumId,
                lumHeight: lumRecords.lumHeight,
                lumDataSource: lumRecords.lumHeight === null ? 'manual' : 'iot',
                lumOuterDiameter: lumRecords.lumOuterDiameter,
                lumDiameterDataSource:
                  lumRecords.lumOuterDiameter === null ? 'manual' : 'iot',
              }
            }),
            norRecords: item.norRecords.map((norRecords: any) => {
              return {
                lumImage: norRecords.lumImage,
                lumId: norRecords.lumId,
                lumHeight: norRecords.lumHeight,
                lumDataSource: norRecords.lumHeight === null ? 'manual' : 'iot',
              }
            }),
            leafRecords: item.leafRecords.map((leafRecords: any) => {
              return {
                leafImage: leafRecords.leafImage,
                leafLength: leafRecords.leafLength,
                lumDataSource:
                  leafRecords.leafLength === null ? 'manual' : 'iot',
              }
            }),
          }
        }),
      })
      navigation.navigate('StationGrowthTrackingDetail', {
        assignmentId: assignmentId,
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(JSON.stringify(e.response, null, 2))
    }
  }
  const schema = yup.object().shape({
    // date: yup.string().required('กรุณากรอกวันที่'),
    // time: yup.object().shape({
    //   hour: yup.string().required('กรุณากรอกชั่วโมง'),
    //   minute: yup.string().required('กรุณากรอกนาที'),
    // }),
    // factory: yup.string(),
    formList: yup.array().of(
      yup.object().shape({
        //koImage: yup.string(),
        koId: yup.string().required('กรุณากรอก กอ ID'),
        koHeight: yup.string().nullable(),
        lumRecords: yup.array().of(
          yup.object().shape({
            lumId: yup.string().required('กรุณากรอก ลำ ID'),
            //lumImage: yup.string().required('กรุณาเพิ่มรูปลำ'),
            lumHeight: yup.number().nullable(),
            //lumOuterDiameter: yup.number().nullable(),
          })
        ),
        norRecords: yup.array().of(
          yup.object().shape({
            lumId: yup.string().required('กรุณากรอก ลำ ID'),
            //lumImage: yup.string().required('กรุณาเพิ่มรูปลำ'),
            lumHeight: yup.number().nullable(),
          })
        ),
        leafRecords: yup.array().of(
          yup.object().shape({
            // id: yup.string(),
            //leafImage: yup.string().required('กรุณาเพิ่มรูปใบ'),
            leafLength: yup.number().nullable(),
          })
        ),
      })
    ),
  })

  //const schema = yup.object().shape({})
  const initialValues: FormData = {
    date: dayjs(),
    time: {
      hour: dayjs().hour(),
      minute: dayjs().minute(),
    },
    formList: [
      {
        koId: undefined,
      },
    ],
  }
  return (
    <Container>
      <Header
        showBack
        title="ระบบตรวจวัดและวิเคราะห์การเจริญเติบโตของพืช"
        style={{ paddingBottom: 0 }}
        numberOfLines={2}
        widthText={Dimensions.get('window').width - 60}
      />
      <Background typeBackground="BgDefaultHeader" />
      <Content enabledScroll={false} noPadding>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <Card style={{ paddingTop: 16, marginTop: 24 }} borderRadiusTop>
            <Form defaultValues={initialValues} schema={schema}>
              <InputDate name="date" label="วันที่" />
              <InputTimeForm name="time" label="เวลา" />
              <Input
                name="factory"
                label="แปลง/โรงงาน"
                defaultValue={farmData?.name}
                editable
              />
              <FormArrayTracking />
              <View
                style={{
                  flexDirection: 'column',
                  marginTop: 12,
                }}
              >
                {link?.map((item: RootObject, idx: any) => {
                  return (
                    <View
                      key={idx}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 12,
                      }}
                    >
                      <Image
                        source={LinkIcon}
                        style={{
                          width: 18,
                          height: 18,
                          marginRight: 8,
                        }}
                      />
                      <TouchableOpacity
                        onPress={() => Linking.openURL(item.path)}
                      >
                        <Text
                          sub1
                          color="secondary2"
                          style={{
                            textDecorationLine: 'underline',
                            textDecorationColor: theme.color.secondary2,
                          }}
                        >
                          {item.text}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )
                })}
              </View>
              <View style={{ marginTop: 32, paddingHorizontal: '8%' }}>
                <SubmitButton title="บันทึก" onSubmit={onSubmit} />
              </View>
            </Form>
          </Card>
        </ScrollView>
      </Content>
    </Container>
  )
}
