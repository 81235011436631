export enum CalendarType {
  P = 'P',
  OT = 'OT',
  A = 'A',
}
type EventColor = {
  [key: string]: string
}

const eventColors: EventColor = {
  [CalendarType.P]: '#75BD5B',
  [CalendarType.A]: '#75BD5B',
  [CalendarType.OT]: '#F3A84D',
}

export const getEventColor = (eventType: string) => {
  return eventColors[eventType]
}
