/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Text from 'components/Text'
import Background from 'components/Background'
import { useLocalization } from 'contexts/LocalizationContext'
import { Image, View, TouchableOpacity } from 'react-native'
import Calendar from 'assets/images/icons/CalendarBlankWhite.png'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { Provider } from 'react-native-paper'
import Card from 'components/Card'
import Icon from 'assets/images/icons/Truck_black.png'
import theme from 'theme/index'
import useSWR from 'swr'
import { fetcher } from 'utils/request'
import dayjs from 'dayjs'
import { useFocusEffect } from '@react-navigation/native'

export type Props = {
  navigation: BottomTabNavigationHelpers
}

const MachineryScreen = ({ navigation }: Props): JSX.Element => {
  const { t } = useLocalization()
  const { data, mutate: mutation } = useSWR(`/toolStore/myRequest`, fetcher)
  const { data: myReserve, mutate: mutationReserve } = useSWR(
    `/toolStore/myReserve`,
    fetcher
  )

  const getStatus = (status: string) => {
    if (/request/.test(status)) {
      return {
        label: 'รอการอนุมัติ',
        color: theme.color.primary1,
      }
    } else if (/not/.test(status)) {
      return {
        label: 'ไม่อนุมัติ',
        color: theme.color.error,
      }
    } else if (/reserved/.test(status)) {
      return {
        label: 'จองสำเร็จ',
        color: theme.color.reserved,
      }
    } else if (/approved/.test(status)) {
      return {
        label: 'อนุมัติ',
        color: theme.color.success,
      }
    } else if (/cancel/.test(status)) {
      return {
        label: 'ยกเลิก',
        color: theme.color.black65,
      }
    } else {
      return {
        label: '',
        color: theme.color.primary1,
      }
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      mutation('/toolStore/myRequest')
    }, [mutation])
  )
  useFocusEffect(
    React.useCallback(() => {
      mutationReserve('/toolStore/myReserve')
    }, [mutationReserve])
  )

  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          title={t('screens.MachineryScreen.title')}
          alignTitle="flex-start"
        />
        <View
          style={{
            height: 50,
            width: '100%',
            paddingLeft: 16,
            paddingRight: 16,
            marginBottom: 16,
          }}
        >
          <TouchableOpacity
            style={{
              flex: 1,
              borderWidth: 1,
              borderColor: 'white',
              borderRadius: 10,
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
            onPress={() => navigation.navigate('ReserveMachine')}
          >
            <Image
              source={Calendar}
              style={{
                width: 30,
                height: 30,
              }}
            />
            <Text color="white" sub1 bold style={{ paddingLeft: 10 }}>
              {t('screens.MachineryScreen.reservation')}
            </Text>
          </TouchableOpacity>
        </View>
        <Content>
          {data?.data?.map((item: any, index: number) => {
            return (
              <View style={{ marginBottom: 10 }} key={index}>
                <Card backgroundColor="#F2FBFF" style={{ padding: 10 }}>
                  <Text>{item.toolStore.name}</Text>
                  <Text color="secondary2" style={{ marginBottom: 5 }}>
                    {`จอง ${dayjs(item.startDate).format(
                      'DD/MM/YYYY'
                    )} - ${dayjs(item.endDate).format('DD/MM/YYYY')}`}
                  </Text>
                  <View
                    style={{
                      backgroundColor: getStatus(item.status).color,
                      alignSelf: 'flex-start',
                      paddingHorizontal: 10,
                      paddingVertical: 2,
                      borderRadius: 15,
                    }}
                  >
                    <Text color="white">{getStatus(item.status).label}</Text>
                  </View>
                </Card>
              </View>
            )
          })}
          {myReserve?.data?.map((item: any, index: number) => {
            return (
              <View style={{ marginBottom: 10 }} key={index}>
                <Card
                  Pressable={item.status === 'reserved' ? true : false}
                  activeOpacity={0.8}
                  style={{ padding: 10 }}
                  onPress={() =>
                    navigation.navigate('UsageReserve', {
                      id: item.id,
                      startDate: item.startDate,
                      endDate: item.endDate,
                      startUsageDate: item.startUsageDate,
                      endUsageDate: item.endUsageDate,
                    })
                  }
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Image
                      source={Icon}
                      style={{
                        width: 35,
                        height: 35,
                      }}
                    />
                    <View style={{ marginLeft: 10 }}>
                      <Text>{item.toolStore.name}</Text>
                      <Text color="success">{`ใช้งาน : ${dayjs(
                        item.startDate
                      ).format('DD/MM/YYYY')} - ${dayjs(item.endDate).format(
                        'DD/MM/YYYY'
                      )}`}</Text>
                      {item.status === 'reserved' && (
                        <Text color="primary1">
                          {item.startUsageDate
                            ? `วันที่ใช้งานจริง : ${dayjs(
                                item.startUsageDate
                              ).format('DD/MM/YYYY')} - ${dayjs(
                                item.endUsageDate
                              ).format('DD/MM/YYYY')}`
                            : 'วันที่ใช้งานจริง : -'}
                        </Text>
                      )}
                    </View>
                  </View>
                  <View
                    style={{
                      backgroundColor: getStatus(item.status).color,
                      alignSelf: 'flex-start',
                      paddingHorizontal: 10,
                      paddingVertical: 2,
                      borderRadius: 15,
                      marginTop: 5,
                      marginLeft: 45,
                    }}
                  >
                    <Text color="white">{getStatus(item.status).label}</Text>
                  </View>
                  {item.rejectReason && (
                    <View
                      style={{
                        alignSelf: 'flex-start',
                        borderRadius: 15,
                        marginTop: 5,
                        marginLeft: 45,
                      }}
                    >
                      <Text color="black40">หมายเหตุ: {item.rejectReason}</Text>
                    </View>
                  )}
                </Card>
              </View>
            )
          })}
        </Content>
      </Container>
    </Provider>
  )
}

export default MachineryScreen
