import React, { useState } from 'react'
import { Image, Platform, TouchableOpacity } from 'react-native'
import { Menu, List } from 'react-native-paper'
import dotIcon from 'assets/images/DotsThree.png'
import theme from '../../theme'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import request, { fetcher } from 'utils/request'
import useSWR from 'swr'
import { useFocusEffect } from '@react-navigation/native'

export type Props = {
  navigation: BottomTabNavigationHelpers
  farmId: string
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

const DotMenu = ({ navigation, farmId, setRefresh }: Props): JSX.Element => {
  const [visible, setVisible] = useState(false)
  const [farmIdValue, setFarmIdValue] = useState(farmId)
  const openMenu = () => setVisible(true)
  const closeMenu = () => setVisible(false)
  const { data, mutate } = useSWR(`/user/me/farm/${farmIdValue}`, fetcher)

  useFocusEffect(
    React.useCallback(() => {
      mutate(`/user/me/farm/${farmIdValue}`)
      setFarmIdValue(farmId)
    }, [farmId])
  )

  const onDelete = async () => {
    try {
      await request.delete(`/user/me/farm/${farmId}`)
      alert('ลบสำเร็จ')
      setRefresh((prev) => !prev)
      closeMenu()
    } catch (e) {
      console.log(JSON.stringify(e, null, 2))
    }
  }
  const isWeb = Platform.OS === 'web'
  return isWeb ? (
    <Menu
      visible={visible}
      onDismiss={closeMenu}
      contentStyle={{
        width: 80,
      }}
      style={{ right: 16, left: 'auto' }}
      anchor={
        <TouchableOpacity onPress={openMenu}>
          <Image source={dotIcon} style={{ width: 40, height: 20 }} />
        </TouchableOpacity>
      }
    >
      <List.Item
        onPress={() => {
          navigation.navigate('CreateArea', { farmId: farmId, item: data })
        }}
        title="แก้ไข"
        style={{
          width: '100%',
          margin: 0,
          paddingLeft: 0,
          paddingVertical: 0,
        }}
        titleStyle={{
          fontFamily: 'medium',
          color: theme.color.primary1,
          textAlign: 'center',
        }}
      />
      <List.Item
        onPress={onDelete}
        title="ลบ"
        style={{
          width: '100%',
          margin: 0,
          paddingLeft: 0,
          paddingVertical: 0,
        }}
        titleStyle={{ fontFamily: 'medium', textAlign: 'center' }}
      />
    </Menu>
  ) : (
    <Menu
      visible={visible}
      onDismiss={closeMenu}
      contentStyle={{
        width: 80,
      }}
      anchor={
        <TouchableOpacity onPress={openMenu}>
          <Image source={dotIcon} style={{ width: 40, height: 20 }} />
        </TouchableOpacity>
      }
    >
      <List.Item
        onPress={() => {
          navigation.navigate('CreateArea', { farmId: farmId, item: data })
        }}
        title="แก้ไข"
        style={{
          width: '100%',
          margin: 0,
          paddingLeft: 0,
          paddingVertical: 0,
        }}
        titleStyle={{
          fontFamily: 'medium',
          color: theme.color.primary1,
          textAlign: 'center',
        }}
      />
      <List.Item
        onPress={onDelete}
        title="ลบ"
        style={{
          width: '100%',
          margin: 0,
          paddingLeft: 0,
          paddingVertical: 0,
        }}
        titleStyle={{ fontFamily: 'medium', textAlign: 'center' }}
      />
    </Menu>
  )
}
export default DotMenu
