import React from 'react'
import {
  Platform,
  StyleSheet,
  TextInputProps,
  TextStyle,
  View,
} from 'react-native'
import styled, { css } from '@emotion/native'
import { useFormContext, Controller } from 'react-hook-form'
import theme from 'theme/index'
import { ErrorMessage, Item, Label } from 'components/Form/components'
import CheckBox from 'components/CheckBox'
import Text from 'components/Text'
import BarcodeScanner from 'components/BarcodeScan'
import _ from 'lodash'
interface Props extends TextInputProps {
  unitLabelInside?: boolean
  name: string
  line?: boolean
  label?: string
  showOnFocus?: boolean
  themeWhite?: boolean
  frontInput?: boolean
  unitLabel?: string
  onChangeInput?: (value: string) => void
  selectionColor?: 'black' | 'white'
  padding?: string
  colorLabel?:
    | 'primary1'
    | 'primary2'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
  colorUnit?:
    | 'primary1'
    | 'primary2'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
  backgroundColor?: string
  testID?: string
  itemStyle?: {
    [key: string]: unknown
  }
  frontLabelStyle?: TextStyle
  unitLabelStyle?: TextStyle
  errorInside?: boolean
  bold?: boolean
  semiBold?: boolean
  showCheckBox?: boolean
  hasScanner?: boolean
  index?: number
  indexNested?: number
  nameList: string
  errorName: string
  belowInput?: boolean
  nestNameList?: string
}
interface InputProps {
  line: boolean
  isFocus?: boolean
  themeWhite?: boolean
  padding?: string
}
interface ErrorBoxProps {
  errorInside: boolean
}

const TextInput = styled.TextInput<InputProps>`
  ${({ theme, line, isFocus, themeWhite }) => {
    if (themeWhite) {
      if (isFocus && Platform.OS === 'web') {
        return css`
          border: 1px solid ${theme.color.primary1};
          outline-color: ${theme.color.primary1};
          border-radius: 10px;
          margin-top: 2px;
        `
      } else {
        return `
        border: 1px solid ${theme.color.white};
        border-radius: 10px;
        margin-top: 2px;
        `
      }
    }
    if (line) {
      return `
        border-bottom-width:1px;
        border-bottom-color: ${theme.input.borderColor};
        border-radius: 0px;
      `
    }
    if (isFocus && Platform.OS === 'web') {
      return css`
        border: 1px solid ${theme.color.primary1};
        outline-color: ${theme.color.primary1};
        border-radius: 10px;
        margin-top: 2px;
      `
    }
    if (isFocus && Platform.OS !== 'web') {
      return `
      border: 1px solid ${theme.color.primary1};
      border-radius: 10px;
      margin-top: 2px;
    `
    }
    return `
    border: 1px solid ${theme.color.black22};
    border-radius: 10px;
    margin-top: 2px;
      `
  }}
  background-color: transparent;
  /* font-family: Kanit_400Regular; */
`
const ErrorBox = styled.View<ErrorBoxProps>`
  position: ${(props) => props.errorInside && 'absolute'};
  bottom: ${(props) => props.errorInside && '5px'};
  padding: ${(props) => props.errorInside && '8px'};
`

export const InputText = ({
  name,
  line = false,
  label,
  testID,
  itemStyle = {},
  errorInside = false,
  hasScanner = false,
  colorLabel = 'black',
  selectionColor = 'black',
  style,
  unitLabelStyle,
  bold,
  semiBold,
  backgroundColor,
  unitLabel,
  belowInput = false,
  unitLabelInside = false,
  colorUnit,
  showCheckBox = false,
  index = 0,
  indexNested = 0,
  nameList,
  errorName,
  nestNameList = '',
  ...props
}: Props): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const [isFocus, setIsFocus] = React.useState(false)

  return (
    <Item style={{ ...itemStyle }}>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {label ? (
                <Label
                  input
                  color={colorLabel}
                  bold={bold ? true : false}
                  semiBold={semiBold ? true : false}
                >
                  {label}
                </Label>
              ) : null}
              {showCheckBox && !belowInput ? (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <CheckBox
                    checkBoxColor="black40"
                    iconBlack
                    onChange={() => {
                      if (value === null) {
                        return onChange(undefined)
                      }
                      onChange(null)
                    }}
                    isChecked={value === null}
                  />
                  <Text input color={colorLabel}>
                    ไม่มีข้อมูล
                  </Text>
                </View>
              ) : null}
            </View>
            <Item
              style={
                unitLabel
                  ? {
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      // marginTop: 5,
                    }
                  : itemStyle
              }
            >
              <TextInput
                testID={testID}
                editable={value !== null}
                onBlur={() => {
                  onBlur()
                  setIsFocus(false)
                }}
                onChangeText={(value) => {
                  onChange(value)
                  props.onChangeInput?.(value)
                }}
                // onChange={(value) => {
                //   onChange(value)
                // }}
                value={value === null ? '' : value}
                onFocus={() => setIsFocus(true)}
                line={line}
                textAlignVertical={props.multiline ? 'top' : 'center'}
                isFocus={value !== null ? isFocus : false}
                selectionColor={selectionColor}
                style={[
                  {
                    color: theme.color.secondary2,
                    fontFamily: 'regular',
                    paddingHorizontal: 10,
                    backgroundColor:
                      value === null ? theme.color.black40 : backgroundColor,
                    paddingVertical: 8,

                    width: Platform.OS === 'web' ? '100%' : '100%',
                    minHeight:
                      Platform.OS === 'ios' && props.numberOfLines
                        ? 20 * props.numberOfLines
                        : 'auto',
                  },
                  style,
                ]}
                {...props}
              />
              {hasScanner && Platform.OS !== 'web' ? (
                <View style={[styles.unitLabelInside, { top: 12 }]}>
                  <BarcodeScanner onChange={onChange} />
                </View>
              ) : null}
              {unitLabel ? (
                <Label
                  input
                  color={colorUnit ? colorUnit : colorLabel}
                  bold={bold ? true : false}
                  style={
                    unitLabelInside
                      ? [styles.unitLabelInside, unitLabelStyle]
                      : unitLabelStyle
                  }
                >
                  {unitLabel}
                </Label>
              ) : null}
            </Item>
            {showCheckBox && belowInput ? (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: 16,
                  }}
                >
                  <CheckBox
                    checkBoxColor="black40"
                    iconBlack
                    onChange={() => {
                      if (value === null) {
                        return onChange(undefined)
                      }
                      onChange(null)
                    }}
                    isChecked={value === null}
                  />
                  <Text input color={colorLabel}>
                    ไม่มีข้อมูล
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <CheckBox
                    checkBoxColor="black40"
                    iconBlack
                    onChange={() => {
                      onChange('')
                    }}
                    isChecked={value !== null}
                  />
                  <Text input color={colorLabel}>
                    รับข้อมูลจาก IOT
                  </Text>
                </View>
              </View>
            ) : null}
            {/* {errors?.[nameList]?.[index]?.[errorName] && (
              <ErrorBox errorInside={errorInside}>
                <ErrorMessage error>
                  {errors?.[nameList]?.[index]?.[errorName].message}
                </ErrorMessage>
              </ErrorBox>
            )}
            {errors?.[nameList]?.[index]?.[nestNameList]?.[indexNested]?.[
              errorName
            ] && (
              <ErrorBox errorInside={errorInside}>
                <ErrorMessage error>
                  {
                    errors?.[nameList]?.[index]?.[nestNameList]?.[
                      indexNested
                    ]?.[errorName].message
                  }
                </ErrorMessage>
              </ErrorBox>
            )} */}
            {_.get(errors, name) && (
              <ErrorBox errorInside={errorInside}>
                <ErrorMessage error>{_.get(errors, name).message}</ErrorMessage>
              </ErrorBox>
            )}
          </>
        )}
        name={name}
      />
    </Item>
  )
}

const styles = StyleSheet.create({
  unitLabelInside: {
    position: 'absolute',
    right: 10,
    marginTop: 2,
  },
})
