/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import Text from 'components/Text'
import theme from 'theme/index'
import DropDownForm from 'components/Form/DropDownForm'
import useSWR from 'swr'
import { fetcher } from 'utils/request'

interface Props {
  setPlantItem: React.Dispatch<React.SetStateAction<any>>
  id: string | undefined
  plantEnvironment?: any
}

export default function BambooAgeDetail({
  id,
  setPlantItem,
  plantEnvironment,
}: Props): JSX.Element {
  const [bambooAge, setBambooAge] = useState<
    string | number | boolean | undefined | null
  >('')

  const { data: plantData } = useSWR(
    `/workAssignment/${id}/waterManagement/plantEnvironment`,
    fetcher
  )
  useEffect(() => {
    if (plantEnvironment) {
      setBambooAge(plantEnvironment?.age.toString())
    }
  }, [plantEnvironment])

  const ageOption = useMemo(() => {
    return plantData?.map((item: { age: number }) => {
      return { label: item.age.toString(), value: item.age.toString() }
    })
  }, [plantData])

  const plantItem = useMemo(() => {
    return plantData?.find((item: { age: number }) => {
      const ageString = item.age.toString()
      return ageString === bambooAge
    })
  }, [plantData, bambooAge])

  if (plantItem) {
    setPlantItem(plantItem)
  } else {
    setPlantItem(undefined)
  }

  return (
    <View>
      <DropDownForm
        name="age"
        label="อายุไผ่"
        data={ageOption}
        setValueDropdown={setBambooAge}
        placeholder={''}
      />
      <View style={[styles.row, styles.line]}>
        <Text sub1 style={styles.flex}>
          ชนิดของพืช
        </Text>
        <Text sub1 color="black85" style={styles.flex}>
          {plantItem?.type || '-'}
        </Text>
      </View>
      <View style={[styles.row, styles.line]}>
        <Text sub1 style={styles.flex}>
          ปริมาณน้ำ
        </Text>
        <Text sub1 color="black85" style={styles.flex}>
          {plantItem ? (plantItem?.waterAmount || 0) + ' มม. ต่อวัน' : '-'}
        </Text>
      </View>
      <View style={[styles.row, styles.line]}>
        <Text sub1 style={styles.flex}>
          อุณหภูมิ
        </Text>
        <Text sub1 color="black85" style={styles.flex}>
          {plantItem
            ? (plantItem?.minTemperature || 0) +
              ' - ' +
              (plantItem?.maxTemperature || 0) +
              ' องศา'
            : '-'}
        </Text>
      </View>
      <View style={styles.row}>
        <Text sub1 style={styles.flex}>
          ความชื้นสัมพัทธ์
        </Text>
        <Text sub1 color="black85" style={styles.flex}>
          {plantItem ? plantItem?.humidity + '%' : '-'}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  flex: {
    flex: 0.5,
  },
  line: {
    borderBottomColor: theme.color.black6,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    paddingBottom: 10,
    marginBottom: 10,
  },
})
