import { View, Text } from 'react-native'
import React from 'react'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import Content from 'components/Content'
import Card from 'components/Card'
import TokenExpired from 'components/TokenExpired'

interface Props {
  titleHeader: string
}
const CannotGetData = ({ titleHeader }: Props): JSX.Element => {
  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <Header title={titleHeader} showBack />
      <Content noPadding enabledScroll={false}>
        <Card
          borderRadiusTop
          style={{
            alignSelf: 'flex-end',
            flex: 1,
            borderBottomStartRadius: 0,
            borderBottomEndRadius: 0,
            padding: 0,
            marginTop: 16,
          }}
        >
          <TokenExpired />
        </Card>
      </Content>
    </Container>
  )
}

export default CannotGetData
