import {
  Image,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import React from 'react'
import Modal from 'components/Modal/Modal'
import SuccessIcon from 'assets/images/icons/SuccessAlert.png'
import ErrorIcon from 'assets/images/icons/ErrorAlert.png'
import Close from 'assets/images/icons/Close.png'
import Text from 'components/Text'
import Button from 'components/Button'
interface Props {
  visible: boolean
  setVisibile: React.Dispatch<React.SetStateAction<boolean>>
  type?: 'success' | 'error'
  textDesc?: string
  textButton?: string
  onRequestClose?: () => void
}
const ModalAlert = ({
  visible = false,
  setVisibile,
  type = 'success',
  textDesc,
  textButton,
  onRequestClose,
}: Props): JSX.Element => {
  const Icon = type === 'success' ? SuccessIcon : ErrorIcon
  const defaultTextButton = 'ลองอีกครั้ง'
  const defaultText =
    type === 'error' ? (
      <View>
        <Text title1 color="error" center>
          ไม่สำเร็จ
        </Text>
        <Text title1 color="error" center>
          กรุณาลองใหม่ในภายหลัง
        </Text>
      </View>
    ) : (
      <Text title1 center>
        สำเร็จ
      </Text>
    )
  return (
    <Modal
      visible={visible}
      width={Platform.OS === 'web' ? '40%' : '80%'}
      onRequestClose={() => {
        setVisibile(false)
        onRequestClose?.()
      }}
    >
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
        <TouchableOpacity
          onPress={() => {
            setVisibile(false)
            onRequestClose?.()
          }}
        >
          <Image source={Close} style={{ width: 24, height: 24 }} />
        </TouchableOpacity>
      </View>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 200,
        }}
      >
        <Image source={Icon} style={styles.iconStyle} />
        <View style={{ marginTop: 16 }}>
          {textDesc ? (
            <Text
              title1
              center
              color={type === 'success' ? 'success' : 'error'}
            >
              {textDesc}
            </Text>
          ) : (
            defaultText
          )}
          {type === 'error' && (
            <View style={{ marginTop: 16 }}>
              <Button
                onPress={() => setVisibile(false)}
                title={textButton ? textButton : defaultTextButton}
              />
            </View>
          )}
        </View>
      </View>
    </Modal>
  )
}
const styles = StyleSheet.create({
  iconStyle: {
    ...Platform.select({
      web: { width: 120, height: 120 },
      default: { width: 90, height: 90 },
    }),
  },
})
export default ModalAlert
