import React, { useEffect, useState } from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Text from 'components/Text'
import Background from 'components/Background'
import { FlatList, View, Platform } from 'react-native'
import Plus from 'assets/images/icons/Plus.png'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { Provider } from 'react-native-paper'
import DotMenuLiat from 'components/DotMenuLiat'
import Card from 'components/Card'
import Button from 'components/Button'
import request from 'utils/request'
import { useFocusEffect } from '@react-navigation/native'
import dayjs from 'dayjs'
import { useLocalization } from 'contexts/LocalizationContext'
import DowloadIcon from 'assets/images/icons/Download.png'
import env from '../../../config'
import { useAuth } from 'contexts/AuthContext'
import * as Sharing from 'expo-sharing'
import * as FileSystem from 'expo-file-system'
export type Props = {
  navigation: BottomTabNavigationHelpers
}
type purchaseOrder = {
  data: RootObject[]
  page: number
  total: number
  limit: number
}
interface RootObject {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  orderDate: Date
  documentNumber: string
  customer: string
  status: string
}

const PurchaseOrderScreen = ({ navigation }: Props): JSX.Element => {
  const { t, locale } = useLocalization()
  const [refresh, setRefresh] = useState<boolean>(false)
  const limit = 10

  const [purchaseOrder, setPurchaseOrder] = useState<purchaseOrder>({
    data: [],
    page: 1,
    total: 1,
    limit: 10,
  })
  const fatchPurchaseOrder = async () => {
    try {
      const res = await request.get(
        `/purchaseOrder?limit=${limit}&page=1&isCanceled=false`
      )
      setPurchaseOrder(res?.data)
    } catch (err) {
      console.log(err)
    }
  }
  const onLoadMore = async () => {
    try {
      if (purchaseOrder?.total > purchaseOrder.data.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        try {
          const res = await request.get(
            `/purchaseOrder?limit=${limit}&page=${
              purchaseOrder?.page + 1
            }&isCanceled=false`
          )
          const data = {
            data: [...purchaseOrder.data, ...res.data.data],
            page: purchaseOrder?.page + 1,
            total: purchaseOrder.total,
            limit: purchaseOrder.limit,
          }
          setPurchaseOrder(data)
        } catch (err) {
          console.log(err)
        }
      }
    } catch (e) {
      // console.log(e)
    }
  }
  const [purchaseOrderCancel, setPurchaseOrderCancel] = useState<purchaseOrder>(
    {
      data: [],
      page: 1,
      total: 1,
      limit: 1,
    }
  )
  const fatchPurchaseOrderCancel = async () => {
    try {
      const res = await request.get(
        `/purchaseOrder?limit=${limit}&page=1&isCanceled=true`
      )
      setPurchaseOrderCancel(res?.data)
    } catch (err) {
      console.log(err)
    }
  }
  const onLoadMoreCancel = async () => {
    try {
      if (purchaseOrder?.total > purchaseOrder.data.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        try {
          const res = await request.get(
            `/purchaseOrder?limit=${limit}&page=${
              purchaseOrder?.page + 1
            }&isCanceled=false`
          )
          const data = {
            data: [...purchaseOrder.data, ...res.data.data],
            page: purchaseOrder?.page + 1,
            total: purchaseOrder.total,
            limit: purchaseOrder.limit,
          }
          setPurchaseOrder(data)
        } catch (err) {
          console.log(err)
        }
      }
    } catch (e) {
      // console.log(e)
    }
  }
  useFocusEffect(
    React.useCallback(() => {
      fatchPurchaseOrder()
      fatchPurchaseOrderCancel()
    }, [refresh])
  )
  // const { data: PurchaseOrder, mutate } = useSWR(
  //   `/purchaseOrder?limit=${limit}&page=1&isCanceled=false`,
  //   fetcher
  // )
  // const { data: PurchaseOrderCancel, mutate: mutateCancel } = useSWR(
  //   [`/purchaseOrder?limit=${limit}&page=1&isCanceled=true`, refresh],
  //   fetcher
  // )

  // useFocusEffect(
  //   React.useCallback(() => {
  //     mutate(`/purchaseOrder?limit=${limit}&page=1&isCanceled=false`)
  //   }, [mutate])
  // )
  // useFocusEffect(
  //   React.useCallback(() => {
  //     mutate(`/purchaseOrder?limit=${limit}&page=1&isCanceled=true`)
  //   }, [mutateCancel])
  // )

  // console.log('PurchaseOrder', PurchaseOrder)
  //โหลดไฟล์
  const {
    state: { userToken },
  } = useAuth()
  const config = env()
  const baseUrl = config.API_URL
  const openShareDialogAsync = async (fileUri: string) => {
    await Sharing.shareAsync(fileUri)
  }
  const onPrintSaveWeb = async (id: string, documentNumber: string) => {
    try {
      const data = await request
        .get(`/purchaseOrder/${id}/report`, { responseType: 'blob' })
        .then((res: any) => res.data)
      const url = window.URL.createObjectURL(
        new Blob([data as BlobPart], {
          type: 'application/xlsx',
        })
      )
      // window.open(url)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `ใบสั่งซื้อ-${documentNumber}.xlsx`) //or any other extension
      document.body.appendChild(link)
      link.click()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      //message.error('Failed file.')
      console.log(e)
    }
  }
  const onPrintSaveApp = async (id: string, documentNumber: string) => {
    try {
      const downloadedFile = await FileSystem.downloadAsync(
        `${baseUrl}/purchaseOrder/${id}/report`,
        FileSystem.documentDirectory + `ใบสั่งซื้อ-${documentNumber}.xlsx`,
        { headers: { Authorization: 'Bearer ' + userToken } }
      )
      console.log('Finished downloading to ', downloadedFile)
      if (downloadedFile.status === 200) {
        openShareDialogAsync(downloadedFile.uri)
      }
    } catch (e: any) {
      //message.error('Failed file.')
      console.log(e)
    }
  }

  const onPrintSave = (id: string, documentNumber: string) => {
    if (Platform.OS !== 'web') {
      onPrintSaveApp(id, documentNumber)
    } else {
      onPrintSaveWeb(id, documentNumber)
    }
  }

  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          title={t('screens.PurchaseOrderScreen.title')}
          alignTitle="center"
        />
        <View style={{ width: '90%', marginTop: 70, marginBottom: 20 }}>
          <Button
            title={t('screens.PurchaseOrderScreen.buttonPurchase')}
            onPress={() =>
              navigation.navigate('PurchaseOrderDetailEdit', {
                PoId: null,
              })
            }
            icon={Plus}
          />
        </View>
        <Content enabledScroll={true}>
          <FlatList
            nestedScrollEnabled={true}
            data={purchaseOrder?.data}
            onEndReached={onLoadMore}
            indicatorStyle="default"
            style={{ marginTop: 15 }}
            contentContainerStyle={{ paddingBottom: 0 }}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => {
              return (
                <View style={{ marginBottom: 20 }}>
                  <Card>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <View>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <Text color="black" sub1 medium>
                            {`${t('screens.PurchaseOrderScreen.document')} : ${
                              item.documentNumber
                            }`}
                          </Text>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <Text color="black85" sub1 medium>
                            {`${t(
                              'screens.PurchaseOrderScreen.datePurchase'
                            )} : `}
                          </Text>
                          <Text color="success" sub1 medium>
                            {dayjs(item.orderDate)
                              .add(locale === 'th' ? 543 : 0, 'years')
                              .format('D MMM YYYY')}
                          </Text>
                        </View>
                      </View>
                      <View>
                        <DotMenuLiat
                          navigation={() =>
                            navigation.navigate('PurchaseOrderDetailEdit', {
                              PoId: item.id,
                            })
                          }
                          onCancel={async () => {
                            await request.delete(
                              `/purchaseOrder/${item.id}/cancel`
                            )
                            setRefresh((prev) => !prev)
                          }}
                          onDelete={async () => {
                            await request.delete(`/purchaseOrder/${item.id}`)
                            setRefresh((prev) => !prev)
                          }}
                        />
                      </View>
                    </View>
                    <View
                      style={{
                        paddingTop: 10,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        icon={DowloadIcon}
                        padding="10px"
                        style={{ width: '48%' }}
                        color="primary1"
                        title={t('screens.PurchaseOrderScreen.reportButton')}
                        onPress={() => {
                          onPrintSave(item.id, item.documentNumber)
                        }} //ใส่โหลดไฟล์
                        secondary
                      />
                      <Button
                        padding="10px"
                        style={{ width: '48%' }}
                        title={t('screens.PurchaseOrderScreen.project')}
                        onPress={() => {
                          navigation.navigate('Project', {
                            PoId: item.id,
                          })
                        }}
                      />
                    </View>
                  </Card>
                </View>
              )
            }}
          />
          {purchaseOrderCancel?.data?.length > 0 ? (
            <>
              <View
                style={{
                  width: '100%',
                  marginVertical: 8,
                  borderColor: '#CDCDCD',
                  borderWidth: 0.7,
                }}
              ></View>
              <Text
                style={{
                  color: 'red',
                  paddingLeft: 15,
                }}
              >
                รายการที่ยกเลิก
              </Text>

              <FlatList
                nestedScrollEnabled={true}
                data={purchaseOrderCancel?.data}
                onEndReached={onLoadMoreCancel}
                indicatorStyle="default"
                style={{ marginTop: 15 }}
                contentContainerStyle={{ paddingBottom: 60 }}
                keyExtractor={(item) => item.id}
                renderItem={({ item }) => {
                  return (
                    <View style={{ marginBottom: 10 }}>
                      <Card>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <View>
                            <View
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <Text color="black" sub1 medium>
                                {`${t(
                                  'screens.PurchaseOrderScreen.document'
                                )} : ${item.documentNumber}`}
                              </Text>
                            </View>
                            <View
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <Text color="black85" sub1 medium>
                                {`${t(
                                  'screens.PurchaseOrderScreen.datePurchase'
                                )} : `}
                              </Text>
                              <Text color="success" sub1 medium>
                                {dayjs(item.orderDate)
                                  .add(locale === 'th' ? 543 : 0, 'years')
                                  .format('D MMM YYYY')}
                              </Text>
                            </View>
                          </View>
                          <DotMenuLiat
                            // navigation={() =>
                            //   navigation.navigate('PurchaseOrderDetailEdit', {
                            //     PoId: item.id,
                            //   })
                            // }
                            // onCancel={async () => {
                            //   await request.delete(
                            //     `/purchaseOrder/${item.id}/cancel`
                            //   )
                            //   setRefresh((prev) => !prev)
                            // }}
                            onDelete={async () => {
                              await request.delete(`/purchaseOrder/${item.id}`)
                              setRefresh((prev) => !prev)
                            }}
                          />
                        </View>
                        <View
                          style={{
                            paddingTop: 10,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Button
                            icon={DowloadIcon}
                            iconSize={16}
                            padding="10px"
                            style={{ width: '48%' }}
                            color="primary1"
                            title={t(
                              'screens.PurchaseOrderScreen.reportButton'
                            )}
                            onPress={() => {
                              onPrintSave(item.id, item.documentNumber)
                            }} //ใส่โหลดไฟล์
                            secondary
                          />
                          <Button
                            disabled
                            padding="10px"
                            style={{ width: '48%' }}
                            title={t('screens.PurchaseOrderScreen.project')}
                            onPress={() => {
                              navigation.navigate('Project', {
                                PoId: item.id,
                              })
                            }}
                          />
                        </View>
                      </Card>
                    </View>
                  )
                }}
              />
            </>
          ) : (
            <></>
          )}
        </Content>
      </Container>
    </Provider>
  )
}

export default PurchaseOrderScreen
