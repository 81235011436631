/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Text from 'components/Text'
import Background from 'components/Background'
import { Image, FlatList, View, TouchableOpacity } from 'react-native'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { Provider } from 'react-native-paper'
import Card from 'components/Card'
import { useFocusEffect } from '@react-navigation/native'
import { useLocalization } from 'contexts/LocalizationContext'
import { RouteProp } from '@react-navigation/native'
import Box from 'assets/images/icons/box2.png'
import request from 'utils/request'
export type Props = {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<
    { params: { PoId: string; title: string; id: string } },
    'params'
  >
}

export interface Inventory {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  productCode: string
  type: string
  name: string
  custodian: string
  valuePerUnit: number
  unit: string
  amount: number
  stockPurchaseDate: string
  expiredDate: string
}

export interface Datum {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  date: string
  quantity: number
  status: string
  workingDay: number
  inventoryId: string
  workAssignmentId: string
  inventory: Inventory
}

export interface RootObject {
  data: Datum[]
  total: number
  page: number
  limit: number
}

const MeansOfProductionScreen = ({ navigation, route }: Props): JSX.Element => {
  const { t } = useLocalization()
  const { id } = route.params
  const limit = 10
  const page = 1
  const [inventory, setInventory] = useState<RootObject>({
    data: [],
    page: 1,
    total: 1,
    limit: 10,
  })
  const fatchInventory = async () => {
    try {
      const res = await request.get(
        `/inventory/requests?page=${page}&limit=${limit}&workAssignmentId=${id}`
      )
      setInventory(res?.data)
    } catch (err) {
      console.log(err)
    }
  }
  const onLoadMore = async () => {
    try {
      if (inventory?.total > inventory.data.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        try {
          const res = await request.get(
            `/inventory/requests?page=${
              inventory?.page + 1
            }&limit=${limit}&workAssignmentId=${id}`
          )
          const data = {
            data: [...inventory.data, ...res.data.data],
            page: inventory?.page + 1,
            total: inventory.total,
            limit: inventory.limit,
          }
          setInventory(data)
        } catch (err) {
          console.log(err)
        }
      }
    } catch (e) {
      // console.log(e)
    }
  }
  // const { data: Inventory, mutate } = useSWR(
  //   [`/inventory/requests?page=${page}&limit=${limit}&workAssignmentId=${id}`],

  //   fetcher
  // )

  useFocusEffect(
    React.useCallback(() => {
      fatchInventory()
    }, [])
  )
  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={t('screens.MeansOfProductionScreen.title')}
          alignTitle="flex-start"
        />
        <View
          style={{
            height: 50,
            width: '100%',
            paddingLeft: 16,
            paddingRight: 16,
            marginBottom: 32,
            marginTop: 40,
          }}
        >
          <TouchableOpacity
            style={{
              flex: 1,
              padding: 8,
              borderWidth: 1,
              borderColor: 'white',
              borderRadius: 10,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() =>
              navigation.navigate('MeansOfProductionEdit', {
                id: id,
              })
            }
          >
            <Text color="white" sub1 bold style={{ paddingTop: 0 }}>
              เบิกปัจจัยการผลิต
            </Text>
          </TouchableOpacity>
        </View>
        <Content enabledScroll={true}>
          <View
            style={{
              marginBottom: 10,
            }}
          >
            <FlatList
              nestedScrollEnabled={true}
              data={inventory?.data}
              onEndReached={onLoadMore}
              indicatorStyle="default"
              style={{ marginTop: 15 }}
              contentContainerStyle={{ paddingBottom: 0 }}
              renderItem={({ item }) => {
                return (
                  <TouchableOpacity
                    onPress={() =>
                      navigation.navigate('MeansOfProductionDetail', {
                        inventoryId: item.id,
                        id: id,
                      })
                    }
                    style={{ marginBottom: 16 }}
                  >
                    <Card>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              marginRight: 16,
                            }}
                          >
                            <Image
                              source={Box}
                              style={{ width: 24, height: 24 }}
                            />
                          </View>
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              alignItems: 'flex-start',
                            }}
                          >
                            <View
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <Text color="black" sub1 medium>
                                {item?.inventory?.name}
                              </Text>
                            </View>
                            <View
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <Text color="black85" sub1 medium>
                                {item?.inventory?.productCode}
                              </Text>
                            </View>
                          </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          {item.status === 'Requirements On-Hand' ? (
                            <Text color="warning" sub1 medium>
                              • รออนุมัติ
                            </Text>
                          ) : (
                            <Text color="success" sub1 medium>
                              • อนุมัติ
                            </Text>
                          )}
                          {/* <Text color="error" sub1 medium>
                            • ไม่อนุมัติ
                          </Text>
                          <Text color="warning" sub1 medium>
                            • รออนุมัติ
                          </Text>
                          <Text color="success" sub1 medium>
                            • อนุมัติ
                          </Text> */}
                        </View>
                      </View>
                    </Card>
                  </TouchableOpacity>
                )
              }}
              keyExtractor={(item) => item?.id}
            />
          </View>
        </Content>
      </Container>
    </Provider>
  )
}

export default MeansOfProductionScreen
