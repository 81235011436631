import React, { useState } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { useLocalization } from 'contexts/LocalizationContext'
import { Input } from 'components/Form'
import Address from 'components/Form/Address'
import Text from 'components/Text'
import { User } from 'src/interface'
import CheckBoxForm from 'components/Form/CheckBoxForm'
import DropDownForm from 'components/Form/DropDownForm'
import InputDate from 'components/Form/InputDate'

type Props = {
  defaultValue: User
  display: boolean
}

export const RegisterForm = ({ defaultValue, display }: Props) => {
  const { t } = useLocalization()
  const [valueProvince, setValueProvince] = useState<string>()

  const titleOption = [
    { label: 'นาย', value: 'mr' },
    { label: 'นาง', value: 'mrs' },
    { label: 'นางสาว', value: 'miss' },
  ]

  const mainOccupation = [
    { label: '1.ประกอบอาชีพเกษตร', value: 'farmer' },
    { label: '2.รับเงินเดือนประจำ', value: 'salary man' },
    { label: '3.รับจ้างทางการเกษตร', value: 'agriculture worker' },
    { label: '4.ประกอบธุรกิจการค้า', value: 'merchant' },
    { label: '5.รับจ้างทั่วไป', value: 'worker' },
    { label: '6.อื่นๆ', value: 'other' },
  ]

  const additionalOccupation = [
    { label: '1.ประกอบอาชีพเกษตร', value: 'farmer' },
    { label: '2.รับเงินเดือนประจำ', value: 'salary man' },
    { label: '3.รับจ้างทางการเกษตร', value: 'agriculture worker' },
    { label: '4.ประกอบธุรกิจการค้า', value: 'merchant' },
    { label: '5.รับจ้างทั่วไป', value: 'worker' },
    { label: '6.อื่นๆ', value: 'other' },
    { label: '7.ไม่มีอาชีพรอง', value: 'no' },
  ]

  const sortItem = [
    { label: '1. ระบบโรงเรือน', value: 'house system', name: 'house system' },
    {
      label: '2. ระบบเซ็นเซอร์อัตโนมัติ/กึ่งอัตโนมัติ',
      value: 'sensor system',
      name: 'sensor system',
    },
    { label: '3. IOT', value: 'IOT', name: 'IOT' },
  ]

  return (
    <View
      style={{
        display: display ? 'flex' : 'none',
      }}
    >
      <DropDownForm
        name="title"
        label={t('screens.RegisterScreen.form.title')}
        data={titleOption}
        placeholder={defaultValue.title ? undefined : 'เลือกคำนำหน้าชื่อ'}
      />
      <Input
        name="firstName"
        label={t('screens.RegisterScreen.form.firstname')}
        style={styles.input}
      />
      <Input
        name="lastName"
        label={t('screens.RegisterScreen.form.lastname')}
        style={styles.input}
      />
      <Input
        name="citizenId"
        label={t('screens.RegisterScreen.form.citizenId')}
        style={styles.input}
        maxLength={13}
      />
      <InputDate
        name="birthDate"
        label={t('screens.RegisterScreen.form.birthDate')}
        testID="date"
        style={{ height: 42 }}
        iconRight={true}
        defaultValue={defaultValue.birthDate}
      />
      <Input
        name="address"
        label={t('screens.RegisterScreen.form.address')}
        style={styles.input}
      />
      <Address
        name="subDistrict"
        keyName="district"
        label={t('Address.district')}
        style={styles.input}
        province={valueProvince}
      />
      <Address
        name="district"
        keyName="amphoe"
        label={t('Address.amphoe')}
        style={styles.input}
      />
      <Address
        name="province"
        keyName="province"
        label={t('Address.province')}
        style={styles.input}
        setProvince={setValueProvince}
      />
      <Input
        name="postcode"
        label={t('screens.RegisterScreen.form.postcode')}
        style={styles.input}
        keyboardType="phone-pad"
        maxLength={5}
      />
      <Input
        name="phoneNumber"
        label={t('screens.RegisterScreen.form.tel')}
        style={styles.input}
        maxLength={10}
        keyboardType="phone-pad"
      />
      <Input
        name="email"
        label={t('screens.RegisterScreen.form.email')}
        style={styles.input}
      />
      <Text sub1 semiBold style={{ marginTop: 10 }}>
        {t('screens.RegisterScreen.1.title')}
      </Text>
      <Text body1 style={{ marginBottom: 10 }}>
        {t('screens.RegisterScreen.1.desc')}
      </Text>
      <DropDownForm
        name="mainOccupation"
        label={t('screens.RegisterScreen.1.1-1')}
        data={mainOccupation}
        placeholder={defaultValue.mainOccupation ? undefined : 'เลือกอาชีพหลัก'}
      />
      <DropDownForm
        name="additionalOccupation"
        label={t('screens.RegisterScreen.1.1-2')}
        data={additionalOccupation}
        placeholder={defaultValue.mainOccupation ? undefined : 'เลือกอาชีพรอง'}
      />
      <Text sub1 semiBold style={{ marginTop: 10 }}>
        {t('screens.RegisterScreen.2.title')}
      </Text>
      <Text body1> {t('screens.RegisterScreen.2.desc')}</Text>
      <CheckBoxForm
        data={sortItem}
        name="smartAgricultureSystem"
        defaultValue={defaultValue.smartAgricultureSystem}
      />
      <Text sub1 semiBold style={{ marginTop: 10 }}>
        {t('screens.RegisterScreen.3.title')}
      </Text>
      <Input
        name="memberNumber"
        label={t('screens.RegisterScreen.3.member')}
        style={styles.input}
        keyboardType="phone-pad"
      />
    </View>
  )
}

const styles = StyleSheet.create({
  input: {
    ...Platform.select({
      web: {
        paddingVertical: 6,
        height: 40,
      },
      default: {
        paddingVertical: 6,
      },
    }),
  },
})
