import { View } from 'react-native'
import React from 'react'
import { RouteProp } from '@react-navigation/native'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import { useLocalization } from 'contexts/LocalizationContext'
import Content from 'components/Content'
import Text from 'components/Text'
import * as yup from 'yup'
import { Form, Input, SubmitButton } from 'components/Form'
import theme from 'theme/index'
import { BASE_URL } from 'utils/request'
import axios from 'axios'
interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<{ params: { token: string } }, 'params'>
}
export default function FillNewPasswordScreen({
  navigation,
  route,
}: Props): JSX.Element {
  const { t } = useLocalization()
  const { token } = route.params
  const defaultValues = {
    password: '',
    confirmPassword: '',
  }
  const schema = yup.object().shape({
    password: yup
      .string()
      .required(t('screens.FillNewPasswordScreen.validation.password'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        t('screens.FillNewPasswordScreen.validation.validPassword')
      ),
    confirmPassword: yup
      .string()
      .required(t('screens.FillNewPasswordScreen.validation.confirmPassword'))
      .oneOf(
        [yup.ref('password'), null],
        t('screens.FillNewPasswordScreen.validation.notMatch')
      ),
  })
  const onSubmit = async (data: { password: string }) => {
    try {
      await axios.post(
        `${BASE_URL}/user/reset-password`,
        {
          newPassword: data?.password,
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      navigation.navigate('FinalFillNewPassword')
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Container>
      <Background typeBackground="BgWithCircle" />
      <Header title="" />
      <Content enabledScroll={false}>
        <View style={{ flex: 0.8, justifyContent: 'center' }}>
          <Text semiBold h1 color="white" center style={{ marginBottom: 16 }}>
            {t('screens.FillNewPasswordScreen.title')}
          </Text>
          <Form defaultValues={defaultValues} schema={schema}>
            <Input
              name="password"
              label={t('screens.FillNewPasswordScreen.form.password')}
              colorLabel="white"
              selectionColor={'white'}
              style={{ color: theme.color.white, paddingVertical: 8 }}
              themeWhite
              secureTextEntry
            />
            <Input
              name="confirmPassword"
              label={t('screens.FillNewPasswordScreen.form.confirmPassword')}
              colorLabel="white"
              selectionColor={'white'}
              style={{ color: theme.color.white, paddingVertical: 8 }}
              themeWhite
              secureTextEntry
            />
            <View style={{ marginTop: 16 }}>
              <SubmitButton
                testID="signupForm"
                onSubmit={onSubmit}
                title={t('screens.ForgetPasswordScreen.button')}
              />
            </View>
          </Form>
        </View>
      </Content>
    </Container>
  )
}
