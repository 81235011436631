import { Dimensions, View, Platform } from 'react-native'
import React, { useState } from 'react'
import Button from 'components/Button'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { Provider } from 'react-native-paper'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import StationSecondStep from 'components/StationSecondStep'
import ModalSearch from './ModalSearch'
import { useLocalization } from 'contexts/LocalizationContext'
import { RouteProp, useFocusEffect } from '@react-navigation/native'
import PlusIcon from 'assets/images/icons/Plus.png'
import request from 'utils/request'
import { useAuth } from 'contexts/AuthContext'
export interface RootObject {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  path: string
  text: string
}
interface Props {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { params: { assignmentId: string; framData: any; farmId: string } },
    'params'
  >
}
const StationHarvestingList = ({ navigation, route }: Props): JSX.Element => {
  const { t } = useLocalization()
  const [visible, setVisible] = useState(false)
  const { assignmentId, framData, farmId } = route.params
  const [refresh, setRefresh] = useState(false)
  const {
    state: { link },
  } = useAuth()
  const url: RootObject[] = []
  if (link) {
    link?.map((item: RootObject) => {
      if (item.text === 'คู่มือการเก็บเกี่ยวพืช') {
        return url.push(item)
      }
    })
  }
  const limit = 10
  const page = 1
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any>({
    data: [],
    page: 1,
    total: 1,
    limit: 10,
  })

  const fatchData = async () => {
    try {
      const res = await request.get(
        `/workAssignment/${assignmentId}/harvestLogs?limit=${limit}&page=${page}`
      )
      setData(res?.data)
    } catch (err) {
      console.log(err)
    }
  }
  useFocusEffect(
    React.useCallback(() => {
      fatchData()
    }, [refresh])
  )
  // const { data, mutate } = useSWR(
  //   () =>
  //     assignmentId
  //       ? [
  //           `/workAssignment/${assignmentId}/harvestLogs?limit=${limit}&page=1`,
  //           refresh,
  //         ]
  //       : null,
  //   fetcher
  // )
  const onLoadMore = async () => {
    try {
      if (data.data.length < data.total) {
        const res = await request.get(
          `/workAssignment/${assignmentId}/harvestLogs?limit=${limit}&page=${
            data?.page + 1
          }`
        )
        const value = {
          data: [...data.data, ...res.data.data],
          page: data?.page + 1,
          total: data.total,
          limit: data.limit,
        }
        setData(value)
      } else {
        return null
      }
    } catch (e) {
      console.log(e)
    }
  }
  const onDelete = async (deleteId: string) => {
    try {
      await request.delete(
        `/workAssignment/${assignmentId}/harvestLogs/${deleteId}`
      )
      setRefresh((prev) => !prev)
    } catch (e) {
      console.log(e)
    }
  }

  const onEdit = (editId: string) => {
    navigation.navigate('StationHarvestingEdit', {
      editId,
      assignmentId,
      framData,
      link: url,
    })
  }

  const RenderFooter = () => {
    return (
      <View style={{ marginTop: 32, width: '100%', alignItems: 'center' }}>
        <Button
          title={t('screens.StationHarvestingScreen.list.footerRender')}
          icon={PlusIcon}
          style={{ width: Platform.OS === 'web' ? '90%' : '90%' }}
          onPress={() => setVisible(true)}
        />
      </View>
    )
  }
  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={t('screens.StationHarvestingScreen.list.title')}
          style={{ paddingBottom: 0 }}
          numberOfLines={2}
          widthText={Dimensions.get('window').width - 60}
          componentBelowHeader={<RenderFooter />}
        />

        <StationSecondStep
          data={data?.data}
          onDelete={onDelete}
          onEdit={onEdit}
          onLoadMore={onLoadMore}
          //FooterComponent={<RenderFooter />}
        />
      </Container>
      <ModalSearch
        visible={visible}
        setVisible={setVisible}
        navigation={navigation}
        assignmentId={assignmentId}
        framData={framData}
        link={url}
      />
    </Provider>
  )
}

export default StationHarvestingList
