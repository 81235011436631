import React from 'react'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { useLocalization } from 'contexts/LocalizationContext'
import { RouteProp } from '@react-navigation/native'
import { Platform, View } from 'react-native'
import Text from 'components/Text'
import StationFirstStep from 'components/StationFirstStep'
import Button from 'components/Button'
// import { Grid } from 'antd-mobile'

export type Props = {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<{ params: { id: string; data: { id: string } } }, 'params'>
}

const StationNutrientManagementScreen = ({
  navigation,
  route,
}: Props): JSX.Element => {
  const { data, id } = route.params
  const { t } = useLocalization()

  const FooterRender = () => {
    return (
      <View style={{ alignItems: 'center' }}>
        <Button
          title="ปริมาณธาตุอาหารและฮอร์โมน (ใบ)"
          arrowBack
          onPress={() =>
            navigation.navigate('StationNutrientManagementDetail', {
              type: 'leaf',
              id,
            })
          }
          style={{
            width: Platform.OS === 'web' ? '100%' : '100%',
            marginBottom: 8,
          }}
        />
        <Button
          title="ปริมาณธาตุอาหารและฮอร์โมน (ดิน)"
          arrowBack
          onPress={() =>
            navigation.navigate('StationNutrientManagementDetail', {
              type: 'soil',
              id,
            })
          }
          style={{
            width: Platform.OS === 'web' ? '100%' : '100%',

            marginBottom: 8,
          }}
        />
      </View>
    )
  }
  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <View style={{ width: '100%', alignItems: 'center', height: 130 }}>
        <Header
          showBack
          title={t('screens.StationNutrientManagementScreen.header')}
          style={{ paddingBottom: 0 }}
        />
        <Text
          title1
          color="white"
          style={{ width: '70%', textAlign: 'center', lineHeight: 30 }}
        >
          {t(`screens.StationNutrientManagementScreen.nutrientManagement`)}
        </Text>
      </View>
      <StationFirstStep
        dataAssignment={{
          assignmentId: data?.workAssignmentNumber || 'assignmentId',
          refId: data?.documentNumber || data?.project?.order?.documentNumber,
          projectId: data?.projectNumber || 'documentId',
        }}
        FooterComponent={<FooterRender />}
      />
    </Container>
  )
}

export default StationNutrientManagementScreen
