import React from 'react'
import Modal from 'components/Modal/Modal'
import Text from 'components/Text'
import { Image, View, StyleSheet, TouchableOpacity } from 'react-native'
import Close from 'assets/images/icons/Close.png'
import { Form, Input, SubmitButton } from 'components/Form'
import * as yup from 'yup'
import { useLocalization } from 'contexts/LocalizationContext'
// import InputDateTest from 'components/Form/InputDateTest'
import request, { fetcher } from 'utils/request'
import useSWR from 'swr'
import InputDate from 'components/Form/InputDate'
type Props = {
  onRequestClose?: () => void
  visible: boolean
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
  workAssignmentId: string
  taskId?: string | null
}

interface Dataplan {
  date: string
  actual: string
  note: string
}
const AddWorkSchedule = ({
  visible,
  workAssignmentId,
  onRequestClose,
  setRefresh,
  taskId,
}: Props): JSX.Element => {
  const { t } = useLocalization()
  const { data: task } = useSWR(
    () =>
      taskId ? [`/workAssignment/${workAssignmentId}/task/${taskId}`] : null,
    fetcher
  )

  const defaultValue = {
    date: task?.date,
    actual: task?.actual,
  }

  const schema = yup.object().shape({
    date: yup
      .string()
      .nullable()
      .required(t('screens.WorkScheduleScreen.AddWorkSchedule.required.date')),
    actual: yup
      .string()
      .required(
        t('screens.WorkScheduleScreen.AddWorkSchedule.required.success')
      ),
  })

  const onSubmit = async (data: Dataplan) => {
    try {
      await request.patch(
        `/workAssignment/${workAssignmentId}/task/${taskId}/actual`,
        {
          actual: parseInt(data.actual),
        }
      )
      onRequestClose?.()
      setRefresh((prev) => !prev)
    } catch (e) {
      console.log(JSON.stringify(e, null, 2))
    }
  }
  return (
    <Modal
      visible={visible}
      //onRequestClose={onRequestClose}
    >
      <View style={styles.view}>
        <Text title1 color="black" medium>
          {t('screens.WorkScheduleScreen.AddWorkSchedule.title')}
        </Text>
        <TouchableOpacity
          style={{
            justifyContent: 'center',
          }}
          onPress={onRequestClose}
        >
          <Image
            source={Close}
            style={{
              width: 25,
              height: 25,
            }}
            resizeMode="contain"
          />
        </TouchableOpacity>
      </View>
      <Form enableReinitial defaultValues={defaultValue} schema={schema}>
        {/* <InputDateTest
          name="date"
          label="วันที่"
          testID="date"
          defaultValue={defaultValue?.date}
          iconRight
          style={{ height: 42 }}
        /> */}
        <InputDate
          name="date"
          label="วันที่"
          testID="date"
          defaultValue={defaultValue?.date}
          iconRight
          style={{ height: 42 }}
        />
        <Input
          name="actual"
          label={t('screens.WorkScheduleScreen.AddWorkSchedule.success')}
          keyboardType="phone-pad"
          style={{ height: 42 }}
        />
        <Input
          name="note"
          label={t('screens.WorkScheduleScreen.AddWorkSchedule.note')}
          style={{ height: 42 }}
        />
        <SubmitButton
          testID="plan"
          style={{ marginTop: 16 }}
          title="บันทึก"
          onSubmit={onSubmit}
          color="primary1"
        />
      </Form>
    </Modal>
  )
}

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  minWidth: { minWidth: 30 },
  headButton: {
    paddingTop: 10,
    paddingHorizontal: 5,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  viewButton: { width: '50%' },
})

export default AddWorkSchedule
