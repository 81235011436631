/* eslint-disable @typescript-eslint/no-explicit-any */
import { View } from 'react-native'
import React, { useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import Text from 'components/Text'
import PlusIconButton from 'components/PlusIconButton'
import DeleteButton from 'components/DeleteButton'
import ImagePickerMulti from 'components/FormArray/ImagePickerMulti'
import { InputText } from 'components/FormArray/InputText'
import Seperator from 'components/Seperator'

interface Props {
  i: number
}
const FormNestLumList = ({ i }: Props) => {
  const { control } = useFormContext()
  const { append, remove, fields } = useFieldArray({
    name: `formList[${i}].lumRecords`,
    control,
  })
  useEffect(() => {
    if (fields.length === 0) {
      append({
        lumHeight: undefined,
        lumId: undefined,
        lumImage: undefined,
        lumOuterDiameter: undefined,
      })
    }
  }, [])
  return (
    <View>
      {fields.map((field: any, idx) => {
        return (
          <>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              key={field.id}
            >
              <Text sub1> {`ภาพลำ ชุดที่ ${i + 1}.${idx + 1}`} </Text>
              {idx === 0 ? (
                <PlusIconButton onPress={() => append({ id: undefined })} />
              ) : (
                <DeleteButton onPress={() => remove(idx)} />
              )}
            </View>
            <ImagePickerMulti
              index={i}
              indexNested={idx}
              errorName="image"
              nestNameList="lumRecords"
              nameList="formList"
              name={`formList[${i}].lumRecords[${idx}].lumImage`}
              defaultValue={field.lumImage}
            />
            <InputText
              name={`formList[${i}].lumRecords[${idx}].lumId`}
              label="ลำ ID"
              index={i}
              indexNested={idx}
              hasScanner
              nameList="formList"
              errorName="id"
              nestNameList="lumRecords"
              colorUnit="black85"
            />
            <InputText
              index={i}
              indexNested={idx}
              nestNameList="lumRecords"
              errorName="height"
              nameList="formList"
              name={`formList[${i}].lumRecords[${idx}].lumHeight`}
              unitLabelInside
              belowInput
              showCheckBox
              unitLabel={'เมตร'}
              label="ขนาดความสูง"
              colorUnit="black85"
            />
            <InputText
              index={i}
              indexNested={idx}
              nestNameList="lumRecords"
              errorName="height"
              nameList="formList"
              name={`formList[${i}].lumRecords[${idx}].lumOuterDiameter`}
              unitLabelInside
              belowInput
              showCheckBox
              unitLabel={'นิ้ว'}
              label="ขนาดเส้นผ่าศูนย์กลางขอบนอก"
              colorUnit="black85"
            />

            {idx !== fields.length - 1 && (
              <Seperator style={{ marginBottom: 16 }} />
            )}
          </>
        )
      })}
    </View>
  )
}

export default FormNestLumList
