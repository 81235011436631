import Constants from 'expo-constants'

import devEnv from './dev'
import prodEnv from './prod'
import stagingEnv from './staging'

interface ENV {
  GOOGLE_MAPS_API_KEY: string
  API_URL: string
}

const getEnvVars = (env = Constants?.manifest?.extra?.myEnvironment): ENV => {
  const environment = Constants?.manifest?.extra?.myEnvironment
  if (window?.location?.host === 'app-bamboo.precise.co.th') {
    return prodEnv
  }
  if (window?.location?.host === 'dev.app.bamboo.sab.witsawa.com') {
    return devEnv
  }
  if (__DEV__) {
    return stagingEnv
  } else if (environment === 'prod') {
    return prodEnv
  } else if (environment === 'staging') {
    return stagingEnv
  } else if (/version.+/.test(env || '')) {
    return prodEnv
  }
  return prodEnv
}

export default getEnvVars
