import React from 'react'
import {
  View,
  Modal as ModalRN,
  StyleSheet,
  Pressable,
  ViewStyle,
  Platform,
} from 'react-native'

type Props = {
  onRequestClose?: () => void
  visible: boolean
  children: React.ReactNode
  disableOnBlur?: boolean
  style?: ViewStyle
  width?: string
}
export default function Modal({
  visible,
  onRequestClose,
  children,
  disableOnBlur,
  style,
  width,
}: Props) {
  return (
    <ModalRN
      animationType="fade"
      onRequestClose={onRequestClose}
      visible={visible}
      transparent
    >
      <Pressable
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0,0,0,0.4)',
        }}
        onPressOut={disableOnBlur ? undefined : onRequestClose}
      >
        <View style={[[styles.modalView, style], { width }]}>{children}</View>
      </Pressable>
    </ModalRN>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  modalView: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 6,
    padding: 20,
    width: Platform.OS === 'web' ? '70%' : '90%',
    // alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  Image: {
    width: 70,
    height: 70,
  },
})
