import React from 'react'
import { TextProps } from 'react-native'
import styled from '@emotion/native'
import theme from 'theme/index'

export type Props = {
  children?: React.ReactNode
  bold?: boolean
  semiBold?: boolean
  medium?: boolean
  h1?: boolean
  h2?: boolean
  title1?: boolean
  sub1?: boolean
  body1?: boolean
  body2?: boolean
  body3?: boolean
  input?: boolean
  error?: boolean
  left?: boolean
  right?: boolean
  center?: boolean
  lineHeight?: string
  fontSize?: string
  textDecorationLine?: string
  color?:
    | 'primary1'
    | 'primary2'
    | 'primary3'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
    | 'selected'
} & TextProps

const RNText = styled.Text<Props>`
  font-family: ${({ bold, semiBold, medium }) =>
    bold ? 'bold' : semiBold ? 'semiBold' : medium ? 'medium' : 'regular'};
  color: ${({ color }) => (color ? theme.color[color] : theme.color.black)};

  ${({
    h1,
    h2,
    title1,
    sub1,
    body1,
    body2,
    body3,
    input,
    error,
    fontSize = '14px',
    lineHeight = '22px',
  }) => {
    if (h1)
      return `
        font-size: 28px;
        line-height: 42px;
        font-family: semiBold;
      `
    if (h2)
      return `
        font-size: 24px;
        line-height: 32px;
        font-family: bold;

      `
    if (title1)
      return `
        font-size: 20px;
        line-height: 36px;
      `

    if (sub1)
      return `
        font-size: 16px;
        line-height: 24px;
      `
    if (body1)
      return `
        font-size: 14px;
        line-height: 22px;
      `
    if (body2)
      return `
        font-size: 12px;
        line-height: 20px;
      `
    if (body3)
      return `
        font-size: 10px;
        line-height: 18px;
      `
    if (input)
      return `font-size: 16px;
        line-height: 30px;`
    if (error)
      return `
      font-size: 14px;
      line-height: 26px;`
    return `font-size: ${fontSize};
        line-height: ${lineHeight};`
  }};

  ${({ left, center, right }) => {
    if (left) {
      return 'text-align: left;'
    }
    if (center) {
      return 'text-align: center;'
    }
    if (right) {
      return 'text-align: right;'
    }
    return ''
  }};
`

const Text = ({ children, bold, ...props }: Props): JSX.Element => {
  return (
    <RNText bold={bold} {...props}>
      {children}
    </RNText>
  )
}

export default Text
