import React from 'react'
import { ViewStyle, TouchableOpacity, Image } from 'react-native'
import Text from 'components/Text'
import LinkIcon from 'assets/images/icons/Link.png'

export type Props = {
  title: string
  url?: string
  style?: ViewStyle
}

const Link = ({ title, url = '', style }: Props): JSX.Element => {
  return (
    <TouchableOpacity
      style={[
        { flexDirection: 'row', marginBottom: 5, paddingRight: 10 },
        style,
      ]}
      onPress={() => console.log(url)}
    >
      <Image
        source={LinkIcon}
        style={{
          width: 20,
          height: 20,
          marginRight: 5,
          resizeMode: 'contain',
        }}
      />
      <Text sub1 color="secondary2" style={{ textDecorationLine: 'underline' }}>
        {title}
      </Text>
    </TouchableOpacity>
  )
}

export default Link
