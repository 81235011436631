import { View, Platform } from 'react-native'
import React from 'react'
import { useLocalization } from 'contexts/LocalizationContext'
// import { Grid } from 'antd-mobile'
import Button from 'components/Button'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import Container from 'components/Container'
import Background from 'components/Background'
import Header from 'components/Header'
import StationFirstStep from 'components/StationFirstStep'
import Text from 'components/Text'
import { RouteProp } from '@react-navigation/native'
import useSWR from 'swr'
import { fetcher } from '../../utils/request'
interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { params: { id: string; data: any; farmData: any; farmId: string } },
    'params'
  >
}
const StationHarvestingScreen = ({ navigation, route }: Props): JSX.Element => {
  const { data, id, farmData, farmId } = route.params
  const { t } = useLocalization()
  const { data: farm } = useSWR([`/farm/${farmId}`], fetcher)
  const Data = {
    id: id,
    data: data,
    farmData: farmData ? farmData : farm,
    farmId: farmId,
  }

  const FooterRender = () => {
    return (
      <View style={{ alignItems: 'center' }}>
        <Button
          title={t('screens.StationHarvestingScreen.footerRender')}
          arrowBack
          onPress={() =>
            navigation.navigate('StationHarvestingList', {
              assignmentId: data?.id,
              framData: Data,
            })
          }
          style={{
            width: Platform.OS === 'web' ? '100%' : '100%',
            marginBottom: 8,
          }}
        />
      </View>
    )
  }
  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <View style={{ width: '100%', alignItems: 'center', height: 130 }}>
        <Header
          showBack
          title={t('screens.StationHarvestingScreen.header')}
          style={{ paddingBottom: 0 }}
        />
        <Text
          title1
          color="white"
          style={{ width: '70%', textAlign: 'center', lineHeight: 30 }}
        >
          {t('screens.StationHarvestingScreen.headerDetail')}
        </Text>
      </View>
      <StationFirstStep
        dataAssignment={{
          assignmentId: data?.workAssignmentNumber,
          refId: data?.documentNumber || data?.project?.order?.documentNumber,
          projectId: data?.projectNumber,
        }}
        FooterComponent={<FooterRender />}
      />
    </Container>
  )
}

export default StationHarvestingScreen
