import React from 'react'
import * as yup from 'yup'
import Container from 'components/Container'
import Content from 'components/Content'
import Background from 'components/Background'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { useLocalization } from 'contexts/LocalizationContext'
import { RouteProp } from '@react-navigation/native'
import { View } from 'react-native'
import { Form, Select, SubmitButton } from 'components/Form'
import Text from 'components/Text'
import { nitrogenOption, phosphorusOption, potassiumOption } from './option'
import request from 'utils/request'
import dayjs from 'dayjs'

export type Props = {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<
    { params: { id: string; data: any; station: string } },
    'params'
  >
}

type FormData = {
  nitrogenInSoil: string
  phosphorusInSoil: string
  potassiumInSoil: string
  nitrogenInLeaf: string
  phosphorusInLeaf: string
  potassiumInLeaf: string
}

const TestNutrientScreen = ({ navigation, route }: Props): JSX.Element => {
  const { t } = useLocalization()
  const { id, data, station } = route.params

  const nutrientData = data?.data[0]

  const defaultValue = {
    nitrogenInSoil: nutrientData?.nitrogenInSoil || '',
    phosphorusInSoil: nutrientData?.phosphorusInSoil || '',
    potassiumInSoil: nutrientData?.potassiumInSoil || '',
    nitrogenInLeaf: nutrientData?.nitrogenInLeaf || '',
    phosphorusInLeaf: nutrientData?.phosphorusInLeaf || '',
    potassiumInLeaf: nutrientData?.potassiumInLeaf || '',
  }

  const schema = yup.object().shape({
    nitrogenInSoil: yup
      .string()
      .required(
        t(
          'screens.StationNutrientManagementScreen.testScreen.required.nitrogen'
        )
      ),
  })

  const onSubmit = async (data: FormData) => {
    try {
      await request.post(`/workAssignment/${id}/nutrientSampleDataLog`, {
        date: dayjs(),
        nitrogenInSoil: data.nitrogenInSoil,
        phosphorusInSoil: data.phosphorusInSoil,
        potassiumInSoil: data.potassiumInSoil,
        nitrogenInLeaf: data.nitrogenInLeaf,
        phosphorusInLeaf: data.phosphorusInLeaf,
        potassiumInLeaf: data.potassiumInLeaf,
      })
      navigation.navigate('StationNutrient', { id, station })
    } catch (e) {
      console.log(JSON.stringify(e.response, null, 2))
    }
  }

  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <View style={{ width: '100%', alignItems: 'center', height: 130 }}>
        <Header
          showBack
          title={t('screens.StationNutrientManagementScreen.testScreen.title')}
          style={{ paddingBottom: 0 }}
        />
      </View>
      <Content
        noPadding
        enabledScroll={true}
        style={{
          backgroundColor: '#fff',
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
        }}
      >
        <Form
          defaultValues={defaultValue}
          schema={schema}
          style={{
            marginTop: 30,
            paddingHorizontal: 30,
            flex: 1,
            justifyContent: 'space-between',
          }}
        >
          <View>
            <Text sub1 color="secondary2" semiBold style={{ marginBottom: 10 }}>
              {t('screens.StationNutrientManagementScreen.testScreen.InLeaf')}
            </Text>
            <Select
              name="nitrogenInLeaf"
              label={t(
                'screens.StationNutrientManagementScreen.testScreen.form.nitrogen'
              )}
              semiBold
              option={nitrogenOption}
              itemStyle={{ marginBottom: 10 }}
              defaultValue={defaultValue.nitrogenInLeaf}
            />
            <Select
              name="phosphorusInLeaf"
              label={t(
                'screens.StationNutrientManagementScreen.testScreen.form.phosphorus'
              )}
              semiBold
              option={phosphorusOption}
              itemStyle={{ marginBottom: 10 }}
              defaultValue={defaultValue.phosphorusInLeaf}
            />
            <Select
              name="potassiumInLeaf"
              label={t(
                'screens.StationNutrientManagementScreen.testScreen.form.potassium'
              )}
              semiBold
              option={potassiumOption}
              itemStyle={{ marginBottom: 30 }}
              defaultValue={defaultValue.potassiumInLeaf}
            />
            <Text sub1 color="secondary2" semiBold style={{ marginBottom: 10 }}>
              {t('screens.StationNutrientManagementScreen.testScreen.InSoil')}
            </Text>
            <Select
              name="nitrogenInSoil"
              label={t(
                'screens.StationNutrientManagementScreen.testScreen.form.nitrogen'
              )}
              semiBold
              option={nitrogenOption}
              itemStyle={{ marginBottom: 10 }}
              defaultValue={defaultValue.nitrogenInSoil}
            />
            <Select
              name="phosphorusInSoil"
              label={t('screens.StationPreparePlotScreen.form.phosphorus')}
              semiBold
              option={phosphorusOption}
              itemStyle={{ marginBottom: 10 }}
              defaultValue={defaultValue.phosphorusInSoil}
            />
            <Select
              name="potassiumInSoil"
              label={t(
                'screens.StationNutrientManagementScreen.testScreen.form.potassium'
              )}
              semiBold
              option={potassiumOption}
              itemStyle={{ marginBottom: 10 }}
              defaultValue={defaultValue.potassiumInSoil}
            />
          </View>
          <View style={{ marginTop: 36, marginBottom: 24 }}>
            <SubmitButton
              testID="form.TestNutrientScreen"
              title={t('Buttons.Save')}
              onSubmit={onSubmit}
            />
          </View>
        </Form>
      </Content>
    </Container>
  )
}

export default TestNutrientScreen
