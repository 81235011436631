/* eslint-disable @typescript-eslint/no-explicit-any */
import { Image, TouchableOpacity, View } from 'react-native'
import React from 'react'
import PlusIconButton from 'components/PlusIconButton'
import Text from 'components/Text'
import { useFieldArray, useFormContext } from 'react-hook-form'
import DeleteRed from 'assets/images/icons/deleteRed.png'

import theme from 'theme/index'
import ImagePickerMulti from 'components/FormArray/ImagePickerMulti'
import { InputText } from 'components/FormArray/InputText'
import FormNestLumList from './FormNestLumList'
import FormNestNorList from './FormNestNorList'
import FormNestBaiList from './FormNestBaiList'

// interface KorObj {
//   koId: string
//   image: string
//   amountExpected?: string
//   id: string
// }
const initalData = {
  koId: undefined,
}
export default function FormList(): JSX.Element {
  const { control } = useFormContext()

  const { append, remove, fields } = useFieldArray({
    name: 'formList',
    control,
  })

  // useEffect(() => {
  //   append(initalData)
  // }, [])

  return (
    <>
      {fields.map((field: any, i) => {
        return (
          <View key={field.id}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Text
                style={{ marginVertical: 8 }}
                fontSize="18px"
                lineHeight="30px"
                semiBold
              >{`ชุดที่ ${i + 1}`}</Text>
              {i !== 0 && (
                <TouchableOpacity onPress={() => remove(i)}>
                  <Image source={DeleteRed} style={{ width: 24, height: 24 }} />
                </TouchableOpacity>
              )}
            </View>

            <View
              style={{
                padding: 16,
                borderWidth: 1,
                borderColor: theme.color.black40,
                borderRadius: 10,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                key={field.id}
              >
                <Text sub1> {`ภาพทรงกอ`} </Text>
              </View>
              <ImagePickerMulti
                index={i}
                errorName="koImage"
                nameList="formList"
                name={`formList[${i}].koImage`}
                defaultValue={field.koImage}
              />
              <InputText
                name={`formList[${i}].koId`}
                label="กอ ID"
                index={i}
                hasScanner
                nameList="formList"
                errorName="koId"
                colorUnit="black85"
              />
              <InputText
                index={i}
                belowInput
                errorName="koHeight"
                nameList="formList"
                name={`formList[${i}].koHeight`}
                unitLabelInside
                unitLabel={'เมตร'}
                showCheckBox
                label="ขนาดความสูง"
                colorUnit="black85"
              />
              <FormNestLumList i={i} />
              <FormNestNorList i={i} />
              <FormNestBaiList i={i} />
            </View>
          </View>
        )
      })}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginVertical: 8,
        }}
      >
        <Text sub1>เพิ่มข้อมูล</Text>
        <PlusIconButton onPress={() => append(initalData)} />
      </View>
    </>
  )
}
