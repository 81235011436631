import { View, ActivityIndicator, Image } from 'react-native'
import React, { useEffect } from 'react'
import theme from 'theme/index'
import Text from 'components/Text'
import Warning from 'assets/images/icons/Warning.png'
export default function TokenExpired() {
  const [isLoading, setIsLoading] = React.useState(true)
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 5000)
    return () => {
      setIsLoading(true)
    }
  }, [])
  return isLoading ? (
    <View
      style={{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ActivityIndicator size={'large'} color={theme.color.primary1} />
    </View>
  ) : (
    <View
      style={{
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20%',
      }}
    >
      <Image
        source={Warning}
        style={{
          height: 125,
          width: 140,
        }}
      />
      <Text color="black85" style={{ marginTop: 16 }} title1>
        ไม่สามารถเข้าใช้งานได้ในขณะนี้
      </Text>
      <Text color="black85" title1>
        กรุณาลองใหม่ในภายหลัง
      </Text>
    </View>
  )
}
