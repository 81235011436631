import React, { useState } from 'react'
import Container from 'components/Container'
import Background from 'components/Background'
import Content from 'components/Content'
import Header from 'components/Header'
import { View } from 'react-native'
import { Form, SubmitButton } from 'components/Form'
import { useLocalization } from 'contexts/LocalizationContext'
import * as yup from 'yup'
import Card from 'components/Card'
import { RouteProp, useNavigation } from '@react-navigation/native'
import { RegisterForm } from './RegisterForm'
import Button from 'components/Button'
import { PrivacyPolicy } from './PrivacyPolicy'
import { User } from 'src/interface'
import { ScrollView } from 'react-native-gesture-handler'
import request from 'utils/request'
import { useAuth } from 'contexts/AuthContext'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'

type Props = {
  navigation: StackNavigationHelpers
  route: RouteProp<{ params: { user: any } }, 'params'>
}

const RegisterScreen = ({ navigation, route }: Props): JSX.Element => {
  const {
    authContext: { getMe },
  } = useAuth()
  const { user } = route.params
  const { t } = useLocalization()
  const [page, setPage] = useState(1)

  const schema = yup.object().shape({
    title: yup
      .string()
      .nullable()
      .required(t('screens.RegisterScreen.required.title')),
    firstName: yup
      .string()
      .nullable()
      .required(t('screens.RegisterScreen.required.firstname')),
    lastName: yup
      .string()
      .nullable()
      .required(t('screens.RegisterScreen.required.lastname')),
    citizenId: yup
      .string()
      .nullable()
      .matches(/^\d+$/, t('screens.RegisterScreen.required.number'))
      .min(13, t('screens.RegisterScreen.required.maxLength'))
      .required(t('screens.RegisterScreen.required.citizenId')),
    birthDate: yup
      .string()
      .nullable()
      .required(t('screens.RegisterScreen.required.birthDate')),
    address: yup
      .string()
      .nullable()
      .required(t('screens.RegisterScreen.required.address')),
    subDistrict: yup
      .string()
      .nullable()
      .required(t('screens.RegisterScreen.required.subDistrict')),
    district: yup
      .string()
      .nullable()
      .required(t('screens.RegisterScreen.required.district')),
    province: yup
      .string()
      .nullable()
      .required(t('screens.RegisterScreen.required.province')),
    postcode: yup
      .string()
      .nullable()
      .required(t('screens.RegisterScreen.required.postcode')),
    phoneNumber: yup
      .string()
      .nullable()
      .required(t('screens.RegisterScreen.required.tel')),
    email: yup
      .string()
      .trim()
      .lowercase()
      .email(t('screens.LoginScreen.errors.emailInvalid'))
      .required(t('screens.LoginScreen.errors.emailRequired')),
    mainOccupation: yup
      .string()
      .nullable()
      .required(t('screens.RegisterScreen.required.mainOccupation')),
    additionalOccupation: yup
      .string()
      .nullable()
      .required(t('screens.RegisterScreen.required.additionalOccupation')),
    smartAgricultureSystem: yup
      .array()
      .min(1, t('screens.RegisterScreen.required.smartAgricultureSystem')),
    memberNumber: yup
      .string()
      .nullable()
      .required(t('screens.RegisterScreen.required.memberNumber')),
  })

  const privacySchema = yup.object().shape({
    permission: yup
      .boolean()
      .nullable()
      .required(t('screens.RegisterScreen.required.permission'))
      .oneOf([true], t('screens.RegisterScreen.required.permission')),
  })

  const schemaRegister = schema

  const defaultValue: User = {
    title: user.title,
    firstName: user.firstName,
    lastName: user.lastName,
    citizenId: user.citizenId,
    birthDate: user.birthDate,
    address: user.address,
    subDistrict: user.subDistrict,
    district: user.district,
    province: user.province,
    postcode: user.postcode,
    phoneNumber: user.phoneNumber,
    email: user.email,
    mainOccupation: user.mainOccupation,
    additionalOccupation: user.additionalOccupation,
    smartAgricultureSystem: user.smartAgricultureSystem || [],
    memberNumber: user.memberNumber,
    permission: null,
  }

  const onNext = () => {
    setPage((prev) => prev + 1)
  }

  const onSubmit = async (data: User) => {
    const {
      title,
      firstName,
      lastName,
      citizenId,
      birthDate,
      address,
      subDistrict,
      district,
      province,
      postcode,
      phoneNumber,
      email,
      mainOccupation,
      additionalOccupation,
      smartAgricultureSystem,
      memberNumber,
    } = data
    console.log(data)
    try {
      await request.patch(`/user/me`, {
        title,
        firstName,
        lastName,
        citizenId,
        birthDate,
        address,
        subDistrict,
        district,
        province,
        postcode,
        phoneNumber,
        email,
        mainOccupation,
        additionalOccupation,
        smartAgricultureSystem,
        memberNumber: parseInt(memberNumber),
      })
      alert('แก้ไขสำเร็จ')
      getMe()
      navigation.navigate('Settings')
    } catch (e) {
      console.log(JSON.stringify(e, null, 2))
    }
  }

  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <Header title={t('screens.RegisterScreen.title')} showBack />
      <Content noPadding enabledScroll={false}>
        <Card
          borderRadiusTop
          style={{
            alignSelf: 'flex-end',
            flex: 1,
            borderBottomStartRadius: 0,
            borderBottomEndRadius: 0,
            padding: 0,
            marginTop: 16,
          }}
        >
          <Form
            defaultValues={defaultValue}
            schema={page === 1 ? schema : privacySchema}
            style={{
              paddingTop: 20,
              paddingHorizontal: 16,
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            <ScrollView
              style={{
                flex: 1,
              }}
              contentContainerStyle={{
                flex: page === 2 ? 1 : 0,
                justifyContent: 'space-between',
              }}
            >
              <RegisterForm defaultValue={defaultValue} display={page === 1} />
              <PrivacyPolicy display={page === 2} />
              <View style={{ marginTop: 24, marginBottom: 24 }}>
                {page === 1 && (
                  <Button
                    testID="form.createProfile"
                    title={t('Buttons.Next')}
                    onPress={onNext}
                  />
                )}
                {page === 2 && (
                  <SubmitButton
                    testID="form.createCompany"
                    title={t('Buttons.Save')}
                    onSubmit={onSubmit}
                  />
                )}
              </View>
            </ScrollView>
          </Form>
        </Card>
      </Content>
    </Container>
  )
}

export default RegisterScreen
