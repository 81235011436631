import { RouteProp, useFocusEffect } from '@react-navigation/native'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import Background from 'components/Background'
import Button from 'components/Button'
import Container from 'components/Container'
import Header from 'components/Header'
import StationSecondStep from 'components/StationSecondStep'
// import { useLocalization } from 'contexts/LocalizationContext'
import React, { useState } from 'react'
import { Dimensions, View, Platform } from 'react-native'
import { Provider } from 'react-native-paper'
import request from 'utils/request'
import PlusIcon from 'assets/images/icons/Plus.png'
import { useAuth } from 'contexts/AuthContext'
export interface RootObject {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  path: string
  text: string
}
interface Props {
  navigation: StackNavigationHelpers
  route: RouteProp<
    {
      params: {
        id: string
      }
    },
    'params'
  >
}
function WaterManagement({ route, navigation }: Props): JSX.Element {
  // const { t } = useLocalization()
  const { id } = route.params
  const [refresh, setRefresh] = useState(false)
  const {
    state: { link },
  } = useAuth()
  const url: RootObject[] = []
  if (link) {
    link?.map((item: RootObject) => {
      if (item.text === 'ปริมาณการใช้น้ำของพืช') {
        return url.push(item)
      }
    })
  }
  const limit = 6
  const page = 1
  const [data, setData] = useState<any>({
    data: [],
    page: 1,
    total: 1,
    limit: 10,
  })
  const fatchData = async () => {
    try {
      const res = await request.get(
        `/workAssignment/${id}/waterManagement?limit=${limit}&page=${page}`
      )
      setData(res?.data)
    } catch (err) {
      console.log(err)
    }
  }
  useFocusEffect(
    React.useCallback(() => {
      fatchData()
    }, [refresh])
  )

  const onLoadMore = async () => {
    try {
      if (data.data.length < data.total) {
        const res = await request.get(
          `/workAssignment/${id}/waterManagement?limit=${limit}&page=${
            data?.page + 1
          }`
        )
        const value = {
          data: [...data.data, ...res.data.data],
          page: data?.page + 1,
          total: data.total,
          limit: data.limit,
        }
        setData(value)
      } else {
        return null
      }
    } catch (e) {
      console.log(e)
    }
  }
  // const { data: waterManagement, mutate } = useSWR(
  //   [`/workAssignment/${id}/waterManagement?limit=${limit}&page=1`, refresh],
  //   fetcher
  // )
  // useFocusEffect(
  //   React.useCallback(() => {
  //     mutate(`/workAssignment/${id}/waterAnalyze?limit=${limit}&page=1`)
  //   }, [mutate])
  // )

  const onPressList = (itemId: string) => {
    navigation.navigate('WaterManagementDetail', {
      id,
      itemId,
    })
  }
  const onEdit = (editId: string) => {
    navigation.navigate('EditWaterManagement', {
      id,
      editId,
      link: url,
    })
  }
  const onDelete = async (deleteId: string) => {
    try {
      await request.delete(`/workAssignment/${id}/waterManagement/${deleteId}`)
      setRefresh((prev) => !prev)
    } catch (e) {
      console.log(e)
    }
  }

  const RenderFooter = () => {
    return (
      <View style={{ marginTop: 32, width: '100%', alignItems: 'center' }}>
        <Button
          title="เพิ่มข้อมูล"
          icon={PlusIcon}
          style={{ width: Platform.OS === 'web' ? '90%' : '90%' }}
          onPress={() =>
            navigation.navigate('AddWaterManagement', {
              id,
              link: url,
            })
          }
        />
      </View>
    )
  }
  // const { height } = useWindowDimensions()
  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />

        <Header
          showBack
          title={'ระบบจัดการน้ำ\nในเกษตรแปลงใหญ่'}
          style={{ paddingBottom: 0 }}
          numberOfLines={2}
          widthText={Dimensions.get('window').width - 60}
          componentBelowHeader={<RenderFooter />}
        />
        <StationSecondStep
          data={data?.data}
          onLoadMore={onLoadMore}
          onDelete={onDelete}
          onEdit={onEdit}
          //FooterComponent={<RenderFooter />}
          // onPressList={onPressList}
        />
      </Container>
    </Provider>
  )
}

export default WaterManagement
