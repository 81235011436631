const color = {
  primary1: '#009DDC',
  primary2: '#566b30',
  primary3: '#E87D1E',
  secondary1: '#C1D82F',
  secondary2: '#005596',
  black: '#151618',
  black90: '#2C2D2F',
  black85: '#777777',
  black65: '#a2a2a2',
  black40: '#c0c0c0',
  black22: '#CDCDCD',
  black6: '#efefef',
  white: '#FFFFFF',
  success: '#75BD5B',
  warning: '#F3A84D',
  error: '#E14A30',
  reserved: '#7030A0',
  selected: '#259F46',
}

const input = {
  borderColor: '#d0d0d0',
}

const theme = {
  color,
  input,
}

export default theme
