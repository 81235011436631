import { View, Text } from 'react-native'
import React from 'react'
import FormList from './FormList'

const FormArrayTracking = (): JSX.Element => {
  return (
    <View>
      <FormList />
    </View>
  )
}

export default FormArrayTracking
