import { Image, Platform, View } from 'react-native'
import React from 'react'
import BgDefault from 'assets/images/BgDefault.png'
import BgDefaultHeader from 'assets/images/BgDefaultHeader.png'
import BgWithBamboo from 'assets/images/BgWithBamboo.png'
import BgWithCircle from 'assets/images/BgWithCircle.png'
import FarmBg from 'assets/images/FarmBg.png'
import theme from 'theme/index'
import { useDimension } from 'contexts/DimensionContext'
interface Props {
  typeBackground?:
    | 'BgDefault'
    | 'BgDefaultHeader'
    | 'BgWithBamboo'
    | 'BgWithCircle'
    | 'FarmBg'
    | 'BgSecondary2'
  ratio?: number
}
export default function Background({
  typeBackground = 'BgDefault',
  ratio,
}: Props): JSX.Element {
  const { width, height: h } = useDimension()

  const height =
    typeBackground === 'BgDefaultHeader'
      ? ratio
        ? ratio
        : Platform.OS === 'web'
        ? 235
        : 265
      : typeBackground === 'BgSecondary2'
      ? (1180 / 1580) * width
      : typeBackground === 'BgWithBamboo' ||
        typeBackground === 'BgWithCircle' ||
        typeBackground === 'BgDefault'
      ? (3450 / 1588) * width
      : (856 / 1500) * width
  const backgroundImage = (typeBackground: string) => {
    switch (typeBackground) {
      case 'BgDefaultHeader':
        return BgDefaultHeader
      case 'BgWithBamboo':
        return BgWithBamboo
      case 'BgWithCircle':
        return BgWithCircle

      case 'FarmBg':
        return FarmBg
      default:
        return BgDefault
    }
  }
  return (
    <View
      style={{
        alignItems: 'center',
        width: '100%',
        flex: 1,
        position: 'absolute',
        backgroundColor: 'white',
        height: Platform.OS === 'web' ? h : h + 100,
      }}
    >
      {typeBackground === 'BgSecondary2' ? (
        <View
          style={{
            backgroundColor: theme.color.secondary2,
            position: 'absolute',
            width,
            height,
            top: 0,
          }}
        />
      ) : (
        <>
          <Image
            source={backgroundImage(typeBackground)}
            style={{
              width,
              height,
              zIndex: 2,
            }}
          />
        </>
      )}
    </View>
  )
}
