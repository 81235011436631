import { View, Image, StyleSheet, Platform } from 'react-native'
import React, { useEffect, useState } from 'react'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import Container from 'components/Container'
import Header from 'components/Header'
import Content from 'components/Content'
import { useLocalization } from 'contexts/LocalizationContext'
import theme from 'theme/index'
import Text from 'components/Text'
import {
  Form,
  Input,
  SearchInput,
  SubmitButton,
  TagInput,
  Map,
  // RadioButton,
} from 'components/Form'

import * as yup from 'yup'
import FarmBg from 'assets/images/FarmBg.png'
import { useDimension } from 'contexts/DimensionContext'
import Address from 'components/Form/Address'
import request from 'utils/request'
import { RouteProp, useFocusEffect } from '@react-navigation/native'
import { useMapLocation } from 'contexts/MapLocationContext'
import ImagePicker from 'components/Form/ImagePicker'
import { getImage } from 'utils/getImage'
import { ActivityIndicator } from 'react-native'
import DropDownForm from 'components/Form/DropDownForm'
import {
  // areaZone,
  // IrrigableArea,
  // Ownership,
  // sustainableAgricultureOption,
  // WateringMethod,
  // waterSources,
  documentTypeData,
  statusLandData,
} from './option'
//import { MultiRadioGroup } from 'components/Form/MultiRadioGroup'
import { AnimatedRegion, LatLng } from 'react-native-maps'
import useSWR from 'swr'
import { fetcher } from '../../utils/request'
type Props = {
  navigation: StackNavigationHelpers
  route: RouteProp<{ params: { farmId: string; item: any } }, 'params'>
}
// type FormData = {
//   name: string
//   areaSize: string
//   address: string
//   province: string
//   admin: string
//   ownerId: string
//   coverImage: string
//   memberIds: string[]
//   map: {
//     latitude: number
//     longitude: number
//   }
//   areaZone: string
//   ownership: string
//   documentType: string
//   documentNumber: string
//   documentAreaSize: string
//   ownerName: string
//   ownerCitizenId: string
//   acceptorName: string
//   acceptorCitizenId: string
//   acceptorPosition: string
//   irrigableArea: string
//   waterSources: string[]
//   wateringMethods: string[]
//   sustainableAgriculture: string
//   subSustainableAgriculture: string
// }
type FormData = {
  name: string
  rai: number
  ngan: number
  squareWha: number
  address: string
  subDistrict: string
  district: string
  province: string
  statusLand: string
  typeOfStatusLand: string
  historyOfUse: string
  extensionistId: string
  admin: string
  ownerId: string
  coverImage: string
  memberIds: string[]
  map: {
    latitude: number
    longitude: number
  }
  areaZone: string
  ownership: string
  documentType: string
  documentNumber: string
  documentAreaSize: string
  ownerName: string
  ownerCitizenId: string
  acceptorName: string
  acceptorCitizenId: string
  acceptorPosition: string
  irrigableArea: string
  waterSources: string[]
  wateringMethods: string[]
  sustainableAgriculture: string
  subSustainableAgriculture: string
}
export default function CreateAreaScreen({
  navigation,
  route,
}: Props): JSX.Element {
  const { t } = useLocalization()
  const { farmId, item } = route.params
  const { ownLocation, farmLocation, setFarmLocation } = useMapLocation()
  const [valueProvince, setValueProvince] = useState<string>()
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  //  const [selectSub, setSelectSub] = useState<string | undefined>('')
  // const [latLng, setlatLng] = useState<LatLng | AnimatedRegion | undefined>()
  const squareWah = 400
  const limit = 10

  const [image, setImage] = useState<string | null>(
    getImage(item?.coverImage) || null
  )
  const owner = item?.farmMembers
    .filter((item: { role: string }) => item.role === 'owner')
    .map((item: { user: any }) => item.user)[0]
  const member = item?.farmMembers
    .filter((item: { role: string }) => item.role == 'admin')
    .map((item: { user: any }) => item.user)
  const requestMember = item?.requestFarmMembers.map(
    (item: { user: any }) => item.user
  )
  const role = 'extensionist'
  const { data: extensionist } = useSWR(
    [`/user?limit=${limit}&page=1&role=${role}`],
    fetcher
  )
  const rai = Math.floor(item?.areaSize / 400)
  const ngan = Math.floor((item?.areaSize - rai * 400) / 100)
  const squareWha = item?.areaSize - (rai * 400 + ngan * 100)
  const defaultValue = {
    name: item?.name || '',
    areaSize: item?.areaSize || '',
    rai: rai || 0,
    ngan: ngan || 0,
    squareWha: squareWha || 0,
    address: item?.address || '',
    province: item?.province || '',
    subDistrict: item?.subDistrict || '',
    district: item?.district || '',
    ownerId: owner || '',
    memberIds: member || '',
    coverImage: item?.coverImage,
    map: {
      latitude: item?.lat || ownLocation?.latitude || 13.736717,
      longitude: item?.lng || ownLocation?.longitude || 100.523186,
    },
    areaZone: item?.areaZone,
    lat: item?.lat,
    lng: item?.lng,
    ownership: item?.ownership,
    documentType: item?.documentType,
    documentNumber: item?.documentNumber,
    documentAreaSize: `${
      item?.documentAreaSize === undefined
        ? ''
        : item?.documentAreaSize / squareWah
    }`,
    ownerName: item?.ownerName,
    ownerCitizenId: item?.ownerCitizenId,
    acceptorName: item?.acceptorName,
    acceptorCitizenId: item?.acceptorCitizenId,
    acceptorPosition: item?.acceptorPosition,
    irrigableArea: item?.irrigableArea,
    waterSources: item?.waterSources,
    wateringMethods: item?.wateringMethods,
    sustainableAgriculture:
      item !== undefined &&
      item?.sustainableAgriculture !== 'no' &&
      item?.sustainableAgriculture !== 'agroforestry'
        ? 'mix'
        : item?.sustainableAgriculture,
    subSustainableAgriculture: `${item?.subSustainableAgriculture}${item?.sustainableAgriculture}`,
    statusLand: item?.statusLand,
    historyOfUse: item?.historyOfUse,
    extensionistId: item?.extensionistId,
    typeOfStatusLand: item?.typeOfStatusLand,
  }

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(t('screens.CreateAreaScreen.required.nameArea')),
    rai: yup.number().typeError('กรุณาใส่เฉพาะตัวเลข'),
    ngan: yup.number().typeError('กรุณาใส่เฉพาะตัวเลข'),
    squareWha: yup.number().typeError('กรุณาใส่เฉพาะตัวเลข'),
    address: yup
      .string()
      .required(t('screens.CreateAreaScreen.required.address')),
    subDistrict: yup
      .string()
      .required(t('screens.CreateAreaScreen.required.subDistrict')),
    district: yup
      .string()
      .required(t('screens.CreateAreaScreen.required.district')),
    province: yup
      .string()
      .required(t('screens.CreateAreaScreen.required.province')),
    ownerId: yup
      .string()
      .required(t('screens.CreateAreaScreen.required.owner')),
    documentType: yup
      .string()
      .required(t('screens.CreateAreaScreen.required.documentType')),
    documentNumber: yup
      .string()
      .required(t('screens.CreateAreaScreen.required.documentNumber')),
    statusLand: yup
      .string()
      .required(t('screens.CreateAreaScreen.required.statusLand')),
    historyOfUse: yup
      .string()
      .required(t('screens.CreateAreaScreen.required.historyOfUse')),
    extensionistId: yup
      .string()
      .typeError(t('screens.CreateAreaScreen.required.extensionistId'))
      .required(t('screens.CreateAreaScreen.required.extensionistId')),
  })

  const onSubmit = async (data: FormData) => {
    setLoadingButton(true)
    const areaSize = data?.rai * 400 + data?.ngan * 100 + data?.squareWha
    if (farmId) {
      try {
        await request.patch(`user/me/farm/${farmId}`, {
          name: data?.name,
          areaSize: areaSize,
          address: data?.address,
          subDistrict: data?.subDistrict,
          district: data?.district,
          province: data?.province,
          lat: +data?.map?.latitude,
          lng: +data?.map?.longitude,
          ownerId: data?.ownerId,
          documentType: data?.documentType,
          documentNumber: data?.documentNumber,
          statusLand: data?.statusLand,
          typeOfStatusLand: data?.typeOfStatusLand,
          historyOfUse: data?.historyOfUse,
          extensionistId: data?.extensionistId,
          memberIds: data?.memberIds,
        })
        setTimeout(() => {
          navigation.goBack()
        }, 200)
      } catch (e) {
        console.log(JSON.stringify(e, null, 2))
      }
    } else {
      try {
        await request.post('/farm', {
          name: data?.name,
          areaSize: areaSize,
          address: data?.address,
          subDistrict: data?.subDistrict,
          district: data?.district,
          province: data?.province,
          lat: +data?.map?.latitude,
          lng: +data?.map?.longitude,
          ownerId: data?.ownerId,
          documentType: data?.documentType,
          documentNumber: data?.documentNumber,
          statusLand: data?.statusLand,
          typeOfStatusLand: data?.typeOfStatusLand,
          historyOfUse: data?.historyOfUse,
          extensionistId: data?.extensionistId,
          memberIds: data?.memberIds,
        })
        setTimeout(() => {
          navigation.goBack()
        }, 200)
      } catch (e: any) {
        console.log(JSON.stringify(e, null, 2))
        console.log('e.response.data.message', e.response.data.message)
      }
    }
  }

  const { width } = useDimension()
  const height =
    Platform.OS === 'web' ? (556 / 1300) * width : (856 / 1500) * width
  const widthApp = Platform.OS === 'web' ? '90%' : '100%'

  useEffect(() => {
    if (farmId && item?.lat && item?.lng) {
      setFarmLocation({ latitude: item?.lat, longitude: item?.lng })
    } else {
      setFarmLocation(
        ownLocation || {
          latitude: 13.736717,
          longitude: 100.523186,
        }
      )
    }
  }, [farmId])

  // useFocusEffect(
  //   React.useCallback(() => {
  //     setlatLng(farmLocation)
  //   }, [farmLocation])
  // )

  return (
    <Form
      defaultValues={defaultValue}
      schema={schema}
      style={{
        flex: 1,
        justifyContent: 'space-between',
      }}
    >
      <Container>
        <Header
          title={
            farmId
              ? t('screens.CreateAreaScreen.edit')
              : t('screens.CreateAreaScreen.title')
          }
          showBack
          onPressBack={() => {
            navigation.goBack()
          }}
        />
        <Image
          style={{ height, width, position: 'absolute', top: 0 }}
          source={image ? { uri: image } : FarmBg}
        />
        <View
          style={{
            height: height - 110,
            justifyContent: 'flex-end',
            alignItems: 'flex-end',

            width: width - 32,
            paddingBottom: 8,
          }}
        >
          <View style={[styles.button, { alignSelf: 'flex-end' }]}>
            <ImagePicker setImage={setImage} name="coverImage" />
          </View>
        </View>
        <Content style={{ backgroundColor: 'white' }} enabledScroll={false}>
          <View
            style={{
              paddingTop: 32,
              paddingBottom: 16,
              width: widthApp,
              alignSelf: 'center',
              flex: 1,
            }}
          >
            <Content noPadding>
              <View style={{ flex: 1, marginBottom: 15 }}>
                <Input
                  name="name"
                  label={t('screens.CreateAreaScreen.form.nameArea')}
                  style={styles.input}
                />
                <Input
                  name="rai"
                  label={t('screens.CreateAreaScreen.form.rai')}
                  style={styles.input}
                />
                <Input
                  name="ngan"
                  label={t('screens.CreateAreaScreen.form.ngan')}
                  style={styles.input}
                />
                <Input
                  name="squareWha"
                  label={t('screens.CreateAreaScreen.form.squareWha')}
                  style={styles.input}
                />
                <Input
                  name="address"
                  multiline
                  numberOfLines={3}
                  label={t('screens.CreateAreaScreen.form.address')}
                  style={styles.input}
                />
                <Address
                  name="subDistrict"
                  keyName="district"
                  label={t('Address.district')}
                  style={styles.input}
                  province={valueProvince}
                  defaultValue={defaultValue.subDistrict}
                />
                <Address
                  name="district"
                  keyName="amphoe"
                  label={t('Address.amphoe')}
                  style={styles.input}
                  defaultValue={defaultValue.district}
                />
                <Address
                  name="province"
                  keyName="province"
                  label={t('Address.province')}
                  style={styles.input}
                  setProvince={setValueProvince}
                />
                <Map
                  label={t('screens.CreateAreaScreen.form.position')}
                  name="map"
                  navigation={navigation}
                  defaultValue={defaultValue?.map}
                />
                <Input
                  // editable={true}
                  name="map.latitude"
                  label={t('screens.CreateAreaScreen.5.x')}
                  // value={latLng?.latitude ? latLng?.latitude?.toString() : ''}
                  // value={latLng?.latitude ? latLng?.latitude?.toString() : ''}
                  style={styles.input}
                  // onChangeText={(text) => setlatLng(() => )}
                />
                <Input
                  // editable={true}
                  name="map.longitude"
                  label={t('screens.CreateAreaScreen.5.y')}
                  style={styles.input}
                  // value={latLng?.longitude ? latLng?.longitude?.toString() : ''}
                />
                <SearchInput
                  name="ownerId"
                  label={t('screens.CreateAreaScreen.form.owner')}
                  style={styles.input}
                  defaultValue={defaultValue.ownerId}
                />
                <DropDownForm
                  name="documentType"
                  label={t('screens.CreateAreaScreen.form.documentType')}
                  data={documentTypeData}
                  placeholder={'เลือกประเภท'}
                />
                <Input
                  name="documentNumber"
                  label={t('screens.CreateAreaScreen.form.documentNumber')}
                  style={styles.input}
                />

                <DropDownForm
                  name="statusLand"
                  label={t('screens.CreateAreaScreen.form.statusLand')}
                  data={statusLandData}
                  placeholder={'เลือกสถานะการใช้ที่ดิน'}
                />
                <Input
                  name="typeOfStatusLand"
                  label={t('screens.CreateAreaScreen.form.typeOfStatusLand')}
                  style={styles.input}
                />
                <Input
                  name="historyOfUse"
                  label={t('screens.CreateAreaScreen.form.historyOfUse')}
                  style={styles.input}
                />
                <DropDownForm
                  name="extensionistId"
                  label={t('screens.CreateAreaScreen.form.extensionist')}
                  data={(extensionist?.data || []).map(
                    (item: {
                      firstName: string
                      lastName: string
                      id: string
                    }) => {
                      return {
                        label: `${item.firstName} ${item.lastName}`,
                        value: item.id,
                      }
                    }
                  )}
                  placeholder={'เลือกผู้รับผิดชอบ'}
                />
                <TagInput
                  name="memberIds"
                  label={t('screens.CreateAreaScreen.form.member')}
                  style={styles.input}
                  defaultValue={defaultValue.memberIds}
                  requestMember={requestMember}
                />
                {/* <RadioButton
                  itemStyle={{ marginTop: 5 }}
                  defaultValue={defaultValue.ownership}
                  name="ownership"
                  label={t('screens.CreateAreaScreen.2')}
                  subFlexRow={true}
                  option={Ownership}
                /> */}
                {/* <Text sub1 style={{ marginTop: 5 }}>
                  {t('screens.CreateAreaScreen.3.title')}
                </Text>
                <Text body1 style={{ marginBottom: 5 }}>
                  {t('screens.CreateAreaScreen.3.desc')}
                </Text>

                <Text sub1 style={{ marginTop: 5 }}>
                  {t('screens.CreateAreaScreen.3.3-1')}
                </Text> */}

                {/* <Input
                  name="documentType"
                  label={t('screens.CreateAreaScreen.form.documentType')}
                  style={styles.input}
                /> */}
                {/* <Input
                  name="documentNumber"
                  label={t('screens.CreateAreaScreen.form.documentNumber')}
                  style={styles.input}
                  keyboardType="phone-pad"
                  unitLabel={t('screens.CreateAreaScreen.unit.position')}
                  unitLabelStyle={{ width: 50, textAlign: 'right' }}
                /> */}
                {/* <Input
                  name="documentAreaSize"
                  label={t('screens.CreateAreaScreen.form.area')}
                  style={styles.input}
                  keyboardType="phone-pad"
                  unitLabel={t('screens.CreateAreaScreen.unit.rai')}
                  unitLabelStyle={{ width: 50, textAlign: 'right' }}
                /> */}
                {/*
                <Text sub1 style={{ marginTop: 5 }}>
                  {t('screens.CreateAreaScreen.3.owner')}
                </Text> */}

                {/* <Input
                  name="ownerName"
                  label={t('screens.CreateAreaScreen.form.ownerName')}
                  style={styles.input}
                /> */}
                {/* <Input
                  name="ownerCitizenId"
                  label={t('screens.CreateAreaScreen.form.ownerCitizenId')}
                  style={styles.input}
                  keyboardType="phone-pad"
                  maxLength={13}
                /> */}

                {/* <Text sub1 style={{ marginTop: 5 }}>
                  {t('screens.CreateAreaScreen.3.3-2')}
                </Text> */}

                {/* <Input
                  name="acceptorName"
                  label={t('screens.CreateAreaScreen.form.ownerName')}
                  style={styles.input}
                />
                <Input
                  name="acceptorCitizenId"
                  label={t('screens.CreateAreaScreen.form.ownerCitizenId')}
                  style={styles.input}
                  keyboardType="phone-pad"
                  maxLength={13}
                />
                <Input
                  name="acceptorPosition"
                  label={t('screens.CreateAreaScreen.form.acceptorPosition')}
                  style={styles.input}
                /> */}
                {/*
                <RadioButton
                  itemStyle={{ marginTop: 5 }}
                  name="irrigableArea"
                  label={t('screens.CreateAreaScreen.4')}
                  flexRow={true}
                  defaultValue={defaultValue.irrigableArea}
                  option={IrrigableArea}
                /> */}
                {/* <Text sub1 style={{ marginTop: 5 }}>
                  {t('screens.CreateAreaScreen.5.title')}
                </Text>
                <Text body1 style={{ marginBottom: 5 }}>
                  {t('screens.CreateAreaScreen.5.desc')}
                </Text>
                <RadioButton
                  label={t('screens.CreateAreaScreen.form.zone')}
                  itemStyle={{
                    flexDirection: 'row',
                    alignItem: 'center',
                  }}
                  labelStyle={{ alignSelf: 'center', marginRight: 20 }}
                  name="areaZone"
                  flexRow={true}
                  option={areaZone}
                /> */}
                {/* <Text sub1 style={{ marginTop: 5 }}>
                  {t('screens.CreateAreaScreen.6.title')}
                </Text>
                <Text body1>{t('screens.CreateAreaScreen.6.desc')}</Text>
                <MultiRadioGroup
                  itemStyle={{ marginTop: 5 }}
                  defaultValue={defaultValue.waterSources}
                  name="waterSources"
                  subFlexRow={true}
                  option={waterSources}
                  except="self"
                />
                <Text sub1 style={{ marginTop: 5 }}>
                  {t('screens.CreateAreaScreen.7.title')}
                </Text>
                <Text body1>{t('screens.CreateAreaScreen.7.desc')}</Text> */}
                {/* <MultiRadioGroup
                  itemStyle={{ marginTop: 5 }}
                  defaultValue={defaultValue.wateringMethods}
                  name="wateringMethods"
                  subFlexRow={true}
                  option={WateringMethod}
                  except="pipe"
                /> */}
                {/* <Input
                  name="areaSize"
                  label={t('screens.CreateAreaScreen.8')}
                  style={styles.input}
                  keyboardType="phone-pad"
                  unitLabel={t('screens.CreateAreaScreen.unit.rai')}
                  unitLabelStyle={{ width: 50, textAlign: 'right' }}
                /> */}
                {/* <RadioButton
                  itemStyle={{ marginTop: 5 }}
                  name="sustainableAgriculture"
                  label={t('screens.CreateAreaScreen.9')}
                  setSelectSub={setSelectSub}
                  defaultValue={defaultValue.sustainableAgriculture}
                  subDefaultValue={defaultValue.subSustainableAgriculture}
                  option={sustainableAgricultureOption}
                /> */}
              </View>
              {loadingButton ? (
                <View
                  style={{
                    height: 56,
                    borderRadius: 10,
                    justifyContent: 'center',
                    backgroundColor: '#99d7f1',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    bold
                    title1
                    color="white"
                    style={{ position: 'absolute' }}
                  >
                    {farmId ? t('Buttons.Edit') : t('Buttons.Create')}sss
                  </Text>
                  <ActivityIndicator
                    color={theme.color.primary1}
                    size="large"
                  />
                </View>
              ) : (
                <SubmitButton
                  testID="form.createProfile"
                  title={farmId ? t('Buttons.Save') : t('Buttons.Create')}
                  onSubmit={(data) =>
                    onSubmit(data).finally(() => setLoadingButton(false))
                  }
                />
              )}
            </Content>
          </View>
        </Content>
      </Container>
    </Form>
  )
}
const styles = StyleSheet.create({
  icon: {
    width: 30,
    height: 30,
  },
  input: {
    ...Platform.select({
      web: {
        paddingVertical: 6,
        height: 44,
      },
      default: {
        paddingVertical: 6,
        flex: 1,
      },
    }),
  },
  button: {
    width: 40,
    height: 40,
    marginTop: 52,
    borderRadius: 50 / 2,
    backgroundColor: theme.color.primary1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
