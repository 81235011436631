import { View, ScrollView, Platform, StyleSheet } from 'react-native'
import React, { useState } from 'react'
import Container from 'components/Container'
import Header from 'components/Header'
import Background from 'components/Background'
import Content from 'components/Content'
import Card from 'components/Card'
import { Form, SubmitButton, Input } from 'components/Form'
import * as yup from 'yup'
// import { Grid } from 'antd-mobile'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import request, { fetcher } from 'utils/request'
import { RouteProp } from '@react-navigation/native'
import { useLocalization } from 'contexts/LocalizationContext'
import InputDate from 'components/Form/InputDate'
import dayjs from 'dayjs'
import ModalAlert from 'components/ModalAlert'
import { InputText } from 'components/FormArray/InputText'
import useSWR from 'swr'
export default function AddPlantGreenhouse({
  navigation,
  route,
}: {
  navigation: StackNavigationHelpers
  route: RouteProp<{ params: { id: string; farmId: string } }, 'params'>
}): JSX.Element {
  const { t } = useLocalization()
  const { id, farmId } = route.params
  const { data: farm } = useSWR([`/farm/${farmId}`], fetcher)

  const schema = yup.object().shape({
    date: yup.string().required('กรุณากรอกวันที่'),
    convert: yup.string().required('กรุณากรอกแปลง'),
    startID: yup.string().required('กรุณากรอกกอเริ่มต้น'),
    endID: yup.string().required('กรุณากรอกกอสิ้นสุด'),
  })
  const [isSuccess, setIsSuccess] = useState(false)
  //const [visible, setVisible] = useState(false)
  const onSubmitForm = async (data: {
    date: string
    startID: string
    endID: string
    deadIDs: [number]
  }) => {
    try {
      await request.post(`/workAssignment/${id}/seedling-logs`, {
        date: data?.date,
        startID: parseInt(data?.startID),
        endID: parseInt(data?.endID),
        deadIDs: data?.deadIDs ? data?.deadIDs : [],
        assignmentId: id,
      })
      setIsSuccess(true)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <Header showBack title="กอ ID / ลำ ID" widthText={'90%'} />
      <Content enabledScroll={false} noPadding>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <Card
            borderRadiusTop
            style={{ justifyContent: 'space-between', flex: 1, marginTop: 32 }}
          >
            <Form
              schema={schema}
              defaultValues={{ date: dayjs(), convert: farm?.name }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <View>
                <InputDate label="วันที่" name="date" iconRight />

                <Input
                  name="convert"
                  label={t('screens.StationPlantGreenhouse.form.convert')}
                  style={styles.Input}
                />
                <Input
                  name="startID"
                  label={t('screens.StationPlantGreenhouse.form.startID')}
                  style={styles.Input}
                />
                <Input
                  name="endID"
                  label={t('screens.StationPlantGreenhouse.form.endID')}
                  style={styles.Input}
                />
                <InputText
                  name="deadIDs"
                  label={t('screens.StationPlantGreenhouse.form.deadIDs')}
                  unitLabelInside
                  //showCheckBox
                  hasScanner
                  colorLabel="error"
                  nameList="deadIDs"
                  errorName="estimatedAmountAnimal"
                />
              </View>

              <View style={{ paddingHorizontal: '8%' }}>
                <SubmitButton title="ตกลง" onSubmit={onSubmitForm} />
              </View>
            </Form>
          </Card>
        </ScrollView>
      </Content>
      <ModalAlert
        visible={isSuccess}
        setVisibile={setIsSuccess}
        textDesc="เพิ่มข้อมูลสำเร็จ"
        onRequestClose={() => navigation.goBack()}
      />
    </Container>
  )
}
const styles = StyleSheet.create({
  Input: {
    ...Platform.select({
      web: {
        paddingVertical: 6,
        height: 40,
      },
      default: {
        paddingVertical: 6,
        flex: 1,
      },
    }),
  },
})
