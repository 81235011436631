import React, { useState } from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Text from 'components/Text'
import Background from 'components/Background'
import { FlatList, View } from 'react-native'
import Plus from 'assets/images/icons/Plus.png'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { Provider } from 'react-native-paper'
import DotMenuLiat from 'components/DotMenuLiat'
import Card from 'components/Card'
import Button from 'components/Button'
import useSWR from 'swr'
import { fetcher } from '../../utils/request'
import { useFocusEffect } from '@react-navigation/native'
import dayjs from 'dayjs'
import { useLocalization } from 'contexts/LocalizationContext'
import { RouteProp } from '@react-navigation/native'
import request from 'utils/request'

import { objWorkStation } from 'utils/staticData'

export type Props = {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<{ params: { PoId: string; PjId: string } }, 'params'>
}
export interface Order {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  orderDate: Date
  documentNumber: string
  customer: string
  status: string
}

export interface Project {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  projectNumber: string
  projectDate: Date
  itemCode: string
  detail: string
  quantity: number
  unit: string
  fundPerUnit: number
  totalFund: number
  dueDate: Date
  status: string
  deliveredDate: Date
  lastedUpdateActualDate: Date
  farmId: string
  speciesId: string
  orderId: string
  productId: string
  order: Order
}

export interface Datum {
  createdAt: Date
  updatedAt: Date
  deletedAt?: any
  id: string
  projectNumber: string
  workAssignmentNumber: string
  farmId: string
  startDate: string
  workStation: string
  workDetail: string
  farmerId: string
  extensionistId: string
  approverId: string
  status: string
  quantity: number
  unit: string
  capacityPerDay: number
  isPublished: boolean
  project: Project
}

export interface RootObject {
  data: Datum[]
  total: number
  page: number
  limit: number
}

const WorkAssignmentScreen = ({ navigation, route }: Props): JSX.Element => {
  const { t, locale } = useLocalization()
  const [refresh, setRefresh] = useState<boolean>(false)
  const { PoId, PjId } = route.params
  const limit = 10
  const page = 1
  const [workAssignment, setWorkAssignment] = useState<RootObject>({
    data: [],
    page: 1,
    total: 1,
    limit: 10,
  })
  const fatchData = async () => {
    try {
      const res = await request.get(
        `/workAssignment?limit=${limit}&page=${page}&projectNumber=${PjId}&isCanceled=false`
      )
      setWorkAssignment(res?.data)
    } catch (err) {
      console.log(err)
    }
  }
  const onLoadMore = async () => {
    try {
      if (workAssignment?.total > workAssignment.data.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        try {
          const res = await request.get(
            `/workAssignment?limit=${limit}&page=${
              workAssignment?.page + 1
            }&projectNumber=${PjId}&isCanceled=false`
          )
          const data = {
            data: [...workAssignment.data, ...res.data.data],
            page: workAssignment?.page + 1,
            total: workAssignment.total,
            limit: workAssignment.limit,
          }
          setWorkAssignment(data)
        } catch (err) {
          console.log(err)
        }
      }
    } catch (e) {
      // console.log(e)
    }
  }
  const [workAssignmentCancel, setWorkAssignmentCancel] = useState<RootObject>({
    data: [],
    page: 1,
    total: 1,
    limit: 10,
  })
  const fatchDataCancel = async () => {
    try {
      const res = await request.get(
        `/workAssignment?limit=${limit}&page=${page}&projectNumber=${PjId}&isCanceled=true`
      )
      setWorkAssignmentCancel(res?.data)
    } catch (err) {
      console.log(err)
    }
  }
  const onLoadMoreCancel = async () => {
    try {
      if (workAssignmentCancel?.total > workAssignmentCancel.data.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        try {
          const res = await request.get(
            `/workAssignment?limit=${limit}&page=${
              workAssignmentCancel?.page + 1
            }&projectNumber=${PjId}&isCanceled=true`
          )
          const data = {
            data: [...workAssignmentCancel.data, ...res.data.data],
            page: workAssignmentCancel?.page + 1,
            total: workAssignmentCancel.total,
            limit: workAssignmentCancel.limit,
          }
          setWorkAssignmentCancel(data)
        } catch (err) {
          console.log(err)
        }
      }
    } catch (e) {
      // console.log(e)
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      fatchData()
      fatchDataCancel()
    }, [refresh])
  )

  const summitPlan = async (id: string) => {
    try {
      await request.post(`/workAssignment/${id}/publish`)
      setRefresh(true)
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Provider>
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={t('screens.WorkAssignmentScreen.title')}
          alignTitle="flex-start"
        />
        <View style={{ width: '90%', marginTop: 70, marginBottom: 20 }}>
          <Button
            title={t('screens.WorkAssignmentScreen.buttonWorkAssignment')}
            onPress={() =>
              navigation.navigate('WorkAssignmentDetailEdit', {
                PoId: PoId,
                PjId: PjId,
                // id: null,
              })
            }
            icon={Plus}
          />
        </View>
        <Content enabledScroll={true}>
          <FlatList
            nestedScrollEnabled={true}
            data={workAssignment?.data}
            onEndReached={onLoadMore}
            indicatorStyle="default"
            style={{ marginTop: 15 }}
            contentContainerStyle={{ paddingBottom: 60 }}
            renderItem={({ item }) => {
              return (
                <View
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <Card>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <View>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <Text color="black" sub1 medium>
                            {`${t(
                              'screens.WorkAssignmentScreen.workAssignmentNumber'
                            )} : ${item.workAssignmentNumber}`}
                          </Text>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <Text color="black85" sub1 medium>
                            {`${t(
                              'screens.WorkAssignmentScreen.workAssignmentDate'
                            )} :  `}
                          </Text>

                          <Text color="success" sub1 medium>
                            {dayjs(item.startDate)
                              .add(locale === 'th' ? 543 : 0, 'years')
                              .format('D MMM YYYY')}
                          </Text>
                        </View>
                      </View>

                      <DotMenuLiat
                        navigation={() => {
                          navigation.navigate('WorkAssignmentDetailEdit', {
                            PoId: PoId,
                            PjId: PjId,
                            id: item.id,
                          })
                        }}
                        onCancel={async () => {
                          await request.delete(
                            `/workAssignment/${item.id}/cancel`
                          )
                          setRefresh((prev) => !prev)
                        }}
                        onDelete={async () => {
                          await request.delete(`/workAssignment/${item.id}`)
                          setRefresh((prev) => !prev)
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <View>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <Text color="black85" sub1 medium>
                            {`${t('screens.WorkAssignmentScreen.station')} : `}
                          </Text>
                          <Text color="success" sub1 medium>
                            {`${
                              objWorkStation[
                                item.workStation as keyof typeof objWorkStation
                              ]
                            }`}
                          </Text>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <Text color="black85" sub1 medium>
                            {`${t('screens.WorkAssignmentScreen.status')} : `}
                          </Text>

                          {item.status === 'completed' ? (
                            <Text color="success" sub1 medium>
                              • {` ${t('screens.ProjectScreen.success')}`}
                            </Text>
                          ) : item.status === 'inProgress' ? (
                            <Text color="success" sub1 medium>
                              • กำลังดำเนินการ
                            </Text>
                          ) : item.status === 'pending' ? (
                            <Text color="success" sub1 medium>
                              • ยังไม่เริ่มดำเนินการ
                            </Text>
                          ) : (
                            <></>
                          )}
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        paddingTop: 10,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        padding="10px"
                        style={{ width: '48%' }}
                        color="primary1"
                        title={t('screens.WorkAssignmentScreen.reportButton')}
                        onPress={() =>
                          navigation.navigate('WorkAssignmentReport', {
                            PoId: PoId,
                            PjId: item.projectNumber,
                            id: item.id,
                            type: 'report',
                          })
                        }
                        secondary
                      />
                      {item.isPublished === false ? (
                        <Button
                          padding="10px"
                          style={{ width: '48%' }}
                          iconSize={20}
                          title={t('screens.WorkAssignmentScreen.Plan')}
                          onPress={() => {
                            summitPlan(item.id)
                          }}
                        />
                      ) : (
                        <Button
                          padding="10px"
                          style={{ width: '48%' }}
                          iconSize={20}
                          disabled
                          title={t('screens.WorkAssignmentScreen.PlanDone')}
                          // onPress={() => {
                          //   console.log('Plan Done')
                          // }}
                        />
                      )}
                    </View>
                  </Card>
                </View>
              )
            }}
            keyExtractor={(item) => item.id}
          />
          {workAssignmentCancel?.data?.length > 0 ? (
            <>
              <View
                style={{
                  width: '100%',
                  marginVertical: 8,
                  borderColor: '#CDCDCD',
                  borderWidth: 0.7,
                }}
              ></View>
              <Text
                style={{
                  color: 'red',
                  paddingLeft: 15,
                }}
              >
                รายการที่ยกเลิก
              </Text>
              <FlatList
                nestedScrollEnabled={true}
                data={workAssignmentCancel?.data}
                indicatorStyle="default"
                onEndReached={onLoadMoreCancel}
                style={{ marginTop: 15 }}
                contentContainerStyle={{ paddingBottom: 60 }}
                renderItem={({ item }) => {
                  return (
                    <View
                      style={{
                        marginBottom: 10,
                      }}
                    >
                      <Card>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <View>
                            <View
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <Text color="black" sub1 medium>
                                {`${t(
                                  'screens.WorkAssignmentScreen.workAssignmentNumber'
                                )} : ${item.workAssignmentNumber}`}
                              </Text>
                            </View>
                            <View
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <Text color="black85" sub1 medium>
                                {`${t(
                                  'screens.WorkAssignmentScreen.workAssignmentDate'
                                )} :  `}
                              </Text>

                              <Text color="success" sub1 medium>
                                {dayjs(item.startDate)
                                  .add(locale === 'th' ? 543 : 0, 'years')
                                  .format('D MMM YYYY')}
                              </Text>
                            </View>
                          </View>

                          <DotMenuLiat
                            // navigation={() => {
                            //   navigation.navigate('WorkAssignmentDetailEdit', {
                            //     PoId: PoId,
                            //     PjId: PjId,
                            //     id: item.id,
                            //   })
                            // }}
                            // onCancel={async () => {
                            //   await request.delete(
                            //     `/workAssignment/${item.id}/cancel`
                            //   )
                            //   setRefresh((prev) => !prev)
                            // }}
                            onDelete={async () => {
                              await request.delete(`/workAssignment/${item.id}`)
                              setRefresh((prev) => !prev)
                            }}
                          />
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <View>
                            <View
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <Text color="black85" sub1 medium>
                                {`${t(
                                  'screens.WorkAssignmentScreen.station'
                                )} : `}
                              </Text>
                              <Text color="success" sub1 medium>
                                {`${item.workStation}`}
                              </Text>
                            </View>
                            <View
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <Text color="black85" sub1 medium>
                                {`${t(
                                  'screens.WorkAssignmentScreen.status'
                                )} : `}
                              </Text>
                              <Text color="error" sub1 medium>
                                ยกเลิกใบงาน
                              </Text>
                            </View>
                          </View>
                        </View>
                        <View
                          style={{
                            paddingTop: 10,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Button
                            disabled
                            padding="10px"
                            style={{ width: '48%' }}
                            iconSize={20}
                            title={t(
                              'screens.WorkAssignmentScreen.informationButton'
                            )} //ข้อมูล
                            // onPress={() => {
                            //   console.log('onPrintSave')
                            //   // onPrintSave()
                            // }}
                            color="primary1"
                            secondary
                          />
                          <Button
                            disabled
                            padding="10px"
                            style={{ width: '48%' }}
                            title={t(
                              'screens.WorkAssignmentScreen.reportButton'
                            )}
                            onPress={() =>
                              navigation.navigate('WorkAssignmentReport', {
                                PoId: PoId,
                                PjId: item.projectNumber,
                                id: item.id,
                                type: 'report',
                              })
                            }
                          />
                        </View>
                      </Card>
                    </View>
                  )
                }}
                keyExtractor={(item) => item.id}
              />
            </>
          ) : (
            <></>
          )}
        </Content>
      </Container>
    </Provider>
  )
}

export default WorkAssignmentScreen
