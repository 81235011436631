import { Image, StyleSheet, View } from 'react-native'
import React from 'react'
import ImagePicker from 'components/Form/ImagePicker'
import theme from 'theme/index'
import Text from 'components/Text'
import PlusIcon from 'assets/images/icons/PlusAdd.png'
import { Label } from 'components/Form/components'
import { useFile } from 'utils/getImage'

interface Props {
  index: number
  name: string
  errorName: string
  nameList: string
  label?: string
  nestNameList?: string
  indexNested?: number
  defaultValue?: string
}
const ImagePickerMulti = ({
  index,
  errorName,
  name,
  nameList,
  label,
  nestNameList,
  indexNested = 0,
  defaultValue,
}: Props): JSX.Element => {
  const [image, setImage] = React.useState('')
  const { getImage } = useFile()
  const isBase64 = image.includes('data:image')
  const isFile = /^file:\/\//.test(image)

  return (
    <View>
      {label ? (
        <Label input medium>
          {label}
        </Label>
      ) : null}
      <ImagePicker
        index={index}
        name={name}
        setImage={setImage}
        nameList={nameList}
        errorName={errorName}
        nestNameList={nestNameList}
        indexNested={indexNested}
        defaultValue={defaultValue}
      >
        {image ? (
          <View style={styles.hasImage}>
            <Image
              source={{
                uri: isBase64 || isFile ? image : getImage(image) || '',
              }}
              style={{ height: 248, width: '100%', borderRadius: 10 }}
            />
          </View>
        ) : (
          <View style={styles.noImage}>
            <View style={styles.center}>
              <Image
                source={PlusIcon}
                style={{
                  width: 24,
                  height: 24,
                }}
              />
              <Text color="primary1" sub1 style={{ marginTop: 4 }}>
                เพิ่มรูป
              </Text>
            </View>
          </View>
        )}
      </ImagePicker>
    </View>
  )
}

const styles = StyleSheet.create({
  noImage: {
    borderColor: theme.color.primary1,
    borderRadius: 10,
    borderStyle: 'dotted',
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    height: 250,
    width: '100%',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  hasImage: {
    borderColor: theme.color.primary1,
    borderRadius: 10,
    borderStyle: 'dotted',
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    height: 250,
    width: '100%',
  },
})
export default ImagePickerMulti
