/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import Container from 'components/Container'
import Content from 'components/Content'
import Background from 'components/Background'
import { View, StyleSheet, Platform, ActivityIndicator } from 'react-native'
import Header from 'components/Header'
import { BottomTabNavigationHelpers } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import Card from 'components/Card'
import { Form, SubmitButton, Input, RadioButton } from 'components/Form'
import InputDate from 'components/Form/InputDate'
//import DropDownNew from 'components/Form/DropDownNew'
import * as yup from 'yup'
import request from 'utils/request'
import { useLocalization } from 'contexts/LocalizationContext'
import { RouteProp } from '@react-navigation/native'
import { useAuth } from 'contexts/AuthContext'
import useSWR from 'swr'
import { fetcher } from '../../utils/request'
import Text from 'components/Text'
import theme from 'theme/index'
import { workStationOption } from './option'
import DropDownForm from 'components/Form/DropDownForm'
import dayjs from 'dayjs'

export type Props = {
  navigation: BottomTabNavigationHelpers
  route: RouteProp<
    { params: { PoId: string; PjId: string; id: string } },
    'params'
  >
}

type FormData = {
  PoId: null
  projectNumber: null
  farmId: null
  workAssignmentNumber: null
  code: null
  startDate: string
  productDetail: null
  workStation: null
  workDetail: null
  farmerId: null
  extensionistId: null
  approvedBy: null
  quantity: number
  unit: null
  capacityPerDay: number
  speciesId: null
  status: null
}

const WorkAssignmentDetailEditScreen = ({
  navigation,
  route,
}: Props): JSX.Element => {
  const {
    state: { user },
  } = useAuth()
  const { t } = useLocalization()
  const { PoId, PjId, id } = route.params
  const limit = 10
  //const isAndroid = Platform.OS === 'android'
  const role = 'extensionist'

  const { data: farm } = useSWR([`/farm?limit=${limit}&page=1`], fetcher)
  const { data: purchaseOrder } = useSWR(
    () => [`/purchaseOrder/${PoId}`],
    fetcher
  )

  const { data: extensionist } = useSWR(
    [`/user?limit=${limit}&page=1&role=${role}`],
    fetcher
  )
  const { data: project } = useSWR([`/project/${PjId}`], fetcher)

  const { data: workAssignment } = useSWR(`/workAssignment/${id}`, fetcher)

  const [valueDropdown, setValueDropdown] = useState<
    string | number | boolean | undefined | null
  >(workAssignment?.farmId)
  useEffect(() => {
    setValueDropdown(workAssignment?.farmId)
  }, [workAssignment])

  const farmMember = (farm?.data || [])
    .filter((item: any) => item.id === valueDropdown)
    .map((item: any) => {
      const result = item.farmMembers.map((farmMember: any) => ({
        label: `${farmMember.user.firstName}  ${farmMember.user.lastName}`,
        value: farmMember.user.id,
      }))
      return result
    })

  const defaultValue = {
    documentNumber: purchaseOrder?.documentNumber || '',
    projectNumber: PjId,
    workAssignmentNumber: workAssignment?.workAssignmentNumber || '',
    itemCode: project?.itemCode || '',
    startDate: dayjs(workAssignment?.startDate).format('YYYY-MM-DD'),
    endDate: dayjs(workAssignment?.endDate).format('DD-MM-YYYY'),
    speciesId:
      project?.species?.brandName || workAssignment?.species?.brandName || '',
    productDetail: project?.detail || '',
    workStation: workAssignment?.workStation || '',
    workDetail: workAssignment?.workDetail || '',
    farmerId: workAssignment?.farmerId || '',
    extensionistId: workAssignment?.extensionistId || '',
    approvedBy: user?.firstName + ' ' + user?.lastName || '',
    quantity: workAssignment?.quantity || '',
    unit: workAssignment?.unit || '',
    capacityPerDay: workAssignment?.capacityPerDay || '',
    farmId: workAssignment?.farmId || '',
    status: workAssignment?.status || 'pending',
  }

  const schema = yup.object().shape({
    projectNumber: yup
      .string()
      .nullable()
      .required(t('screens.WorkAssignmentScreen.form.required.projectNumber')),
    farmId: yup
      .string()
      .nullable()
      .required(t('screens.WorkAssignmentScreen.form.required.farmId')),
    workAssignmentNumber: yup
      .string()
      .nullable()
      .required(
        t('screens.WorkAssignmentScreen.form.required.workAssignmentNumber')
      ),

    startDate: yup
      .string()
      .nullable()
      .required(t('screens.WorkAssignmentScreen.form.required.startDate')),
    // productDetail: yup
    //   .string()
    //   .nullable()
    //   .required(t('screens.WorkAssignmentScreen.form.required.productDetail')),
    workStation: yup
      .string()
      .nullable()
      .required(t('screens.WorkAssignmentScreen.form.required.workStation')),
    // speciesId: yup
    //   .string()
    //   .nullable()
    //   .required(t('screens.WorkAssignmentScreen.form.required.speciesId')),
    workDetail: yup
      .string()
      .nullable()
      .required(t('screens.WorkAssignmentScreen.form.required.workDetail')),
    farmerId: yup
      .string()
      .nullable()
      .required(t('screens.WorkAssignmentScreen.form.required.farmerId')),
    extensionistId: yup
      .string()
      .nullable()
      .required(t('screens.WorkAssignmentScreen.form.required.extensionistId')),
    // approverBy: yup
    //   .string()
    //   .nullable()
    //   .required(t('screens.WorkAssignmentScreen.form.required.approvedBy')),
    quantity: yup
      .string()
      .nullable()
      .required(t('screens.WorkAssignmentScreen.form.required.quantity')),
    unit: yup
      .string()
      .nullable()
      .required(t('screens.WorkAssignmentScreen.form.required.unit')),
    capacityPerDay: yup
      .number()
      .typeError(
        t('screens.WorkAssignmentScreen.form.required.capacityPerDayError')
      )
      .nullable()
      .required(t('screens.WorkAssignmentScreen.form.required.capacityPerDay')),
    status: yup
      .string()
      .nullable()
      .required(t('screens.WorkAssignmentScreen.form.required.capacityPerDay')),
  })

  const onSubmit = async (data: FormData) => {
    if (id) {
      try {
        await request.patch(`/workAssignment/${id}`, {
          projectNumber: data?.projectNumber,
          workAssignmentNumber: data?.workAssignmentNumber,
          farmId: data?.farmId,
          startDate: data?.startDate,
          workStation: data?.workStation,
          workDetail: data?.workDetail,
          farmerId: data?.farmerId,
          // speciesId: data?.speciesId,
          extensionistId: data?.extensionistId,
          approverId: user?.id,
          quantity: +data?.quantity,
          unit: data?.unit,
          capacityPerDay: +data?.capacityPerDay,
          status: workAssignment?.status,
        })
        navigation.navigate('WorkAssignment', {
          PoId: PoId,
          PjId: PjId,
        })
      } catch (e: any) {
        console.log(JSON.stringify(e.response, null, 2))
      }
    } else {
      try {
        await request.post(`/workAssignment`, {
          projectNumber: data?.projectNumber,
          workAssignmentNumber: data?.workAssignmentNumber,
          farmId: data?.farmId,
          startDate: data?.startDate,
          workStation: data?.workStation,
          workDetail: data?.workDetail,
          // speciesId: data?.speciesId,
          farmerId: data?.farmerId,
          extensionistId: data?.extensionistId,
          approverId: user?.id,
          quantity: +data?.quantity,
          unit: data?.unit,
          capacityPerDay: +data?.capacityPerDay,
          status: 'pending',
        })
        navigation.navigate('WorkAssignment', {
          PoId: PoId,
          PjId: PjId,
        })
      } catch (e: any) {
        console.log(JSON.stringify(e.response, null, 2))
      }
    }
  }

  if (id && !workAssignment) {
    return (
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={t('screens.WorkAssignmentScreen.workAssignment')}
          alignTitle="flex-start"
        />
        <View style={{ marginTop: 70, marginBottom: 20 }}></View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size="large" color={theme.color.primary1} />
          <Text center style={{ marginTop: 16 }} h2 color="primary2">
            Loading...
          </Text>
        </View>
      </Container>
    )
  }
  if (PjId && !purchaseOrder) {
    return (
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={t('screens.WorkAssignmentScreen.workAssignment')}
          alignTitle="flex-start"
        />
        <View style={{ marginTop: 70, marginBottom: 20 }}></View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size="large" color={theme.color.primary1} />
          <Text center style={{ marginTop: 16 }} h2 color="primary2">
            Loading...
          </Text>
        </View>
      </Container>
    )
  }
  if (!project?.species?.brandName) {
    return (
      <Container>
        <Background typeBackground="BgDefaultHeader" />
        <Header
          showBack
          title={t('screens.WorkAssignmentScreen.workAssignment')}
          alignTitle="flex-start"
        />
        <View style={{ marginTop: 70, marginBottom: 20 }}></View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size="large" color={theme.color.primary1} />
          <Text center style={{ marginTop: 16 }} h2 color="primary2">
            Loading...
          </Text>
        </View>
      </Container>
    )
  }

  return (
    <Container>
      <Background typeBackground="BgDefaultHeader" />
      <Header
        showBack
        title={
          id
            ? t('screens.WorkAssignmentScreen.edit')
            : t('screens.WorkAssignmentScreen.create')
        }
        alignTitle="flex-start"
      />
      <View style={{ marginTop: 70, marginBottom: 20 }}></View>
      <Content noPadding enabledScroll={false}>
        <Card
          borderRadiusTop
          style={{
            alignSelf: 'flex-end',
            flex: 1,
            borderBottomStartRadius: 0,
            borderBottomEndRadius: 0,
            padding: 0,
            marginTop: 16,
          }}
        >
          <Content
            noPadding
            style={{
              flex: 1,
            }}
          >
            <Form
              defaultValues={defaultValue}
              schema={schema}
              style={{
                paddingTop: 20,
                paddingHorizontal: 16,
                flex: 1,
                justifyContent: 'space-between',
              }}
            >
              <Input
                name="documentNumber"
                label={t('screens.WorkAssignmentScreen.form.poId')}
                style={styles.Input}
                editable
              />
              <Input
                name="projectNumber"
                label={t('screens.WorkAssignmentScreen.form.projectNumber')}
                style={styles.Input}
                editable
              />
              <Input
                name="workAssignmentNumber"
                label={t(
                  'screens.WorkAssignmentScreen.form.workAssignmentNumber'
                )}
                style={styles.Input}
              />
              <DropDownForm
                name="farmId"
                label={t('screens.WorkAssignmentScreen.form.farmId')}
                data={(farm?.data || []).map(
                  (item: { name: string; id: string }) => {
                    return { label: item.name, value: item.id }
                  }
                )}
                setValueDropdown={setValueDropdown}
                placeholder={'เลือกแปลง/โรงงาน'}
              />
              <Input
                name="itemCode"
                label={t('screens.WorkAssignmentScreen.form.code')}
                style={styles.Input}
              />
              <Input
                name="productDetail"
                label={t('screens.WorkAssignmentScreen.form.productDetail')}
                style={styles.Input}
              />

              <Input
                name="speciesId"
                label={t('screens.ProjectScreen.form.species')}
                style={styles.Input}
                editable
              />
              <DropDownForm
                name="workStation"
                label={t('screens.WorkAssignmentScreen.form.workStation')}
                data={workStationOption}
                placeholder={'เลือกสถานีงาน'}
              />

              <Input
                name="workDetail"
                label={t('screens.WorkAssignmentScreen.form.workDetail')}
                style={styles.Input}
              />

              <DropDownForm
                name="farmerId"
                label={t('screens.WorkAssignmentScreen.form.farmerId')}
                data={farmMember?.flat()}
                placeholder={'เลือกผู้รับผิดชอบ'}
              />

              <DropDownForm
                name="extensionistId"
                label={t('screens.WorkAssignmentScreen.form.extensionistId')}
                data={(extensionist?.data || []).map(
                  (item: {
                    firstName: string
                    lastName: string
                    id: string
                  }) => {
                    return {
                      label: `${item.firstName} ${item.lastName}`,
                      value: item.id,
                    }
                  }
                )}
                placeholder={'เลือกผู้รับผิดชอบ'}
              />
              <Input
                editable
                name="approvedBy"
                label={t('screens.WorkAssignmentScreen.form.approvedBy')}
                style={styles.Input}
              />
              <Input
                name="quantity"
                label={t('screens.WorkAssignmentScreen.form.quantity')}
                style={styles.Input}
              />
              <DropDownForm
                name="unit"
                label={t('screens.WorkAssignmentScreen.form.unit')}
                data={[
                  { label: 'ไร่', value: 'farm' },
                  {
                    label: 'ต้น',
                    value: 'plant',
                  },
                ]}
                placeholder={''}
              />
              <Input
                name="capacityPerDay"
                label={t('screens.WorkAssignmentScreen.form.capacityPerDay')}
                style={styles.Input}
              />
              <InputDate
                name="startDate"
                label={t('screens.WorkAssignmentScreen.form.startDate')}
                testID="startDate"
                style={{ height: 42 }}
                defaultValue={defaultValue.startDate}
                enableReinitial
              />
              {workAssignment ? (
                <Input
                  name="endDate"
                  label={t('screens.WorkAssignmentScreen.form.endDate')}
                  testID="endDate"
                  style={{ height: 42 }}
                  editable
                />
              ) : (
                <></>
              )}
              {/* <RadioButton
                name="status"
                label={t('screens.WorkAssignmentScreen.form.status')}
                defaultValue={defaultValue?.status}
                option={[
                  { label: 'เสร็จสิ้น', value: 'completed' },
                  {
                    label: 'กำลังดำเนินการ',
                    value: 'inProgress',
                  },
                  {
                    label: 'ยังไม่เริ่มดำเนินการ',
                    value: 'pending',
                  },
                ]}
              /> */}
              <View style={{ marginTop: 56, marginBottom: 24 }}>
                <SubmitButton
                  testID="form.createCompany"
                  title={t('Buttons.Save')}
                  onSubmit={onSubmit}
                />
              </View>
            </Form>
          </Content>
        </Card>
      </Content>
    </Container>
  )
}

const styles = StyleSheet.create({
  Input: {
    ...Platform.select({
      web: {
        paddingVertical: 6,
        height: 40,
      },
      default: {
        paddingVertical: 6,
        flex: 1,
      },
    }),
  },
})

export default WorkAssignmentDetailEditScreen
