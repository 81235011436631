import { View, ViewStyle } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ErrorMessage, Item, Label } from './components'
import styled from '@emotion/native'
import CheckBox from 'components/CheckBox'
import Text from 'components/Text'

interface Props {
  itemStyle?: ViewStyle
  label?: string
  name: string
  justify?: 'row' | 'column'
  defaultValue?: string[]
  colorLabel?:
    | 'primary1'
    | 'primary2'
    | 'secondary1'
    | 'secondary2'
    | 'black'
    | 'black90'
    | 'black85'
    | 'black65'
    | 'black40'
    | 'black22'
    | 'black6'
    | 'white'
    | 'success'
    | 'warning'
    | 'error'
  errorInside?: boolean
}
interface ErrorBoxProps {
  errorInside: boolean
}
const ErrorBox = styled.View<ErrorBoxProps>`
  position: ${(props) => props.errorInside && 'absolute'};
  bottom: ${(props) => props.errorInside && '5px'};
  padding: ${(props) => props.errorInside && '8px'};
`
export default function CheckBoxOnce({
  itemStyle = {},
  label,
  name,
  colorLabel = 'black',
  errorInside = false,
  defaultValue = [],
}: Props): JSX.Element {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext()

  const [checkBoxValue, setCheckBoxValue] = useState<boolean>()

  return (
    <Item style={{ marginBottom: 7, ...itemStyle }}>
      {/* {label && (
        <Label input color={colorLabel}>
          {label}
        </Label>
      )} */}
      <Controller
        control={control}
        render={({ field: { onChange } }) => (
          <>
            <Item style={itemStyle}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <CheckBox
                  checkBoxColor={'black40'}
                  isChecked={checkBoxValue}
                  style={{
                    borderRadius: 4,
                  }}
                  onChange={() => {
                    setCheckBoxValue((prev) => !prev)
                    onChange(!checkBoxValue)
                  }}
                />
                <Text lineHeight="20px">{label}</Text>
              </View>
            </Item>
            {errors[name] && (
              <ErrorBox errorInside={errorInside}>
                <ErrorMessage error>{errors[name].message}</ErrorMessage>
              </ErrorBox>
            )}
          </>
        )}
        name={name}
      />
    </Item>
  )
}
