import React from 'react'
import {
  BottomTabBarButtonProps,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs'
import {
  Image,
  ImageSourcePropType,
  View,
  StyleSheet,
  TouchableOpacity,
  Platform,
} from 'react-native'
import HomeScreen from 'screens/HomeScreen'
import WorksheetScreen from 'screens/WorksheetScreen'
import WorksheetCompanyScreen from 'screens/WorkSheetCompanyScreen'
import PurchaseOrderScreen from 'screens/PurchaseOrderScreen'
import SettingsScreen from 'screens/SettingsScreen'
import { useLocalization } from 'contexts/LocalizationContext'
import { useMainTabState } from 'contexts/MainTabsContext'
import { tabsPathName } from './tabsPathName'
import HomeIcon from 'assets/images/icons/SquaresFour.png'
import ClipboardText from 'assets/images/icons/ClipboardText.png'
import GearSix from 'assets/images/icons/GearSix.png'
import Truck from 'assets/images/icons/Truck.png'
import Purchase from 'assets/images/icons/Purchase.png'
import ActivedHomeIcon from 'assets/images/icons/ActiveSquaresFour.png'
import ActiveClipboardText from 'assets/images/icons/ActiveClipboardText.png'
import ActiveGearSix from 'assets/images/icons/ActiveGearSix.png'
import ActiveTruck from 'assets/images/icons/ActiveTruck.png'
import ActivePurchase from 'assets/images/icons/ActivePurchase.png'
import { useAuth } from 'contexts/AuthContext'
import theme from 'theme/index'
import Text from 'components/Text'
import MachineryScreen from 'screens/MachineryScreen'

export type TabParamList = {
  Home: undefined
  Worksheet: undefined
  WorksheetCompany: undefined
  PurchaseOrder: undefined
  Machinery: undefined
  Settings: undefined
}

interface ButtonProps {
  props: BottomTabBarButtonProps
}

const Tab = createBottomTabNavigator<TabParamList>()
const TabButton = ({ props }: ButtonProps): JSX.Element => {
  const { t } = useLocalization()
  const isSelected = props.accessibilityState?.selected
  const pageName = props.to?.split('/')[2]

  let icon: ImageSourcePropType = {}
  let label = '.'
  switch (pageName) {
    case tabsPathName.Home:
      icon = isSelected ? ActivedHomeIcon : HomeIcon
      label = isSelected ? t('TabButtons.Home') : ''
      break
    case tabsPathName.Worksheet:
      icon = isSelected ? ActiveClipboardText : ClipboardText
      label = isSelected ? t('TabButtons.Worksheet') : ''
      break
    case tabsPathName.WorksheetCompany:
      icon = isSelected ? ActiveClipboardText : ClipboardText
      label = isSelected ? t('TabButtons.Worksheet') : ''
      break
    case tabsPathName.PurchaseOrder:
      icon = isSelected ? ActivePurchase : Purchase
      label = isSelected ? t('TabButtons.PurchaseOrder') : ''
      break
    case tabsPathName.Machinery:
      icon = isSelected ? ActiveTruck : Truck
      label = isSelected ? t('TabButtons.Machinery') : ''
      break
    case tabsPathName.Settings:
      icon = isSelected ? ActiveGearSix : GearSix
      label = isSelected ? t('TabButtons.Setting') : ''
      break
    default:
      break
  }
  const styles = StyleSheet.create({
    icon: {
      width: 26,
      height: 26,
    },

    containerButton: {
      backgroundColor: theme.color.primary1,
      borderRadius: 50,
      paddingVertical: 7,
      paddingHorizontal: 16,
    },
    containerNM: {
      borderRadius: 50,
      paddingVertical: 7,
      paddingHorizontal: 16,
      backgroundColor: 'transparent',
    },
  })
  const isMachine = 'Machinery' === pageName
  return (
    <TouchableOpacity {...props}>
      <View style={isSelected ? styles.containerButton : styles.containerNM}>
        <Image source={icon} style={styles.icon} />
      </View>
      <Text
        color={isSelected ? 'primary1' : 'white'}
        bold
        body1
        style={{
          marginTop: 4,
          width: isMachine ? 100 : 60,
          textAlign: 'center',
          minHeight: 30,
        }}
      >
        {label}
      </Text>
    </TouchableOpacity>
  )
}
const MainTab = (): JSX.Element => {
  const {
    state: { userRole },
  } = useAuth()

  const { t } = useLocalization()
  const { tabState, setTabState } = useMainTabState()
  return (
    <Tab.Navigator
      initialRouteName="Home"
      backBehavior="history"
      screenOptions={{
        headerShown: false,
        tabBarStyle: {
          height: Platform.OS === 'web' ? 105 : 105,

          flexDirection: 'row',
          width: '100%',
          justifyContent: Platform.OS === 'web' ? 'space-between' : 'center',
          alignItems: 'center',
        },
        tabBarLabelPosition: 'below-icon',
        tabBarButton: (props: BottomTabBarButtonProps) => {
          return <TabButton props={props} />
        },

        header: ({ navigation }) => {
          const currentState = navigation.getState().index

          if (currentState !== tabState) {
            setTabState(currentState)
          }
          return null
        },
      }}
    >
      <Tab.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: t('screens.HomeScreen.title', { defaultValue: 'Home' }),
        }}
      />
      {userRole === 'company' && (
        <Tab.Screen
          name="PurchaseOrder"
          component={PurchaseOrderScreen}
          options={{
            title: t('screens.PurchaseOrder.title', {
              defaultValue: 'PurchaseOrder',
            }),
          }}
        />
      )}
      {userRole === 'company' ? (
        <Tab.Screen
          name="WorksheetCompany"
          component={WorksheetCompanyScreen}
          options={{
            title: t('screens.Worksheet.title', {
              defaultValue: 'WorksheetCompany',
            }),
          }}
        />
      ) : (
        <Tab.Screen
          name="Worksheet"
          component={WorksheetScreen}
          options={{
            title: t('screens.Worksheet.title', { defaultValue: 'Worksheet' }),
          }}
        />
      )}

      <Tab.Screen
        name="Machinery"
        component={MachineryScreen}
        options={{
          title: t('screens.Worksheet.title', { defaultValue: 'Machinery' }),
        }}
      />
      <Tab.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          title: t('screens.SettingsScreen.title', {
            defaultValue: 'Settings',
          }),
        }}
      />
    </Tab.Navigator>
  )
}

export default MainTab
