/* eslint-disable @typescript-eslint/no-explicit-any */
import { Platform, StyleSheet, Image, View } from 'react-native'
import React, { useState } from 'react'
import { StackNavigationHelpers } from '@react-navigation/stack/lib/typescript/src/types'
import { Form, Input, SubmitButton } from 'components/Form'
import * as yup from 'yup'
import { useLocalization } from 'contexts/LocalizationContext'
import Address from 'components/Form/Address'
import DropDownForm from 'components/Form/DropDownForm'
import Text from 'components/Text'
import request from 'utils/request'
import { useAuth } from 'contexts/AuthContext'
import ImagePicker from 'components/Form/ImagePicker'
import Farmer from 'assets/images/Farmer.png'
import Company from 'assets/images/Company.png'
import Promoter from 'assets/images/Promoter.png'
import theme from 'theme/index'
import { getImage } from 'utils/getImage'
import { useDimension } from 'contexts/DimensionContext'
import InputDate from 'components/Form/InputDate'
import dayjs from 'dayjs'

type Props = {
  navigation: StackNavigationHelpers
  role: 'company' | 'farmer' | 'extensionist'
  setting: 'create' | 'edit'
  setPage: React.Dispatch<React.SetStateAction<number>>
  page: number
}
type FormData = {
  role: null
  profileImageUrl: null
  type: null
  title: null
  firstName: null
  lastName: null
  citizenId: null
  birthDate: null
  address: null
  subDistrict: null
  district: null
  province: null
  postcode: null
  phoneNumber: null
  farmerCode: null
  lineId: null
  unit: null
  companyName: null
  position: null
  corporationName: null
  taxId: null
  companyAddress: null
  companySubDistrict: null
  companyDistrict: null
  companyProvince: null
  companyPostcode: null
  companyPhoneNumber: null
}
export default function CreateProfileForm({
  navigation,
  role,
  setting,
  setPage,
  page,
}: Props): JSX.Element {
  const { t } = useLocalization()
  const {
    state: { user },
    authContext: { getMe },
  } = useAuth()
  const { width, height } = useDimension()
  const [valueProvince, setValueProvince] = useState<string>()
  const [valueDropdown, setValueDropdown] = useState<
    string | number | boolean | undefined | null
  >(user?.type)
  const defaultImage =
    role === 'farmer' ? Farmer : role === 'company' ? Company : Promoter
  const [image, setImage] = useState<string | null>(
    getImage(user?.profileImageUrl) || null
  )
  const schemaCompany = yup.object().shape({
    // type: yup.string().required('กรุณาเลือกประเภท').nullable(),
    title: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.title')),
    firstName: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.firstname')),
    lastName: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.lastname')),
    citizenId: yup
      .string()
      .nullable()
      .matches(/^\d+$/, t('screens.CreateProfileScreen.required.number'))
      .min(13, t('screens.CreateProfileScreen.required.maxLength'))
      .required(t('screens.CreateProfileScreen.required.citizenId')),
    // birthDate: yup
    //   .string()
    //   .nullable()
    //   .required(t('screens.CreateProfileScreen.required.birthDate')),
    address: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.address')),
    subDistrict: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.subDistrict')),
    district: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.district')),
    province: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.province')),
    postcode: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.postcode')),
    phoneNumber: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.tel')),
    lineId: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.lineId')),
    unit: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.unit')),
    companyName: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.companyName')),
    position: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.position')),
  })

  const schemaFarmer = yup.object().shape({
    type: yup.string().required('กรุณาเลือกประเภท').nullable(),
    corporationName: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.corporationName')),
    taxId: yup
      .string()
      .nullable()
      .matches(/^\d+$/, t('screens.CreateProfileScreen.required.number'))
      .min(13, t('screens.CreateProfileScreen.required.maxLength'))
      .required(t('screens.CreateProfileScreen.required.taxId')),
    companyAddress: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.address')),
    companySubDistrict: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.subDistrict')),
    companyDistrict: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.district')),
    companyProvince: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.province')),
    companyPostcode: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.postcode')),
    companyPhoneNumber: yup
      .string()
      .nullable()
      .required(t('screens.CreateProfileScreen.required.tel')),
  })

  const schemaIndividual = yup.object().shape({
    farmerCode: yup
      .string()
      .nullable()
      .matches(/^\d+$/, t('screens.CreateProfileScreen.required.number'))
      .min(12, t('screens.CreateProfileScreen.required.maxCode'))
      .required(t('screens.CreateProfileScreen.required.farmerCode')),
  })

  const schemaFarmerCompany = schemaFarmer.concat(schemaCompany)
  const schemaFarmerIndividual = schemaIndividual.concat(schemaCompany)

  const defaultValue = {
    role: role,
    profileImageUrl: user?.profileImageUrl,
    type: user?.type,
    title: user?.title,
    firstName: user?.firstName,
    lastName: user?.lastName,
    citizenId: user?.citizenId,
    birthDate: user?.birthDate,
    address: user?.address,
    subDistrict: user?.subDistrict,
    district: user?.district,
    province: user?.province,
    postcode: user?.postcode,
    phoneNumber: user?.phoneNumber,
    farmerCode: user?.farmerCode,
    lineId: user?.lineId,
    unit: user?.unit,
    companyName: user?.companyName,
    position: user?.position,
    corporationName: user?.corporationName,
    taxId: user?.taxId,
    companyAddress: user?.companyAddress,
    companySubDistrict: user?.companySubDistrict,
    companyDistrict: user?.companyDistrict,
    companyProvince: user?.companyProvince,
    companyPostcode: user?.companyPostcode,
    companyPhoneNumber: user?.companyPhoneNumber,
    email: user?.email,
  }

  const onSubmit = async (data: FormData) => {
    try {
      const res = await request.patch(`/user/me`, {
        ...data,
        birthDate: data.birthDate,
      })
      if (res) {
        getMe()
        if (setting === 'create') {
          navigation.replace('MainTab')
        } else {
          navigation.navigate('Settings')
        }
      }
    } catch (e: any) {
      console.log(JSON.stringify(e.response, null, 2))
    }
  }

  const onNext = () => {
    setPage((prev) => prev + 1)
  }

  const titleOption = [
    { label: 'นาย', value: 'mr' },
    { label: 'นาง', value: 'mrs' },
    { label: 'นางสาว', value: 'miss' },
  ]

  const typeOption = [
    { label: 'บุคคลธรรมดา', value: 'individual' },
    { label: 'นิติบุคคล', value: 'company' },
  ]
  // const unitOption = [
  //   { label: 'หน่วยงานรัฐ', value: 'government' },
  //   { label: 'รัฐวิสาหกิจ', value: 'state enterprise' },
  //   { label: 'เอกชน', value: 'private' },
  // ]

  return (
    <>
      {role === 'company' || role === 'extensionist' ? (
        <Form defaultValues={defaultValue} schema={schemaCompany}>
          <View style={styles.shadow}>
            <Image
              source={image ? { uri: image } : defaultImage}
              style={styles.image}
            />
            <View style={styles.button}>
              <ImagePicker setImage={setImage} name="profileImageUrl" />
            </View>
          </View>
          <View
            style={{
              width: width,
              marginTop: height / 8,
              paddingHorizontal: 16,
            }}
          >
            <DropDownForm
              name="title"
              label={t('screens.CreateProfileScreen.form.title')}
              data={titleOption}
              placeholder={defaultValue.title ? undefined : 'เลือกคำนำหน้าชื่อ'}
            />
            <Input
              name="firstName"
              label={t('screens.CreateProfileScreen.form.firstname')}
              style={styles.input}
            />
            <Input
              name="lastName"
              label={t('screens.CreateProfileScreen.form.lastname')}
              style={styles.input}
            />
            <Input
              name="citizenId"
              label={t('screens.CreateProfileScreen.form.citizenId')}
              style={styles.input}
              keyboardType="phone-pad"
              maxLength={13}
            />
            <InputDate
              name="birthDate"
              label={t('screens.CreateProfileScreen.form.birthDate')}
              testID="date"
              style={{ height: 42 }}
              defaultValue={defaultValue.birthDate}
            />
            <Input
              name="address"
              label={t('screens.CreateProfileScreen.form.address')}
              style={styles.input}
            />
            <Address
              name="subDistrict"
              keyName="district"
              label={t('Address.district')}
              style={styles.input}
              province={valueProvince}
            />
            <Address
              name="district"
              keyName="amphoe"
              label={t('Address.amphoe')}
              style={styles.input}
            />
            <Address
              name="province"
              keyName="province"
              label={t('Address.province')}
              style={styles.input}
              setProvince={setValueProvince}
            />
            <Input
              name="postcode"
              label={t('screens.CreateProfileScreen.form.postcode')}
              style={styles.input}
              keyboardType="phone-pad"
              maxLength={5}
            />
            <Input
              name="phoneNumber"
              label={t('screens.CreateProfileScreen.form.tel')}
              style={styles.input}
              maxLength={10}
              keyboardType="phone-pad"
            />
            <Input
              name="email"
              label={t('screens.CreateProfileScreen.form.email')}
              style={styles.input}
              editable
            />
            <Input
              name="lineId"
              label={t('screens.CreateProfileScreen.form.lineId')}
              style={styles.input}
            />
            <Input
              name="unit"
              label={t('screens.CreateProfileScreen.form.unit')}
              style={styles.input}
            />
            <Input
              name="companyName"
              label={t('screens.CreateProfileScreen.form.companyName')}
              style={styles.input}
            />
            <Input
              name="position"
              label={t('screens.CreateProfileScreen.form.position')}
              style={styles.input}
            />
            <View style={{ marginTop: 56, marginBottom: 24 }}>
              <SubmitButton
                testID="form.createCompany"
                title={
                  setting === 'create'
                    ? t('screens.CreateProfileScreen.form.submit')
                    : t('screens.CreateProfileScreen.form.edit')
                }
                onSubmit={onSubmit}
              />
            </View>
          </View>
        </Form>
      ) : (
        <Form
          defaultValues={defaultValue}
          schema={
            valueDropdown === 'company'
              ? page === 1
                ? schemaFarmer
                : schemaFarmerCompany
              : schemaFarmerIndividual
          }
        >
          <View style={styles.shadow}>
            <Image
              source={image ? { uri: image } : defaultImage}
              style={styles.image}
            />
            <View style={styles.button}>
              <ImagePicker setImage={setImage} name="profileImageUrl" />
            </View>
          </View>
          <View
            style={{
              width: width,
              marginTop: height / 8,
              paddingHorizontal: 16,
            }}
          >
            <DropDownForm
              name="type"
              label={t('screens.CreateProfileScreen.form.type')}
              data={typeOption}
              setValueDropdown={setValueDropdown}
              itemStyle={{
                display: page === 1 ? 'flex' : 'none',
                opacity: page === 1 ? 1 : 0,
              }}
              placeholder={defaultValue.type ? undefined : 'เลือกประเภท'}
            />
            {valueDropdown === 'company' ? (
              <>
                <View
                  style={{
                    height: page === 1 ? '100%' : 0,
                    opacity: page === 1 ? 1 : 0,
                  }}
                >
                  <Input
                    name="corporationName"
                    label={t(
                      'screens.CreateProfileScreen.form.corporationName'
                    )}
                    style={styles.input}
                  />
                  <Input
                    name="taxId"
                    label={t('screens.CreateProfileScreen.form.taxId')}
                    style={styles.input}
                    maxLength={13}
                  />
                  <Input
                    name="companyAddress"
                    label={t('screens.CreateProfileScreen.form.companyAddress')}
                    style={styles.input}
                  />
                  <Address
                    name="companySubDistrict"
                    keyName="district"
                    label={t('Address.district')}
                    style={styles.input}
                    province={valueProvince}
                  />
                  <Address
                    name="companyDistrict"
                    keyName="amphoe"
                    label={t('Address.amphoe')}
                    style={styles.input}
                  />
                  <Address
                    name="companyProvince"
                    keyName="province"
                    label={t('Address.province')}
                    style={styles.input}
                    setProvince={setValueProvince}
                  />
                  <Input
                    name="companyPostcode"
                    label={t('screens.CreateProfileScreen.form.postcode')}
                    style={styles.input}
                    keyboardType="phone-pad"
                    maxLength={5}
                  />
                  <Input
                    name="companyPhoneNumber"
                    label={t('screens.CreateProfileScreen.form.tel')}
                    style={styles.input}
                    maxLength={10}
                    keyboardType="phone-pad"
                  />
                  <Input
                    name="email"
                    label={t('screens.CreateProfileScreen.form.email')}
                    style={styles.input}
                    editable
                  />
                  <View style={{ marginTop: 36, marginBottom: 24 }}>
                    <SubmitButton
                      testID="form.createProfile"
                      title={t('screens.CreateProfileScreen.form.next')}
                      onSubmit={onNext}
                    />
                  </View>
                </View>
                <View
                  style={{
                    height: page === 2 ? '100%' : 0,
                    opacity: page === 2 ? 1 : 0,
                  }}
                >
                  <Text sub1 semiBold style={{ marginBottom: 10 }}>
                    ข้อมูลผู้ติดต่อ
                  </Text>
                  <DropDownForm
                    name="title"
                    label={t('screens.CreateProfileScreen.form.title')}
                    data={titleOption}
                    placeholder={
                      defaultValue.title ? undefined : 'เลือกคำนำหน้าชื่อ'
                    }
                  />
                  <Input
                    name="firstName"
                    label={t('screens.CreateProfileScreen.form.firstname')}
                    style={styles.input}
                  />
                  <Input
                    name="lastName"
                    label={t('screens.CreateProfileScreen.form.lastname')}
                    style={styles.input}
                  />
                  <Input
                    name="citizenId"
                    label={t('screens.CreateProfileScreen.form.citizenId')}
                    style={styles.input}
                    maxLength={13}
                  />
                  <InputDate
                    name="birthDate"
                    label={t('screens.CreateProfileScreen.form.birthDate')}
                    testID="date"
                    style={{ height: 42 }}
                    defaultValue={defaultValue.birthDate}
                  />
                  <Input
                    name="address"
                    label={t('screens.CreateProfileScreen.form.address')}
                    style={styles.input}
                  />
                  <Address
                    name="subDistrict"
                    keyName="district"
                    label={t('Address.district')}
                    style={styles.input}
                    province={valueProvince}
                  />
                  <Address
                    name="district"
                    keyName="amphoe"
                    label={t('Address.amphoe')}
                    style={styles.input}
                  />
                  <Address
                    name="province"
                    keyName="province"
                    label={t('Address.province')}
                    style={styles.input}
                    setProvince={setValueProvince}
                  />
                  <Input
                    name="postcode"
                    label={t('screens.CreateProfileScreen.form.postcode')}
                    style={styles.input}
                    keyboardType="phone-pad"
                    maxLength={5}
                  />
                  <Input
                    name="phoneNumber"
                    label={t('screens.CreateProfileScreen.form.tel')}
                    style={styles.input}
                    maxLength={10}
                    keyboardType="phone-pad"
                  />
                  <Input
                    name="email"
                    label={t('screens.CreateProfileScreen.form.email')}
                    style={styles.input}
                    editable
                  />
                  <Input
                    name="lineId"
                    label={t('screens.CreateProfileScreen.form.lineId')}
                    style={styles.input}
                  />
                  <Input
                    name="unit"
                    label={t('screens.CreateProfileScreen.form.unit')}
                    style={styles.input}
                  />
                  <Input
                    name="companyName"
                    label={t('screens.CreateProfileScreen.form.companyName')}
                    style={styles.input}
                  />
                  <Input
                    name="position"
                    label={t('screens.CreateProfileScreen.form.position')}
                    style={styles.input}
                  />
                  <View style={{ marginTop: 36, marginBottom: 24 }}>
                    <SubmitButton
                      testID="form.createProfile"
                      title={
                        setting === 'create'
                          ? t('screens.CreateProfileScreen.form.submit')
                          : t('screens.CreateProfileScreen.form.edit')
                      }
                      onSubmit={onSubmit}
                    />
                  </View>
                </View>
              </>
            ) : (
              <>
                <DropDownForm
                  name="title"
                  label={t('screens.CreateProfileScreen.form.title')}
                  data={titleOption}
                  placeholder={
                    defaultValue.title ? undefined : 'เลือกคำนำหน้าชื่อ'
                  }
                />
                <Input
                  name="firstName"
                  label={t('screens.CreateProfileScreen.form.firstname')}
                  style={styles.input}
                />
                <Input
                  name="lastName"
                  label={t('screens.CreateProfileScreen.form.lastname')}
                  style={styles.input}
                />
                <Input
                  name="citizenId"
                  label={t('screens.CreateProfileScreen.form.citizenId')}
                  style={styles.input}
                  maxLength={13}
                />
                <InputDate
                  name="birthDate"
                  label={t('screens.CreateProfileScreen.form.birthDate')}
                  testID="date"
                  style={{ height: 42 }}
                  iconLeft={false}
                  defaultValue={defaultValue.birthDate}
                />
                <Input
                  name="address"
                  label={t('screens.CreateProfileScreen.form.address')}
                  style={styles.input}
                />
                <Address
                  name="subDistrict"
                  keyName="district"
                  label={t('Address.district')}
                  style={styles.input}
                  province={valueProvince}
                />
                <Address
                  name="district"
                  keyName="amphoe"
                  label={t('Address.amphoe')}
                  style={styles.input}
                />
                <Address
                  name="province"
                  keyName="province"
                  label={t('Address.province')}
                  style={styles.input}
                  setProvince={setValueProvince}
                />
                <Input
                  name="postcode"
                  label={t('screens.CreateProfileScreen.form.postcode')}
                  style={styles.input}
                  keyboardType="phone-pad"
                  maxLength={5}
                />
                <Input
                  name="phoneNumber"
                  label={t('screens.CreateProfileScreen.form.tel')}
                  style={styles.input}
                  maxLength={10}
                  keyboardType="phone-pad"
                />
                <Input
                  name="email"
                  label={t('screens.CreateProfileScreen.form.email')}
                  style={styles.input}
                  editable
                />
                <Input
                  name="farmerCode"
                  label={t('screens.CreateProfileScreen.form.farmerCode')}
                  style={styles.input}
                  keyboardType="phone-pad"
                  maxLength={12}
                />
                <Input
                  name="lineId"
                  label={t('screens.CreateProfileScreen.form.lineId')}
                  style={styles.input}
                />
                <Input
                  name="unit"
                  label={t('screens.CreateProfileScreen.form.unit')}
                  style={styles.input}
                />
                <Input
                  name="companyName"
                  label={t('screens.CreateProfileScreen.form.companyName')}
                  style={styles.input}
                />
                <Input
                  name="position"
                  label={t('screens.CreateProfileScreen.form.position')}
                  style={styles.input}
                />
                <View style={{ marginTop: 36, marginBottom: 24 }}>
                  <SubmitButton
                    testID="form.createProfile"
                    title={
                      setting === 'create'
                        ? t('screens.CreateProfileScreen.form.submit')
                        : t('screens.CreateProfileScreen.form.edit')
                    }
                    onSubmit={onSubmit}
                  />
                </View>
              </>
            )}
          </View>
        </Form>
      )}
    </>
  )
}
const styles = StyleSheet.create({
  input: {
    ...Platform.select({
      web: {
        paddingVertical: 6,
        height: 40,
      },
      default: {
        paddingVertical: 6,
      },
    }),
  },
  bgWhite: {
    paddingHorizontal: 16,
    backgroundColor: 'white',
    alignSelf: 'center',

    alignItems: 'center',
  },
  image: {
    height: 160,
    width: 160,
    borderRadius: 160 / 2,
  },
  shadow: {
    alignSelf: 'center',
    position: 'absolute',
    top: -80,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
    width: 162,
    height: 162,
    borderRadius: 160 / 2,
  },
  icon: {
    width: 36,
    height: 36,
  },
  button: {
    width: 50,
    height: 50,
    borderRadius: 50 / 2,
    backgroundColor: theme.color.primary1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 8,
    bottom: 0,
  },
})
