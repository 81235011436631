import React from 'react'
import Content from 'components/Content'
import { ScrollView, View } from 'react-native'
import Text from 'components/Text'
import theme from 'theme/index'
import Card from 'components/Card'
import TokenExpired from 'components/TokenExpired'

interface Props {
  dataAssignment: {
    assignmentId: string
    refId: string
    projectId: string
  }
  isLoading?: boolean
  FooterComponent: React.ReactNode
}
const StationFirstStep = ({
  dataAssignment,
  FooterComponent,
  isLoading = false,
}: Props) => {
  const formatData = [
    {
      label: 'เลขอ้างอิง:',
      value: dataAssignment?.refId || '1234567890',
    },
    {
      label: 'เลขโปรเจค:',
      value: dataAssignment?.projectId || '1234qee',
    },
    {
      label: 'เลขใบงาน:',
      value: dataAssignment?.assignmentId || '56448',
    },
  ]

  return (
    <Content noPadding enabledScroll={false}>
      <Card
        borderRadiusTop
        width="100%"
        style={{ height: '100%', flex: 1, paddingTop: 32 }}
      >
        {isLoading ? (
          <TokenExpired />
        ) : (
          <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
            <View style={{ justifyContent: 'space-between', flex: 1 }}>
              <View>
                {formatData.map((item, index) => {
                  return (
                    <View
                      key={index}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: 8,
                        justifyContent: 'space-between',
                      }}
                    >
                      <Text sub1 style={{ flex: 0.25 }}>
                        {item.label}
                      </Text>
                      <View
                        style={{
                          backgroundColor: theme.color.black6,
                          borderRadius: 5,
                          flex: 0.8,
                          paddingHorizontal: 8,
                        }}
                      >
                        <Text sub1 color="black85">
                          {item.value}
                        </Text>
                      </View>
                    </View>
                  )
                })}
              </View>
              <View>{FooterComponent}</View>
            </View>
          </ScrollView>
        )}
      </Card>
    </Content>
  )
}

export default StationFirstStep
