import React from 'react'
import { TextInputProps, View } from 'react-native'
import styled from '@emotion/native'
import { useFormContext, Controller } from 'react-hook-form'
import Text from 'components/Text'

import { Item, Label, ErrorMessage } from './components'
import { TouchableOpacity } from 'react-native-gesture-handler'
import theme from 'theme/index'
interface Props extends TextInputProps {
  name: string
  label?: string
  testID?: string
  itemStyle?: {
    [key: string]: unknown
  }
  option: {
    [key: string]: any
  }
  errorInside?: boolean
  bold?: boolean
  semiBold?: boolean
  defaultValue?: string
}

interface ErrorBoxProps {
  errorInside: boolean
}

const ErrorBox = styled.View<ErrorBoxProps>`
  position: ${(props) => props.errorInside && 'absolute'};
  bottom: ${(props) => props.errorInside && '5px'};
  padding: ${(props) => props.errorInside && '8px'};
`

export const Select = ({
  name,
  label,
  testID,
  itemStyle = {},
  errorInside = false,
  bold,
  semiBold,
  option,
  defaultValue,
}: Props): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const [selectItem, setSelectItem] = React.useState<string | null>(
    defaultValue || null
  )

  return (
    <Item style={{ ...itemStyle }}>
      <Label
        input
        color={'black'}
        bold={bold ? true : false}
        semiBold={semiBold ? true : false}
      >
        {label}
      </Label>
      <Controller
        control={control}
        render={({ field: { onChange } }) => (
          <>
            <Item>
              <View
                style={{
                  borderWidth: 1,
                  borderColor: theme.input.borderColor,
                  borderRadius: 5,
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  marginTop: 10,
                }}
              >
                {option.map(
                  (item: { title: string; value: string }, index: number) => {
                    return (
                      <View
                        testID={testID}
                        key={index}
                        style={{
                          flex: 1 / option.length,
                          borderRightWidth: index === option.length - 1 ? 0 : 1,
                          borderColor: '#A2A2A2',
                          marginVertical: 10,
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            alignItems: 'center',
                            padding: 5,
                          }}
                          onPress={() => {
                            setSelectItem(item.value)
                            onChange(item.value)
                          }}
                        >
                          <Text
                            sub1
                            bold={selectItem === item.value ? true : false}
                            color={
                              selectItem === item.value ? 'selected' : 'black65'
                            }
                          >
                            {item.title}
                          </Text>
                        </TouchableOpacity>
                      </View>
                    )
                  }
                )}
              </View>
            </Item>
            {errors[name] && (
              <ErrorBox errorInside={errorInside}>
                <ErrorMessage error>{errors[name].message}</ErrorMessage>
              </ErrorBox>
            )}
          </>
        )}
        name={name}
      />
    </Item>
  )
}
